import { SusProcedure } from "./susProcedure";

export interface SusProcedureGroup {
    id?: number;
    code?: number;
    description?: string;
    parent?: SusProcedureGroup;
    children?: SusProcedureGroup[];
    amount?: number;
    selected?: boolean;
}


// Api Response
export enum SusApiResponseType {
    SUS_PROCEDURE = 'SUS_PROCEDURE',
    SUS_GROUP = 'SUS_GROUP'
}
export interface SusGroupApiResponse {
    susGroups?: SusProcedureGroup[];
    susProcedures?: SusProcedure[];
    type: SusApiResponseType;
}
