import { AxiosResponse } from 'axios';
import MedicalScoreApi from '../api/medical-score.api';
import HttpStatus from '../model/enums/httpStatus';
import { MedicalScore, MedicalScoreSimple } from '../model/medical-score/medical-score';
import { PageableResponse } from '../model/pageable';

export const MedicalScoreService = () => {
    const getInfo = async (id: number): Promise<MedicalScore> => {
        try {
            const result: AxiosResponse<MedicalScore> = await MedicalScoreApi.getInfo(id);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const getAvailableForFree = async (): Promise<MedicalScore[]> => {
        try {
            const result: AxiosResponse<PageableResponse<MedicalScore>> = await MedicalScoreApi.getAvailableForFreeScores();
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data.content);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const search = async (text?: string): Promise<MedicalScoreSimple[]> => {
        try {
            const result: AxiosResponse<PageableResponse<MedicalScoreSimple>> = await MedicalScoreApi.search(text);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data.content);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    return {
        getInfo,
        getAvailableForFree,
        search
    };
};

export default MedicalScoreService();
