import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Note, NoteType } from '../../model/note';
import NotesService from '../../services/notesService';
import './annotations.scss';

interface Props {
    note?: Note;
    itemId: number;
    noteType: NoteType;
    saveCallback: (note: Note) => void;
}

const Annotations = (props: Props) => {
    const [isSaved, setIsSaved] = useState<boolean | undefined>();
    const [isSaving, setIsSaving] = useState<boolean | undefined>();
    const [hasNewChange, setHasNewChange] = useState<boolean | undefined>();
    const [textValue, setTextValue] = useState<string>(props.note ? props.note.content : '');
    const [latestSavedValues, setLatestSavedValues] = useState<string[]>([props.note ? props.note.content : '']);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            save(textValue);
        }, 2000);

        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [textValue]);

    // handlers
    const save = async (value: string): Promise<void> => {
        const { noteType, itemId } = props;
        const item = { id: itemId };
        const note: Note = {
            pediatricDose: noteType === NoteType.PEDIATRIC_DOSE ? item : null,
            medicineLeaflet: noteType === NoteType.MEDICINE_LEAFLET ? item : undefined,
            medicalScore: noteType === NoteType.MEDICAL_SCORE ? item : undefined,
            medicalCalculator: noteType === NoteType.MEDICAL_CALCULATOR ? item : undefined,
            medicalCriteria: noteType === NoteType.MEDICAL_CRITERIA ? item : undefined,
            disease: noteType === NoteType.DISEASE ? item : undefined,
            semiology: noteType === NoteType.SEMIOLOGY ? item : undefined,
            laboratory: noteType === NoteType.LABORATORY ? item : undefined,
            type: noteType,
            content: value,
            isActive: value != null && value !== ''
        };
        props.saveCallback(note);
        setTimeout(() => setIsSaving(false), 15000);
        NotesService.save(note);
        setIsSaved(true);
        setIsSaving(false);
        setHasNewChange(true);
        setLatestSavedValues(latestSavedValues.concat(value));
        clearTimeout(0);
    };
    // const debounceSave = _.debounce(save, 2000);

    const onChangeAnnotationText = event => {
        const { value } = event.target;
        setIsSaved(false);
        setIsSaving(true);
        setTextValue(value);
        // debounceSave(value);
    };

    const onUndo = (): void => {
        if (latestSavedValues.length > 1) {
            const latestSavedValue: string = latestSavedValues[latestSavedValues.length - 2];
            setTextValue(latestSavedValue);
            setLatestSavedValues([]);
            save(latestSavedValue);
        }
    };

    // renders
    return (
        <div className="annotations">
            <div className="annotations__menu">
                {hasNewChange && (
                    <div className="annotations__undo" onClick={onUndo}>
                        <div className="annotations__undo__icon" />
                        <span className="annotations__undo__text">{'Desfazer'}</span>
                    </div>
                )}
                <div className="annotations__saving">
                    {isSaving && <div id={'saving-icon'} className="annotations__saving__icon" />}
                    {isSaving && <span className="annotations__saving__text">{'Salvando'}</span>}
                    {isSaved && <span className="annotations__saving__text">{'Salvo'}</span>}
                </div>
            </div>
            <textarea
                placeholder={'Faça aqui suas anotações'}
                className="annotations__text"
                onChange={onChangeAnnotationText}
                value={textValue}
            />
        </div>
    );
};

export default Annotations;
