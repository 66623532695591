import { AxiosResponse } from 'axios';
import { Customer } from '../model/customer';
import { Pageable, PageableResponse } from '../model/pageable';
import { api } from './api';
import { CompanyDashboardData } from '../model/company-dashboard-data';

const CompanyUserApi = () => {
    const getCompanyDashboardData = (): Promise<AxiosResponse<CompanyDashboardData>> => {
        return api.get<CompanyDashboardData>(`/companies/dashboard`);
    };

    const getCompanyUsers = (searchText: string, page: Pageable): Promise<AxiosResponse<PageableResponse<Customer>>> => {
        return api.get<PageableResponse<Customer>>(`/companies/user?name=${searchText}&size=${page.size}&page=${page.number}`);
    };

    const saveCompanyUser = (customer: Customer): Promise<AxiosResponse<void>> => {
        if (customer.id != null) {
            return api.put<void>(`/companies/user`, customer);
        } else {
            return api.post<void>(`/companies/user`, customer);
        }
    };

    const deleteCustomer = (customer: Customer): Promise<AxiosResponse<void>> => {
        return api.delete<void>(`/companies/user?id=${customer.id}`);
    };

    return {
        getCompanyDashboardData,
        getCompanyUsers,
        saveCompanyUser,
        deleteCustomer
    };
};

export default CompanyUserApi();
