import React from 'react';
import './laboratory.scss';

export interface CardItemContent {
    icon?: any;
    title: string;
    isPaid?: boolean;
}

interface Props {
    item?: CardItemContent;
}

const LaboratoryCardItem = (props: Props) => {
    const { item } = props;

    return item != null ? (
        <div className="laboratory-card-content">
            <div className="laboratory-card-content__left">
                {item.icon && <img src={`data:image/png;base64, ${item.icon}`} className="laboratory-card-content__icon" style={{}} />}
                <span className="laboratory-card-content__title">{item.title}</span>
            </div>
            {item.isPaid ? (
                <div className="lock-img" />
            ) : (
                <div className="arrow-right-img" />
            )}
        </div>
    ) : <div />;
}

export default LaboratoryCardItem;
