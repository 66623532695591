import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import AuthUtil from '../../../api/authUtil';
import Button from '../../../components/button/button';
import SelectFieldMeds from '../../../components/select-field-meds/select-field-meds';
import ShowDataInput from '../../../components/show-data-input/show-data-input';
import TextFieldMeds from '../../../components/text-field-meds/text-field-meds';
import { User } from '../../../model/user';
import { IRootState } from '../../../reducer';
import { getSessionRequest } from '../../../reducer/authentication/actions';
import CustomerService from '../../../services/customerService';
import { STATES } from '../../../shared/util/states';
import './components.scss';

export interface ProfileProps extends RouteComponentProps<{}> {
    t: (path: string, param?: any) => string;
    history: any;
    user?: User;
    getSession: () => void;
}

export interface ProfileState {
    user?: User;
    isEditing?: boolean;
    hasError?: boolean;
    showSuccessMessage?: boolean;
}

export class Profile extends React.Component<ProfileProps, ProfileState> {
    constructor(props: Readonly<ProfileProps>, context?: any) {
        super(props, context);

        this.state = {};
    }
    // Component Life Cycle
    componentDidMount(): void {
        const { user } = this.props;
        if (user) {
            this.setState({
                user
            });
        } else {
            this.props.getSession();
        }
    }
    componentWillReceiveProps(newProps: ProfileProps): void {
        const { user } = newProps;
        if (user != null && user !== this.state.user) {
            this.setState({
                user
            });
        }
    }
    // Handlers
    private onEditPress = (): void => {
        this.setState({ isEditing: true });
    };
    private onSavePress = async (): Promise<void> => {
        if (this.state.user == null) {
            return;
        }
        const response: User = await CustomerService.save(this.state.user);
        if (response) {
            if (response.token) {
                AuthUtil.setToken(response.token);
            }
            this.props.getSession();
            this.setState({ isEditing: false, showSuccessMessage: true }, () => {
                setTimeout(() => this.onCloseSuccessPopup(), 3000);
            });
        }
    };
    private onChangeField = (value: string, key: string): void => {
        this.setState({
            user: {
                ...this.state.user,
                [key]: value,
                customer: {
                    [key]: value
                }
            }
        });
    };
    private onChangeAddressField = (value: string, key: string): void => {
        this.setState({
            user: {
                ...this.state.user,
                customer: {
                    ...this.state.user!.customer!,
                    address: {
                        ...this.state.user!.customer!.address,
                        customer: {id: this.state.user!.customer!.id},
                        [key]: value,
                        
                    }
                }
            }
        });
    };
    private onSetError = (value: boolean) => {
        this.setState({
            hasError: value
        });
    };
    private onCloseSuccessPopup = () => {
        this.setState({ showSuccessMessage: false });
    };

    // Renders
    render() {
        const { isEditing, user, showSuccessMessage } = this.state;
        if (user == null || user.customer == null) {
            return <div />;
        }
        const name = user.customer.name || user.name;
        const email = user.customer.email || user.email;
        const { address } = user.customer;
        return (
            <div className="component">
                <div className="profile-title-row">
                    <span className="component__title">{this.props.t('preferences.profileComponent.title')}</span>
                    {showSuccessMessage && (
                        <div className="profile-success-popup">
                            <span className="profile-success-popup__text">
                                {this.props.t('preferences.profileComponent.successMessage')}
                            </span>
                            <a className="profile-success-popup__close-icon" onClick={this.onCloseSuccessPopup} />
                        </div>
                    )}
                </div>
                <div className="profile-content">
                    {isEditing ? (
                        <div className="profile-inputs">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <TextFieldMeds
                                    onChange={(value: string) => this.onChangeField(value, 'name')}
                                    id="name"
                                    value={name}
                                    onError={this.onSetError}
                                    errorText={this.props.t('preferences.profileComponent.nameError')}
                                    label={this.props.t('preferences.profileComponent.namePlaceholder')}
                                    placeholder={this.props.t('preferences.profileComponent.name')}
                                    style={{ marginRight: 25, width: '95%' }}
                                />
                                <TextFieldMeds
                                    onChange={(value: string) => this.onChangeField(value, 'email')}
                                    id="email"
                                    value={email}
                                    onError={this.onSetError}
                                    errorText={this.props.t('preferences.profileComponent.emailError')}
                                    label={this.props.t('preferences.profileComponent.emailPlaceholder')}
                                    placeholder={this.props.t('preferences.profileComponent.email')}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
                                <TextFieldMeds
                                    onChange={(value: string) => this.onChangeAddressField(value, 'street')}
                                    id="street"
                                    value={address ? address.street : undefined}
                                    onError={this.onSetError}
                                    errorText={this.props.t('preferences.profileComponent.addressError')}
                                    label={this.props.t('preferences.profileComponent.addressPlaceholder')}
                                    placeholder={this.props.t('preferences.profileComponent.address')}
                                    style={{ marginRight: 25, width: '95%' }}
                                />
                                <TextFieldMeds
                                    onChange={(value: string) => this.onChangeAddressField(value, 'streetNumber')}
                                    id="number"
                                    value={address ? address.streetNumber : undefined}
                                    onError={this.onSetError}
                                    errorText={this.props.t('preferences.profileComponent.numberError')}
                                    label={this.props.t('preferences.profileComponent.numberPlaceholder')}
                                    placeholder={this.props.t('preferences.profileComponent.number')}
                                    style={{ width: '30%' }}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
                                <TextFieldMeds
                                    onChange={(value: string) => this.onChangeAddressField(value, 'complement')}
                                    id="complement"
                                    value={address ? address.complement : undefined}
                                    onError={this.onSetError}
                                    errorText={this.props.t('preferences.profileComponent.complementError')}
                                    label={this.props.t('preferences.profileComponent.complementPlaceholder')}
                                    placeholder={this.props.t('preferences.profileComponent.complement')}
                                    style={{ marginRight: 25, width: '95%' }}
                                />
                                <TextFieldMeds
                                    onChange={(value: string) => this.onChangeAddressField(value, 'district')}
                                    id="neighborhood"
                                    value={address ? address.district : undefined}
                                    onError={this.onSetError}
                                    errorText={this.props.t('preferences.profileComponent.neighborhoodError')}
                                    label={this.props.t('preferences.profileComponent.neighborhoodPlaceholder')}
                                    placeholder={this.props.t('preferences.profileComponent.neighborhood')}
                                />
                            </div>
                            <TextFieldMeds
                                onChange={(value: string) => this.onChangeAddressField(value, 'city')}
                                id="city"
                                value={address ? address.city : undefined}
                                onError={this.onSetError}
                                errorText={this.props.t('preferences.profileComponent.cityError')}
                                label={this.props.t('preferences.profileComponent.cityPlaceholder')}
                                placeholder={this.props.t('preferences.profileComponent.city')}
                                style={{ marginTop: 25 }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
                                <SelectFieldMeds
                                    dataArray={STATES.map(it => ({ label: it, value: it }))}
                                    id="state"
                                    onChange={(value: string) => this.onChangeAddressField(value, 'state')}
                                    placeholder={this.props.t('preferences.profileComponent.statePlaceholder')}
                                    selected
                                    style={{ marginRight: 25, width: '95%' }}
                                    initialValue={address ? address.state : undefined}
                                />
                                <TextFieldMeds
                                    onChange={(value: string) => this.onChangeAddressField(value, 'zipCode')}
                                    id="zipCode"
                                    value={address ? address.zipCode : undefined}
                                    onError={this.onSetError}
                                    errorText={this.props.t('preferences.profileComponent.zipcodeError')}
                                    label={this.props.t('preferences.profileComponent.zipcodePlaceholder')}
                                    placeholder={this.props.t('preferences.profileComponent.zipcode')}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="profile-inputs">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <ShowDataInput
                                    title={this.props.t('preferences.profileComponent.name')}
                                    value={name}
                                    style={{ marginRight: 28 }}
                                />
                                <ShowDataInput title={this.props.t('preferences.profileComponent.email')} value={email} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <ShowDataInput
                                    title={this.props.t('preferences.profileComponent.address')}
                                    value={address ? address.street : undefined}
                                    style={{ marginRight: 25, width: '95%' }}
                                />
                                <ShowDataInput
                                    title={this.props.t('preferences.profileComponent.number')}
                                    value={address ? address.streetNumber : undefined}
                                    style={{ width: '30%' }}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <ShowDataInput
                                    title={this.props.t('preferences.profileComponent.complement')}
                                    value={address ? address.complement : undefined}
                                    style={{ marginRight: 28 }}
                                />
                                <ShowDataInput
                                    title={this.props.t('preferences.profileComponent.neighborhood')}
                                    value={address ? address.district : undefined}
                                />
                            </div>
                            <ShowDataInput
                                title={this.props.t('preferences.profileComponent.city')}
                                value={address ? address.city : undefined}
                            />
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <ShowDataInput
                                    title={this.props.t('preferences.profileComponent.state')}
                                    value={address ? address.state : undefined}
                                    style={{ marginRight: 28 }}
                                />
                                <ShowDataInput
                                    title={this.props.t('preferences.profileComponent.zipcode')}
                                    value={address ? address.zipCode : undefined}
                                />
                            </div>
                        </div>
                    )}
                    <div className="profile__button-container">
                        {isEditing ? (
                            <Button
                                label={this.props.t('preferences.profileComponent.save')}
                                onPress={this.onSavePress}
                                style={{ width: 191, height: 51, marginTop: 36 }}
                            />
                        ) : (
                            <a className="edit-button" onClick={this.onEditPress}>
                                <span className="edit-button__label">{this.props.t('preferences.profileComponent.edit')}</span>
                            </a>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState): Pick<ProfileProps, 'user'> => ({
    user: authentication.account
});

const mapDispatchToProps = {
    getSession: getSessionRequest
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Profile) as React.ComponentType<any>;
