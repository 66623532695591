import _ from 'lodash';
import { WemedsReference } from '../../features/disease/disease-tab-content';

export enum WemedsLink {
    MEDICINE_LEAFLET = 'MEDICINE_LEAFLET',
    DISEASE = 'DISEASE',
    PEDIATRIC_DOSES = 'PEDIATRIC_DOSES',
    MEDICAL_CALCULATORS = 'MEDICAL_CALCULATORS',
    ILLUSTRADED_PROCEDURES = 'ILLUSTRADED_PROCEDURES',
    LABORATORIES = 'LABORATORIES',
    CRITERIA = 'CRITERIA',
    SCORES = 'SCORES',
    MICRO = 'MICRO',
    NONE = 'NONE'
}

const WemedsLinkUtil = () => {
    const replaceLinkRegex = /(MEDICINE_LEAFLET|DISEASE|PEDIATRIC_DOSES|MEDICAL_CALCULATORS|ILLUSTRADED_PROCEDURES|LABORATORIES|CRITERIA|SCORES|MICRO)/gi;
    const isContentRegex = /(DISEASE)/gi;
    const isMedicineLeafletRegex = /(MEDICINE_LEAFLET)/gi;
    const isPediatricDoseRegex = /(PEDIATRIC_DOSES)/gi;

    const getScreenRoute = (name?: string): string => {
        switch (name) {
            case 'MEDICINE_LEAFLET':
                return 'smart-bulas';
            case 'DISEASE':
                return 'disease';
            case 'PEDIATRIC_DOSES':
                return 'pediatric-dose';
            case 'MEDICAL_CALCULATORS':
                return 'calculator';
            case 'ILLUSTRADED_PROCEDURES':
                return 'procedure';
            case 'CRITERIA':
                return 'criteria';
            case 'LABORATORIES':
                return 'laboratory';
            case 'SCORES':
                return 'scores';
            default:
                return '';
        }
    };

    const getEnumByName = (name: String): WemedsLink => {
        switch (name) {
            case 'MEDICINE_LEAFLET':
                return WemedsLink.MEDICINE_LEAFLET;
            case 'DISEASE':
                return WemedsLink.DISEASE;
            case 'PEDIATRIC_DOSES':
                return WemedsLink.PEDIATRIC_DOSES;
            case 'MEDICAL_CALCULATORS':
                return WemedsLink.MEDICAL_CALCULATORS;
            case 'ILLUSTRADED_PROCEDURES':
                return WemedsLink.ILLUSTRADED_PROCEDURES;
            case 'LABORATORIES':
                return WemedsLink.LABORATORIES;
            case 'CRITERIA':
                return WemedsLink.CRITERIA;
            case 'SCORES':
                return WemedsLink.SCORES;
            case 'MICRO':
                return WemedsLink.MICRO;
            default:
                return WemedsLink.MEDICINE_LEAFLET;
        }
    };

    const isContent = (link: string): boolean => {
        return isContentRegex.test(link);
    };

    const isMedicineLeaflet = (link: string): boolean => {
        return isMedicineLeafletRegex.test(link);
    };

    const isPediatricDose = (link: string): boolean => {
        return isPediatricDoseRegex.test(link);
    };

    const replaceEnumNameByLink = (link: string): string => {
        const name = link.split('/')[1];
        const newLink = getScreenRoute(name);
        link = _.replace(link, replaceLinkRegex, newLink);
        return link;
    };

    const getHighlightName = (type: WemedsLink): string => {
        switch (type) {
            case WemedsLink.DISEASE:
                return 'Ler';
            case WemedsLink.MEDICINE_LEAFLET:
                return 'Ver bula';
            case WemedsLink.CRITERIA:
                return 'Ver critério';
            case WemedsLink.LABORATORIES:
            case WemedsLink.ILLUSTRADED_PROCEDURES:
                return 'Ver procedimento';
            case WemedsLink.SCORES:
            case WemedsLink.MEDICAL_CALCULATORS:
            case WemedsLink.PEDIATRIC_DOSES:
                return 'Calcular';
            case WemedsLink.MEDICINE_LEAFLET:
                return '';
        }
        return '';
    };

    const getLinksFromMarkdown = (content: string) => {
        const result: WemedsReference[] = [];
        const regex = /(\(\((MEDICINE_LEAFLET|DISEASE|PEDIATRIC_DOSES|MEDICAL_CALCULATORS|ILLUSTRADED_PROCEDURES|LABORATORIES|CRITERIA|SCORES|MICRO);[^\)]*\)\))/gi;
        const matches = content.match(regex);
        if (matches != null) {
            matches?.forEach(match => {
                if (!match.includes('®')) {
                    match = _.replace(match, /\(\(/gm, '');
                    match = _.replace(match, /\)\)/gm, '');
                    const splitResult = match.split(';');
                    result.push({
                        id: Number(splitResult[1]),
                        name: splitResult[2],
                        type: getEnumByName(splitResult[0]),
                        isHighlight: splitResult[3] != null,
                        code: `((${match}))`
                    });
                }
            });
        }
        return result;
    };

    const isFreeItemOfType = (link: string): boolean => {
        const type: WemedsLink = getEnumByName(link.split('/')[1]);
        const itemId: number = Number(link.split('/')[2]);
        const availableDiseases = [
            43,
            73,
            108,
            131,
            155,
            176,
            196,
            212,
            233,
            243,
            266,
            301,
            360,
            381,
            396,
            416,
            466,
            488,
            493,
            506,
            564,
            612,
            618,
            619,
            656,
            681,
            738,
            763,
            814,
            828,
            835,
            849,
            854,
            875,
            888
        ];
        const availableCalculators = [4, 22, 28, 29, 35];
        const availableProcedures = [15, 17, 18, 25, 39];
        const availableLaboratories = [
            29,
            43,
            44,
            54,
            73,
            86,
            88,
            127,
            130,
            141,
            143,
            152,
            159,
            184,
            226,
            236,
            241,
            254,
            285,
            292,
            297,
            317,
            319,
            357
        ];
        const availableScores = [6, 20, 28, 40, 44, 63, 70, 81, 91];
        switch (type) {
            case 'DISEASE':
                return availableDiseases.includes(itemId);
            case 'PEDIATRIC_DOSES':
                return false;
            case 'MEDICAL_CALCULATORS':
                return availableCalculators.includes(itemId);
            case 'ILLUSTRADED_PROCEDURES':
                return availableProcedures.includes(itemId);
            case 'LABORATORIES':
                return availableLaboratories.includes(itemId);
            case 'SCORES':
                return availableScores.includes(itemId);
            default:
                return true;
        }
    };

    return {
        isPediatricDose,
        replaceEnumNameByLink,
        getHighlightName,
        getLinksFromMarkdown,
        isMedicineLeaflet,
        isFreeItemOfType,
        isContent
    };
};

export default WemedsLinkUtil();
