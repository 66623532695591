import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './button.scss';

export interface IButtonProps {
    t: any;

    label: string;
    onPress: () => void;
    leftIcon?: JSX.Element;
    rightIcon?: JSX.Element;
    style?: any;
    labelStyle?: any;

    inverse?: boolean;

    isLoading?: boolean;
}

export class Button extends React.Component<IButtonProps> {
    constructor(props) {
        super(props);

        this.state = {};
    }
    render() {
        const { label, onPress, leftIcon, style, rightIcon, inverse, isLoading, labelStyle } = this.props;
        return (
            <a className={`${inverse ? 'button--inverse' : 'button'}`} onClick={isLoading ? undefined : onPress} style={style}>
                <div className="button__content">
                    {leftIcon != null && <div style={{ marginRight: 8 }}>{leftIcon}</div>}
                    <span style={labelStyle ? labelStyle : {}} className={`${inverse ? 'button__content__label button__content__label--inverse' : 'button__content__label'}`}>
                        {label}
                    </span>
                    {rightIcon != null && <div style={{ marginLeft: 8 }}>{rightIcon}</div>}
                </div>
            </a>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Button) as React.ComponentType<any>;
