import { AxiosResponse } from 'axios';
import { CidAmount } from '../model/cid';
import { CidCategoryAmount } from '../model/cidCategory';
import { CidChapterAmount } from '../model/cidChapter';
import { CidGroupAmount } from '../model/cidGroup';
import { CidTree } from '../model/cidTree';
import { api } from './api';
// import cidCategories from './mocks/cidCategories.json';
// import cidChapterJson from './mocks/cidChapter.json';
// import cidGroupsJson from './mocks/cidGroups.json';
// import cids from './mocks/cids.json';
// import Mocker from './mockApi';

export const cidApi = () => {
  const getChapters = (): Promise<AxiosResponse<CidChapterAmount[]>> => {
      // return new Mocker<CidChapterAmount[]>().mock(cidChapterJson as any);
      return api.get<CidChapterAmount[]>('/cid-chapters/amount');
  };  
  const getGroups = (chapterId: number): Promise<AxiosResponse<CidGroupAmount[]>> => {
      // return new Mocker<CidGroupAmount[]>().mock(cidGroupsJson as any);
      return api.get<CidGroupAmount[]>(`/cid-groups/amount/${chapterId}`);
  };  
  const getCategories = (groupId: number): Promise<AxiosResponse<CidCategoryAmount[]>> => {
      // return new Mocker<CidCategoryAmount[]>().mock(cidCategories as any);
      return api.get<CidCategoryAmount[]>(`/cid-categories/amount/${groupId}`);
  };  
  const getCids = (categoryId: number): Promise<AxiosResponse<CidAmount[]>> => {
      // return new Mocker<CidAmount[]>().mock(cids as any);
      return api.get<CidAmount[]>(`/cids/amount/${categoryId}`);
  };
  const getCidTree = (cidId: number): Promise<AxiosResponse<CidTree>> => {
      return api.get<CidTree>(`/cids/tree/${cidId}`);
  };

  return {
    getChapters,
    getGroups,
    getCategories,
    getCids,
    getCidTree
  };
};

export default cidApi();
