import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';

export interface IMenuItem {
    to: string;
    id?: string;
    onSelect?: () => void;
    className?: string;
}

export default class MenuItem extends React.Component<IMenuItem> {
    render() {
        const { to, id, children, onSelect, className } = this.props;

        return (
            <DropdownItem tag={Link} onClick={onSelect} to={to} id={id} className={className}>
                {children}
            </DropdownItem>
        );
    }
}
