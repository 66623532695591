import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { useFlashcardViewContext } from '../flashcard-carousel/flashcard.provider';
import '../flashcard.scss';
import { ItemContainer, ItemIcon, ItemIconContainer, ItemTitle, MainContainer } from './flashcard-tool-bar.styles';

interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
    closeIconIsActive?: boolean;
    onPressConsultButton: () => void;
    onPressFinishButton?: () => void;
    hideQueryButton?: boolean;
    hideRandomButton?: boolean;
    hideFavoriteButton?: boolean;
    hideFinishButton?: boolean;
    viewMode: 'LIST' | 'PERFORMANCE' | 'CARD';
}

const FlashcardToolBar = (props: Props) => {
    const { randomModeStatus, setRandomMode, favoriteFlashcard, cards, currentIndex } = useFlashcardViewContext();
    const {
        onPressFinishButton,
        onPressConsultButton,
        hideQueryButton,
        hideRandomButton,
        hideFinishButton,
        viewMode,
        hideFavoriteButton
    } = props;


    const renderToolbarButton = (icon: string, iconActive: string, label: string, onPress: () => void, isActive: boolean) => {
        return (
            <ItemContainer onClick={onPress}>
                <ItemIconContainer backgroundColor={isActive ? '#ef7d00' : '#F4F5F5'}>
                    <ItemIcon className={isActive ? iconActive : icon} />
                </ItemIconContainer>
                <ItemTitle>{props.t(`flashcardToolbar.${label}`)}</ItemTitle>
            </ItemContainer>
        );
    };

    const favoriteIsActive = cards[currentIndex]?.favorite?.isActive;
    return (
        <MainContainer>
            <ItemContainer onClick={() => favoriteFlashcard()}>
                {hideFavoriteButton !== true && <>
                    <ItemIconContainer backgroundColor={favoriteIsActive ? '#EF7D00' : '#F4F5F5'}>
                        <ItemIcon
                            className={favoriteIsActive ? 'white-yellow-star-img' : 'star-img'}
                        />
                    </ItemIconContainer>
                    <ItemTitle>{props.t('flashcardToolbar.favorite')}</ItemTitle>
                </>}
            </ItemContainer>
            {hideRandomButton !== true &&
                renderToolbarButton('shuffle-img', 'white-shuffle-img', 'random', () => setRandomMode(!randomModeStatus), randomModeStatus)}
            {hideQueryButton !== true &&
                renderToolbarButton('black-list-dashes-img', 'white-list-dashes-img', viewMode === 'LIST' ? 'cards' : 'query', onPressConsultButton, viewMode === 'LIST')}
            {!hideFinishButton && (
                <ItemContainer onClick={onPressFinishButton}>
                    <ItemIconContainer backgroundColor={'#F4F5F5'}>
                        <ItemIcon className={'red-flag-img'} tintColor={'#e04444'} />
                    </ItemIconContainer>
                    <ItemTitle>{props.t('flashcardToolbar.close')}</ItemTitle>
                </ItemContainer>
            )}
        </MainContainer>
    );
};

export default compose(connect(null, null), withTranslation())(FlashcardToolBar) as React.ComponentType<Props>;
