import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { Cid } from '../../model/cid';
import CidGender from '../../model/enums/cidGender';
import { Favorite, FavoriteType } from '../../model/favorite';
import { IRootState } from '../../reducer';
import FavoritesService from '../../services/favoritesService';
import './cid.scss';
import { Tooltip, Slider, withStyles } from '@material-ui/core';

export interface CidLastColumnProps extends RouteComponentProps<{}> {
    t: any;
    cid: Cid;
    isFavorited?: boolean;
}

export interface CidLastColumnState {
    fontClassName: string;
    starClassName: string;

    showFontSlider?: boolean;
    fontSliderValue: number;
    descriptionFontSize: number;

    isFavorited?: boolean;
    showFavoriteSuccessToast?: boolean;
}

const CustomSlider = withStyles({
    root: {
      color: '#e7e7e7',
      height: 4,
      opacity: 1,
    },
    thumb: { 
        width: 16,
        height: 16,
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.32)',
        backgroundColor: '#ffffff',
        marginTop: -6
    },
    mark: {
        backgroundColor: '#e7e7e7',
        height: 9,
        width: 4,
        borderRadius: 3,
        marginTop: -2.02,
        zIndex: -1
    },    
    active: {},
    track: {
      height: 4,
      borderRadius: 3,
      opacity: 1,
      backgroundImage: "linear-gradient(90deg, rgba(44,147,147,1) 0%, rgba(66,210,206,1) 100%)"
    },
    rail: {
      height: 4,
      borderRadius: 3,
      opacity: 1,
      backgroundColor: '#e7e7e7',
    },
  })(Slider);

export class CidLastColumn extends React.Component<CidLastColumnProps, CidLastColumnState> {
    constructor(props: Readonly<CidLastColumnProps>, context?: any) {
        super(props, context);
        this.state = {
            isFavorited: props.isFavorited,
            fontClassName: 'cid-info__font-img',
            starClassName: props.isFavorited ? 'cid-info__star-img cid-info__star-img--orange' : 'cid-info__star-img',
            fontSliderValue: 0,
            descriptionFontSize: 13,
        };
    }
    componentWillReceiveProps(newProps: CidLastColumnProps): void {
        if (newProps.isFavorited !== this.props.isFavorited) {
            this.setState({
                isFavorited: newProps.isFavorited,
                starClassName: newProps.isFavorited ? 'cid-info__star-img cid-info__star-img--orange' : 'cid-info__star-img',
            });
        }
    }

    private onResizeFont = (): void => {
        const { showFontSlider } = this.state;
        this.setState({
            fontClassName: showFontSlider ? 'cid-info__font-img' : 'cid-info__font-img cid-info__font-img--orange',
            showFontSlider: !showFontSlider
        });
    };
    private onFavorite = async (): Promise<void> => {
        const { cid } = this.props;
        const { isFavorited } = this.state;
        const params: Favorite = {
            type: FavoriteType.CID,
            cid: {
                id: cid.id
            },
            isActive: isFavorited ? false : undefined
        };
        FavoritesService.favorite(params).then(() => {
            this.setState({
                starClassName: isFavorited ? 'cid-info__star-img' : 'cid-info__star-img cid-info__star-img--orange',
                isFavorited: !isFavorited,
                showFavoriteSuccessToast: !isFavorited
            }, () => {
                setTimeout(() => this.setState({ showFavoriteSuccessToast: false }), 3000);
            });
        });
    };
    private onChangeSlider = (event: React.ChangeEvent<{}>, value: number | number[]): void => {
        this.setState({
            fontSliderValue: value as number,
            descriptionFontSize: value as number
        });
    };


    private renderGender = (gender?: CidGender): JSX.Element => {
        switch (gender) {
            case CidGender.MALE:
                return <div className="cid-info__gender__male-img" />;
            case CidGender.FEMALE:
                return <div className="cid-info__gender__female-img" />;
            case CidGender.BOTH:
                return (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="cid-info__gender__female-img" />
                        <div className="cid-info__gender__male-img" />
                    </div>
                );
            default:
                return <div />;
        }
    };
    render() {
        const { cid } = this.props;
        const { fontClassName, starClassName, showFontSlider, fontSliderValue, descriptionFontSize, showFavoriteSuccessToast } = this.state;
        return (
            <div className="cid-info">
                <div className="cid-info__header">
                    <a className={fontClassName} onClick={this.onResizeFont} />
                    <a className={starClassName} onClick={this.onFavorite} />
                </div>
                <div className="cid-info__content">
                    {showFontSlider && (
                        <div className="cid-info__slider-container">
                            <CustomSlider
                                className="cid-info__slider"
                                value={fontSliderValue}
                                max={17}
                                min={13}
                                step={2}
                                marks={[ { value: 15, label: ''}]}
                                onChange={this.onChangeSlider}   
                            />
                        </div>
                    )}
                    {showFavoriteSuccessToast && (
                        <div className="cid-info__favorite-success-toast">
                            <div className="cid-info__white-star-img" />
                            <span className="cid-info__favorite-success-msg">{this.props.t('cid.favoriteSuccessMessage')}</span>
                        </div>
                    )}
                    <span className="cid-info__code">{cid.code}</span>
                    <div className="cid-info__gender">
                        <span className="cid-info__gender__title">{this.props.t('cid.genderInfoTitle')}</span>
                        {this.renderGender(cid.gender)}
                    </div>
                    <div className="cid-info__description">
                        <h1 className="cid-info__description__title">{this.props.t('cid.descriptionTitle')}</h1>
                        <p className="cid-info__description__text" style={{ fontSize: descriptionFontSize }}>{cid.description}</p>
                    </div>
                    <div className="cid-info__classification">
                        <span className="cid-info_classification__title" >{this.props.t('cid.classificationTitle')}</span>
                        <span className="cid-info_classification__text">{cid.classification}</span>
                    </div>
                    <div className="cid-info__deadly">
                        <span className="cid-info__deadly__title">{this.props.t('cid.deadlyTitle')}</span>
                        {cid.deathCause === true ? (
                            <div className="cid-info__deadly__label" >
                                <span className="cid-info__deadly__text">{this.props.t('cid.yes').toUpperCase()}</span>
                            </div>
                        ) : (
                            <div className="cid-info__deadly__label cid-info__deadly__label--black" >
                                <span className="cid-info__deadly__text">{this.props.t('cid.no').toUpperCase()}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(CidLastColumn) as React.ComponentType<any>;
