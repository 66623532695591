import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import FlashcardApi from '../../../../api/flashcard.api';
import Loading from '../../../../components/loading/loading';
import { Disease } from '../../../../model/disease';
import { FlashcardAnswers } from '../../../../model/flashcard/flashcard-answers';
import { FlashcardStatistic } from '../../../../model/flashcard/flashcard-statistic';
import { convertToPerformanceData, PerformanceData } from '../../../../model/flashcard/performance-data';
import { IRootState } from '../../../../reducer';
import { openPaidFlashcardModal } from '../../../../reducer/globalModal/actions';
import DiseaseService from '../../../../services/diseaseService';
import FlashcardStatisticService from '../../../../services/flashcardStatisticService';
import { getDateNowTimestamp } from '../../../../shared/util/utils';
import FlashcardHeader from '../../components/flashcard-header';
import FlashcardToolBar from '../../components/flashcard-tool-bar';
import FlashcardCarousel from '../../flashcard-carousel/flashcard-carousel';
import { useFlashcardViewContext } from '../../flashcard-carousel/flashcard.provider';
import FlashcardList from '../../flashcard-list/flashcard-list';
import FlashcardPerformance from '../../flashcard-statistic/flashcard-performance';
import { Container, MainContainer } from '../../flashcard-styles';
import '../../flashcard.scss';

interface Props extends RouteComponentProps<{}, {}, { id?: number }> {
    t: any;
    hasSubscription?: boolean;
    openPaidModal: () => void;
}

const FlashcardDisease = (props: Props) => {
    const { setCards, setDiseaseId, cards, startTimestamp, setCurrentIndex, fontSize } = useFlashcardViewContext();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [disease, setDisease] = useState<Disease | undefined>();
    const [flashcardStatistic, setFlashcardStatistic] = useState<FlashcardStatistic>({});
    const [performanceData, setPerformanceData] = useState<PerformanceData>({ answers: [] });
    const [viewMode, setViewMode] = useState<'LIST' | 'PERFORMANCE' | 'CARD'>('CARD');

    useEffect(() => {
        const {
            match: { params }
        } = props;
        if(!props.hasSubscription) {
            setTimeout(() => props.openPaidModal(), 500);
            props.history.goBack();
        }
        const diseaseId = (params as any).id;
        setDiseaseId(diseaseId);
        DiseaseService.getDiseaseNameById(diseaseId).then(setDisease);
    }, []);

    useEffect(() => {
        if (disease != null) {
            setFlashcardInformation().catch(err => console.log(err));
        }
    }, [disease]);

    useEffect(() => {
        const allCardsAnswered = cards.filter(it => it.answer != null).length === cards.length;
        if (allCardsAnswered && cards.length > 0 && !isLoading) {
            finishReview();
        }
    }, [cards]);

    const onBack = () => {
        const answers = flashcardStatistic.answers as FlashcardAnswers;
        const updatedStatistic = getUpdatedFlashcardStatistic({ ...flashcardStatistic, answers: { ...answers } });
        FlashcardStatisticService.save(updatedStatistic).then(() => props.history.goBack()).catch(err => console.log(err));
    };

    const resetStatistic = () => {
        setIsLoading(true);
        const newFlashcardStatistic: FlashcardStatistic = {
            id: flashcardStatistic?.id,
            secondsSpent: 0,
            remainingQuestions: 0,
            answeredQuestions: 0,
            disease,
            answers: { answers: { finished: false, answers: [] } }
        };
        FlashcardStatisticService.save(newFlashcardStatistic)
            .then(() => {
                setCurrentIndex(0);
                setViewMode('CARD');
                setFlashcardInformation();
            })
            .catch(err => console.log(err));
    };

    const setFlashcardInformation = async () => {
        const response = await FlashcardApi.getByDiseasesId([disease?.id!], true);
        let newCards = response.data;
        newCards = newCards.sort((a, b) => a.position! - b.position!);
        const newFlashcardStatistic = await getFlashcardStatistic();
        const answers = (newFlashcardStatistic?.answers as FlashcardAnswers)?.answers?.answers ?? [];
        if (answers.length! > 0) {
            answers.map(answer => {
                const cardIndex = newCards.findIndex(it => it.id === answer.flashcardId!);
                if (cardIndex > -1) {
                    newCards[cardIndex].seeAnswer = true;
                    newCards[cardIndex].answer = answer.answer!;
                }
            });
            const lastIndexWithoutAnswer = newCards.findIndex(card => card.seeAnswer !== true);
            setTimeout(() => setCurrentIndex(lastIndexWithoutAnswer), 500);
        }

        setFlashcardStatistic(newFlashcardStatistic!);
        setCards(newCards);

        const allCardsAnswered = answers.length === newCards.length;
        if (allCardsAnswered || (newFlashcardStatistic?.answers as FlashcardAnswers)?.answers?.finished) {
            const data = convertToPerformanceData(newFlashcardStatistic!);
            setPerformanceData(data);
            setViewMode('PERFORMANCE');
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    const getFlashcardStatistic = async () => {
        let newFlashcardStatistic: FlashcardStatistic | null = await FlashcardStatisticService.getByDiseaseId(disease?.id!);
        if (newFlashcardStatistic == null) {
            newFlashcardStatistic = {
                secondsSpent: 0,
                remainingQuestions: 0,
                answeredQuestions: 0,
                disease,
                answers: { answers: { finished: false, answers: [] } }
            };
        }
        return Promise.resolve(newFlashcardStatistic);
    };

    const getUpdatedFlashcardStatistic = (statistic: FlashcardStatistic) => {
        const answer = statistic?.answers as FlashcardAnswers;
        const secondsSpent = (getDateNowTimestamp() - startTimestamp) / 1000;
        const newAnswers = cards
            .filter(it => it.answer != null)
            .map(it => {
                return { flashcardId: it.id, answer: it.answer };
            });
        const answeredQuestions = newAnswers.length;
        const remainingQuestions = cards.length - answeredQuestions;
        const isFinished = answer?.answers?.finished ?? false;
        const newFlashcardStatistic: FlashcardStatistic = {
            ...statistic,
            secondsSpent: Math.floor((statistic?.secondsSpent ?? 0) + secondsSpent),
            remainingQuestions,
            answeredQuestions,
            disease,
            answers: { answers: { finished: isFinished, answers: newAnswers } }
        };
        return newFlashcardStatistic;
    };

    const goToPerformanceScreen = () => {
        const updatedStatistic = getUpdatedFlashcardStatistic(flashcardStatistic);
        const data = convertToPerformanceData(updatedStatistic);
        setPerformanceData(data);
        setViewMode('PERFORMANCE');
    };

    const finishReview = () => {
        const answers = flashcardStatistic.answers as FlashcardAnswers;
        const updatedStatistic = getUpdatedFlashcardStatistic({
            ...flashcardStatistic,
            answers: { ...answers, answers: { ...answers!.answers, finished: true } }
        });
        setFlashcardStatistic(updatedStatistic);
        FlashcardStatisticService.save(updatedStatistic).then(() => {
            const data = convertToPerformanceData(updatedStatistic);
            setPerformanceData(data);
            setViewMode('PERFORMANCE');
        }).catch(err => console.log(err));

    };

    const onPressBack = (viewMode === 'PERFORMANCE' || viewMode === 'LIST') && (flashcardStatistic?.answers as FlashcardAnswers)?.answers?.finished === false ? () => setViewMode('CARD') : () => onBack();
    return isLoading ? (
        <div className="disease-screen-container">
            <Loading />
        </div>
    ) : (
        <MainContainer>
            <Container>
                <FlashcardHeader
                    {...props}
                    viewMode={viewMode}
                    onPressBack={onPressBack}
                    title={disease?.name ?? ''}
                    subtitle={props.t('flashcardDisease.subtitle')}
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {viewMode !== 'PERFORMANCE' && <FlashcardToolBar
                        t={props.t}
                        history={props.history}
                        location={props.location}
                        match={props.match}
                        viewMode={viewMode}
                        hideFavoriteButton={viewMode === 'LIST'}
                        onPressConsultButton={() => setViewMode(viewMode === 'LIST' ? 'CARD' : 'LIST')}
                        onPressFinishButton={() => finishReview()}
                    />}
                    {viewMode === 'CARD' &&
                        <FlashcardCarousel {...props}
                                           fontSize={fontSize}
                                           onPressPerformanceButton={() => goToPerformanceScreen()} />}
                    {viewMode === 'LIST' && <FlashcardList {...props} fontSize={fontSize} />}
                    {viewMode === 'PERFORMANCE' &&
                        <FlashcardPerformance {...props} data={performanceData} resetStatistic={resetStatistic} />}

                </div>
            </Container>
        </MainContainer>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account?.customer?.hasSubscription
});

const mapDispatchToProps = {
    openPaidModal: openPaidFlashcardModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(FlashcardDisease) as React.ComponentType<any>;
