import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import DiagnAppRadio, { RadioOption } from '../../../components/diagnapp-radio/diagnapp-radio';
import Ethnicity from '../../../model/enums/ethnicity';
import { IRootState } from '../../../reducer';
import '../diagnapp.scss';

export interface EthnicityComponentProps extends RouteComponentProps<{}> {
    t: (path: string, params?: any) => string;
    onChangeInput: (ethnicity: Ethnicity) => void;
    ethnicity?: Ethnicity;
}

export interface EthnicityComponentState {
    ethnicity?: Ethnicity;
}
export class EthnicityComponent extends React.Component<EthnicityComponentProps, EthnicityComponentState> {
    private ethnicityOptions: RadioOption[] = [
        {
            label: this.props.t('diagnApp.ethnicityComponent.white'),
            data: Ethnicity.WHITE,
            leftIcon: require('../../../images/branco.svg')
        },
        { label: this.props.t('diagnApp.ethnicityComponent.black'), data: Ethnicity.BLACK, leftIcon: require('../../../images/negro.svg') },
        {
            label: this.props.t('diagnApp.ethnicityComponent.indigenous'),
            data: Ethnicity.INDIGENOUS,
            leftIcon: require('../../../images/indigena.svg')
        },
        { label: this.props.t('diagnApp.ethnicityComponent.brown'), data: Ethnicity.BROWN, leftIcon: require('../../../images/pardo.svg') },
        {
            label: this.props.t('diagnApp.ethnicityComponent.yellow'),
            data: Ethnicity.YELLOW,
            leftIcon: require('../../../images/amarelo.svg')
        }
    ];

    constructor(props: Readonly<EthnicityComponentProps>, context?: any) {
        super(props, context);

        this.state = {
            ethnicity: props.ethnicity
        };
    }

    // Handlers
    private onChangeEthnicity = (ethnicity: Ethnicity): void => {
        this.setState({ ethnicity }, () => {
            this.props.onChangeInput(this.state.ethnicity!);
        });
    };
    private getEthnicityIndex = (ethnicity?: Ethnicity): number | undefined => {
        if (ethnicity != null) {
            return _.findIndex(this.ethnicityOptions, ['data', ethnicity]);
        }        
        return undefined;
    };
    // Renders
    render() {
        const { ethnicity } = this.state;
        return (
            <div className="ethnicity-component">
                <p className="ethnicity-component__title">{this.props.t('diagnApp.ethnicityComponent.title').toUpperCase()}</p>
                <div className="ethnicity-component__radio">
                    <DiagnAppRadio
                        options={this.ethnicityOptions}
                        onPress={this.onChangeEthnicity}
                        initialIndex={this.getEthnicityIndex(ethnicity)}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({}: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(EthnicityComponent) as React.ComponentType<any>;
