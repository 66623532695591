import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../../components/loading/loading';
import { FlashcardView } from '../../../model/flashcard/flashcard';
import { MyFlashcard } from '../../../model/flashcard/my-flashcard';
import MyFlashcardService from '../../../services/my-flashcard.service';
import FlashcardHeader from '../components/flashcard-header';
import { useFlashcardViewContext } from '../flashcard-carousel/flashcard.provider';
import FlashcardList from '../flashcard-list/flashcard-list';
import {
    Container,
    EmptyIcon,
    EmptyText,
    MainContainer, MathPlusIcon,
    PracticeButton,
    PracticeIcon,
    PracticeText
} from '../flashcard-styles';
import '../flashcard.scss';

interface Props extends RouteComponentProps<{}, {}, { id?: number }>, WithTranslation {
    hasSubscription?: boolean;
    openPaidModal: () => void;
    onPressPerformanceButton?: () => void;
    hideCreateCardButton?: boolean;
}

const MyFlashcards = (props: Props) => {
    const { setCards, cards, fontSize, updateCard } = useFlashcardViewContext();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        MyFlashcardService.getMyFlashcards().then(response => {
            let newCards = response;
            newCards = newCards.sort((a, b) => a.id! - b.id!);
            setCards(newCards as FlashcardView[]);
        }).finally(() => setIsLoading(false));
    }, []);

    const onPressFavorite = (index: number) => {
        const newMyFlashcard: MyFlashcard = { ...cards[index] };
        newMyFlashcard.isFavorite = !newMyFlashcard.isFavorite;
        MyFlashcardService.save(newMyFlashcard).then((response) => updateCard(index, newMyFlashcard as FlashcardView));
    };

    const onBack = () => {
        props.history.goBack();
    };

    const listIsEmpty = cards.length === 0;

    return isLoading ? (
        <div className="disease-screen-container">
            <Loading />
        </div>
    ) : (
        <MainContainer>
            <Container>
                <FlashcardHeader
                    {...props}
                    title={props.t('myFlashcards.subtitle')}
                    subtitle={props.t('myFlashcards.title')}
                    viewMode={listIsEmpty ? 'CARD' : 'LIST'}
                    onPressBack={onBack}
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FlashcardList {...props} width={55} fontSize={fontSize} rightIcon={'pencil-icon-img'}
                                   onPressFavorite={onPressFavorite}
                                   onPressRightIcon={(value) => props.history.push(`/my-flashcard-form/${value}`)}>
                        {listIsEmpty && <div className={'favorite-empty-list-container'}>
                            <EmptyIcon className={'empty-list-img'} />
                            <EmptyText>{props.t('myFlashcards.emptyList')}</EmptyText>
                        </div>}
                        {!listIsEmpty &&
                            <PracticeButton onClick={() => props.history.push('/my-flashcard')}
                                            width={32}>
                                <div style={{ height: 56 }} />
                                <PracticeText>
                                    {props.t('myFlashcards.practice')}
                                </PracticeText>
                                <PracticeIcon className={'orange-play-img'} />
                            </PracticeButton>}
                        <MathPlusIcon className={'plus-circle-img'}
                                      onClick={() => props.history.push('/my-flashcard-form')} />
                    </FlashcardList>
                </div>
            </Container>
        </MainContainer>
    );
};

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(MyFlashcards) as React.ComponentType<any>;
