import { AxiosResponse } from 'axios';
import susApi from '../api/susApi';
import HttpStatus from '../model/enums/httpStatus';
import { SusProcedure } from '../model/susProcedure';
import { SusGroupApiResponse } from '../model/susProcedureGroup';
import { SusTree } from '../model/susTree';

export const SusService = () => {
   const getGroups = async (parentId?: number): Promise<SusGroupApiResponse> => {
       try {
           const result: AxiosResponse<SusGroupApiResponse> = await susApi.getGroups(parentId);
           console.log('result ', result);
           if (result.status === HttpStatus.OK && result.data != null) {
               return Promise.resolve(result.data);
           } else if (result.status === HttpStatus.UNAUTHORIZED) {
               // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
               return Promise.reject({ status: 401 } as AxiosResponse);
           } else {
               return Promise.reject({ status: 400 } as AxiosResponse);
           }
       } catch (error) {
           console.error(error);
           return Promise.reject(error);
       }
   };     
   const getProcedure = async (procedureId: number): Promise<SusProcedure> => {
       try {
           const result: AxiosResponse<SusProcedure> = await susApi.getProcedure(procedureId);
           console.log('result ', result);
           if (result.status === HttpStatus.OK && result.data != null) {
               return Promise.resolve(result.data);
           } else if (result.status === HttpStatus.UNAUTHORIZED) {
               // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
               return Promise.reject({ status: 401 } as AxiosResponse);
           } else {
               return Promise.reject({ status: 400 } as AxiosResponse);
           }
       } catch (error) {
           console.error(error);
           return Promise.reject(error);
       }
   };   
   const getSusTree = async (susId: number): Promise<SusTree> => {
    try {
        const result: AxiosResponse<SusTree> = await susApi.getSusTree(susId);
        if (result.status === HttpStatus.OK && result.data != null) {
            return Promise.resolve(result.data);
        } else if (result.status === HttpStatus.UNAUTHORIZED) {
            return Promise.reject({ status: 401 } as AxiosResponse);
        } else {
            return Promise.reject({ status: 400 } as AxiosResponse);
        }
    } catch (error) {
        console.error(error);
        return Promise.reject(error);
    }
};   
  return {
    getGroups,
    getProcedure,
    getSusTree
  }
}

export default SusService();
