import { AxiosResponse } from 'axios';
import { FlashcardView } from '../model/flashcard/flashcard';
import { MyFlashcard } from '../model/flashcard/my-flashcard';
import { api } from './api';

const MyFlashcardApi = () => {

    const save = (myFlashcard: MyFlashcard): Promise<AxiosResponse<void>> => {
        return api.post<void>(`/my-flashcards`, myFlashcard);
    };

    const getMyFlashcards = (): Promise<AxiosResponse<MyFlashcard[]>> => {
        return api.get<MyFlashcard[]>(`/my-flashcards`);
    };

    const getMyFlashcardById = (id: number): Promise<AxiosResponse<MyFlashcard>> => {
        return api.get<MyFlashcard>(`/my-flashcards/${id}`);
    };

    return {
        save,
        getMyFlashcards,
        getMyFlashcardById
    };
};

export default MyFlashcardApi();
