import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import Button from '../../../components/button/button';
import { SelectOption } from '../../../components/inline-select/inline-select';
import SelectFieldMeds from '../../../components/select-field-meds/select-field-meds';
import { SimpleRadio } from '../../../components/simple-radio/simple-radio';
import { CustomerRegister } from '../../../model/customer-register';
import FieldOfWork from '../../../model/enums/fieldOfWork';
import Formation from '../../../model/enums/formation';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import Specialization from '../../../model/enums/specialization';
import { IRootState } from '../../../reducer';
import FinishStep from './finish-step';

export interface Props {
    t: (path: string, params?: any) => string;
    createSubscription: CustomerRegister;
    onNextStep: (createSubscription: CustomerRegister, hasErrors: boolean, errorMessage?: string) => void;
    showErrors?: boolean;
    onBack: () => void;
    history: any;
    finished: boolean;
    registerStatus: HttpRequestStatus;
}

export const DataStep = (props: Props): JSX.Element => {
    const getYearsOptions = (): SelectOption[] => {
        const years: SelectOption[] = [];

        for (let index = 0; index < 15; index++) {
            const year: string = moment()
                .add(index, 'years')
                .format('YYYY');
            years.push({
                label: year,
                value: year
            });
        }

        return years;
    };

    const getSpecializationsOptions = (): SelectOption[] => {
        const result: SelectOption[] = [];
        Object.values(Specialization).forEach(spec => {
            result.push({
                label: props.t(`specialization.${spec}`),
                value: spec
            });
        });

        return result;
    };
    const getFieldsOfWorkOptions = (): SelectOption[] => {
        const fields: SelectOption[] = [];

        Object.values(FieldOfWork).forEach(field => {
            fields.push({
                label: props.t(`fieldOfWork.${field}`),
                value: field
            });
        });

        return fields;
    };

    const user = props.createSubscription;
    const [formation, setFormation] = useState<Formation | undefined>(user.customer.formation || Formation.STUDENT);
    const [formationYear, setFormationYear] = useState<string | undefined>(String(user.customer.formationYear));
    const [specialization, setSpecialization] = useState<string | undefined>(user.customer.specialization);
    const [fieldOfWork, setFieldOfWork] = useState<string | undefined>(user.customer.fieldOfWork);
    const [checkbox, setCheckbox] = useState<boolean>(false);
    const [yearsOptions] = useState<SelectOption[]>(getYearsOptions());
    const [specializationsOptions] = useState<SelectOption[]>(getSpecializationsOptions());
    const [fieldsOfWorkOptions] = useState<SelectOption[]>(getFieldsOfWorkOptions());
    const [finished, setFinished] = useState<boolean>(props.finished);

    useEffect(() => {
        setFinished(props.finished);
    }, [props.finished]);

    const onChangeFormation = (value: Formation): void => {
        setFormation(value);
    };
    const validateFormation = (value?: string): boolean => {
        const hasError: boolean = value == null;
        return !hasError;
    };
    const onChangeFormationYear = (value: string): void => {
        setFormationYear(value);
    };
    const validateFormationYear = (value?: string): boolean => {
        if (formation === Formation.STUDENT) {
            const hasError: boolean = value == null;
            return !hasError;
        }
        return true;
    };
    const onChangeFieldOfWork = (value: string): void => {
        setFieldOfWork(value);
    };
    const validateFieldOfWork = (value?: string): boolean => {
        const hasError: boolean = value == null;
        return !hasError;
    };
    const onChangeSpecialization = (value: string): void => {
        setSpecialization(value);
    };
    const validateSpecialization = (value?: string): boolean => {
        if (fieldOfWork === FieldOfWork.MEDICINE && formation === Formation.PROFESSIONAL) {
            const hasError: boolean = value == null;
            return !hasError;
        }
        return true;
    };
    const onChangeCheckbox = (): void => {
        setCheckbox(!checkbox);
    };

    const onNextStep = (): void => {
        if (!finished) {
            const inputsValid: boolean =
                validateFormation(formation) &&
                validateFormationYear(formationYear) &&
                validateFieldOfWork(fieldOfWork) &&
                validateSpecialization(specialization);
            const isValid: boolean = inputsValid && checkbox;
            const createSubscription: CustomerRegister = {
                ...props.createSubscription,
                customer: {
                    ...props.createSubscription.customer
                }
            };
            props.onNextStep(createSubscription, !isValid, !isValid && inputsValid ? props.t('register.dataStep.errors.term') : undefined);
        }
    };

    if (props.registerStatus === HttpRequestStatus.SUCCESS) {
        return <FinishStep history={props.history} />;
    }
    return (
        <div className="data-step">
            <Row className="justity-content-center">
                <b>
                    <p className={'main-text main-text-mobile'}>{props.t('register.text2')}</p>
                </b>
            </Row>
            <Row className="justify-content-center">
                <Col md={{ size: '6' }} xs={{ size: '12' }}>
                    <FormControl className="form" style={{ width: 500, paddingLeft: 100, paddingRight: 50 }}>
                        <span className="label-radio">{props.t('register.form.formation')}</span>
                        <Row className="radio-options">
                            <SimpleRadio
                                id="professional-formation"
                                onChange={() => onChangeFormation(Formation.PROFESSIONAL)}
                                placeholder={props.t('global.formation.pro')}
                                checked={formation === Formation.PROFESSIONAL}
                            />
                            <SimpleRadio
                                id="student-formation"
                                onChange={() => onChangeFormation(Formation.STUDENT)}
                                placeholder={props.t('global.formation.student')}
                                checked={formation === Formation.STUDENT}
                            />
                        </Row>

                        <Row className="justify-content-center" style={{ width: '90%', marginTop: '20px' }}>
                            {formation === Formation.STUDENT ? (
                                <div style={{ width: '100%' }}>
                                    <SelectFieldMeds
                                        dataArray={yearsOptions}
                                        id="year-formation"
                                        onChange={onChangeFormationYear}
                                        placeholder={props.t('register.form.formationYear')}
                                        selected={!_.isEmpty(formationYear)}
                                    />

                                    <SelectFieldMeds
                                        dataArray={fieldsOfWorkOptions}
                                        id="fieldOfWorks"
                                        onChange={onChangeFieldOfWork}
                                        placeholder={props.t('register.form.fieldOfWork')}
                                        selected={!_.isEmpty(fieldOfWork)}
                                    />
                                </div>
                            ) : (
                                <div style={{ width: '100%' }}>
                                    <SelectFieldMeds
                                        dataArray={fieldsOfWorkOptions}
                                        id="fieldOfWorks"
                                        onChange={onChangeFieldOfWork}
                                        placeholder={props.t('register.form.fieldOfWork')}
                                        selected={!_.isEmpty(fieldOfWork)}
                                    />

                                    {fieldOfWork === FieldOfWork.MEDICINE ? (
                                        <SelectFieldMeds
                                            dataArray={specializationsOptions}
                                            id="specializations"
                                            onChange={onChangeSpecialization}
                                            placeholder={props.t('register.form.specialization')}
                                            selected={!_.isEmpty(specialization)}
                                        />
                                    ) : null}
                                </div>
                            )}
                        </Row>
                        <FormControlLabel
                            style={{ marginTop: '30px' }}
                            control={<Checkbox checked={checkbox} onChange={onChangeCheckbox} value={'terms'} />}
                            label={
                                <div>
                                    <span className="terms">{props.t('register.check')}</span>
                                </div>
                            }
                        />
                    </FormControl>
                </Col>
            </Row>

            <div className="button-container" style={{ marginTop: 30 }}>
                <Row className="align-items-center" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button label={props.t('global.button.finishRegister')} onPress={onNextStep} style={{ width: 320, height: 54 }} />
                    <span className="terms-link-web">
                        {props.t('register.terms1')}
                        <a href="https://wemeds.com.br/termos/" target="_blank" className="terms-link-web--dash">
                            {props.t('register.terms2')}
                        </a>
                        {props.t('register.terms2')}
                    </span>
                </Row>
            </div>
        </div>
    );
};

const mapStateToProps = (state: IRootState): Pick<Props, 'registerStatus'> => ({
    registerStatus: state.register.registerStatus
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(DataStep) as React.ComponentType<
    Omit<Props, 't' | 'registerStatus'>
>;
