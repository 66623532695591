import { AxiosResponse } from 'axios';
import { DiagnAppSearchParams } from '../model/diagnAppSearchParams';
import { Symptom } from '../model/symptom';
import { api } from './api';

export const symptomApi = () => {
    const search = (params: DiagnAppSearchParams): Promise<AxiosResponse<Symptom[]>> => {
        const urlParams: string = Object.keys(params)
            .filter((key) => params[key] != null)
            .map(key => `${key}=${params[key]}`)
            .join('&');

        return api.get<Symptom[]>(`/symptoms/diagnapp?${urlParams}`);
    };

    return {
        search
    };
};

export default symptomApi();
