import { Checkbox, FormControl, FormControlLabel, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import Button from '../../components/button/button';
import TextFieldMeds from '../../components/text-field-meds/text-field-meds';
import { Customer } from '../../model/customer';
import { IRootState } from '../../reducer';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import { validateEmail } from '../../shared/util/utils';
import './company-dashboard.scss';
import CompanyUserService from '../../services/company-user.service';
import toast from 'react-hot-toast';
import { AxiosResponse } from 'axios';

interface Props extends RouteComponentProps<{}, {}, { customer?: Customer }> {
    t: any;
    hasSubscription?: boolean;
    openPaidModal: () => void;
}

const useStyles = makeStyles({
    table: {
        minWidth: 800
    }
});

const CompanyCreateLogin = (props: Props) => {
    const [customer, setCustomer] = useState<Customer>(props.location?.state?.customer ?? {});
    const [emailError, setEmailError] = useState<boolean | undefined>(undefined);
    const [emailErrorText, setEmailErrorText] = useState<string | undefined>(props.t('registerSubscription.loginStep.errors.email'));
    const [nameError, setNameError] = useState<boolean | undefined>(undefined);
    const [passwordError, setPasswordError] = useState<boolean | undefined>(undefined);

    useEffect(() => {});

    const validateEmailInput = (value: string): boolean => {
        const hasError: boolean = !validateEmail(value);
        setEmailErrorText(props.t('registerSubscription.loginStep.errors.email'));
        setEmailError(hasError);
        return hasError;
    };

    const validateName = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length <= 3;
        setNameError(hasError);
        return hasError;
    };

    const validatePassword = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length < 4;
        setPasswordError(hasError);
        return hasError;
    };

    const save = () => {
        CompanyUserService.saveCompanyUser(customer)
            .then(() => {
                toast.success(props.t('companyLogin.saveMessage'));
                props.history.goBack();
            })
            .catch((e: AxiosResponse) => toast.error(props.t(e.statusText)));
    };

    return (
        <div className="steps">
            <div className={'step-header-register'} style={{ justifyContent: 'center' }}>
                <span className={'step-title'}>{customer.id != null ? 'Editar Login' : 'Criar Login'}</span>
            </div>
            <div className="steps__content container">
                <div className="login-step">
                    <Row>
                        <Col md={{ size: '6', offset: '3' }} xs={{ size: '12' }}>
                            <div className="login-step__inputs">
                                <FormControl className="form" style={{ width: 500, paddingLeft: 60, paddingRight: 50 }}>
                                    <div style={{ display: 'none' }}>
                                        <input type="password" tabIndex={-1} />
                                    </div>
                                    <TextFieldMeds
                                        onChange={(value: string) => setCustomer({ ...customer, name: value })}
                                        id="name"
                                        value={customer.name}
                                        errorText={props.t('registerSubscription.loginStep.errors.name')}
                                        label={props.t('companyLogin.placeholder.name')}
                                        placeholder={props.t('companyLogin.placeholder.name')}
                                        error={nameError}
                                        onBlur={() => validateName(customer.name)}
                                    />
                                    <TextFieldMeds
                                        onChange={(value: string) => setCustomer({ ...customer, email: value })}
                                        id="email"
                                        isEmail
                                        value={customer.email}
                                        errorText={emailErrorText}
                                        label={props.t('companyLogin.placeholder.email')}
                                        placeholder={props.t('companyLogin.placeholder.email')}
                                        error={emailError}
                                        onBlur={() => validateEmailInput(customer.email ?? '')}
                                    />
                                    <TextFieldMeds
                                        onChange={(value: string) => setCustomer({ ...customer, newPassword: value })}
                                        id="password"
                                        value={customer.newPassword}
                                        isPassword
                                        helpText={props.t('register.form.helpPass')}
                                        errorText={props.t('registerSubscription.loginStep.errors.password')}
                                        label={
                                            customer.id != null
                                                ? props.t('companyLogin.placeholder.newPassword')
                                                : props.t('companyLogin.placeholder.password')
                                        }
                                        placeholder={
                                            customer.id != null
                                                ? props.t('companyLogin.placeholder.newPassword')
                                                : props.t('companyLogin.placeholder.password')
                                        }
                                        error={passwordError}
                                        onBlur={() => validatePassword(customer.newPassword)}
                                        InputProps={{
                                            autComplete: 'off'
                                        }}
                                    />
                                    <FormControlLabel
                                        style={{ marginTop: '30px' }}
                                        control={
                                            <Checkbox
                                                checked={customer.hasSubscription}
                                                onChange={() => setCustomer({ ...customer, hasSubscription: !customer.hasSubscription })}
                                                value={'subscription'}
                                            />
                                        }
                                        label={
                                            <div>
                                                <span className="terms">{props.t('companyLogin.hasSubscription')}</span>
                                            </div>
                                        }
                                    />
                                </FormControl>
                            </div>
                        </Col>
                    </Row>
                    <div className="steps__footer">
                        <Button onPress={save} style={{ width: 400 }} label={props.t('companyLogin.save')} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account?.customer?.hasSubscription === true
});

const mapDispatchToProps = {
    openPaidModal: openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(CompanyCreateLogin) as React.ComponentType<any>;
