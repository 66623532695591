import * as React from 'react';
import './select-field-meds.scss';
import { Select, InputLabel, FormControl } from '@material-ui/core';


export interface SelectOption {
    label: string;
    value: any;
}
export interface ISelectFieldMedsProps {
    id: string;
    placeholder?: string;
    selected: boolean;
    dataArray: SelectOption[];
    onChange: (value: any) => void;
    style?: any;
    initialValue?: string;
}

export interface ISelectFieldMedsState {}

export default class RadioFieldMeds extends React.Component<ISelectFieldMedsProps, ISelectFieldMedsState> {
    constructor(props: any) {
        super(props);

        this.state = {
            placeholder: this.props.placeholder ? this.props.placeholder : '',
            selected: this.props.selected ? this.props.selected : ''
        };
    }

    callOnChange = (event: { target: { value: any } }) => {
        this.props.onChange(event.target.value);
    };

    render() {
        const { style, initialValue } = this.props;
        return (
            <FormControl variant="filled" fullWidth style={style}>
                <InputLabel htmlFor={this.props.id}>{this.props.placeholder}</InputLabel>
                <Select
                    id={this.props.id}
                    native
                    fullWidth
                    onChange={this.callOnChange}
                    error={this.props.selected}
                    inputProps={{
                        name: this.props.placeholder,
                        id: this.props.id
                    }}
                    value={initialValue != null ? initialValue : undefined}
                >
                    <option value={''}></option>

                    {this.props.dataArray.map((data, index) => {
                        return <option key={index} value={data.value}>{data.label}</option>;
                    })}
                </Select>
            </FormControl>
        );
    }
}
