import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { closeExpiredHourModal } from '../../reducer/globalModal/actions';
import Button from '../button/button';
import './expired-hour-modal.scss';

export interface IExpiredHourModalProps {
    t: (path: string, params?: any) => string;
    history: any;

    open: boolean;
    closeModal: () => void;
}

export class ExpiredHourModal extends React.Component<IExpiredHourModalProps> {
    constructor(props) {
        super(props);

        this.state = {};
    }
    private onClose = () => {
        this.props.closeModal();
        this.props.history.push('/');
    };
    private onButtonPress = () => {
        this.props.closeModal();
        this.props.history.push('/');
    };
    render() {
        const { open } = this.props;
        if (!open) {
            return <div />;
        }
        return (
            <div className="expired-hour-modal">
                <div className="expired-hour-modal__full-content">
                    <a className="close-btn" onClick={this.onClose}>
                        <div className="close-btn__icon" />
                    </a>
                    <div className="expired-hour-modal__content">
                        <div className="top-content">
                            <div className="top-content__logo">
                                <div className="top-content__logo__img_hour"/>
                            </div>
                            <span className="top-content__title">{this.props.t('expiredHourModal.title')}</span>
                            <span className="top-content__subtitle">{this.props.t('expiredHourModal.subtitle')}</span>
                        </div>
                        <div className="bottom-content">
                            <div className="bottom-content__btn-container">
                                <Button
                                    label={this.props.t('expiredHourModal.buttonLabel').toUpperCase()}
                                    onPress={this.onButtonPress}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    closeModal: closeExpiredHourModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ExpiredHourModal) as React.ComponentType<any>;
