import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { SusApiResponseType } from '../../model/susProcedureGroup';
import { IRootState } from '../../reducer';
import './sus.scss';

export interface CardItemContent {
    code: string;
    title: string;
    type: SusApiResponseType;
}
export interface SusCardItemProps extends RouteComponentProps<{}> {
    t: any;
    item: CardItemContent;
    isFirstList?: boolean;
    codeIsGolden?: boolean;
}

export class SusCardItem extends React.Component<SusCardItemProps> {
    constructor(props: Readonly<SusCardItemProps>, context?: any) {
        super(props, context);

        this.state = {};
    }
    render() {
        const { item, codeIsGolden } = this.props;
        const codeColor: string = codeIsGolden ? 'sus-card-content__code--golden' : 'sus-card-content__code--grey';
        if (item.type === SusApiResponseType.SUS_GROUP) {
            return ( 
                <div className="sus-card-content">
                    <div className="sus-card-content__row">
                        <div className="sus-card-content__row-2">
                            <span className={`sus-card-content__code ${codeColor}`}>{_.padStart(item.code, 2, '0')}</span>
                            <span className="sus-card-content__title">{item.title}</span>
                        </div>
                        <div className="arrow-right-img"/>
                    </div>
                </div>
            );
        }
        return ( 
            <div className="sus-card-content">
                <div className="sus-card-content__column-2">
                    <span className="sus-card-content__code sus-card-content__code--green">{item.code}</span>
                    <span className="sus-card-content__title sus-card-content__title--light">{item.title}</span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SusCardItem) as React.ComponentType<any>;
