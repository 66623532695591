import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import CustomToggleButton from '../../../components/custom-toggle-button/custom-toggle-button';
import { MedicalCalculatorParam } from '../../../model/medical-calculator/medical-calculator-param';
import { MedicalCalculatorParamOption } from '../../../model/medical-calculator/medical-calculator-param-option';
import { useMedicalCalculatorContext } from '../medical-calculator.provider';

interface Props {
    parameterInfo: MedicalCalculatorParam;
}

const MedicalCalculatorToggleParam = (props: Props) => {
    const { parameterInfo } = props;
    const ordenedOptions = _.sortBy(parameterInfo.options, 'order');
    const [value, setValue] = useState<MedicalCalculatorParamOption>(ordenedOptions?.[0]);
    const { paramValues, setParamValues } = useMedicalCalculatorContext();

    useEffect(() => {
        if (paramValues[parameterInfo.code!] !== value.code) {
            const newValue = parameterInfo.options
                ?.find(it => it.code === paramValues[parameterInfo.code!]) ?? ordenedOptions?.[0];
            setValue(newValue);
        }
    }, [paramValues]);

    useEffect(() => {
        const newValues = { ...paramValues };
        newValues[parameterInfo.code!] = value.code!;
        setParamValues(newValues);
    }, [value]);

    return (
        <CustomToggleButton<MedicalCalculatorParamOption>
            firstOption={ordenedOptions?.[0]}
            secondOption={ordenedOptions?.[1]}
            optionSelected={value}
            onChange={setValue}
        />
    );
};

export default MedicalCalculatorToggleParam;
