import _ from 'lodash';
import { Reducer } from 'redux';
import { RecentHistory } from '../../model/recentHistory';
import { RecentHistoryState, RecentHistoryTypes } from './types';

export const initialState: RecentHistoryState = {
    recents: [],
    ordenedRecents: []
};

const reducer: Reducer<RecentHistoryState> = (state = initialState, action) => {
    switch (action.type) {
        case RecentHistoryTypes.ADD_IN_RECENTS: {
            const newRecents = [...state.recents];
            const newId = state.recents.pop()?.id ?? 1;
            action.payload.id = newId;
            newRecents.push(action.payload);
            localStorage.setItem('history', JSON.stringify(newRecents));
            const ordenedResult: RecentHistory[] = [];
            _.orderBy(newRecents, 'id', 'desc').forEach(result => {
                if (ordenedResult.findIndex(it => it.contentId === result.contentId && it.type === result.type) === -1) {
                    ordenedResult.push(result);
                }
            });
            return { ...state, recents: newRecents, ordenedRecents: _.orderBy(ordenedResult, 'id', 'asc') };
        }
        case RecentHistoryTypes.SET_RECENTS: {
            const newRecents = JSON.parse(localStorage.getItem('history') ?? '[]');
            const ordenedResult: RecentHistory[] = [];
            _.orderBy(newRecents, 'id', 'desc').forEach(result => {
                if (ordenedResult.findIndex(it => it.contentId === result.contentId && it.type === result.type) === -1) {
                    ordenedResult.push(result);
                }
            });
            return { ...state, recents: newRecents, ordenedRecents: _.orderBy(ordenedResult, 'id', 'asc') };
        }
        default: {
            return state;
        }
    }
};

export { reducer as recentHistoryReducer };
