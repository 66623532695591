import { AxiosResponse } from 'axios';
import forgotPasswordApi from '../api/forgotPasswordApi';
import HttpStatus from '../model/enums/httpStatus';

export const ForgotPasswordService = () => {
    const sendEmail = async (email: string): Promise<string|undefined> => {
        try {
            const result: AxiosResponse<string> = await forgotPasswordApi.sendEmail(email)
            if (result.status === HttpStatus.OK || result.status === HttpStatus.NO_CONTENT) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    return {
        sendEmail
    };
};

export default ForgotPasswordService();
