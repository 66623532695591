import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { MedicalScore } from '../../../model/medical-score/medical-score';
import { IRootState } from '../../../reducer';
import MedicalScoreService from '../../../services/medical-score.service';
import './components.scss';

interface Props extends RouteComponentProps<{}> {
    t: (path: string, param?: any) => string;
    history: any;
}

const FreeScores = (props: Props) => {
    const [scores, setScores] = useState<MedicalScore[]>([]);

    useEffect(() => {
        MedicalScoreService.getAvailableForFree()
            .then(response => setScores(response));
    }, []);

    return (
        <div className="component">
            <h1 className="component__title">{props.t('preferences.freeScoresComponent.title')}</h1>
            <div className="component__content">
                {scores && scores.map((it, index) => (
                    <a key={index} className="component__card-item" href={`/scores/${it.id}`}>
                        <div className="component__card-item__content">
                            <div className="component__card-item__content__row">
                                <span className="component__card-item__label">{it.name}</span>
                                <div className="arrow-right-img" />
                            </div>
                        </div>
                        <div className="component__card-item__extension" />
                    </a>
                ))}
            </div>
        </div>
    );
}

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(FreeScores) as React.ComponentType<any>;
