import { AxiosResponse } from 'axios';
import favoritesApi from '../api/favoritesApi';
import HttpStatus from '../model/enums/httpStatus';
import { Favorite } from '../model/favorite';
import { HomeListItem } from '../model/HomeListItem';

export const FavoritesService = () => {
    const favorite = async (params: Favorite): Promise<any> => {
        try {
            const result: AxiosResponse<any> = await favoritesApi.favorite(params);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(true);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };    
    const getFavorites = async (): Promise<HomeListItem[]> => {
        try {
            const result: AxiosResponse<HomeListItem[]> = await favoritesApi.getFavorites();
            console.log('result ', result);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    return {
        favorite,
        getFavorites
    };
};

export default FavoritesService();
