import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { IRootState } from '../../reducer';
import './preferences.scss';

export interface SideMenuItemProps extends RouteComponentProps<{}> {
    t: (path: string, param?: any) => string;
    history: any;

    label: string;
    path: string;
    icon: string;
    isLogout?: boolean;
}

export class SideMenuItem extends React.Component<SideMenuItemProps> {
    constructor(props: Readonly<SideMenuItemProps>, context?: any) {
        super(props, context);

        this.state = {};
    }
    // Component Life Cycle

    // Handlers
    private onPress = (): void => {
        const { path, isLogout } = this.props;
        if (isLogout) {
            // TO DO
            return;
        }
        this.props.history.replace(`${this.props.match.path + path}`);
    };
    private isSelected = (): boolean => {
        const splittedFullPath: string[] = this.props.location.pathname.split('/');
        const splittedPath: string[] = this.props.path.split('/');
        return splittedFullPath[splittedFullPath.length - 1] === splittedPath[splittedPath.length - 1];
    }

    // Renders
    render() {
        const { label, icon } = this.props;
        const isSelected: boolean = this.isSelected();
        const selectedItemClass: string = isSelected ? 'side-menu__item--selected' : '';
        const selectedIconClass: string = isSelected ? 'side-menu__item__icon--selected' : '';
        return (
            <a className={`side-menu__item ${selectedItemClass}`} onClick={this.onPress}>
                <div className="side-menu__item__left">
                    <img src={icon} className={`side-menu__item__icon ${selectedIconClass}`} />
                    <span className="side-menu__item__label">{label.toUpperCase()}</span>
                </div>
                <div className="side-menu__item__right">{isSelected && <div className="side-menu__item__arrow-icon" />}</div>
            </a>
        );
    }
}

const mapStateToProps = ({ search }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SideMenuItem) as React.ComponentType<any>;
