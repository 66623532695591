import { Slider, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import '../../App.scss';
import FontResizer from '../../components/font-resizer/font-resizer';
import { Disease } from '../../model/disease';
import { Favorite, FavoriteType } from '../../model/favorite';
import { IRootState } from '../../reducer';
import FavoritesService from '../../services/favoritesService';
import DiseaseSearch from './disease-search';

export interface DiseaseTabProps extends RouteComponentProps<{}> {
    t: any;
    disease: Disease;
    diagnostic: () => JSX.Element;
    treatment: () => JSX.Element;

    onChangeFontSize: (fontSize: number) => void;
    onShowAnnotation: () => void;
    onChangeTab: (tab: DiseaseTabEnum) => void;

    title: string;
    cid: string;
    onBack: () => void;

    annotationIsOpen?: boolean;
}

export interface DiseaseTabState {
    currentTab: DiseaseTabEnum;

    showFontSlider?: boolean;
    fontSize: number;

    isFavorited?: boolean;
    showFavoriteSuccessToast?: boolean;
}

export enum DiseaseTabEnum {
    DIAGNOSTIC = 'DIAGNOSTIC',
    TREATMENT = 'TREATMENT'
}

export class DiseaseTab extends React.Component<DiseaseTabProps, DiseaseTabState> {
    private sliderRef;

    constructor(props: Readonly<DiseaseTabProps>, context?: any) {
        super(props, context);

        this.state = {
            currentTab: DiseaseTabEnum.DIAGNOSTIC,
            fontSize: 13,
            isFavorited: props.disease.favorite && props.disease.favorite.isActive
        };
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    private handleClickOutside = (event: any): void => {
        if (this.sliderRef && !this.sliderRef.contains(event.target) && this.state.showFontSlider) {
            this.setState({
                showFontSlider: false
            });
        }
    };
    private onClickTab = (tab: DiseaseTabEnum) => {
        this.setState({
            currentTab: tab
        });
        this.props.onChangeTab(tab);
    };
    private onChangeSlider = (value: number): void => {
        this.setState({ fontSize: value });
        this.props.onChangeFontSize(value);
    };
    private onResizeFont = () => {
        this.setState({
            showFontSlider: !this.state.showFontSlider
        });
    };
    private onFavorite = async (): Promise<void> => {
        const { isFavorited } = this.state;
        const { disease } = this.props;
        const params: Favorite = {
            type: FavoriteType.DISEASE,
            disease: {
                id: disease.id
            },
            isActive: isFavorited ? false : undefined
        };
        FavoritesService.favorite(params).then(() => {
            this.setState(
                {
                    isFavorited: !isFavorited,
                    showFavoriteSuccessToast: !isFavorited
                },
                () => {
                    setTimeout(() => this.setState({ showFavoriteSuccessToast: false }), 3000);
                }
            );
        });
    };
    private onAnnotation = () => {
        this.props.onShowAnnotation();
    };

    render(): JSX.Element {
        const { diagnostic, treatment, disease, onBack, title, cid } = this.props;
        const { currentTab, showFontSlider, showFavoriteSuccessToast, isFavorited } = this.state;

        const contentRender: () => JSX.Element = currentTab === DiseaseTabEnum.DIAGNOSTIC ? diagnostic : treatment;
        return (
            <div className="tab">
                <div className="tab__menu-containter">
                    <div className="tab__menu">
                        <div className="tab__menu__left">
                            <div className="tab__menu__left__title">
                                <div className="screen__title">
                                    <div className="screen__title__back" onClick={onBack}>
                                        <div className="screen__title__back__img" />
                                    </div>
                                    <span className="screen__title__text">{title}</span>
                                    {cid != null && (
                                        <div className="screen__title__cid">
                                            <span className="screen__title__cid__text">{`CID: ${cid}`}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="tab__menu__left__tabs">
                                {disease.diagnostic && disease.treatment && (
                                    <a
                                        className={`tab__menu__tab ${
                                            currentTab === DiseaseTabEnum.DIAGNOSTIC ? 'tab__menu__tab--selected' : ''
                                        }`}
                                        onClick={() => this.onClickTab(DiseaseTabEnum.DIAGNOSTIC)}
                                    >
                                        <span
                                            className="tab__menu__tab__text">{this.props.t('disease.diagnostic')}</span>
                                    </a>
                                )}

                                {disease.treatment && disease.diagnostic && (
                                    <a
                                        className={`tab__menu__tab ${
                                            currentTab === DiseaseTabEnum.TREATMENT ? 'tab__menu__tab--selected' : ''
                                        }`}
                                        onClick={() => this.onClickTab(DiseaseTabEnum.TREATMENT)}
                                    >
                                        <span
                                            className="tab__menu__tab__text">{this.props.t('disease.treatment')}</span>
                                    </a>
                                )}
                            </div>
                        </div>
                        <DiseaseSearch
                            fontSliderIsOpen={this.state.showFontSlider}
                            annotationIsOpen={this.props.annotationIsOpen}
                            isFavorited={isFavorited}
                            onResizeFont={this.onResizeFont}
                            onFavorite={this.onFavorite}
                            onAnnotation={this.onAnnotation}
                            disease={this.props.disease}
                        />
                        {showFontSlider && (
                            <div className="disease-content__slider-container" ref={ref => (this.sliderRef = ref)}>
                                <FontResizer
                                    changeFontSize={this.onChangeSlider}
                                    fontSize={this.state.fontSize}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="tab__content">{contentRender()}</div>

                {showFavoriteSuccessToast && (
                    <div className="disease-content__favorite-success-toast">
                        <div className="disease-content__white-star-img" />
                        <span
                            className="disease-content__favorite-success-msg">{this.props.t('cid.favoriteSuccessMessage')}</span>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(DiseaseTab) as React.ComponentType<any>;
