import styled from 'styled-components';

export const MainContainer = styled.div<{ width?: number }>`
    height: 65vh;
    width: ${props => props.width ?? 50}vw;
    display: flex;
    flex-direction: column;
    border: 1.2px solid #e9eced;
    border-radius: 8px;
    background-color: #F4F5F5;
    margin-left: ${props => props.width == null ? 35 : 0}px;
`;

export const CardsContainer = styled.div<{ fullHeight: boolean, width?: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    width: ${props => props.width ?? 50}vw;
    height: ${props => props.fullHeight ? 65 : 55}vh;
    padding: 24px 32px 32px 32px;
    background-color: #F4F5F5;
    overflow-y: auto;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    position: relative;
    width: 35vw;
    background-color: #ffffff;
    border: 1.2px solid #e9eced;
    border-radius: 8px;
    margin-bottom: 20px;
`;

export const ChildrenContainer = styled.div<{ width?: number }>`
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
`;

export const MarkdownContainer = styled.div`
    width: 100%;
`;

export const Icon = styled.div`
    width: 24px;
    height: 24px;
    margin-left: 5%;
    margin-right: 5%;
`;
