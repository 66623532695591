import { AxiosResponse } from 'axios';
import SemiologyCategory from '../model/enums/semiologyCategory';
import { PageableResponse } from '../model/pageable';
import { Semiology } from './../model/semiology/semiology';
import { api } from './api';

export const semiologyApi = () => {
  const getByCategory = (category: SemiologyCategory): Promise<AxiosResponse<Semiology[]>> => {
    return api.get<Semiology[]>(`/semiologies/${category}/category`);
  };

  const getSemiology = (id: number): Promise<AxiosResponse<Semiology>> => {
    return api.get<Semiology>(`/semiologies/info/${id}`);
  };

  const getAvailableForFreeSemiology = (): Promise<AxiosResponse<PageableResponse<Semiology>>> => {
    return api.get<PageableResponse<Semiology>>(`/semiologies/free?size=100`);
  };

  return {
    getAvailableForFreeSemiology,
    getSemiology,
    getByCategory
  };
};

export default semiologyApi();
