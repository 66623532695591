import { AxiosResponse } from 'axios';
import { EditorContent } from '../model/editorContent';
import { PageableResponse } from '../model/pageable';
import { api } from './api';

export const editorContentApi = () => {
    const getEditorContents = (): Promise<AxiosResponse<PageableResponse<EditorContent>>> => {
        return api.get<PageableResponse<EditorContent>>(`/editor-content`);
    };

    return {
        getEditorContents
    };
};

export default editorContentApi();
