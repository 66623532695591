import * as React from 'react';
import './diagnapp-radio.scss';

interface Props {
    options: RadioOption[];
    onPress: (data: any) => void;
    initialIndex?: number;
    useRow?: boolean;
    centeredOption?: boolean;
}
interface State {
    selectedIndex?: number;
}

export interface RadioOption {
    label: string;
    data: any;
    leftIcon?: string;
}
export default class DiagnAppRadio extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedIndex: props.initialIndex
        };
    }
    private onOptionPress = (option: RadioOption, index: number) => {
        this.setState({ selectedIndex: index }, () => {
            this.props.onPress(option.data);
        });
    };
    render() {
        const { options, centeredOption, useRow } = this.props;
        const { selectedIndex } = this.state;
        const optionStyle: any = useRow
            ? centeredOption
                ? { padding: 0, justifyContent: 'center', marginRight: 3 }
                : { marginRight: 3 }
            : centeredOption
            ? { padding: 0, justifyContent: 'center' }
            : {};
        const optionContentStyle: any = centeredOption ? { justifyContent: 'center' } : {};
        return (
            <div
                className="diagnapp-radio"
                style={useRow ? { flexDirection: 'row', width: 330, justifyContent: 'center' } : { height: 160 }}
            >
                {options.map((it, index) => (
                    <div
                        key={index}
                        className={`${
                            selectedIndex === index ? 'diagnapp-radio__option diagnapp-radio__option--selected' : 'diagnapp-radio__option'
                        }`}
                        onClick={() => this.onOptionPress(it, index)}
                        style={optionStyle}
                    >
                        <div className="diagnapp-radio__option__content" style={optionContentStyle}>
                            {it.leftIcon && <img src={it.leftIcon} className="diagnapp-radio__option__icon" />}
                            <span
                                className={`${
                                    selectedIndex === index
                                        ? 'diagnapp-radio__option__label diagnapp-radio__option__label--selected'
                                        : 'diagnapp-radio__option__label'
                                }`}
                            >
                                {it.label}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}
