import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Button from '../../components/button/button';
import CustomToast from '../../components/custom-toast/custom-toast';
import { DiagnAppParams } from '../../model/diagnAppParams';
import { DiagnAppSearchParams } from '../../model/diagnAppSearchParams';
import { DiseaseWeight } from '../../model/diseaseWeight';
import AgeType from '../../model/enums/ageType';
import Ethnicity from '../../model/enums/ethnicity';
import Gender from '../../model/enums/gender';
import Pregnant from '../../model/enums/pregnant';
import Region from '../../model/enums/region';
import RegionType from '../../model/enums/regionType';
import { RiskFactor } from '../../model/riskFactor';
import { Symptom } from '../../model/symptom';
import { User } from '../../model/user';
import { IRootState } from '../../reducer';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import DiagnAppService from '../../services/diagnAppService';
import AgeComponent from './components/age-component';
import EthnicityComponent from './components/ethnicity-component';
import GenderComponent from './components/gender-component';
import RegionComponent from './components/region-component';
import RiskFactorComponent from './components/risk-factor-component';
import SuccessComponent from './components/success-component';
import SymptomComponent from './components/symptom-component';
import DiagnAppCarousel, { DiagnAppCarouselCard } from './diagnapp-carousel';
import './diagnapp.scss';

export interface DiagnAppProps extends RouteComponentProps<{}> {
    t: (path: string, params?: any) => string;
    user?: User;
    openPaidDiseaseModal: () => void;
}

export interface DiagnAppState {
    carouselCards: DiagnAppCarouselCard[];
    carouselCurrentIndex: RenderOrder;

    diagnostic: DiagnAppParams;

    results?: DiseaseWeight[];

    showResults?: boolean;
    isLoading?: boolean;

    showErrorToast?: boolean;
    errorMessage?: string;
}

export enum RenderOrder {
    AGE_COMPONENT,
    GENDER_COMPONENT,
    ETHNICITY_COMPONENT,
    REGION_COMPONENT,
    RISK_FACTOR_COMPONENT,
    SYMPTOM_COMPONENT,
    RESULT_COMPONENT
}
export class DiagnApp extends React.Component<DiagnAppProps, DiagnAppState> {
    constructor(props: Readonly<DiagnAppProps>, context?: any) {
        super(props, context);

        this.state = {
            carouselCards: [],
            carouselCurrentIndex: 0,
            diagnostic: {
                ageType: AgeType.YEARS,
                gender: Gender.FEMALE,
                recentRegionType: RegionType.COUNTRY
            }
        };
    }
    componentDidMount() {
        this.setState({
            carouselCards: this.mapCards()
        });
        window.fbq('track', 'ViewContent', { content_type: 'DIAGNAPP' });
    }
    // Mappers
    private mapCards = (): DiagnAppCarouselCard[] => {
        const {
            age,
            ageType,
            lessThanThirtyDays,
            gender,
            pregnant,
            ethnicity,
            recentRegionType,
            recentRegion,
            riskFactors,
            symptoms
        } = this.state.diagnostic;
        return [
            {
                type: RenderOrder.AGE_COMPONENT,
                render: () => (
                    <AgeComponent
                        onChangeInput={this.onChangeAgeInput}
                        age={age}
                        ageType={ageType}
                        lessThanThirtyDays={lessThanThirtyDays}
                    />
                )
            },
            {
                type: RenderOrder.GENDER_COMPONENT,
                render: () => (
                    <GenderComponent
                        onChangeInput={this.onChangeGenderInput}
                        hasPregnantOptions={this.hasPregnantOptions}
                        gender={gender}
                        pregnantType={pregnant}
                    />
                )
            },
            {
                type: RenderOrder.ETHNICITY_COMPONENT,
                render: () => <EthnicityComponent onChangeInput={this.onChangeEthnicityInput} ethnicity={ethnicity} />
            },
            {
                type: RenderOrder.REGION_COMPONENT,
                render: () => (
                    <RegionComponent onChangeInput={this.onChangeRegionInput} regionType={recentRegionType} region={recentRegion} />
                )
            },
            {
                type: RenderOrder.RISK_FACTOR_COMPONENT,
                render: () => (
                    <RiskFactorComponent
                        onChangeInput={this.onChangeRiskFactors}
                        getSearchParams={this.getSearchRiskFactorsParams}
                        riskFactors={riskFactors}
                    />
                )
            },
            {
                type: RenderOrder.SYMPTOM_COMPONENT,
                render: () => (
                    <SymptomComponent
                        onChangeInput={this.onChangeSymptoms}
                        getSearchParams={this.getSearchSymptomsParams}
                        symptoms={symptoms}
                    />
                )
            },
            {
                type: RenderOrder.RESULT_COMPONENT,
                render: () => <SuccessComponent onSeeResults={this.onSeeResults} />
            }
        ];
    };
    componentDidUpdate(prevProps: DiagnAppProps, prevState: DiagnAppState) {
        if (prevState.diagnostic !== this.state.diagnostic) {
            this.setState({
                carouselCards: this.mapCards()
            });
        }
    }
    // Api Calls

    // Handlers
    private getSearchRiskFactorsParams = (): DiagnAppSearchParams => {
        const { age, ageType, gender, pregnant, lessThanThirtyDays } = this.state.diagnostic;
        return {
            age: age || 0,
            ageType,
            riskFactorGender: gender,
            pregnant,
            lessThanThirtyDays
        };
    };
    private getSearchSymptomsParams = (): DiagnAppSearchParams => {
        const { age, ageType, gender, pregnant, lessThanThirtyDays } = this.state.diagnostic;
        return {
            age: age || 0,
            ageType,
            gender,
            pregnant,
            lessThanThirtyDays
        };
    };
    // Age
    private onChangeAgeInput = (age: number, ageType: AgeType, lessThanThirtyDays?: boolean): void => {
        const { diagnostic } = this.state;
        this.setState({ diagnostic: { ...diagnostic, age: lessThanThirtyDays ? undefined : age, ageType, lessThanThirtyDays } });
    };
    private validateAge = (): boolean => {
        const { age, ageType, lessThanThirtyDays } = this.state.diagnostic;
        if (ageType === AgeType.YEARS) {
            if ((age != null && age > 0 && age <= 130) || lessThanThirtyDays === true) {
                return true;
            } else {
                this.showToast(this.props.t('diagnApp.errors.invalidAge'));
                return false;
            }
        } else {
            if ((age != null && age > 0 && age < 12) || lessThanThirtyDays === true) {
                return true;
            } else {
                this.showToast(this.props.t('diagnApp.errors.ageMaxLengthMonths'));
                return false;
            }
        }
    };
    // Gender
    private onChangeGenderInput = (gender: Gender, pregnant?: Pregnant): void => {
        const { diagnostic } = this.state;
        this.setState({ diagnostic: { ...diagnostic, gender, pregnant } });
    };
    private hasPregnantOptions = (): boolean => {
        const { age, ageType } = this.state.diagnostic;
        return age != null && age >= 14 && age <= 51 && ageType === AgeType.YEARS;
    };
    private validateGender = (): boolean => {
        const { gender, pregnant, age } = this.state.diagnostic;
        if (gender === Gender.FEMALE && !pregnant && age != null && age >= 14 && age <= 51) {
            this.showToast(this.props.t('diagnApp.errors.insertPregnant'));
            return false;
        }
        return true;
    };
    // Ethnicity
    private onChangeEthnicityInput = (ethnicity: Ethnicity): void => {
        const { diagnostic } = this.state;
        this.setState({ diagnostic: { ...diagnostic, ethnicity } });
    };
    private validateEthnicity = (): boolean => {
        return true;
    };
    // Region
    private onChangeRegionInput = (recentRegionType: RegionType, recentRegion: Region) => {
        const { diagnostic } = this.state;
        this.setState({ diagnostic: { ...diagnostic, recentRegion, recentRegionType } });
    };
    private validateRegion = (): boolean => {
        if (this.state.diagnostic.recentRegion == null) {
            this.showToast(this.props.t('diagnApp.errors.insertRegion'));
            return false;
        }
        return true;
    };
    // Risk Factors
    private onChangeRiskFactors = (riskFactors: RiskFactor[]): void => {
        const { diagnostic } = this.state;
        this.setState({ diagnostic: { ...diagnostic, riskFactors } });
    };
    private validateRiskFactors = (): boolean => {
        return true;
    };
    // Symptoms
    private onChangeSymptoms = (symptoms: Symptom[]): void => {
        const { diagnostic } = this.state;
        this.setState({ diagnostic: { ...diagnostic, symptoms } });
    };
    private validateSymptoms = (): boolean => {
        const { symptoms } = this.state.diagnostic;
        if (symptoms == null || symptoms.length === 0) {
            this.showToast(this.props.t('diagnApp.errors.insertSymptom'));
            return false;
        }
        return true;
    };
    // Results
    private getResults = async (): Promise<void> => {
        const { diagnostic, carouselCurrentIndex } = this.state;
        this.setState({ isLoading: true });
        const response: DiseaseWeight[] = await DiagnAppService.getResults(diagnostic);
        this.setState({ isLoading: false });
        this.setState({
            results: response,
            carouselCurrentIndex: carouselCurrentIndex + 1
        });
    };
    private onSeeResults = (): void => {
        const { user } = this.props;
        const hasSubscription: boolean = (user && user.customer && user.customer.hasSubscription) || false;
        if (hasSubscription) {
            this.setState({
                showResults: true
            });
        } else {
            this.props.openPaidDiseaseModal();
        }
    };
    // Advance
    private onAdvance = (): void => {
        const { carouselCurrentIndex } = this.state;
        switch (carouselCurrentIndex) {
            case RenderOrder.AGE_COMPONENT:
                if (this.validateAge()) {
                    this.setState({ carouselCurrentIndex: carouselCurrentIndex + 1 });
                }
                break;
            case RenderOrder.GENDER_COMPONENT:
                if (this.validateGender()) {
                    this.setState({ carouselCurrentIndex: carouselCurrentIndex + 1 });
                }
                break;
            case RenderOrder.ETHNICITY_COMPONENT:
                if (this.validateEthnicity()) {
                    this.setState({ carouselCurrentIndex: carouselCurrentIndex + 1 });
                }
                break;
            case RenderOrder.REGION_COMPONENT:
                if (this.validateRegion()) {
                    this.setState({ carouselCurrentIndex: carouselCurrentIndex + 1 });
                }
                break;
            case RenderOrder.RISK_FACTOR_COMPONENT:
                if (this.validateRiskFactors()) {
                    this.setState({ carouselCurrentIndex: carouselCurrentIndex + 1 });
                }
                break;
            case RenderOrder.SYMPTOM_COMPONENT:
                if (this.validateSymptoms()) {
                    this.getResults();
                }
                break;
            default:
                break;
        }
    };
    private onBack = (): void => {
        this.setState({
            carouselCurrentIndex: this.state.carouselCurrentIndex - 1
        });
    };
    private onRedo = (): void => {
        this.setState({ showResults: false, carouselCurrentIndex: 0 });
    };
    private getResultColor = (index: number): string => {
        switch (index) {
            case 0:
                return '#ef7d00';
            case 1:
                return '#f29015';
            case 2:
                return '#f7a82a';
            case 3:
                return '#fcc23c';
            case 4:
                return '#fed14e';
            case 5:
                return '#fcd96f';
            case 6:
                return '#fbe08e';
            default:
                return '#fbe08e';
        }
    };
    private onClickResult = (diseaseId: number): void => {
        this.props.history.push(`/disease/${diseaseId}`);
    };
    private showToast = (message: string): void => {
        this.setState(
            {
                showErrorToast: true,
                errorMessage: message
            },
            () => {
                setTimeout(this.closeErrorToast, 3000);
            }
        );
    };
    private closeErrorToast = (): void => {
        this.setState({
            showErrorToast: false,
            errorMessage: undefined
        });
    };
    // Renders
    private renderResult = (result: DiseaseWeight, index: number): JSX.Element => {
        const backgroundColor: string = this.getResultColor(index);
        const width: string = `${20 - 3 * index}%`;
        return (
            <div key={index} className="diagnapp-results__result" onClick={() => this.onClickResult(result.id)}>
                <div className="diagnapp-results__result__content">
                    <p className="diagnapp-results__result__label">{result.name}</p>
                    <div className="diagnapp-results__result__arrow-icon" />
                </div>
                <div className="diagnapp-results__result__weight-indicator" style={{ width, backgroundColor }} />
            </div>
        );
    };
    render() {
        const { user } = this.props;
        const { carouselCards, carouselCurrentIndex, showResults, isLoading, results, showErrorToast, errorMessage } = this.state;
        const hasSubscription: boolean = (user && user.customer && user.customer.hasSubscription) || false;
        return (
            <div className="diagnapp-screen">
                <div className="diagnapp-screen__content">
                    <div style={{ marginBottom: 35 }}>
                        <h1 className="screen-title">{this.props.t('diagnApp.title')}</h1>
                    </div>
                    {showErrorToast && (
                        <CustomToast
                            title={errorMessage || ''}
                            onClose={this.closeErrorToast}
                            t={this.props.t}
                            style={{ position: 'absolute', minWidth: 490, alignSelf: 'center', top: -8 }}
                        />
                    )}
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        {showResults ? (
                            <div className="diagnapp-results">
                                {hasSubscription && results != null && results.length > 0 ? (
                                    <div className="diagnapp-results__content">
                                        {results.map((it, index) => this.renderResult(it, index))}
                                        <Button
                                            label={this.props.t('diagnApp.redoButtonLabel')}
                                            onPress={this.onRedo}
                                            style={{ marginTop: 30 }}
                                        />
                                    </div>
                                ) : (
                                    hasSubscription && (
                                        <div className="no-results">
                                            <div className="no-results__img" />
                                            <span className="no-results__title">{this.props.t('diagnApp.emptyResults')}</span>
                                            <a className="no-results__btn" onClick={this.onRedo}>
                                                <span className="no-results__btn__label">{this.props.t('diagnApp.redoButtonLabel')}</span>
                                            </a>
                                        </div>
                                    )
                                )}
                            </div>
                        ) : (
                            <DiagnAppCarousel
                                cards={carouselCards}
                                currentIndex={carouselCurrentIndex}
                                onAdvance={this.onAdvance}
                                onBack={this.onBack}
                                showBackButton={carouselCurrentIndex > 0}
                                showAdvanceButton={carouselCurrentIndex < carouselCards.length - 1}
                            />
                        )}
                        {isLoading && (
                            <div className="loading">
                                <div className="loading__background" />
                                <div className="loading-img" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    user: authentication.account
});

const mapDispatchToProps = {
    openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(DiagnApp) as React.ComponentType<any>;
