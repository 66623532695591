import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { MedicalCalculatorParam } from '../../../model/medical-calculator/medical-calculator-param';
import { useMedicalCalculatorContext } from '../medical-calculator.provider';
import '../medical-calculator.scss';
import { InputParam, InputParamContainer, MeasurementText } from './medical-calculator-params-styles';

interface Props {
    placeholder?: string;
    containerStyle?: any;
    parameterInfo: MedicalCalculatorParam;
}

const MedicalCalculatorInputParam = (props: Props) => {
    const { paramValues, setParamValues } = useMedicalCalculatorContext();
    const [value, setValue] = useState<string | undefined>();
    const { parameterInfo, containerStyle, placeholder } = props;

    useEffect(() => {
        if (paramValues[parameterInfo.code!] !== value) {
            setValue(paramValues[parameterInfo.code!] as string);
        }
    }, [paramValues]);

    useEffect(() => {
        if (value !== undefined && value !== '') {
            const delayDebounceFn = setTimeout(() => {
                const newValues = { ...paramValues };
                newValues[parameterInfo.code!] = value;
                setParamValues(newValues);
            }, 500);

            return () => clearTimeout(delayDebounceFn)
        }
    }, [value]);

    return (
        <InputParamContainer style={containerStyle}>
            <InputParam
                type={'text'}
                placeholder={placeholder ?? '0.00'}
                value={value ? value.replace('.', ',') : ''}
                onChange={e => setValue(_.replace(e.target.value, /[^\d,]+/gm, ''))} 
            />
            <MeasurementText>{parameterInfo.measurement}</MeasurementText>
        </InputParamContainer>
    );
};

export default MedicalCalculatorInputParam;
