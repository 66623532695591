import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { MedicalCriteriaParam } from '../../../model/medical-criteria/medical-criteria-param';
import { useMedicalCriteriaContext } from '../medical-criteria.provider';
import '../medical-criteria.scss';
import {
  InputParam,
  InputParamContainer,
  MeasurementText,
} from './medical-criteria-params-styles';

interface Props {
  placeholder?: string;
  containerStyle?: any;
  parameterInfo: MedicalCriteriaParam;
}

const MedicalCriteriaInputParam = (props: Props) => {
  const { paramValues, setParamValues } = useMedicalCriteriaContext();
  const [value, setValue] = useState<string | undefined>();
  const { parameterInfo, containerStyle, placeholder } = props;

  useEffect(() => {
    if (paramValues[parameterInfo.code!] !== value) {
      setValue(paramValues[parameterInfo.code!] as string);
    }
  }, [paramValues]);

  useEffect(() => {
    if (value !== undefined && value !== '') {
      const delayDebounceFn = setTimeout(() => {
        const newValues = { ...paramValues };
        newValues[parameterInfo.code!] = value;
        setParamValues(newValues);
      }, 500);

      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
  }, [value]);

  return (
    <InputParamContainer style={containerStyle}>
      <InputParam
        type={'text'}
        placeholder={placeholder ?? '0'}
        value={value ? value.replace('.', ',') : ''}
        onChange={(e) => {
          setValue(_.replace(e.target.value, /[^\d,]+/gm, ''));
        }}
      />
      <MeasurementText>{parameterInfo.measurement}</MeasurementText>
    </InputParamContainer>
  );
};

export default MedicalCriteriaInputParam;
