import styled from 'styled-components';

export const ContentContainer = styled.div`
    height: 65vh;
    width: 55vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1.2px solid #e9eced;
    border-radius: 8px;
    background-color: #F4F5F5;
    margin-left: 35px;
    overflow-y: auto;
    padding-top: 20px;
`;

export const Title = styled.span`
    color: #414E52;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway,serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
`;

export const Subtitle = styled.span`
    color: #6D7C81;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway,serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
`;

export const DotsIcon = styled.div`
    width: 32px;
    height: 32px;
    cursor: pointer;
    margin-right: -35px;
`;