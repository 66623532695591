import { AxiosResponse } from 'axios';
import MedicalCalculatorApi from '../api/medical-calculator.api';
import HttpStatus from '../model/enums/httpStatus';
import { PageableResponse } from '../model/pageable';
import { MedicalCalculator, MedicalCalculatorSimple } from './../model/medical-calculator/medical-calculator';

export const MedicalCalculatorService = () => {
    const getInfo = async (id: number): Promise<MedicalCalculator> => {
        try {
            const result: AxiosResponse<MedicalCalculator> = await MedicalCalculatorApi.getInfo(id);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const getAvailableForFree = async (): Promise<MedicalCalculator[]> => {
        try {
            const result: AxiosResponse<PageableResponse<MedicalCalculator>> = await MedicalCalculatorApi.getAvailableForFreeCalculators();
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data.content);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const search = async (text?: string): Promise<MedicalCalculatorSimple[]> => {
        try {
            const result: AxiosResponse<PageableResponse<MedicalCalculatorSimple>> = await MedicalCalculatorApi.search(text);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data.content);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    return {
        getInfo,
        getAvailableForFree,
        search
    };
};

export default MedicalCalculatorService();
