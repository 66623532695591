import { InputAdornment, TextField } from '@material-ui/core';
import * as React from 'react';
import './auto-complete-meds.scss';

export interface IAutoCompleteMedsProps {
    id: string;
    placeholder?: string;
    value?: string;

    onChange: (value: string) => void;
    onEnterPress: () => void;
    onBlur: () => void;
    onFocus: () => void;
}

export interface IAutoCompleteMedsState {
    value: string;
    validate: boolean;
    filled: boolean;

    placeholder: string;
    isFocused?: boolean;
}

export default class AutoCompleteMeds extends React.Component<IAutoCompleteMedsProps, IAutoCompleteMedsState> {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ? this.props.value : '',
            validate: false,
            filled: false,
            placeholder: this.props.placeholder ? this.props.placeholder : ''
        };
    }

    componentWillReceiveProps(newProps: IAutoCompleteMedsProps): void {
        if (newProps.value !== this.state.value && newProps.value != null) {
            this.setState({
                value: newProps.value
            });
        }
    }
    private onChangeValue = event => {
        this.setState({
            value: event.target.value,
            validate: false,
            filled: event.target.value !== null && event.target.value !== ''
        });

        this.props.onChange(event.target.value);
    };
    private onFocus = () => {
        this.setState({
            isFocused: true
        });
        this.props.onFocus();
    };
    private onBlur = () => {
        this.setState({
            isFocused: false
        });
        this.props.onBlur();
    };
    private onClear = () => {
        this.setState({
            value: ''
        });
    };
    private onKeyPress = (event) => {
        if (event.which === 13 || event.keyCode === 13) {
            event.target.blur();
            this.props.onEnterPress()
            return false;
        }
        return true;
    
    }

    render() {
        const { placeholder } = this.props;
        const { isFocused } = this.state;
        return (
            <div className="auto-complete-container" style={{ position: 'relative', width: '100%' }}>
                <TextField
                    className={'action'}
                    id={this.props.id}
                    type={'text'}
                    onChange={this.onChangeValue}
                    fullWidth
                    placeholder={placeholder}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <div className="search-icn" />
                            </InputAdornment>
                        ),
                        autoComplete: 'off',
                    }}
                    value={this.state.value}
                    variant="filled"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onKeyPress={this.onKeyPress}
                    autoComplete={'off'}
                />
            </div>
        );
    }
}
