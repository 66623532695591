import * as React from 'react';
import { setNumberSign } from '../../shared/util/utils';
import { OptionContainer, OptionPointsContainer, OptionPointsText, OptionText } from './score-toggle-styles';
import './score-toggle.scss';

interface Props<T> {
    options: T[];
    optionSelected?: T;
    onChange: (option: T) => void;
}

interface Option {
    code?: string | null;
    presentationName?: string | null;
    points?: number | null;
}

const ScoreToggle = <T extends Option>(props: Props<T>) => {
    const { options, optionSelected } = props;

    const onPress = (option: T) => {
        props.onChange(option);
    };

    return (
        <div className="score-toggle">
            {options.map(option => (
                <OptionContainer
                    selected={optionSelected?.code === option.code}
                    width={options.length > 2 ? 33 : 50}
                    onClick={() => onPress(option)}
                >
                    <OptionPointsContainer selected={optionSelected?.code === option.code}>
                        <OptionPointsText selected={optionSelected?.code === option.code}>
                            {option.points != null ? setNumberSign(option.points) : 0}
                        </OptionPointsText>
                    </OptionPointsContainer>
                    <OptionText selected={optionSelected != null}>{option.presentationName}</OptionText>
                </OptionContainer>
            ))}
        </div>
    );
};

export default ScoreToggle;
