import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FeedbackRequest } from '../../model/feedback';
import { User } from '../../model/user';
import { IRootState } from '../../reducer';
import { closeFeedbackModal } from '../../reducer/globalModal/actions';
import feedbackService from '../../services/feedbackService';
import './feedback.scss';

export interface IFeedbackProps {
    t: (path: string, params?: any) => string;
    location: any;
    user?: User;
    showModal?: boolean;
    closeFeedbackModal: () => void;
}
export interface IFeedbackState {
    showModal: boolean;
    feedback: FeedbackRequest;
    showSuccess: boolean;

    nameError?: string;
    emailError?: string;
    messageError?: string;
}

export class Feedback extends React.Component<IFeedbackProps, IFeedbackState> {
    constructor(props) {
        super(props);

        this.state = {
            showModal: props.showModal || false,
            showSuccess: false,
            feedback: { message: '' }
        };
    }
    componentWillReceiveProps(newProps: IFeedbackProps): void {
        if (newProps.showModal !== this.props.showModal) {
            this.setState({
                showModal: newProps.showModal || false
            })
        }
    }
    private onShowModal = (): void => {
        this.setState({
            showModal: !this.state.showModal,
            showSuccess: false
        }, () => {
            if (!this.state.showModal) {
                this.props.closeFeedbackModal();
            }
        });
    };
    private validateName = (name?: string): boolean => {
        return name != null && name.trim().length >= 3;
    };
    private validateEmail = (email?: string): boolean => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    private onSend = async (): Promise<void> => {
        const { feedback } = this.state;
        // if (!this.validateName(feedback.name)) {
        //     this.setState({ nameError: this.props.t('feedback.invalidName') });
        //     return;
        // }
        // if (!this.validateEmail(feedback.email)) {
        //     this.setState({ emailError: this.props.t('feedback.invalidEmail') });
        //     return;
        // }
        if (feedback.message.trim().length <= 3) {
            this.setState({ messageError: this.props.t('feedback.invalidMessage') });
            return;
        }

        let currentRoute: string = '';
        let id: number = 0;
        const { pathname } = this.props.location;
        if (pathname) {
            const fullRoute: string[] = String(pathname).split('/');
            currentRoute = fullRoute[1];
            id = Number(fullRoute[2]);
        }
        
        const params: FeedbackRequest = {
            message: feedback.message,
            disease: currentRoute === 'disease' ? { id } : undefined,
            medicineLeaflet: currentRoute === 'smart-bulas' ? { id } : undefined
        }
        const response: void = await feedbackService.sendFeedback(params);
        this.setState({
            showSuccess: true
        });
    };

    private onNameChange = (event: any): void => {
        const {
            target: { value }
        } = event;
        this.setState({
            feedback: {
                ...this.state.feedback,
                name: value
            },
            nameError: this.validateName(value) ? undefined : this.state.nameError
        });
    };
    private onEmailChange = (event: any): void => {
        const {
            target: { value }
        } = event;
        this.setState({
            feedback: {
                ...this.state.feedback,
                email: value
            },
            emailError: this.validateEmail(value) ? undefined : this.state.emailError
        });
    };
    private onMessageChange = (event: any): void => {
        const {
            target: { value }
        } = event;
        this.setState({
            feedback: {
                ...this.state.feedback,
                email: this.props.user!.email!,
                name: this.props.user!.name!,
                message: value
            },
            messageError: value.trim().length > 3 ? undefined : this.state.messageError
        });
    };

    render() {
        const { showModal, showSuccess, nameError, emailError, messageError } = this.state;
        const nameInputClass: string = nameError
            ? 'feedback__modal__inputs__input feedback__modal__inputs__input--error'
            : 'feedback__modal__inputs__input';
        const emailInputClass: string = emailError
            ? 'feedback__modal__inputs__input feedback__modal__inputs__input--error'
            : 'feedback__modal__inputs__input';
        const messageInputClass: string = messageError
            ? 'feedback__modal__inputs__bottom__textarea feedback__modal__inputs__bottom__textarea--error'
            : 'feedback__modal__inputs__bottom__textarea';
        return (
            <div className="feedback">
                {showModal &&
                    (showSuccess ? (
                        <div className="feedback__modal">
                            <div className="feedback__modal__success">
                                <div className="feedback__modal__success__close-container">
                                    <div className="feedback__modal__success__close" onClick={this.onShowModal} />
                                </div>
                                <div className="feedback__modal__success__icon" />
                                <p className="feedback__modal__success__title">{this.props.t('feedback.successTitle')}</p>
                            </div>
                        </div>
                    ) : (
                        <div className="feedback__modal">
                            <div className="feedback__modal__header">
                                <p className="feedback__modal__header__title">{this.props.t('feedback.title')}</p>
                                <div className="feedback__modal__header__close" onClick={this.onShowModal} />
                            </div>
                            <div className="feedback__modal__inputs">
                                {/* <input
                                    className={nameInputClass}
                                    placeholder={this.props.t('feedback.namePlaceholder')}
                                    onChange={this.onNameChange}
                                />
                                {nameError != null && <p className="feedback__modal__inputs__error">{nameError}</p>} */}
                                {/* <input
                                    className={emailInputClass}
                                    placeholder={this.props.t('feedback.emailPlaceholder')}
                                    onChange={this.onEmailChange}
                                /> */}
                                {/* {emailError != null && <p className="feedback__modal__inputs__error">{emailError}</p>} */}
                                <div className="feedback__modal__inputs__bottom">
                                    <div style={{ display: 'flex', flexDirection: 'column', width: 234 }}>
                                        <textarea
                                            className={messageInputClass}
                                            placeholder={this.props.t('feedback.messagePlaceholder')}
                                            onChange={this.onMessageChange}
                                        />
                                        {messageError != null && <p className="feedback__modal__inputs__error">{messageError}</p>}
                                    </div>
                                    <div
                                        className="feedback__modal__inputs__bottom__send"
                                        onClick={this.onSend}
                                        style={messageError ? { marginBottom: 19 } : {}}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                <div className="feedback__button" onClick={this.onShowModal} />
            </div>
        );
    }
}

const mapStateToProps = (state: IRootState): Pick<IFeedbackProps, 'user'>  => ({
    user: state.authentication.account
});
const mapDispatchToProps = {
    closeFeedbackModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Feedback) as React.ComponentType<any>;
