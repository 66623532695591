import { ContentRating } from './contentRating';
import ContentType from './enums/contentType';
import { SearchType } from './search';

export interface SearchResult {
    id: number;
    title: string;
    subtitle?: string;
    type: SearchType;
    isPaid?: boolean;
}

export function convertContentRatingToSearchResult(rating: ContentRating): SearchResult {
    const isPaid = rating.type !== ContentType.MEDICINE_LEAFLET;
    return { id: rating.contentId!, title: rating.name, type: convertContentTypeToSearchType(rating.type!), isPaid };
}

function convertContentTypeToSearchType(type: ContentType): SearchType {
    switch (type) {
        case ContentType.DISEASE:
            return SearchType.SPECIALTY;
        case ContentType.ILLUSTRATED_PROCEDURE:
            return SearchType.ILLUSTRATED_PROCEDURE;
        case ContentType.LABORATORY:
            return SearchType.LABORATORY;
        case ContentType.MEDICAL_CALCULATOR:
            return SearchType.MEDICAL_CALCULATOR;
        case ContentType.MEDICAL_SCORE:
            return SearchType.MEDICAL_SCORE;
        case ContentType.MEDICINE_LEAFLET:
            return SearchType.SMART_BULAS;
        case ContentType.PEDIATRIC_DOSE:
            return SearchType.PEDIATRIC_DOSE;
        case ContentType.SEMIOLOGY:
            return SearchType.SEMIOLOGY;
        default:
            return SearchType.SPECIALTY;
    }
}

export default SearchResult;
