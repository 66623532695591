import React, { useEffect, useState } from 'react';
import { Collapse, Nav, Navbar, NavbarToggler } from 'reactstrap';
import { User } from '../../../model/user';
import { NavDropdown } from '../menu-components';
import MenuItem from '../menu-item';
import './header.scss';
import authUtil from '../../../api/authUtil';

interface Props {
    t: (path: string, params?: any) => string;
    onLogout: () => void;
    hasSubscription: boolean;
    account?: User;
    isOpen: boolean;
}

export const HeaderProfileDropdown = (props: Props): JSX.Element => {
    const [isOpen, setIsOpen] = useState(props.isOpen);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            window.removeEventListener('mousemove', handleClickOutside);
        };
    }, []);

    const handleClickOutside = event => {
        const navbarDiv = document.getElementById('header-profile-dropdown');
        if (navbarDiv && !navbarDiv.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const onClick = (): void => {
        setIsOpen(!isOpen);
    };
    return (
        <Navbar dark expand="sm" id="header-profile-dropdown">
            <NavbarToggler aria-label="Menu" />
            <Collapse navbar>
                <Nav
                    id="header-tabs"
                    className={`header-profile-dropdown__nav ${isOpen ? 'header-profile-dropdown__nav--open' : ''}`}
                    navbar
                    onClick={onClick}
                >
                    <NavDropdown
                        name={
                            props.t('header.hello') +
                            (props.account !== undefined
                                ? props.account?.customer?.name != null
                                    ? `${props.account?.customer?.name} !`
                                    : `${props.account.email!.split('@')[0]}`
                                : 'Usuário !')
                        }
                        id="account-menu"
                    >
                        <MenuItem to="/preferences/profile" className="first-dropdown-item">
                            <div className="profile" />
                            {props.t('header.account.profile')}
                        </MenuItem>

                        <MenuItem to="/preferences/my-subscription">
                            <div className="subscription" />
                            {props.t('header.account.subscribe')}
                        </MenuItem>

                        {!props.hasSubscription && (
                            <MenuItem to="/preferences/free-diseases">
                                <div className="free-list" />
                                {props.t('header.account.freeList')}
                            </MenuItem>
                        )}

                        {!props.hasSubscription && (
                            <MenuItem to="/preferences/free-procedures">
                                <div className="free-list" />
                                {props.t('header.account.freeProcedureList')}
                            </MenuItem>
                        )}

                        {!props.hasSubscription && (
                            <MenuItem to="/preferences/free-calculators">
                                <div className="free-list" />
                                {props.t('header.account.freeCalculatorList')}
                            </MenuItem>
                        )}

                        {!props.hasSubscription && (
                            <MenuItem to="/preferences/free-scores">
                                <div className="free-list" />
                                {props.t('header.account.freeScoreList')}
                            </MenuItem>
                        )}

                        {!props.hasSubscription && (
                            <MenuItem to="/preferences/free-semiologies">
                                <div className="free-list" />
                                {props.t('header.account.freeSemiologyList')}
                            </MenuItem>
                        )}

                        {!props.hasSubscription && (
                            <MenuItem to="/preferences/free-laboratories">
                                <div className="free-list" />
                                {props.t('header.account.freeLaboratoryList')}
                            </MenuItem>
                        )}

                        <MenuItem to="/content-rating">
                            <div className="chartLineUp" />
                            {props.t('header.account.contentRating')}
                        </MenuItem>

                        <MenuItem to="/editor-content">
                            <div className="trophy" />
                            {props.t('header.account.editorContent')}
                        </MenuItem>

                        <MenuItem to="/recent-history">
                            <div className="arroUpLeft" />
                            {props.t('header.account.recentHistory')}
                        </MenuItem>

                        <MenuItem to="/preferences/faq">
                            <div className="doubts" />
                            {props.t('header.account.doubts')}
                        </MenuItem>

                        {authUtil.hasCompanyManagerPermission() && (
                            <MenuItem to="/company-dashboard">
                                <div className="doubts" />
                                {props.t('header.account.companyManage')}
                            </MenuItem>
                        )}

                        <div style={{ cursor: 'pointer' }} className="dropdown-item logout-item" onClick={props.onLogout}>
                            <div className="logout" />
                            {props.t('header.account.logout')}
                        </div>
                    </NavDropdown>
                </Nav>
            </Collapse>
        </Navbar>
    );
};
