import _ from 'lodash';
import * as React from 'react';
import { Note, NoteType } from '../../model/note';
import { SimpleId } from '../../model/simpleId';
import NotesService from '../../services/notesService';
import './annotation.scss';

export interface IAnnotationProps {
    showAnnotation?: boolean;
    note?: Note;
    diseaseId?: SimpleId;
    semiologyId?: SimpleId;
    leafletId?: SimpleId;
    pediatricDoseId?: SimpleId;
    illustratedProcedureId?: SimpleId;
    laboratoryId?: SimpleId;
}

export interface IAnnotationState {
    hasNewChange?: boolean;
    isSaving?: boolean;
    isSaved?: boolean;

    textValue: string;
    latestSavedValues: string[];
}

export default class Annotation extends React.Component<IAnnotationProps, IAnnotationState> {
    constructor(props: IAnnotationProps) {
        super(props);

        this.state = {
            textValue: props.note ? props.note.content : '',
            latestSavedValues: [props.note ? props.note.content : '']
        };
    }

    // handlers
    private save = async (value: string): Promise<void> => {
        const { leafletId, diseaseId, semiologyId, laboratoryId, pediatricDoseId, illustratedProcedureId } = this.props;
        const type =
            diseaseId != null
                ? NoteType.DISEASE
                : leafletId != null
                ? NoteType.MEDICINE_LEAFLET
                : semiologyId != null
                ? NoteType.SEMIOLOGY
                : pediatricDoseId != null
                ? NoteType.PEDIATRIC_DOSE
                : illustratedProcedureId != null
                ? NoteType.ILLUSTRATED_PROCEDURE
                : NoteType.LABORATORY;
        const note: Note = {
            illustratedProcedure: illustratedProcedureId != null ? illustratedProcedureId : undefined,
            pediatricDose: pediatricDoseId != null ? pediatricDoseId : undefined,
            medicineLeaflet: leafletId != null ? leafletId : undefined,
            disease: diseaseId != null ? diseaseId : undefined,
            semiology: semiologyId != null ? semiologyId : undefined,
            laboratory: laboratoryId != null ? laboratoryId : undefined,
            type,
            content: value
        };
        setTimeout(() => this.setState({ isSaving: false }), 15000);
        NotesService.save(note);
        this.setState({
            isSaved: true,
            isSaving: false,
            hasNewChange: true,
            latestSavedValues: this.state.latestSavedValues.concat(value)
        });
        clearTimeout(0);
    };
    private debounceSave = _.debounce(this.save, 2000);
    private onChangeAnnotationText = event => {
        const { value } = event.target;

        this.setState({
            textValue: value,
            isSaving: true,
            isSaved: false
        });

        this.debounceSave(value);
    };

    private onUndo = (): void => {
        const { latestSavedValues } = this.state;
        if (latestSavedValues.length > 1) {
            const latestSavedValue: string = latestSavedValues[latestSavedValues.length - 2];
            this.setState(
                {
                    textValue: latestSavedValue,
                    latestSavedValues: []
                },
                () => {
                    this.save(latestSavedValue);
                }
            );
        }
    };
    // renders
    render() {
        const { textValue, hasNewChange, isSaving, isSaved } = this.state;
        return this.props.showAnnotation ? (
            <div className="annotation">
                <div className="annotation__menu">
                    {hasNewChange && (
                        <div className="annotation__undo" onClick={this.onUndo}>
                            <div className="annotation__undo__icon" />
                            <span className="annotation__undo__text">{'Desfazer'}</span>
                        </div>
                    )}
                    <div className="annotation__saving">
                        {isSaving && <div id={'saving-icon'} className="annotation__saving__icon" />}
                        {isSaving && <span className="annotation__saving__text">{'Salvando'}</span>}
                        {isSaved && <span className="annotation__saving__text">{'Salvo'}</span>}
                    </div>
                </div>
                <textarea
                    placeholder={'Faça aqui suas anotações'}
                    className="annotation__text"
                    onChange={this.onChangeAnnotationText}
                    value={textValue}
                />
            </div>
        ) : (
            <div />
        );
    }
}
