enum ContentType {
    DISEASE = 'DISEASE',
    MEDICINE_LEAFLET = 'MEDICINE_LEAFLET',
    ILLUSTRATED_PROCEDURE = 'ILLUSTRATED_PROCEDURE',
    SEMIOLOGY = 'SEMIOLOGY',
    LABORATORY = 'LABORATORY',
    PEDIATRIC_DOSE = 'PEDIATRIC_DOSE',
    MEDICAL_CALCULATOR = 'MEDICAL_CALCULATOR',
    MEDICAL_SCORE = 'MEDICAL_SCORE',
    MEDICAL_CRITERIA = 'MEDICAL_CRITERIA'
}

export default ContentType;
