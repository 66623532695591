import * as React from 'react';
import DropdownSearch from '../../components/dropdown-search/dropdown-search';
import { DiagnAppSearchParams } from '../../model/diagnAppSearchParams';
import { RiskFactor } from '../../model/riskFactor';
import DiagnAppService from '../../services/diagnAppService';
import './diagnapp.scss';

export interface IDropdownSearchRiskFactorsProps {
    inputNumber: number;
    onClickItem: (item: RiskFactor) => void;
    onRemove: () => void;
    getSearchParams: () => DiagnAppSearchParams;
    selectedItem?: RiskFactor;
}
export interface IDropdownSearchRiskFactorsState {
    selectedItem?: RiskFactor;
    searchResults: RiskFactor[];
}

export default class DropdownSearchRiskFactors extends React.Component<IDropdownSearchRiskFactorsProps, IDropdownSearchRiskFactorsState> {
    private currentLetter: string = '';
    constructor(props) {
        super(props);

        this.state = {
            searchResults: [],
            selectedItem: props.selectedItem
        };
    }
    componentDidMount(): void {
        this.search('');
    }
    // Api Calls
    private search = async (value: string): Promise<any> => {
        const params: DiagnAppSearchParams = {
            ...this.props.getSearchParams(),
            name: value
        };
        const result: RiskFactor[] = await DiagnAppService.searchRiskFactors(params);
        this.setState({
            searchResults: result
        });
    };

    // Handlers
    private onClickItem = (item: any) => {
        this.setState({ selectedItem: item }, () => {
            this.props.onClickItem(item);
        });
    };
    private onRemove = () => {
        this.setState({
            selectedItem: undefined
        });
        this.props.onRemove();
    };

    // Renders
    render() {
        const { selectedItem, searchResults } = this.state;
        const { inputNumber } = this.props;
        return (
            <div className="dropdown-select-search">
                <DropdownSearch
                    data={searchResults}
                    getData={this.search}
                    onClickItem={this.onClickItem}
                    title={`Adicionar fator de risco ${inputNumber}`}
                    style={{ width: 317, backgroundColor: '#f6f6f6' }}
                    notAlphabeticalOrder
                    selectedRender={
                        selectedItem
                            ? () => (
                                  <div className="dropdown-select-search__selected">
                                      <div className="dropdown-select-search__selected__left">
                                          <div className="dropdown-select-search__selected__rect" />
                                          <div className="dropdown-select-search__selected__text">
                                              <p className="dropdown-select-search__selected__title">{selectedItem.name}</p>
                                          </div>
                                      </div>
                                      <div className="dropdown-select-search__selected__remove-icon" onClick={this.onRemove} />
                                  </div>
                              )
                            : undefined
                    }
                    resultItemStyle={{ fontWeight: 'normal' }}
                />
            </div>
        );
    }
}
