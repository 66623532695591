import { AxiosResponse } from 'axios';
import { DiseaseTree, GroupsDiseases } from '../model/apiResponses/GroupsDiseases';
import { SpecialtyType } from '../model/SpecialtyType';
import { api } from './api';

export const diseaseGroupsApi = () => {
  const getGroupsAndDiseasesBySpecialty = (specialtyType: SpecialtyType): Promise<AxiosResponse<GroupsDiseases>> => {
    return api.get<GroupsDiseases>(`/groups-diseases/category?category=${specialtyType}`);
  };
  const getGroupsAndDiseasesByGroups = (groupId: number): Promise<AxiosResponse<GroupsDiseases>> => {
    return api.get<GroupsDiseases>(`/groups-diseases/${groupId}`);
  };
  const getDiseaseTree = (diseaseId: number, category: SpecialtyType): Promise<AxiosResponse<DiseaseTree>> => {
    return api.get<DiseaseTree>(`groups-diseases/tree/disease/${diseaseId}?category=${category}`);
  };


  return {
    getGroupsAndDiseasesBySpecialty,
    getGroupsAndDiseasesByGroups,
    getDiseaseTree
  };
};

export default diseaseGroupsApi();
