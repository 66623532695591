import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../components/loading/loading';
import { RecentHistory } from '../../model/recentHistory';
import { Search, SearchType } from '../../model/search';
import { SearchResult } from '../../model/searchResult';
import { User } from '../../model/user';
import { IRootState } from '../../reducer';
import { openFeedbackModal, openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import { SearchState } from '../../reducer/search/types';
import SearchService from '../../services/searchService';
import { ResultItem } from '../menu/menu-components';
import './search.scss';

export interface SearchScreenProps extends RouteComponentProps<{}> {
    t: any;
    history: any;
    searchState: SearchState;
    recents: RecentHistory[];
    account: User;
    openPaidDiseaseModal: () => void;
    openFeedbackModal: () => void;
}

const SearchScreen = (props: SearchScreenProps) => {
    const [results, setResults] = useState<SearchResult[]>([]);
    const [search, setSearch] = useState<Search | undefined>();
    const [hasLoaded, setHasLoaded] = useState<boolean | undefined>();

    useEffect(() => {
        if (props.searchState.search) {
            const newSearch: Search = { ...props.searchState.search };
            setSearch(newSearch);
            delete newSearch.limit;
            SearchService.search(newSearch)
                .then(setResults)
                .finally(() => setHasLoaded(true));
        }
    }, []);

    useEffect(() => {
        setHasLoaded(undefined);
        const newSearch: Search = { ...props.searchState.search };
        delete newSearch.limit;
        SearchService.search(newSearch)
            .then(setResults)
            .finally(() => {
                setSearch(props.searchState.search);
                setHasLoaded(true);
            });
    }, [props.searchState.search]);

    const onClickItem = (item: SearchResult): void => {
        if (item.isPaid && !(props.account.customer && props.account.customer.hasSubscription)) {
            props.openPaidDiseaseModal();
            return;
        }
        switch (item.type) {
            case SearchType.SPECIALTY:
                props.history.push(`/disease/${item.id}`);
                break;
            case SearchType.SMART_BULAS:
                props.history.push(`/smart-bulas/${item.id}`);
                break;
            case SearchType.ILLUSTRATED_PROCEDURE:
                props.history.push(`/procedure/${item.id}`);
                break;
            case SearchType.MEDICAL_CALCULATOR:
                props.history.push(`/calculator/${item.id}`);
                break;
            case SearchType.MEDICAL_SCORE:
                props.history.push(`/scores/${item.id}`);
                break;
            case SearchType.MEDICAL_CRITERIA:
                props.history.push(`/criteria/${item.id}`);
                break;
            case SearchType.CID:
                props.history.push(`/cid/${item.id}`);
                break;
            case SearchType.SEMIOLOGY:
                props.history.push(`/semiology/${item.id}`);
                break;
            case SearchType.PEDIATRIC_DOSE:
                props.history.push(`/pediatric-dose/${item.id}`);
                break;
            case SearchType.LABORATORY:
                props.history.push(`/laboratory/${item.id}`);
                break;
            case SearchType.SUS:
                props.history.push(`/sus/${item.id}`);
                break;
            default:
                props.history.push('/construction');
        }
    };
    const onPressNoResultsBtn = () => {
        props.openFeedbackModal();
    };

    const hasResults: boolean = results.length > 0 && search != null;
    return (
        <div className="screen-container">
            <div className="content-container" style={hasResults ? {} : hasLoaded ? { alignItems: 'center' } : {}}>
                {search != null && (
                    <span style={{ marginTop: 40 }}>
                        <span className="results-title">{props.t('specialty.resultsTitle1')}</span>
                        <span className=" results-title results-title--bold">{`“${search.search}”`}</span>
                        <span className="results-title">{props.t(`specialty.resultsTitle${_.upperFirst(_.camelCase(search.type))}`)}</span>
                    </span>
                )}
                {hasLoaded === undefined && <Loading style={{ marginTop: 200 }} />}
                {hasResults && hasLoaded && (
                    <div className="results">
                        {results.map((it, index) => (
                            <ResultItem
                                key={index}
                                result={it}
                                index={index}
                                onClick={onClickItem}
                                hasSubscription={props.account.customer && props.account.customer.hasSubscription}
                            />
                        ))}
                    </div>
                )}
                {!hasResults && hasLoaded && (
                    <div className="no-results">
                        <div className="no-results__img" />
                        <span className="no-results__title">{props.t('specialty.emptyResults')}</span>
                        <a className="no-results__btn" onClick={onPressNoResultsBtn}>
                            <span className="no-results__btn__label">{props.t('specialty.emptyResultsBtnLabel')}</span>
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({ search, authentication, recentHistory }: IRootState) => ({
    searchState: search,
    recents: recentHistory.ordenedRecents,
    account: authentication.account
});

const mapDispatchToProps = {
    openPaidDiseaseModal,
    openFeedbackModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SearchScreen) as React.ComponentType<any>;
