import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { IllustratedProcedure } from '../../../model/illustratedProcedure';
import { IRootState } from '../../../reducer';
import IllustratedProcedureService from '../../../services/illustratedProcedureService';
import './components.scss';

export interface Props extends RouteComponentProps<{}> {
    t: (path: string, param?: any) => string;
    history: any;
}

const FreeProcedures = (props: Props) => {
    const [procedures, setProcedures] = useState<IllustratedProcedure[]>([]);

    const getProcedures = async (): Promise<void> => {
        const response: IllustratedProcedure[] = await IllustratedProcedureService.getFreeIllustratedProcedures();
        setProcedures(response);
    };

    useEffect(() => {
        getProcedures();
    }, []);

    // Renders
    return (
        <div className="component">
            <h1 className="component__title">{props.t('preferences.freeProceduresComponent.title')}</h1>
            <div className="component__content">
                {procedures && procedures.map((it, index) => (
                    <a key={index} className="component__card-item" href={`/procedure/${it.id}`}>
                        <div className="component__card-item__content">
                            <div className="component__card-item__content__row">
                                <span className="component__card-item__label">{it.name}</span>
                                <div className="arrow-right-img" />
                            </div>
                        </div>
                        <div className="component__card-item__extension" />
                    </a>
                ))}
            </div>
        </div>
    );
}

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(FreeProcedures) as React.ComponentType<any>;
