import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '../button/button';
import './cancel-subscription-modal.scss';

export interface ICancelSubscriptionModalProps {
    t: (path: string, params?: any) => string;
    history: any;

    open: boolean;
    closeModal: () => void;

    appShop: string;
}

export class CancelSubscriptionModal extends React.Component<ICancelSubscriptionModalProps> {
    constructor(props) {
        super(props);

        this.state = {};
    }
    private onClose = () => {
        this.props.closeModal();
    };
    private onButtonPress = () => {
        this.props.closeModal();
    };
    render() {
        const { open, appShop } = this.props;
        if (!open) {
            return <div />;
        }
        return (
            <div className="cancel-subscription-modal">
                <div className="cancel-subscription-modal__full-content">
                    <a className="close-btn" onClick={this.onClose}>
                        <div className="close-btn__icon" />
                    </a>
                    <div className="cancel-subscription-modal__content">
                        <div className="top-content">
                            <div className="top-content__logo">
                                <div className="top-content__logo__img"/>
                            </div>
                            <span className="top-content__title">{this.props.t('cancelSubscriptionModal.title')}</span>
                            <span className="top-content__subtitle">{this.props.t('cancelSubscriptionModal.subtitle', { value: appShop })}</span>
                        </div>
                        <div className="bottom-content">
                            <div className="bottom-content__btn-container">
                                <Button
                                    label={this.props.t('cancelSubscriptionModal.buttonLabel').toUpperCase()}
                                    onPress={this.onButtonPress}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(CancelSubscriptionModal) as React.ComponentType<any>;
