import styled from 'styled-components';

export const MainContainer = styled.div`
    height: 65vh;
    width: 55vh;
    left: 35vw;
    top: 20vh;
    position: absolute;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    padding: 32px 16px 16px 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
`;

export const ListContainer = styled.div`
    overflow-y: auto;
    height: 70%;
    width: 80%;
`;

export const SelectedIcon = styled.div`
    height: 24px;
    width: 24px;
`;

export const LineContainer = styled.div<{isSelected: boolean}>`
    display: flex;
    cursor: pointer;
    min-height: 48px;
    border-radius: 28px;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0 5px 5px;
    margin-top: 10px;
    background-color: ${props => props.isSelected ? '#F4F5F5' : '#ffffff'};
`;

export const ButtonContainer = styled.div`
    gap: 4px;
    width: 260px;
    display: flex;
    cursor: pointer;
    padding: 10px 16px;
    border-radius: 28px;
    background: #EF7D00;
    justify-content: center;
    margin-bottom: 2vh;
`;

export const ButtonText = styled.span`
    color: #FFFFFF;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
`;

export const Title = styled.span`
    color: #414E52;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    font-style: normal;
    font-family: Raleway;
`;

export const ThemeName = styled.span`
    color: #414E52;
    text-align: center;
    font-family: Raleway;
    margin-left: 2%;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
`;

export const SearchInput = styled.input`
    min-height: 48px;
    border-radius: 28px;
    align-items: center;
    padding: 5px 0 5px 5px;
    margin-top: 10px;
    text-align: center;
    background-color: #F4F5F5;
    border: transparent 1px solid;
    width: 80%;
    margin-bottom: 10px;
    &:focus {
        border: transparent 1px solid;
        outline: none !important;
    }
    &:active {
        border: transparent 1px solid;
    }
`;
