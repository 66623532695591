import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { MedicalCriteria } from '../../../model/medical-criteria/medical-criteria';
import { IRootState } from '../../../reducer';
import MedicalCriteriaService from '../../../services/medical-criteria.service';
import './components.scss';

interface Props extends RouteComponentProps<{}> {
  t: (path: string, param?: any) => string;
  history: any;
}

const FreeCriteria = (props: Props) => {
  const [criteria, setCriteria] = useState<MedicalCriteria[]>([]);

  useEffect(() => {
    MedicalCriteriaService.getAvailableForFree().then((response) => {
      setCriteria(response);
    });
  }, []);

  return (
    <div className="component">
      <h1 className="component__title">
        {props.t('preferences.freeCriteriaComponent.title')}
      </h1>
      <div className="component__content">
        {criteria?.map((it, index) => (
          <a
            key={index}
            className="component__card-item"
            href={`/criteria/${it.id}`}
          >
            <div className="component__card-item__content">
              <div className="component__card-item__content__row">
                <span className="component__card-item__label">{it.name}</span>
                <div className="arrow-right-img" />
              </div>
            </div>
            <div className="component__card-item__extension" />
          </a>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(FreeCriteria) as React.ComponentType<any>;
