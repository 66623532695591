import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IRootState } from '../../reducer';
import { closeErrorPaymentModal } from '../../reducer/globalModal/actions';
import Button from '../button/button';
import './error-payment-modal.scss';

export interface IErrorPaymentModalProps {
    t: (path: string, params?: any) => string;
    history: any;
    globalModal: any;
    open: boolean;
    closeModal: () => void;
}

const ErrorPaymentModal = (props: IErrorPaymentModalProps) => {

    const onClose = () => {
        props.closeModal();
    };

    const onButtonPress = () => {
        props.globalModal.onPressPaymentError();
        props.closeModal();
    };

    if (!props.open) {
        return <div />;
    }

    return (
        <div className="expired-hour-modal">
            <div className="expired-hour-modal__full-content">
                <div className="expired-hour-modal__content">
                    <div className="top-content">
                        <div className="top-content__logo">
                            <div className="top-content__logo__img" />
                        </div>
                        <span className="top-content__title">{props.t('errorPaymentModal.title')}</span>
                        <span className="top-content__subtitle">{props.t('errorPaymentModal.subtitle')}</span>
                    </div>
                    <div className="bottom-content">
                        <div className="bottom-content__btn-container">
                            <Button
                                label={props.t('errorPaymentModal.buttonLabel1')}
                                onPress={onClose}
                            />
                        </div>
                    </div>
                    <div className="bottom-content">
                        <div className="bottom-content__btn-container">
                            <Button
                                label={props.t('errorPaymentModal.buttonLabel2')}
                                onPress={onButtonPress}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

const mapStateToProps = (state: IRootState): Pick<IErrorPaymentModalProps, 'globalModal'> => ({
    globalModal: state.globalModal
});

const mapDispatchToProps = {
    closeModal: closeErrorPaymentModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ErrorPaymentModal) as React.ComponentType<any>;
