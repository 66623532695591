import moment from 'moment';
import 'moment/locale/pt-br';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { OnDutyDoctor, useDividerOnDutyContext } from '../context/divider-on-duty-provider';
import '../divider-on-duty.scss';
import { BoxPaginationContainer, DoctorIndexContainer, Index, Info, InfoContainer, LineContainer, LogoContainer, NameInput, PrintLogoSubtitle, PrintMainContainer, PrintSubtitle, ScaleTime, Subtitle, Title } from '../on-duty-styles';
import { DoctorsScale } from './share-step';

interface Props {
    t: any;
    scales: DoctorsScale[];
    doctorsWithMinutesLeft: OnDutyDoctor[];
}

const ScalePrint = ({ t, scales, doctorsWithMinutesLeft }: Props) => {
    const { startTime, endTime, doctors } = useDividerOnDutyContext();

    const timePerDoctor = (moment.duration(endTime.diff(startTime)).asMinutes() / doctors.length);
    const doctorsNameWithMinutesLeft = doctorsWithMinutesLeft.map(it => it.name).join(doctorsWithMinutesLeft.length === 2 ? ' e ' : ', ');

    return (
        <PrintMainContainer>
            <LogoContainer>
                <img className={'on-duty-logo'} src={require('../../../images/icons/logo.svg')} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '20px' }}>
                    <Title style={{ marginBottom: 0 }}>{t([`onDuty.title`])}</Title>
                    <PrintLogoSubtitle>{'wemeds.com.br'}</PrintLogoSubtitle>
                </div>
            </LogoContainer>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <PrintSubtitle>{t(['onDuty.shareStep.title'])}</PrintSubtitle>
                <BoxPaginationContainer>
                    <Subtitle style={{ fontWeight: 500, fontSize: 16, marginRight: '5px' }}>{moment().startOf('day').add(timePerDoctor, 'minutes').format('HH[h ]mm[min ]')}</Subtitle>
                    <Subtitle style={{ fontWeight: 400, fontSize: 14 }}>{t([`onDuty.shareStep.subtitle`])}</Subtitle>
                </BoxPaginationContainer>
            </div>
            {scales.map(scale => (
                <LineContainer key={scale.doctor.index} style={{ backgroundColor: 'white' }}>
                    <DoctorIndexContainer style={{ width: '40px', marginLeft: '10px' }}>
                        <Index style={{ color: 'white' }}>{scale.doctor.index + 1}</Index>
                    </DoctorIndexContainer>
                    <NameInput value={scale.doctor.name} readOnly />
                    <div style={{ width: '70%' }}>
                        <ScaleTime>{`${scale.startHour.format('HH[:]mm')} > ${scale.endHour.format('HH[:]mm')}`}</ScaleTime>
                    </div>
                </LineContainer>
            ))}
            {doctorsWithMinutesLeft.length > 0 && <InfoContainer>
                <div className={'on-duty-info'} />
                <Info>{t([`onDuty.${doctorsWithMinutesLeft.length > 1 ? 'minutesLefts' : 'minutesLeft'}`], { name: doctorsNameWithMinutesLeft })}</Info>
            </InfoContainer>}
            <img className={'on-duty-qr-code'} src={require('../../../images/qr-wemeds.png')} />
        </PrintMainContainer>
    );
}

export default compose(connect(null, null), withTranslation())(ScalePrint) as React.ComponentType<any>;