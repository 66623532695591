import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import FontResizer from '../../components/font-resizer/font-resizer';
import { Favorite, FavoriteType } from '../../model/favorite';
import FavoritesService from '../../services/favoritesService';
import { useMedicalScoreContext } from './medical-score.provider';
import './medical-score.scss';

interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
}

const MedicalScoreHeader = (props: Props) => {
    const {
        fontSize,
        setFontSize,
        showAnnotation,
        showResizerModal,
        setShowAnnotation,
        setShowResizerModal,
        medicalScore,
        setMedicalScore,
        showFavoriteSuccessToast,
        setShowFavoriteSuccessToast
    } = useMedicalScoreContext();

    const onFavorite = async (): Promise<void> => {
        const isFavorited = medicalScore?.favorite?.isActive;
        const params: Favorite = {
            type: FavoriteType.MEDICAL_SCORE,
            medicalScore: {
                id: medicalScore?.id!
            },
            isActive: isFavorited ? false : undefined
        };
        setMedicalScore({ ...medicalScore, favorite: { ...params, isActive: isFavorited ? false : true } });
        setShowFavoriteSuccessToast(!isFavorited);
        FavoritesService.favorite(params).then(() => {
            setTimeout(() => setShowFavoriteSuccessToast(false), 3000);
        });
    };

    return (
        <div className={'dose-header-container'}>
            <span className={'score-header-name'}>{medicalScore?.name ?? ''}</span>
            <div className={'dose-icons-container'}>
                <div onClick={() => setShowAnnotation(!showAnnotation)}
                     className={`dose-icon-pencil${medicalScore?.note?.isActive ? '--on' : ''}`} />
                <div onClick={onFavorite}
                     className={`dose-icon-favorite${medicalScore?.favorite?.isActive ? '--on' : ''}`} />
                <div onClick={() => setShowResizerModal(!showResizerModal)}
                     className={`dose-icon-font-resizer${showResizerModal ? '--on' : ''}`} />
            </div>
            {showResizerModal && (
                <div className="disease-content__slider-container" style={{ top: '194px', right: '26%' }}>
                    <FontResizer
                        changeFontSize={setFontSize}
                        fontSize={fontSize}
                    />
                </div>
            )}
            {showFavoriteSuccessToast && (
                <div className="disease-content__favorite-success-toast" style={{ top: '193px', right: '30.4vw' }}>
                    <div className="disease-content__white-star-img" />
                    <span
                        className="disease-content__favorite-success-msg">{props.t('cid.favoriteSuccessMessage')}</span>
                </div>
            )}
        </div>
    );
};

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(MedicalScoreHeader) as React.ComponentType<any>;
