import React, { useCallback, useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import FlashcardAnswerValue from '../../../model/enums/flashcard-answer-value';
import { FlashcardView } from '../../../model/flashcard/flashcard';
import { PerformanceData } from '../../../model/flashcard/performance-data';
import { useFlashcardViewContext } from '../flashcard-carousel/flashcard.provider';
import '../flashcard.scss';
import {
    AnswerQuantityContainer, AnswersQuantityContainer, AnswersQuantityLabel,
    CategoriesContainer, CategoriesData, CategoriesTitle,
    CategoryContainer,
    Icon,
    MainContainer, QuantityContainer, QuantityLabel
} from './flashcard-performance.styles';

interface Props extends WithTranslation {
    data: PerformanceData;
    width?: number;
}

const FlashcardPerformanceData = (props: Props) => {
    const {t, data} = props;
    const [total, setTotal] = React.useState<number>(0);

    useEffect(() => {
        if (data != null) {
            const rightCount = data.answers.filter(it => it.answer === FlashcardAnswerValue.RIGHT).length;
            const almostCount = data.answers.filter(it => it.answer === FlashcardAnswerValue.ALMOST).length;
            const wrongCount = data.answers.filter(it => it.answer === FlashcardAnswerValue.WRONG).length;
            setTotal(rightCount + almostCount + wrongCount);
        }
    }, [data]);

    const convertSecondsToLabel = (secondsSpent: number) => {
        return secondsSpent > 59
            ? `00:${String(Math.floor(secondsSpent / 60)).padStart(2, '0')}:${String(Math.floor(secondsSpent % 60)).padStart(2, '0')}`
            : `${secondsSpent} seg`;
    };

    return (
        <CategoriesContainer width={props.width}>
            <CategoryContainer>
                <Icon className="orange-pencil-line-img" />
                <CategoriesData>{data.answeredQuestions ?? 0}</CategoriesData>
                <CategoriesTitle>{t('flashcardPerformanceData.answered')}</CategoriesTitle>
            </CategoryContainer>
            <CategoryContainer>
                <Icon className="orange-flashcards-img" />
                <CategoriesData>{`${data.answeredQuestions ?? 0}`}</CategoriesData>
                <CategoriesTitle>{t('flashcardPerformanceData.totalCards')}</CategoriesTitle>
            </CategoryContainer>
            <CategoryContainer>
                <Icon className="orange-clock-afternoon-img" />
                <CategoriesData>{convertSecondsToLabel(Math.floor(data.secondsSpent! / (total > 0 ? total : 1)))}</CategoriesData>
                <CategoriesTitle>{t('flashcardPerformanceData.averagePerCard')}</CategoriesTitle>
            </CategoryContainer>
            <CategoryContainer>
                <Icon className="orange-timer-img" />
                <CategoriesData>{convertSecondsToLabel(Math.floor(data.secondsSpent ?? 0))}</CategoriesData>
                <CategoriesTitle>{t('flashcardPerformanceData.totalTime')}</CategoriesTitle>
            </CategoryContainer>
        </CategoriesContainer>
    );
};

export default withTranslation()(FlashcardPerformanceData);
