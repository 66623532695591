import { Slider, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import CidGender from '../../model/enums/cidGender';
import { Favorite, FavoriteType } from '../../model/favorite';
import { SusProcedure } from '../../model/susProcedure';
import { IRootState } from '../../reducer';
import AnalyticsService from '../../services/analytics-service';
import FavoritesService from '../../services/favoritesService';
import './sus.scss';

export interface SusLastColumnProps extends RouteComponentProps<{}> {
    t: any;
    data: SusProcedure;
    isFavorited?: boolean;
}

export interface SusLastColumnState {
    fontClassName: string;
    starClassName: string;

    showFontSlider?: boolean;
    fontSliderValue: number;
    descriptionFontSize: number;

    isFavorited?: boolean;
    showFavoriteSuccessToast?: boolean;
}

const CustomSlider = withStyles({
    root: {
        color: '#e7e7e7',
        height: 4,
        opacity: 1
    },
    thumb: {
        width: 16,
        height: 16,
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.32)',
        backgroundColor: '#ffffff',
        marginTop: -6
    },
    mark: {
        backgroundColor: '#e7e7e7',
        height: 9,
        width: 4,
        borderRadius: 3,
        marginTop: -2.02,
        zIndex: -1
    },
    active: {},
    track: {
        height: 4,
        borderRadius: 3,
        opacity: 1,
        backgroundImage: 'linear-gradient(90deg, rgba(44,147,147,1) 0%, rgba(66,210,206,1) 100%)'
    },
    rail: {
        height: 4,
        borderRadius: 3,
        opacity: 1,
        backgroundColor: '#e7e7e7'
    }
})(Slider);

export class SusLastColumn extends React.Component<SusLastColumnProps, SusLastColumnState> {
    constructor(props: Readonly<SusLastColumnProps>, context?: any) {
        super(props, context);

        this.state = {
            fontClassName: 'last-column__font-img',
            starClassName: props.isFavorited ? 'last-column__star-img last-column__star-img--orange' : 'last-column__star-img',
            fontSliderValue: 0,
            descriptionFontSize: 13,
            isFavorited: props.isFavorited
        };
    }

    componentDidMount() {
        AnalyticsService.susClick(this.props.data);
    }

    componentWillReceiveProps(newProps: SusLastColumnProps): void {
        if (newProps.isFavorited !== this.props.isFavorited) {
            this.setState({
                isFavorited: newProps.isFavorited,
                starClassName: newProps.isFavorited ? 'last-column__star-img last-column__star-img--orange' : 'last-column__star-img'
            });
        }
    }

    private onResizeFont = (): void => {
        const { showFontSlider } = this.state;
        this.setState({
            fontClassName: showFontSlider ? 'last-column__font-img' : 'last-column__font-img last-column__font-img--orange',
            showFontSlider: !showFontSlider
        });
    };
    private onFavorite = async (): Promise<void> => {
        const { data } = this.props;
        const { isFavorited } = this.state;
        const params: Favorite = {
            type: FavoriteType.SUS,
            susProcedure: {
                id: data.id
            },
            isActive: isFavorited ? false : undefined
        };
        FavoritesService.favorite(params).then(() => {
            this.setState(
                {
                    starClassName: isFavorited ? 'last-column__star-img' : 'last-column__star-img last-column__star-img--orange',
                    isFavorited: !isFavorited,
                    showFavoriteSuccessToast: !isFavorited
                },
                () => {
                    setTimeout(() => this.setState({ showFavoriteSuccessToast: false }), 3000);
                }
            );
        });
    };
    private onChangeSlider = (event: React.ChangeEvent<{}>, value: number | number[]): void => {
        this.setState({
            fontSliderValue: value as number,
            descriptionFontSize: value as number
        });
    };

    private renderGender = (gender?: CidGender): JSX.Element => {
        switch (gender) {
            case CidGender.MALE:
                return <div className="last-column__gender__male-img" />;
            case CidGender.FEMALE:
                return <div className="last-column__gender__female-img" />;
            case CidGender.BOTH:
                return (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="last-column__gender__female-img" />
                        <div className="last-column__gender__male-img" />
                    </div>
                );
            default:
                return <div />;
        }
    };
    render() {
        const { data } = this.props;
        const { fontClassName, starClassName, showFontSlider, fontSliderValue, descriptionFontSize, showFavoriteSuccessToast } = this.state;
        return (
            <div className="last-column">
                <div className="last-column__header">
                    <a className={fontClassName} onClick={this.onResizeFont} />
                    <a className={starClassName} onClick={this.onFavorite} />
                </div>
                <div className="last-column__content">
                    {showFontSlider && (
                        <div className="last-column__slider-container">
                            <CustomSlider
                                className="last-column__slider"
                                value={fontSliderValue}
                                max={17}
                                min={13}
                                step={2}
                                marks={[{ value: 15, label: '' }]}
                                onChange={this.onChangeSlider}
                            />
                        </div>
                    )}
                    {showFavoriteSuccessToast && (
                        <div className="last-column__favorite-success-toast">
                            <div className="last-column__white-star-img" />
                            <span className="last-column__favorite-success-msg">{this.props.t('sus.favoriteSuccessMessage')}</span>
                        </div>
                    )}
                    <span className="last-column__code">{data.code}</span>
                    <div className="last-column__description">
                        <p className="last-column__description__text" style={{ fontSize: descriptionFontSize }}>
                            {data.description}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SusLastColumn) as React.ComponentType<any>;
