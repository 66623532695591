import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import '../../App.scss';
import { IRootState } from '../../reducer';
import { closeImageModal } from '../../reducer/globalModal/actions';

export interface ImageModalProps {
    t: any;
    image: string;
    open?: boolean;
    onClose: () => void;
}

export interface ImageModalState {}

export class ImageModal extends React.Component<ImageModalProps, ImageModalState> {
    constructor(props: Readonly<ImageModalProps>, context?: any) {
        super(props, context);

        this.state = { };
    }

    render(): JSX.Element {
        const { image, open } = this.props;
        if (!open || image == null) {
            return <div />;
        }
        return (
            <div className="img-modal">
                <div className="img-modal__content">
                    <img src={image} className="img-modal__content__image"/>
                    <div className="img-modal__content__close-icon" onClick={this.props.onClose}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {
    onClose: closeImageModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ImageModal) as React.ComponentType<any>;
