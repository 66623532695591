import { AxiosResponse } from 'axios';
import noteApi from '../api/noteApi';
import HttpStatus from '../model/enums/httpStatus';
import { HomeListItem } from '../model/HomeListItem';
import { Note } from '../model/note';

export const NotesService = () => {
    const save = async (note: Note): Promise<any> => {
        try {
            const result: AxiosResponse<any> = await noteApi.save(note);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };   
    const getNotes = async (): Promise<HomeListItem[]> => {
        try {
            const result: AxiosResponse<HomeListItem[]> = await noteApi.getNotes();
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    return {
        save,
        getNotes
    };
};

export default NotesService();
