import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../../components/loading/loading';
import FlashcardRunMode from '../../../model/enums/flashcard-run-mode';
import { WeekDay } from '../../../model/enums/week-day';
import { FlashcardRun } from '../../../model/flashcard/flashcard-run';
import FlashcardRunService from '../../../services/flashcard-run.service';
import IntensiveDaySelect from '../components/day-select/intensive-day-select';
import FlashcardHeader from '../components/flashcard-header';
import { ButtonText } from '../components/flashcard-modal-reset/flashcard-modal-reset.styles';
import FlashcardRunInfoCard from '../components/flashcard-run-info-card/flashcard-run-info-card';
import WeekSelect from '../components/week-select/week-select';
import { Container, MainContainer } from '../flashcard-styles';
import '../flashcard.scss';
import {
    ArrowIcon, ButtonContainer,
    CardContainer,
    DateText, FieldQuestionNote,
    FieldQuestionText,
    InputContainer
} from './flashcard-intensive-create-styles';

interface Props extends RouteComponentProps<{}, {}, {}>, WithTranslation {
    hasSubscription?: boolean;
    openPaidModal: () => void;
}

const FlashcardIntensiveCreate = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [viewMode, setViewMode] = useState<'INFO' | 'FORM'>('INFO');
    const [flashcardRun, setFlashcardRun] = useState<FlashcardRun>({
        endDate: moment().add(1, 'months'),
        questionPerDay: 100,
        weekDays: [WeekDay.SUNDAY, WeekDay.MONDAY, WeekDay.TUESDAY, WeekDay.WEDNESDAY, WeekDay.THURSDAY, WeekDay.FRIDAY, WeekDay.SATURDAY]
    });

    useEffect(() => {
        FlashcardRunService.getFlashcardRunByMode(FlashcardRunMode.INTENSIVE).then(it => {
            if (it != null) {
                props.history.push('/flashcard-intensive-dashboard');
            }
        }).finally(() => setIsLoading(false));
    }, []);

    const onPressCreate = () => {
        setIsLoading(true);
        FlashcardRunService.createIntensive(moment(flashcardRun.endDate!), flashcardRun.questionPerDay!, flashcardRun.weekDays as WeekDay[])
            .then(() => props.history.push('/flashcard-intensive-dashboard'));
    };

    const onSelectWeekDay = (weekDay: WeekDay) => {
        const newWeekDays: WeekDay[] = [...(flashcardRun.weekDays as WeekDay[])];
        const weekDayIndex = newWeekDays.indexOf(weekDay);
        if (weekDayIndex === -1) {
            newWeekDays.push(weekDay);
        } else {
            if (flashcardRun.weekDays?.length === 1) {
                // Toast.show(I18n.t(['flashcardIntensiveForm', 'weekDaysErrorMessage']));
                return;
            }
            newWeekDays.splice(weekDayIndex, 1);
        }
        setFlashcardRun({ ...flashcardRun, weekDays: newWeekDays });
    };

    const onChangeEndDate = (newDate: Moment | string) => {
        setFlashcardRun({ ...flashcardRun, endDate: moment(newDate) });
    };

    const renderDateInput = (props, openCalendar, closeCalendar) => {
        return (
            <InputContainer onClick={openCalendar}>
                <DateText>
                    {moment(flashcardRun.endDate!).format('DD MMMM')}
                </DateText>
                <ArrowIcon className={'grey-arrow-img'} />
            </InputContainer>
        );
    };

    const renderInfoCard = () => {
        return (
            <FlashcardRunInfoCard title={props.t('flashcardIntensiveCreate.welcomeTitle')}
                                  text={props.t('flashcardIntensiveCreate.welcomeSubtitle')}
                                  onPress={() => setViewMode('FORM')} />
        );
    };

    const renderFormCard = () => {
        return (
            <CardContainer>
                <FieldQuestionText>
                    {props.t('flashcardIntensiveCreate.testDate')}
                </FieldQuestionText>
                <FieldQuestionNote>
                    {props.t('flashcardIntensiveCreate.testDateSubtitle')}
                </FieldQuestionNote>
                <Datetime
                    closeOnClickOutside
                    onChange={onChangeEndDate}
                    value={moment(flashcardRun.endDate!).toDate()}
                    renderInput={renderDateInput}
                    isValidDate={(current) => moment(current).isBetween(moment().add(1, 'months'), moment().add(6, 'months'))}
                />
                <FieldQuestionText>
                    {props.t('flashcardIntensiveCreate.questionsPerDay')}
                </FieldQuestionText>
                <IntensiveDaySelect {...props}
                                    value={flashcardRun.questionPerDay!}
                                    onChange={value => setFlashcardRun({
                                        ...flashcardRun,
                                        questionPerDay: value
                                    })} />
                <FieldQuestionText>
                            {props.t('flashcardIntensiveCreate.weekDays')}
                </FieldQuestionText>
                <WeekSelect {...props} onSelectWeekDay={onSelectWeekDay}
                            values={flashcardRun.weekDays as WeekDay[]} />
                <ButtonContainer onClick={onPressCreate}>
                    <ButtonText>{props.t('flashcardIntensiveCreate.start')}</ButtonText>
                </ButtonContainer>
            </CardContainer>
        );
    };

    return isLoading ? (
        <div className="disease-screen-container">
            <Loading />
        </div>
    ) : (
        <MainContainer>
            <Container>
                <FlashcardHeader
                    {...props}
                    title={props.t('flashcardIntensiveCreate.subtitle')}
                    subtitle={props.t('flashcardIntensiveCreate.title')}
                    viewMode={'CARD'}
                    onPressBack={() => props.history.push('/flashcard-dashboard')}
                />
                {viewMode === 'INFO' && renderInfoCard()}
                {viewMode === 'FORM' && renderFormCard()}
            </Container>
        </MainContainer>
    );
};

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(FlashcardIntensiveCreate) as React.ComponentType<any>;
