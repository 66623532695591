import React from "react";
import './custom-toast.scss';

interface IProps {
    t: (path: string, params?: any) => string;
    title: string; 
    onClose: () => void;
    style?: any;
}
const CustomToast = ({ title, onClose, t, style }: IProps) => {
    return (
        <div className="custom-toast" style={style}>
            <p className="custom-toast__warning-title">{t('global.customToast.ops')}</p>
            <p className="custom-toast__title">{title}</p>
            <div className="custom-toast__button" onClick={onClose}>
                <span className="custom-toast__button__label">{t('global.customToast.ok')}</span>
            </div>
        </div>
    );
};

export default CustomToast