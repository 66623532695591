import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../../components/loading/loading';
import { MyFlashcard } from '../../../model/flashcard/my-flashcard';
import { IRootState } from '../../../reducer';
import { openPaidFlashcardModal } from '../../../reducer/globalModal/actions';
import MyFlashcardService from '../../../services/my-flashcard.service';
import MyFlashcardThemeModal from '../components/my-flashcard-theme-modal/my-flashcard-theme-modal';
import {
    ArrowUpRightIcon,
    CardContainer,
    CardSeparator,
    SeeContentText
} from '../flashcard-carousel/flashcard-carousel.styles';
import {
    ArrowBack,
    Container,
    HeaderContainer,
    HeaderSubtitle,
    HeaderText,
    MainContainer,
    PracticeText,
    SaveButton
} from '../flashcard-styles';
import '../flashcard.scss';

interface Props extends RouteComponentProps<{}, {}, { myFlashcardId?: number }>, WithTranslation {
    hasSubscription?: boolean;
    openPaidModal: () => void;
}

const MyFlashcardForm = (props: Props) => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [myFlashcard, setMyFlashcard] = useState<MyFlashcard>({});
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        const {
            match: { params }
        } = props;
        const myFlashcardId = (params as any).myFlashcardId;
        if (myFlashcardId != null) {
            MyFlashcardService.getMyFlashcardById(myFlashcardId)
                .then(setMyFlashcard)
                .finally(() => setIsLoading(false));
        } else {
            if ((location.state as any)?.disease != null) {
                setMyFlashcard({ ...myFlashcard, disease: (location.state as any)?.disease });
            }
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (props.hasSubscription === false) {
            setTimeout(() => props.openPaidModal(), 500);
            props.history.goBack();
        }
    }, [props.hasSubscription]);

    const onSave = () => {
        setIsLoading(true);
        MyFlashcardService.save(myFlashcard).then(() => {
            toast((t) => (<span className={'toast-text'}>
                    {props.t('myFlashcardForm.successMessage')}
                </span>), {
                style: {
                    border: '1px solid #F59E24',
                    backgroundColor: '#EF7D00'
                },
                position: 'bottom-center'
            });
            setTimeout(() => {
                props.history.goBack();
                setIsLoading(false);
            }, 1000);
        });
    };

    const onBack = () => {
        props.history.goBack();
    };

    document.querySelectorAll('textarea').forEach((textAreaElement) => {
        textAreaElement.addEventListener('input', () => {
            const regex = /\n/gm;
            if ((textAreaElement.value.match(regex)?.length ?? 0) < 5) {
                textAreaElement.style.height = 'auto';
                textAreaElement.style.height = `${textAreaElement.scrollHeight}px`;
            }
        });
    });

    return isLoading ? (
        <div className="disease-screen-container">
            <Loading />
        </div>
    ) : (
        <MainContainer>
            <Container>
                <HeaderContainer>
                    <ArrowBack className={'black-caret-left-img'} onClick={onBack} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <HeaderSubtitle>{props.t('myFlashcardForm.title')}</HeaderSubtitle>
                        <HeaderText>{myFlashcard.id ? props.t('myFlashcardForm.editCardSubtitle') : props.t('myFlashcardForm.newCardSubtitle')}</HeaderText>
                    </div>
                </HeaderContainer>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CardContainer>
                        <div className={'theme-container'} onClick={() => setShow(true)}>
                            <SeeContentText>{myFlashcard.disease != null && myFlashcard.disease.id !== 938 ? myFlashcard.disease.name : props.t('myFlashcardForm.selectTheme')}</SeeContentText>
                            <ArrowUpRightIcon className="blue-arrow-down-img" />
                        </div>
                        <div className={'my-flashcard-content'}>
                            <textarea id={'my-flashcard-question'}
                                      onChange={e => setMyFlashcard({
                                          ...myFlashcard,
                                          questionText: e.target.value
                                      })}
                                      className={'my-flashcard-text-area'}
                                      value={myFlashcard.questionText ?? undefined}
                                      placeholder={props.t('myFlashcardForm.questionPlaceholder')} />
                            <CardSeparator marginVertical={20} />
                            <textarea id={'my-flashcard-answer'}
                                      onChange={e => setMyFlashcard({
                                          ...myFlashcard,
                                          answerText: e.target.value
                                      })}
                                      className={'my-flashcard-text-area'}
                                      value={myFlashcard.answerText ?? undefined}
                                      placeholder={props.t('myFlashcardForm.answerPlaceholder')} />
                        </div>
                        <SaveButton onClick={() => onSave()} width={22}>
                            <div style={{ height: 56 }} />
                            <PracticeText textColor={'white'} left={9.5} size={22}>
                                {props.t('myFlashcardForm.save')}
                            </PracticeText>
                        </SaveButton>
                    </CardContainer>
                </div>
                <MyFlashcardThemeModal show={show} onClose={() => setShow(false)} onPressConfirm={(disease) => {
                    setMyFlashcard({ ...myFlashcard, disease });
                    setShow(false);
                }} />
            </Container>
        </MainContainer>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account && authentication.account.customer ? authentication.account.customer.hasSubscription : undefined
});

const mapDispatchToProps = {
    openPaidModal: openPaidFlashcardModal
};


export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(MyFlashcardForm) as React.ComponentType<any>;
