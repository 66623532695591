import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import MultipleCardList, { CardItem, CardList } from '../../components/multiple-card-list/multiple-card-list';
import { SusProcedure } from '../../model/susProcedure';
import { SusApiResponseType, SusGroupApiResponse, SusProcedureGroup } from '../../model/susProcedureGroup';
import { SusTree } from '../../model/susTree';
import { IRootState } from '../../reducer';
import SusService from '../../services/susService';
import SusCardItem, { CardItemContent } from './sus-card-item';
import SusLastColumn from './sus-last-column';
import './sus.scss';
import Loading from '../../components/loading/loading';
import AnalyticsService from '../../services/analytics-service';

export interface SusScreenProps extends RouteComponentProps<{}> {
    t: any;
}

export interface SusScreenState {
    firstCardList?: CardList;
    allLists?: CardList[];
    lastColumnData?: any;
}

export class SusScreen extends React.Component<SusScreenProps, SusScreenState> {
    constructor(props: Readonly<SusScreenProps>, context?: any) {
        super(props, context);

        this.state = {};
    }
    // Component Life Cycle
    componentDidMount(): void {
        const {
            match: { params }
        } = this.props;
        const susId: number = (params as any).susId;
        if (susId) {
            this.getSusTree(susId);
        } else {
            this.getGroups();
        }
    }
    componentDidUpdate(prevProps: SusScreenProps, prevState: SusScreenState): void {
        if (prevProps.match !== this.props.match) {
            const {
                match: { params }
            } = this.props;
            const susId: number = (params as any).susId;
            if (susId) {
                this.getSusTree(susId);
            } else {
                this.getGroups();
            }
        }
    }

    // Api Calls
    private getSusTree = async (susId: number): Promise<void> => {
        const response: SusTree = await SusService.getSusTree(susId);
        this.setState({
            allLists: this.mapTreeToCardLists(response),
            lastColumnData: response.susProcedure
        })
    }
    private getGroups = async (parentId?: number, parentCode?: string, parentTitle?: string): Promise<CardList> => {
        const result: SusGroupApiResponse = await SusService.getGroups(parentId);
        const title: string = parentCode != null ? this.formatCodeTitle(parentCode, parentTitle!) : this.props.t('sus.procedure');
        const cardList: CardList = {
            title,
            items: this.mapGroupsToCardItem(result, parentId == null),
        };
        if (parentId == null) {
            this.setState({
                firstCardList: cardList
            });
        }
        return cardList;
    };
    private getProcedure = async (procedureId: number): Promise<SusProcedure> => {
        const sus = await SusService.getProcedure(procedureId);
        window.fbq('track', 'ViewContent', { content_name: sus.code, content_type: 'SUS' });
        return sus;
    };

    // Mappers
    private formatCodeTitle = (parentCode: string, parentTitle: string): string => {
        return `${_.padStart(parentCode.toString(), 2, '0')}. ${parentTitle}`;
    }
    private getListTitle = (index: number, susTree: SusTree): string => {
        if (index === 0) {
            return this.props.t('sus.procedure');
        }
        const parent: SusProcedureGroup = susTree.groups[index - 1].susGroups!.filter((it) => it.selected)[0];
        return this.formatCodeTitle(parent.code!.toString(), parent.description!)
    }
    private mapTreeToCardLists = (susTree: SusTree): CardList[] => {
        return susTree.groups.map((it, index) => ({
            title: this.getListTitle(index, susTree),
            items: this.mapGroupsToCardItem(it, index === 0)
        }));
    }
    private mapGroupsToCardItem = (apiResponse: SusGroupApiResponse, isFirstList?: boolean): CardItem[] => {
        const array: any[] = apiResponse.type === SusApiResponseType.SUS_GROUP ? apiResponse.susGroups! : apiResponse.susProcedures!;
        return array.map<CardItem>((item, index) => ({
            renderItem: () => <SusCardItem item={this.mapCardContent(item, apiResponse.type)} codeIsGolden={isFirstList} />,
            getChildren: () => (apiResponse.type === SusApiResponseType.SUS_GROUP ? this.getGroups(item.id, item.code, item.description) : this.getProcedure(item.id)),
            childrenAmount: apiResponse.type === SusApiResponseType.SUS_GROUP ? item.amount : undefined,
            isSelected: item.selected
        }));
    };
    private mapCardContent = (susTaxonomy: any, type: SusApiResponseType): CardItemContent => {
        return {
            title: type === SusApiResponseType.SUS_GROUP ? susTaxonomy.description : susTaxonomy.title,
            code: type === SusApiResponseType.SUS_GROUP ? String(susTaxonomy.code) : susTaxonomy.code,
            type
        };
    };

    // Renders
    render() {
        const { firstCardList, allLists, lastColumnData } = this.state;
        if (firstCardList == null && allLists == null) {
            return (
                <div className="sus-screen-container">
                    <Loading />
                </div>
            );
        }
        return (
            <div className="sus-screen-container">
                <div style={{ alignItems: 'flex-start', width: '100%', height: '100%' }}>
                    <MultipleCardList
                        firstCardList={firstCardList}
                        color={'#478b46'}
                        renderLastColumn={(sus: SusProcedure) => <SusLastColumn data={sus} isFavorited={sus.favorite && sus.favorite.isActive} />}
                        noCardExtension
                        allLists={allLists}
                        lastColumnData={lastColumnData}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SusScreen) as React.ComponentType<any>;
