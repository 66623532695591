import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import FlashcardAnswerValue from '../../../model/enums/flashcard-answer-value';
import { FlashcardView } from '../../../model/flashcard/flashcard';
import '../flashcard.scss';
import { PaginationContainer, PaginationTextIndex } from './flashcard-carousel.styles';
import { useFlashcardViewContext } from './flashcard.provider';

interface Props extends WithTranslation {
    index: number;
    total: number;
    backPage: () => void;
    nextPage: () => void;
}

const FlashcardCarouselPagination = (props: Props) => {
    const { index, total, nextPage, backPage } = props;
    const { isInfinite } = useFlashcardViewContext();
    const leftArrowDisabled = index === 1;
    const rightArrowDisabled = index === total;

    const renderArrow = (position: 'left' | 'right') => {
        const disabled =
            position === 'left' && leftArrowDisabled
                ? 'disabled-arrow'
                : position === 'right' && rightArrowDisabled
                ? 'disabled-arrow'
                : '';
        return <div className={`arrow-right-orange-img-${position} ${disabled}`} />;
    };

    return (
        <PaginationContainer>
            <div onClick={!leftArrowDisabled ? backPage : () => null}>{renderArrow('left')}</div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '5px' }}>
                <PaginationTextIndex style={{ color: '#404e52', marginRight: index > 99 ? 14 : 8 }}>{index}</PaginationTextIndex>
                <PaginationTextIndex style={{ color: '#a3b0b5' }}>{'/'}</PaginationTextIndex>
                <PaginationTextIndex style={{ color: '#a3b0b5' }}>{isInfinite ? '∞' : total}</PaginationTextIndex>
            </div>
            <div onClick={!rightArrowDisabled ? nextPage : () => null}>{renderArrow('right')}</div>
        </PaginationContainer>
    );
};

export default withTranslation()(FlashcardCarouselPagination);
