enum ScoreSpecificView {
    NONE = 'NONE',
    NEWS2 = 'NEWS2',
    PPS = 'PPS',
    GLASGOW = 'GLASGOW'
}

export const ConversionViews: ScoreSpecificView[] = [
    ScoreSpecificView.NONE,
    ScoreSpecificView.NEWS2,
    ScoreSpecificView.PPS,
    ScoreSpecificView.GLASGOW
];

export default ScoreSpecificView;