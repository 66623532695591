import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { User } from '../../model/user';
import { IRootState } from '../../reducer';
import './footer.scss';

export interface FooterProps {
    t: any;
    user?: User;
}
export const Footer = (props: FooterProps): JSX.Element => {
    if (props.user == null) {
        return <div/>;
    } 
    return (
        <div className="footer">
            <span className="footer__text">{props.t('footer.copyrightMessage')}</span>
        </div>
    );
};

const mapStateToProps = (state: IRootState) => ({
    user: state.authentication.account
});
const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Footer) as React.ComponentType<any>;
