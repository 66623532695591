import { InputAdornment, TextField } from '@material-ui/core';
import * as React from 'react';
import ReactDOM from 'react-dom';
import './text-field-meds.scss';

export interface ITextFieldMedsProps {
    id: string;
    placeholder?: string;
    label?: string;
    isEmail?: boolean;
    isPassword?: boolean;

    value?: string;

    helpText?: string;

    error?: boolean;
    errorText?: string;

    onChange: (value: string) => void;
    onError?: (value: boolean) => void;

    onEnterPress?: () => void;
    style?: any;
    onBlur?: () => void;
    InputProps?: any;
}

export interface ITextFieldMedsState {
    value: string;
    error: boolean;
    filled: boolean;
    focused: boolean;

    placeholder: string;
    label: string;
}

export default class TextFieldMeds extends React.Component<ITextFieldMedsProps, ITextFieldMedsState> {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value != null ? props.value : '',
            error: props.error != null ? props.error : false,
            filled: props.value != null ? true : false,
            focused: false,
            placeholder: props.placeholder != null ? props.placeholder : '',
            label: props.placeholder != null ? props.placeholder : props.label != null ? props.label : ''
        };
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillReceiveProps(newProps: ITextFieldMedsProps) {
        if (this.state.error !== newProps.error) {
            this.setState({
                error: newProps.error != null ? newProps.error : false
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if (
            (!domNode || !domNode.contains(event.target)) &&
            this.state.label !== this.props.label &&
            (this.state.value === null || this.state.value === '')
        ) {
            this.setState({
                label: this.props.label ? this.props.label : ''
            });
        } else if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                error: this.props.error ? this.props.error : false,
                filled: this.state.value != null && this.state.value.length > 0
            });
        }
    };

    private onChangeValue = event => {
        this.setState({
            value: event.target.value,
            filled: event.target.value !== null && event.target.value.length > 0
        });

        this.props.onChange(event.target.value);
    };


    private clickField = () => {
        this.setState({
            label: this.props.placeholder ? this.props.placeholder : ''
        });
    };

    private onKeyPress = event => {
        if ((event.which === 13 || event.keyCode === 13) && this.props.onEnterPress) {
            this.props.onEnterPress();
            return false;
        }
        return true;
    };

    private onKeyDownCapture = event => {
        if (event.which === 9 || event.keyCode === 9) {
            if (this.state.label !== this.props.label && (this.state.value === null || this.state.value === '')) {
                this.setState({
                    label: this.props.label ? this.props.label : ''
                });
            }
            return false;
        }
        return true;
    };
    private onFocus = (): void => {
        this.setState({
            focused: true
        });
    }
    private onBlur = (): void => {
        this.setState({
            focused: false
        });
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }
    render() {
        const { isPassword, error, errorText, helpText, InputProps, style, id } = this.props;
        const { filled, focused, value, label } = this.state;
        const didNotValidated = filled && (error == null || !focused);
        const hasError = error === true && !focused;
        const isValid = error === false && filled && !focused;
        return (
            <div className="text__field" style={{ position: 'relative', width: '100%', ...style }}>
                <TextField
                    className={
                        hasError
                            ? 'error filled MuiFilledInput-underline'
                            :  isValid
                            ? 'filled validate' 
                            : didNotValidated 
                            ? 'filled'
                            : 'action'
                    }
                    id={id}
                    type={isPassword ? 'password' : 'text'}
                    onClick={this.clickField}
                    onChange={this.onChangeValue}
                    fullWidth
                    autoComplete="off"
                    error={hasError}
                    InputProps={
                        filled && isValid
                            ? {
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <div className="check-icn" />
                                      </InputAdornment>
                                  )
                              }
                            : InputProps || {}
                    }
                    helperText={errorText && hasError ? errorText : null}
                    value={value}
                    label={label}
                    variant="filled"
                    onKeyDownCapture={this.onKeyDownCapture}
                    onKeyPress={this.onKeyPress}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                />
                {isPassword === true && helpText && label !== this.props.label && !hasError && focused ? (
                    <div className="helpText">{helpText}</div>
                ) : (
                    <div className="blankHelpText" />
                )}
            </div>
        );
    }
}
