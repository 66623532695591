import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Row } from 'reactstrap';
import { compose } from 'redux';
import 'toasted-notes/src/styles.css';
import '../../App.scss';
import { IRootState } from '../../reducer';
import CustomerService from '../../services/customerService';

export interface ActiveRegisterProps extends RouteComponentProps<{}> {
    t: any;
}

export class ActiveRegister extends React.Component<ActiveRegisterProps> {
    constructor(props: Readonly<ActiveRegisterProps>, context?: any) {
        super(props, context);
    }

    componentDidMount() {
        const { key } = this.props.match.params as { key: string };
        if (key == null) {
            this.props.history.push('/');
            return;
        }
        CustomerService.activeCustomer(key);
    }

    render() {
        return (
            <div className="page-container">
                <div className="blank-header">
                    <div onClick={() => this.props.history.push('/')} className="header-logo" />
                </div>

                <div>
                    <Row className="justity-content-center">
                        <div className="success-back">
                            <div className="success-img" />
                        </div>
                    </Row>
                    <Row style={{ marginTop: '-20px' }} className="justity-content-center">
                        <b>
                            <p className={'main-text'} style={{ fontSize: '26px' }}>
                                {this.props.t('register.active')}
                            </p>
                        </b>
                    </Row>
                    <div className="button-container">
                        <Row className="justify-content-center">
                            <Button
                                onClick={() => this.props.history.push('/')}
                                className="button-primary"
                                style={{ justifyContent: 'center', display: 'flex', width: '170px' }}
                            >
                                {this.props.t('global.button.goLogin')}

                                <div className="next-svg" />
                            </Button>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ register }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ActiveRegister) as React.ComponentType<any>;
