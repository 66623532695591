import styled from 'styled-components';

export const MainContainer = styled.div`
    height: 60vh;
    width: 60px;
    display: flex;
    flex-direction: column;
    text-align: center;
`;

export const ItemContainer = styled.div`
    margin-top: 10%;
    margin-bottom: 10%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100px;
`;

export const ItemIconContainer = styled.div<{ backgroundColor: string }>`
    width: 58px;
    height: 58px;
    display: flex;
    border-radius: 90px;
    align-items: center;
    justify-content: center;
    background-color: ${(props: { backgroundColor: string }) => props.backgroundColor};
`;

export const ItemIcon = styled.div<{ tintColor?: string }>`
    width: 32px;
    height: 32px;
`;

export const ItemTitle = styled.span`
    color: #1a3c47;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    font-family: Raleway;
`;
