export enum SpecialtyType {
    SURGERY = 'SURGERY',
    CLINIC = 'CLINIC',
    GYNECOLOGIC = 'GYNECOLOGIC',
    OBSTETRICS = 'OBSTETRICS',
    DENTISTRY = 'DENTISTRY',
    PEDIATRICS = 'PEDIATRICS',
    PREVENTIVE = 'PREVENTIVE'
}

export function getSpecialtyColor(it: SpecialtyType): string {
    switch (it) {
        case SpecialtyType.SURGERY:
            return '#ef7d00';
        case SpecialtyType.CLINIC:
            return '#f59e24';
        case SpecialtyType.GYNECOLOGIC:
            return '#f5bc14';
        case SpecialtyType.OBSTETRICS:
            return '#b4af8d';
        case SpecialtyType.DENTISTRY:
            return '#918e7a';
        case SpecialtyType.PEDIATRICS:
            return '#4e9a98';
        case SpecialtyType.PREVENTIVE:
            return '#1a3c47';
        default:
            return '#ef7d00';
    }
}
