import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import Datetime from "react-datetime";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { MedicalCalculatorParam } from '../../../model/medical-calculator/medical-calculator-param';
import { useMedicalCalculatorContext } from '../medical-calculator.provider';
import { DateText, InputParamContainer, PlaceholderText } from './medical-calculator-params-styles';

interface Props {
    parameterInfo: MedicalCalculatorParam;
    t: any;
}

const MedicalCalculatorDateParam = (props: Props) => {
    const { paramValues, setParamValues } = useMedicalCalculatorContext();
    const [value, setValue] = useState<Moment | undefined>();
    const { parameterInfo } = props;

    useEffect(() => {
        if(paramValues[parameterInfo.code!] !== undefined) {
            setValue(paramValues[parameterInfo.code!] as Moment);
        }
    }, [paramValues])
    

    const onConfirmChangeDate = (date: Moment | string) => {
        const newDate = moment(date);
        setValue(newDate);
        const newValues = { ...paramValues };
        newValues[parameterInfo.code!] = newDate;
        setParamValues(newValues);
    }

    const renderInput = (newProps, openCalendar, closeCalendar) => {
        return (
            <InputParamContainer onClick={openCalendar}>
                {value ? <DateText>{moment(value).format('DD/MM/YYYY')}</DateText> :
                    <PlaceholderText>{props.t(['medicalCalculator.datePlaceholder'])}</PlaceholderText>}
            </InputParamContainer>
        );
    }

    return (
        <>
            <Datetime
                closeOnClickOutside
                renderInput={renderInput}
                onChange={onConfirmChangeDate}
                value={value ? value.toDate() : new Date()}
            />
        </>
    );
};

export default compose(connect(null, null), withTranslation())(MedicalCalculatorDateParam) as React.ComponentType<any>;
