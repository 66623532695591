import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import MultipleCardList, { CardItem, CardList } from '../../components/multiple-card-list/multiple-card-list';
import { GroupsDiseases } from '../../model/apiResponses/GroupsDiseases';
import { Disease } from '../../model/disease';
import { Group } from '../../model/group';
import { IRootState } from '../../reducer';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import DiseaseGroupsService from '../../services/diseaseGroupsService';
import { CardItemContent, default as SusCardItem } from '../specialties/specialty-card-item';
import '../specialties/specialty.scss';
import ProtocolsCardItem from './protocols-card-item';
import './protocols.scss';

export interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
    hasSubscription?: boolean;
    openPaidDiseaseModal: () => void;
}

const ProtocolsScreen = (props: Props): JSX.Element => {
    const [firstCardList, setFirstCardList] = useState<CardList | undefined>();

    useEffect(() => {
        getProtocols();
    }, []);

    const onDiseasePress = async (disease: Disease) => {
        const { hasSubscription } = props;
        if (disease.free === true || hasSubscription) {
            props.history.push({
                pathname: `/disease/${disease.id}`,
                state: {
                    screenToBack: '/protocols'
                }
            });
        } else {
            props.openPaidDiseaseModal();
        }
    };

    const getGroupsByGroup = async (group: Group, color: string): Promise<CardList | void> => {
        const result: GroupsDiseases = await DiseaseGroupsService.getGroupsDiseasesByGroup(group.id!);
        const title: string = group.name!;
        const cardList: CardList = {
            title,
            items: mapGroupsToCardItem(result, color),
            color
        };
        return cardList;
    };

    const mapGroupsToCardItem = (apiResponse: GroupsDiseases, color: string): CardItem[] => {
        const groups: CardItem[] = apiResponse.groups.map<CardItem>((item, index) => ({
            renderItem: () => <SusCardItem item={mapCardContent(item)} />,
            getChildren: () => getGroupsByGroup(item, color),
            isSelected: item.selected === true,
            childrenAmount: item.amount,
            name: item.name
        }));
        const diseases: CardItem[] = apiResponse.diseases.map<CardItem>((item, index) => ({
            renderItem: () => <SusCardItem item={mapCardContent(item)} />,
            getChildren: () => onDiseasePress(item),
            name: item.name
        }));
        const cardItems: CardItem[] = _.orderBy(groups.concat(diseases), ['name'], ['asc']);
        return cardItems;
    };

    const mapCardContent = (taxonomy: any): CardItemContent => {
        const { hasSubscription } = props;
        return {
            icon: taxonomy.groupPicture ? taxonomy.groupPicture.content : undefined,
            title: taxonomy.name,
            isPaid: taxonomy.free === false && !hasSubscription
        };
    };

    const mapProtocols = (): CardItem[] => [
        {
            renderItem: () => (
                <ProtocolsCardItem
                    iconSize={{ height: '24px', width: '100px' }}
                    protocolIcon={require('../../images/icon-atsl.svg')}
                    protocolTitleImage={require('../../images/atsl.svg')}
                />
            ),
            getChildren: () => getGroupsByGroup({ id: 12, name: 'ATLS' }, '#4e9a98')
        },
        {
            renderItem: () => (
                <ProtocolsCardItem
                    iconSize={{ height: '47px', width: '58px' }}
                    protocolIcon={require('../../images/icon-bls.svg')}
                    protocolTitleImage={require('../../images/bls-acls.png')}
                />
            ),
            getChildren: () => {
                onDiseasePress({id : 397});
                return getGroupsByGroup({ id: 12, name: 'ATLS' }, '#4e9a98');
            }
        },
        {
            renderItem: () => (
                <ProtocolsCardItem
                    iconSize={{ height: '39px', width: '77px' }}
                    protocolIcon={require('../../images/icon-pbls.svg')}
                    protocolTitleImage={require('../../images/pbls-pals.png')}
                />
            ),
            getChildren: () => getGroupsByGroup({ id: 359, name: props.t('protocols.pbls') }, '#4e9a98')
        },
        {
            renderItem: () => (
                <ProtocolsCardItem
                    iconSize={{ height: '34px', width: '100px' }}
                    protocolIcon={require('../../images/icon-reanimacao.svg')}
                    protocolTitleImage={require('../../images/reanima-o-neonatal.png')}
                />
            ),
            getChildren: () => {
                onDiseasePress({id : 809});
                return getGroupsByGroup({ id: 12, name: props.t('protocols.atls') }, '#4e9a98');
            }
        }
    ];

    const getProtocols = () => {
        setFirstCardList({
            title: props.t('protocols.title'),
            items: mapProtocols()
        });
    };

    return (
        <div className="specialty-screen-container" style={{ position: 'relative' }}>
            <div style={{ alignItems: 'flex-start', position: 'relative', width: '100%', height: '100%' }}>
                {firstCardList != null && <MultipleCardList firstCardList={firstCardList} noCardExtension hasExtraFirstColumn />}
            </div>
        </div>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account && authentication.account.customer ? authentication.account.customer.hasSubscription : undefined
});
const mapDispatchToProps = {
    openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ProtocolsScreen) as React.ComponentType<any>;
