import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { IRootState } from '../../reducer';
import { CardItem, CardList } from './multiple-card-list';
import './multiple-card-list.scss';

export interface CardProps extends RouteComponentProps<{}> {
    t: any;
    index: number;
    card: CardItem;
    color: string;
    onGetNewList: (list: CardList, cardIndex?: number, childrenAmmount?: number) => void;
    isSelected?: boolean;
    noExtension?: boolean;
    firstColumnCardStyle?: string;
}

export interface CardState {
    height?: number;
}

export class Card extends React.Component<CardProps, CardState> {
    private ref;
    constructor(props: Readonly<CardProps>, context?: any) {
        super(props, context);

        this.state = {};
    }
    componentWillReceiveProps(newProps: CardProps): void {
        if (this.ref) {
            this.setState({ height: this.ref.getBoundingClientRect().height });
        }
    }
    private onClick = async () => {
        const { card, onGetNewList } = this.props;
        if (card.getChildren) {
            const result = await card.getChildren(card);
            onGetNewList(result, this.props.index, card.childrenAmount);
        }
    };
    render() {
        const { height } = this.state;
        const { card, color, isSelected, noExtension, firstColumnCardStyle } = this.props;
        const amount: string = card.childrenAmount ? _.padStart(card.childrenAmount.toString(), 2, '0') : '';
        const extensionPadding: any = {
            paddingLeft: amount.length > 0 ? 6 : 3.5,
            paddingRight: amount.length > 0 ? 6 : 3.5
        };
        const itemRendered: JSX.Element = card.renderItem();
        const showExtension: boolean = noExtension ? card.childrenAmount == null ? false : true : true;
        if (card == null || card.renderItem == null || itemRendered == null) {
            return <div />;
        }
        return (
            <a
                id="card-item"
                className="card-item"
                onClick={this.onClick}
                style={{ height }}
                ref={ref => {
                    if (!ref) return;
                    this.ref = ref;
                    setTimeout(() => this.setState({ height: ref.getBoundingClientRect().height }), 500);
                }}
            >
                <div className={firstColumnCardStyle ?? "card-item__content"} style={isSelected ? { boxShadow: `0 0 0 1px ${color}`, borderRadius: 4 } : {}}>
                    {itemRendered}
                </div>
                {showExtension && (
                    <div className="card-item__extension" style={{ backgroundColor: color, ...extensionPadding, opacity: height ? 1 : 0 }}>
                        <span className="card-item__extension__label">{amount}</span>
                    </div>
                )}
            </a>
        );
    }
}

const mapStateToProps = ({ }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Card) as React.ComponentType<any>;
