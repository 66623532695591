import { AxiosResponse } from 'axios';
import { PageableResponse } from '../model/pageable';
import { IllustratedProcedure } from './../model/illustratedProcedure';
import { api } from './api';

export const illustratedProcedureApi = () => {
    const getIllustratedProcedures = (name?: string): Promise<AxiosResponse<IllustratedProcedure[]>> => {
        let params = '';
        if (name != null) {
            params = `?name=${name}`;
        }
        return api.get<IllustratedProcedure[]>(`/illustrated-procedures/search${params}`);
    };

    const getFreeIllustratedProcedures = (): Promise<AxiosResponse<PageableResponse<IllustratedProcedure>>> => {
        return api.get<PageableResponse<IllustratedProcedure>>(`/illustrated-procedures/free?size=100`);
    };

    const getIllustratedProcedure = (id: number): Promise<AxiosResponse<IllustratedProcedure>> => {
        return api.get<IllustratedProcedure>(`/illustrated-procedures/markdown/${id}`);
      };

    return {
        getIllustratedProcedure,
        getFreeIllustratedProcedures,
        getIllustratedProcedures
    };
};

export default illustratedProcedureApi();
