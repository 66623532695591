import { Reducer } from 'redux';
import { GlobalModalActionTypes, GlobalModalState } from './types';

export const initialState: GlobalModalState = {
  paidDiseaseModalOpen: false,
  paidFlashcardModalOpen: false,
  imageModal: {
    isOpen: false,
    imageSrc: ''
  },
  onPressPaymentError: () => null,
  feedbackModal: false,
  accessedFromAnotherDevice: false,
  errorPaymentModal: false,
  expiredHourModal: false
};

const reducer: Reducer<GlobalModalState> = (state = initialState, action) => {
  
  switch (action.type) {
    case GlobalModalActionTypes.OPEN_PAID_FLASHCARD_MODAL: {
      return { ...state, paidFlashcardModalOpen: true };
    }
    case GlobalModalActionTypes.CLOSE_PAID_FLASHCARD_MODAL: {
      return { ...state, paidFlashcardModalOpen: false };
    }

    case GlobalModalActionTypes.OPEN_PAID_DISEASE_MODAL: {
      return { ...state, paidDiseaseModalOpen: true };
    }    
    case GlobalModalActionTypes.CLOSE_PAID_DISEASE_MODAL: {
      return { ...state, paidDiseaseModalOpen: false };
    }

    case GlobalModalActionTypes.OPEN_PAID_IMAGE_MODAL: {
      return { ...state, imageModal: { isOpen: true, imageSrc: action.payload } };
    }    
    case GlobalModalActionTypes.CLOSE_PAID_IMAGE_MODAL: {
      return { ...state, imageModal: { isOpen: false, imageSrc: ''} };
    }    

    case GlobalModalActionTypes.OPEN_FEEDBACK_MODAL: {
      return { ...state, feedbackModal: true };
    }    
    case GlobalModalActionTypes.CLOSE_FEEDBACK_MODAL: {
      return { ...state, feedbackModal: false };
    }
    
    case GlobalModalActionTypes.OPEN_EXPIRED_HOUR_MODAL: {
      console.log('open expired hour modal', true);
      return { ...state, expiredHourModal: true };
    }    

    case GlobalModalActionTypes.OPEN_ACCESSED_FROM_ANOTHER_DEVICE_MODAL: {
      return { ...state, accessedFromAnotherDevice: true };
    }   

    case GlobalModalActionTypes.CLOSE_ACCESSED_FROM_ANOTHER_DEVICE_MODAL: {
      return { ...state, accessedFromAnotherDevice: false };
    }   

    case GlobalModalActionTypes.CLOSE_EXPIRED_HOUR_MODAL: {
      return { ...state, expiredHourModal: false };
    } 

    case GlobalModalActionTypes.CLOSE_ERROR_PAYMENTE_MODAL: {
      return { ...state, errorPaymentModal: false };
    }   

    case GlobalModalActionTypes.OPEN_ERROR_PAYMENTE_MODAL: {
      console.log({teste: action});
      return { ...state, errorPaymentModal: true, onPressPaymentError: action.payload };
    }

    default: {
      return state;
    }
  }
};

export { reducer as globalModalReducer };

