import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ForgotPasswordService from '../../services/forgotPasswordService';
import { validateEmail } from '../../shared/util/utils';
import Button from '../button/button';
import Loading from '../loading/loading';
import TextFieldMeds from '../text-field-meds/text-field-meds';
import './forgot-password-modal.scss';

export interface IForgotPasswordModalProps {
    t: (path: string, params?: any) => string;
    open: boolean;
    closeModal: () => void;
}
export interface IForgotPasswordModalState {
    email?: string;
    emailError?: boolean;
    emailErrorMessage?: string;
    contentType: 'REQUEST' | 'SUCCESS' | 'ERROR' | 'LOADING';
}

export class ForgotPasswordModal extends React.Component<IForgotPasswordModalProps, IForgotPasswordModalState> {
    constructor(props) {
        super(props);

        this.state = {
            contentType: 'REQUEST'
        };
    }
    private onClose = (): void => {
        this.props.closeModal();
    };
    private onSendPress = async (): Promise<void> => {
        const { email } = this.state;
        if (!email || email.length === 0) {
            this.setState({
                emailError: true,
                emailErrorMessage: this.props.t('forgotPasswordModal.requiredEmail')
            });
            return;
        } else if (!validateEmail(email)) {
            this.setState({
                emailError: true,
                emailErrorMessage: this.props.t('forgotPasswordModal.invalidEmail')
            });
            return;
        }
        this.setState({
            contentType: 'LOADING'
        });
        await ForgotPasswordService.sendEmail(email)
            .then(it => {
                this.setState({
                    contentType: 'SUCCESS'
                });
            })
            .catch(e => {
                this.setState({
                    contentType: 'ERROR'
                });
            });
    };

    private onChangeEmail = (value: string): void => {
        this.setState({
            email: value
        });
    };

    private onBlurEmail = (): void => {
        const { email } = this.state;
        if (!email || email.length === 0) {
            this.setState({
                emailError: true,
                emailErrorMessage: this.props.t('forgotPasswordModal.requiredEmail')
            });
        } else if (!validateEmail(email)) {
            this.setState({
                emailError: true,
                emailErrorMessage: this.props.t('forgotPasswordModal.invalidEmail')
            });
        }
    };

    private onBack = (): void => {
        this.props.closeModal();
        this.setState({
            contentType: 'REQUEST',
            email: undefined,
            emailError: undefined,
            emailErrorMessage: undefined
        });
    };

    private renderError = () => {
        return (
            <div className='modal__content'>
                <div className='top-content' style={{ width: 400 }}>
                    <div className='wemeds-logo-img' />
                    <span className='top-content__subtitle'>{this.props.t('forgotPasswordModal.error.message')}</span>
                </div>
                <div className='bottom-content' style={{ marginTop: 20 }}>
                    <div className='bottom-content__btn-container'>
                        <Button label={this.props.t('forgotPasswordModal.error.buttonLabel').toUpperCase()} onPress={() => this.setState({ contentType: 'REQUEST' })} />
                    </div>
                </div>
            </div>
        );
    };

    private renderSuccess = () => {
        const { email } = this.state;
        return (
            <div className='modal__content'>
                <div className='top-content' style={{ width: 400 }}>
                    <div className='wemeds-logo-img' />
                    <span className='top-content__subtitle top-content__subtitle--bold'>{this.props.t('forgotPasswordModal.success.title', { email })}</span>
                    <span className='top-content__subtitle'>{this.props.t('forgotPasswordModal.success.subtitle')}</span>
                </div>
                <div className='bottom-content' style={{ marginTop: 20 }}>
                    <div className='bottom-content__btn-container'>
                        <Button label={this.props.t('forgotPasswordModal.success.buttonLabel').toUpperCase()} onPress={this.onBack} />
                    </div>
                </div>
            </div>
        );
    };

    private renderRequest = () => {
        const { email, emailError, emailErrorMessage } = this.state;
        return (
            <div className='modal__content'>
                <div className='top-content' style={{ width: 400 }}>
                    <div className='wemeds-logo-img' />
                    <span className='top-content__title'>{this.props.t('forgotPasswordModal.title')}</span>
                    <span className='top-content__subtitle'>{this.props.t('forgotPasswordModal.subtitle')}</span>
                </div>
                <div className='bottom-content' style={{ marginTop: 20 }}>
                    <TextFieldMeds
                        id='email'
                        value={email}
                        onChange={this.onChangeEmail}
                        placeholder={this.props.t('forgotPasswordModal.inputPlaceholder')}
                        label={this.props.t('forgotPasswordModal.inputPlaceholder')}
                        isEmail
                        errorText={emailErrorMessage}
                        error={emailError}
                        onBlur={this.onBlurEmail}
                    />
                    <div className='bottom-content__btn-container'>
                        <Button label={this.props.t('forgotPasswordModal.buttonLabel').toUpperCase()} onPress={this.onSendPress} />
                    </div>
                </div>
            </div>
        );
    };

    private renderContent = () => {
        const { contentType } = this.state;
        switch (contentType) {
            case 'REQUEST':
                return this.renderRequest();
            case 'LOADING':
                return <Loading />;
            case 'SUCCESS':
                return this.renderSuccess();
            case 'ERROR':
                return this.renderError();
            default:
                return <div />;
        }
    };

    render() {
        const { open } = this.props;
        if (!open) {
            return <div />;
        }
        return (
            <div className='modal'>
                <div className='modal__full-content'>
                    <a className='close-btn' onClick={this.onClose}>
                        <div className='close-btn__icon' />
                    </a>
                    {this.renderContent()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ForgotPasswordModal) as React.ComponentType<Omit<IForgotPasswordModalProps, 't'>>;
