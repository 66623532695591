import styled from 'styled-components';

export const ParamContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 95%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
`;

export const ParamTitle = styled.span`
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1A3C47;
`;

export const InputParamContainer = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #E9ECED;
    border-radius: 6px;
    padding-left: 13px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 180px;
`;

export const MeasurementText = styled.span`
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 14px;
    color: #A3B1B5;
    margin-right: 15px;
`;

export const PlaceholderText = styled.span`
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 14px;
    color: #A3B1B5;
    margin-right: 15px;
`;

export const DateText = styled.span`
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #1A3C47;
`;

export const InputParam = styled.input`
    border: 0px solid transparent;
    min-width: 20px;
    text-align: right;
    padding-right: 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #1A3C47;
    &:focus-visible {
        outline: none;
    };
    &::placeholder {
        color: #A3B1B5;
    }
`;

export const ParamPickerContainer = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #E9ECED;
    border-radius: 6px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 180px;
    background: url('../../../images/icons/caret-down.svg') no-repeat right;
`;

export const ParamPicker = styled.select`
    border: 0px solid transparent;
    background: transparent;
    width: 90%;
    margin-right: 10%;
    text-align: right;
    font-family: 'Raleway';
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #1A3C47;
    &:focus-visible {
        outline: none;
    };
    &::placeholder {
        color: #A3B1B5;
    }
`;

export const UsgParamContainer = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 26px;
    width: 75%;
    justify-content: flex-end;
`;