import { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import flashcardRunApi from '../api/flashcard-run.api';
import ExtensiveFile from '../Extensive.json';
import FlashcardRunMode from '../model/enums/flashcard-run-mode';
import HttpStatus from '../model/enums/httpStatus';
import { WeekDay } from '../model/enums/week-day';
import { FlashcardRun, FlashcardRunMetric } from '../model/flashcard/flashcard-run';

const FlashcardRunService = () => {
    const getFlashcardRunByMode = async (mode: FlashcardRunMode): Promise<FlashcardRun | null> => {
        try {
            const result: AxiosResponse<FlashcardRun> = await flashcardRunApi.getFlashcardRunByMode(mode);
            if (result.status === HttpStatus.OK) {
                const extensive = result.data;
                extensive.metric = JSON.parse(extensive.metric as string);
                return Promise.resolve(extensive);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            if ((error as any).request.status === HttpStatus.NOT_FOUND) {
                return Promise.resolve(null);
            }
            console.error(error);
            return Promise.reject(error);
        }
    };

    const getFlashcardIdsByRepetitionAlgorithm = async (quantity: number): Promise<number[]> => {
        try {
            const result: AxiosResponse<number[]> = await flashcardRunApi.getFlashcardIdsByRepetitionAlgorithm(quantity);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const getFlashcardIdsForExtensiveCheckpoint = async (ids: number[]): Promise<number[]> => {
        try {
            const result: AxiosResponse<number[]> = await flashcardRunApi.getFlashcardIdsForExtensiveCheckpoint(ids);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const resetFlashcardRunByMode = async (mode: FlashcardRunMode): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await flashcardRunApi.resetFlashcardRunByMode(mode);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve();
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const update = async (flashcardRun: FlashcardRun): Promise<void> => {
        try {
            flashcardRun.lastModifiedDate = moment().utc().unix();
            flashcardRun.startDate = moment(flashcardRun.startDate).unix();
            flashcardRun.metric = JSON.stringify(flashcardRun.metric);
            if (flashcardRun.endDate != null) {
                flashcardRun.endDate = moment(flashcardRun.endDate).unix();
            }

            const result: AxiosResponse<void> = await flashcardRunApi.update(flashcardRun);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve();
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const createExtensive = async (): Promise<void> => {
        try {
            const flashcardRun: FlashcardRun = {
                secondsSpent: 0,
                answeredQuestions: 0,
                remainingQuestions: 0,
                weekDays: [WeekDay.MONDAY, WeekDay.TUESDAY, WeekDay.WEDNESDAY, WeekDay.THURSDAY, WeekDay.FRIDAY, WeekDay.SATURDAY, WeekDay.SUNDAY].join(','),
                questionPerDay: 0,
                mode: FlashcardRunMode.EXTENSIVE,
                startDate: moment(),
                endDate: moment().add(35, 'weeks')
            };
            let weekNumber = 0;
            const metric: FlashcardRunMetric[] = ExtensiveFile as unknown as FlashcardRunMetric[];
            for (let i = 0; i < metric.length; i++) {
                metric[i].date = moment().add(weekNumber, 'weeks');
                // Último item é revisão.
                if (i % 3 === 2) {
                    weekNumber++;
                    metric[i].remainingQuestions = 200;
                }
            }

            flashcardRun.startDate = moment(flashcardRun.startDate).unix();
            flashcardRun.endDate = moment(flashcardRun!.endDate!).unix();
            flashcardRun.metric = JSON.stringify(metric);

            const result: AxiosResponse<void> = await flashcardRunApi.create(flashcardRun);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve();
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const createIntensive = async (endDate: Moment, questionPerDay: number, weekDays: WeekDay[]): Promise<void> => {
        try {
            const flashcardRun: FlashcardRun = {
                secondsSpent: 0,
                answeredQuestions: 0,
                remainingQuestions: 0,
                weekDays,
                questionPerDay,
                mode: FlashcardRunMode.INTENSIVE,
                startDate: moment(),
                endDate,
            };
            const metric: FlashcardRunMetric[] = [];
            const differenceDays = moment().diff(endDate, 'days') * -1;
            for (let i = 0; i < differenceDays; i++) {
                const day = moment().add(i, 'days');
                if (weekDays.includes(Object.keys(WeekDay)[day.weekday()] as WeekDay)) {
                    metric.push({
                        id: i + 1,
                        isFinished: false,
                        secondsSpent: 0,
                        answeredQuestions: 0,
                        remainingQuestions: 0,
                        answers: [],
                        questionIds: [],
                        date: day.format('YYYY-MM-DD'),
                    });
                }
            }

            flashcardRun.startDate = moment(flashcardRun.startDate).unix();
            flashcardRun.weekDays = weekDays.join(',');
            flashcardRun.endDate = moment(flashcardRun!.endDate!).unix();
            flashcardRun.metric = JSON.stringify(metric);

            const result: AxiosResponse<void> = await flashcardRunApi.create(flashcardRun);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve();
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    return {
        getFlashcardIdsForExtensiveCheckpoint,
        getFlashcardIdsByRepetitionAlgorithm,
        resetFlashcardRunByMode,
        getFlashcardRunByMode,
        createIntensive,
        createExtensive,
        update
    };
};

export default FlashcardRunService();
