import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import toaster from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import CustomToast from '../../components/custom-toast/custom-toast';
import { CreateSubscriptionResponse } from '../../model/apiResponses/CreateSubscriptionResponse';
import { CreateSubscription } from '../../model/createSubscription';
import { User } from '../../model/user';
import { IRootState } from '../../reducer';
import { openErrorPaymentModal } from '../../reducer/globalModal/actions';
import AnalyticsService from '../../services/analytics-service';
import SubscriptionService from '../../services/subscriptionService';
import { getMobileOperatingSystem, OperatingSystem } from '../../shared/util/utils';
import AddressStep from './components/address-step';
import FinishStep from './components/finish-step';
import PaymentStep from './components/payment-step';
import './register-subscription.scss';
import Steps, { StepItem } from './steps';

export interface SubscriptionProps extends RouteComponentProps<{}> {
    user: User;
    t: any;
    openModal: any;
}

export interface SubscriptionState {
    createSubscription: CreateSubscription;
    createSubscriptionResponse?: CreateSubscriptionResponse;
    steps: StepItem[];
    currentIndex: number;
    isLoadingPayment: boolean;
    showErrors?: boolean;
    hasErrors: boolean;
    paymentValue: number;
}

enum StepType {
    ADDRESS,
    PAYMENT,
    FINISH
}

export class Subscription extends React.Component<SubscriptionProps, SubscriptionState> {
    constructor(props: Readonly<SubscriptionProps>, context?: any) {
        super(props, context);
        this.state = {
            createSubscription: {
                twoYearsPromotion: false,
                newUser: false,
                paymentMethod: {},
                couponCode: undefined,
                user: {}
            },
            isLoadingPayment: false,
            steps: this.mapSteps(),
            currentIndex: 0,
            hasErrors: true,
            paymentValue: 293.88
        };
    }

    // Mappers
    private mapSteps = (): StepItem[] => [
        {
            title: this.props.t('registerSubscription.addressStep.title'),
            render: () => <AddressStep createSubscription={this.state.createSubscription} onNextStep={this.onNextStep} onBack={this.onBack} showErrors={this.state.showErrors} />
        },
        {
            title: this.props.t('registerSubscription.paymentStep.title'),
            render: () => (
                <PaymentStep
                    createSubscription={this.state.createSubscription}
                    onNextStep={this.onNextStep}
                    isLoadingPayment={this.state.isLoadingPayment}
                    onBack={this.onBack}
                    showErrors={this.state.showErrors}
                    paymentValue={this.state.paymentValue}
                    onChangePaymentValue={this.onChangePaymentValue}
                />
            )
        },
        {
            title: this.props.t('registerSubscription.finishStep.title'),
            render: () => (
                <FinishStep
                    createSubscription={this.state.createSubscription}
                    onNextStep={this.onNextStep}
                    isRegisterFlux={false}
                    onBack={this.onBack}
                    showErrors={this.state.showErrors}
                    totalValue={this.state.paymentValue}
                    createSubscriptionResponse={this.state.createSubscriptionResponse!}
                />
            )
        }
    ];

    // Handlers
    private updateSteps = (): void => {
        this.setState({
            steps: this.mapSteps()
        });
    };

    private onChangePaymentValue = (paymentValue: number): void => {
        this.setState({ paymentValue });
    };

    private onNextStep = (createSubscription: CreateSubscription, hasErrors: boolean): void => {
        const { currentIndex } = this.state;
        this.setState({ createSubscription, hasErrors }, this.updateSteps);
        if (hasErrors) {
            this.setState({ showErrors: true }, this.updateSteps);
            toaster.notify(callback => <CustomToast title={this.props.t('register.error.step-1')} onClose={callback.onClose} t={this.props.t} />, {
                duration: 3000,
                position: 'bottom'
            });
            return;
        }
        switch (currentIndex) {
            case StepType.ADDRESS:
                this.setState({ showErrors: false, currentIndex: currentIndex + 1 });
                break;
            case StepType.PAYMENT:
                this.register(createSubscription);
                break;
            case StepType.FINISH:
                AnalyticsService.purchaseEvent(this.props.user?.email ?? '');
                window.fbq('track', 'Purchase');
                this.goToLogin();
                break;
            default:
                break;
        }
    };

    private register = async (createSubscription: CreateSubscription): Promise<void> => {
        const onBack = this.onBack;
        this.setState({
            isLoadingPayment: true
        });
        SubscriptionService.createSubscription(createSubscription)
        .then(save => {
            const response: CreateSubscriptionResponse = {
                save
            };
            this.setState({ createSubscriptionResponse: response }, () => {
                this.setState({ showErrors: false, currentIndex: StepType.FINISH, isLoadingPayment: false }, this.updateSteps);
            });
        })
        .catch(() => {
            this.setState({ isLoadingPayment: false, steps: this.mapSteps() }, () => {
                    console.log({onBack})
                    this.props.openModal(() => this.setState({ currentIndex: 0 }));
                });
            });
    };

    private onBack = (): void => {
        const { currentIndex } = this.state;
        if (currentIndex > 0) {
            this.setState({ currentIndex: currentIndex - 1 });
        } else if (currentIndex === 0) {
            this.props.history.goBack();
        }
    };

    private goToLogin = () => {
        this.props.history.push('/');
    };

    private openApp = (): void => {
        if (getMobileOperatingSystem() === OperatingSystem.ANDROID) {
            window.location.replace('com.wemeds://');
        } else if (getMobileOperatingSystem() === OperatingSystem.IOS) {
            window.location.replace('wemeds://');
        }
    };

    private downloadApp = (): void => {
        if (getMobileOperatingSystem() === OperatingSystem.ANDROID) {
            window.location.replace('market://details?id=com.wemeds');
        } else if (getMobileOperatingSystem() === OperatingSystem.IOS) {
            window.location.replace('https://apps.apple.com/us/app/wemeds-medicina/id1446915058?l=pt&ls=1');
        }
    };

    // Renders
    render() {
        const { steps, currentIndex } = this.state;
        return (
            <div className='register-subscription'>
                <div className='register-subscription__header'>
                    <div onClick={this.goToLogin} className='register-subscription__header__logo' />
                </div>
                <Steps steps={steps} currentIndex={currentIndex} />
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    user: authentication.account
});

const mapDispatchToProps = {
    openModal: openErrorPaymentModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Subscription) as React.ComponentType<any>;
