import { AxiosResponse } from 'axios';
import diseaseApi from '../api/diseaseApi';
import { Disease, DiseaseName } from '../model/disease';
import HttpStatus from '../model/enums/httpStatus';
import { FreeDiseases } from '../model/freeDiseases';
import { Pageable, PageableResponse } from '../model/pageable';

export const DiseaseService = () => {
    const getFreeDiseases = async (): Promise<Disease[]> => {
        try {
            const result: AxiosResponse<FreeDiseases> = await diseaseApi.getFreeDiseases();
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data.content);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    const getDisease = async (diseaseId: number): Promise<Disease> => {
        try {
            const result: AxiosResponse<Disease> = await diseaseApi.getDisease(diseaseId);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    const getSimpleDiseasesByName = async (page: Pageable, name?: string): Promise<PageableResponse<Disease>> => {
        try {
            const result: AxiosResponse<PageableResponse<Disease>> = await diseaseApi.getSimpleDiseasesByName(page, name);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    const getDiseaseNameById = async (diseaseId: number): Promise<DiseaseName> => {
        try {
            const result: AxiosResponse<DiseaseName> = await diseaseApi.getDiseaseNameById(diseaseId);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    return {
        getSimpleDiseasesByName,
        getDiseaseNameById,
        getFreeDiseases,
        getDisease
    };
};

export default DiseaseService();
