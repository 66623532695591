import { getAnalytics, logEvent } from 'firebase/analytics';
import { Disease } from '../model/disease';
import { MedicineLeaflet } from '../model/medicine-leaflet';
import { SusProcedure } from '../model/susProcedure';
import { Cid } from './../model/cid';
import { IllustratedProcedure } from './../model/illustratedProcedure';
import { Laboratory } from './../model/laboratory/laboratory';
import { Semiology } from './../model/semiology/semiology';

export const AnalyticsService = () => {
    const loginEvent = async (email: string): Promise<void> => {
        const analytics = getAnalytics();
        logEvent(analytics, 'customer_web_login', { email });
    };

    const purchaseEvent = async (email: string): Promise<void> => {
        const analytics = getAnalytics();
        try {
            logEvent(analytics, 'purchase', { transaction_id: email });    
        } catch (error) {
            console.log(error)
        }
    };

    const diseaseClick = async (disease: Disease): Promise<void> => {
        const analytics = getAnalytics();
        logEvent(analytics, 'disease_click', { diseaseId: disease?.id, diseaseName: disease?.name });
    };

    const medicineLeafletClick = async (medicine: MedicineLeaflet): Promise<void> => {
        const analytics = getAnalytics();
        logEvent(analytics, 'medicine_click', { medicineId: medicine?.id, medicineName: medicine?.name });
    };

    const laboratoryClick = async (laboratory: Laboratory): Promise<void> => {
        const analytics = getAnalytics();
        logEvent(analytics, 'laboratory_click', { laboratoryId: laboratory?.id, laboratoryName: laboratory?.name });
    };

    const semiologyClick = async (semiology: Semiology): Promise<void> => {
        const analytics = getAnalytics();
        logEvent(analytics, 'semiology_click', { semiologyId: semiology?.id, semiologyName: semiology?.name });
    };

    const procedureClick = async (illustratedProcedure: IllustratedProcedure): Promise<void> => {
        const analytics = getAnalytics();
        logEvent(analytics, 'illustrated_procedure_click', { procedureId: illustratedProcedure?.id, procedureName: illustratedProcedure?.name });
    };

    const susClick = async (susProcedure: SusProcedure): Promise<void> => {
        const analytics = getAnalytics();
        logEvent(analytics, 'sus_procedure_click', { susProcedureId: susProcedure?.id, susProcedureName: susProcedure?.title });
    };

    const cidClick = async (cid: Cid): Promise<void> => {
        const analytics = getAnalytics();
        logEvent(analytics, 'cid_click', { cidId: cid?.id, cidCode: cid?.code });
    };

    return {
        loginEvent,
        purchaseEvent,
        diseaseClick,
        medicineLeafletClick,
        laboratoryClick,
        semiologyClick,
        procedureClick,
        susClick,
        cidClick
    }
}

export default AnalyticsService();
