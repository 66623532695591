import { AxiosResponse } from 'axios';
import { PageableResponse } from '../model/pageable';
import { MedicalCriteria } from '../model/medical-criteria/medical-criteria';
import { api } from './api';

const MedicalCriteriaApi = () => {
  const getInfo = (id: number): Promise<AxiosResponse<MedicalCriteria>> => {
    return api.get<MedicalCriteria>(`/medical-criteria/info/${id}`);
  };

  const search = (
    text?: string
  ): Promise<AxiosResponse<PageableResponse<MedicalCriteria>>> => {
    const query = text !== undefined ? `&name=${text}` : '';
    return api.get<PageableResponse<MedicalCriteria>>(
      `/medical-criteria/search?size=1000${query}`
    );
  };

  const getAvailableForFreeCriterias = (): Promise<
    AxiosResponse<PageableResponse<MedicalCriteria>>
  > => {
    return api.get<PageableResponse<MedicalCriteria>>(
      `/medical-criteria/free?size=100`
    );
  };

  return {
    getInfo,
    getAvailableForFreeCriterias,
    search,
  };
};

export default MedicalCriteriaApi();
