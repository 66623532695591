import { AxiosResponse } from 'axios';
import diagnAppApi from '../api/diagnappApi';
import riskFactorApi from '../api/riskFactorApi';
import symptomApi from '../api/symptomApi';
import { DiagnAppParams } from '../model/diagnAppParams';
import { DiagnAppSearchParams } from '../model/diagnAppSearchParams';
import { DiseaseWeight } from '../model/diseaseWeight';
import HttpStatus from '../model/enums/httpStatus';
import { RiskFactor } from '../model/riskFactor';
import { Symptom } from '../model/symptom';

export const DiagnAppService = () => {
    const searchRiskFactors = async (params: DiagnAppSearchParams): Promise<RiskFactor[]> => {
        try {
            const result: AxiosResponse<RiskFactor[]> = await riskFactorApi.search(params);
            console.log('result ', result);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };    
    const searchSymptoms = async (params: DiagnAppSearchParams): Promise<Symptom[]> => {
        try {
            const result: AxiosResponse<Symptom[]> = await symptomApi.search(params);
            console.log('result ', result);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };    
    const getResults = async (params: DiagnAppParams): Promise<DiseaseWeight[]> => {
        try {
            const result: AxiosResponse<DiseaseWeight[]> = await diagnAppApi.getResults(params);
            console.log('result ', result);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    return {
        searchRiskFactors,
        searchSymptoms,
        getResults
    };
};

export default DiagnAppService();
