import { Base64 } from 'js-base64';
import IllustratedProcedureType from '../../model/enums/illustratedProcedureType';
import { IllustratedProcedureContent } from '../../model/illustratedProcedureContent';

export const getDecodedMarkdownByType = (type: IllustratedProcedureType, illustratedProcedureContents?: IllustratedProcedureContent[]): string => {
    if (illustratedProcedureContents == null || illustratedProcedureContents.length === 0) {
        return '';
    }
    
    return illustratedProcedureContents.filter(it => it.type === type)
        .map(it => Base64.decode(it.content.rawMarkdownAvailable))[0] ?? ''
};