import { Base64 } from 'js-base64';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../components/loading/loading';
import ContentType from '../../model/enums/contentType';
import { MedicineLeaflet } from '../../model/medicine-leaflet';
import { RecentHistory } from '../../model/recentHistory';
import { IRootState } from '../../reducer';
import { logoutRequest } from '../../reducer/authentication/actions';
import { addInRecents } from '../../reducer/recentHistory/actions';
import AnalyticsService from '../../services/analytics-service';
import ContentRatingService from '../../services/contentRatingService';
import LeafletsService from '../../services/leafletsService';
import SmartLeafletTab from './smart-leaflet-tab';
import SmartLeafletTabContent from './smart-leaflet-tab-content';
import './smart-leaflet.scss';

export interface SmartLeafletScreenProps extends RouteComponentProps<{}, {}, { fromAnnotation?: boolean }> {
    logout: () => void;
    t: any;
    hasSubscription?: boolean;
    addInRecents: (recent: RecentHistory) => void;
}

export interface SmartLeafletScreenState {
    leaflet?: MedicineLeaflet;
    fontSize?: number;
    showAnnotation?: boolean;
    fromAnnotation?: boolean;
}

export class SmartLeafletScreen extends React.Component<SmartLeafletScreenProps, SmartLeafletScreenState> {
    constructor(props: Readonly<SmartLeafletScreenProps>, context?: any) {
        super(props, context);

        this.state = {};
    }

    componentDidMount(): void {
        const {
            match: { params }
        } = this.props;
        const leafletId: number = (params as any).leafletId;
        const fromAnnotation: boolean | undefined = this.props.location?.state?.fromAnnotation;
        this.getSmartLeaflet(leafletId);
        this.setState({ fromAnnotation });
    }

    componentDidUpdate(prevProps: SmartLeafletScreenProps, prevState: SmartLeafletScreenState): void {
        if (prevProps.match !== this.props.match) {
            const {
                match: { params }
            } = this.props;
            const leafletId: number = (params as any).leafletId;
            this.getSmartLeaflet(leafletId);
        }
    }
    private getSmartLeaflet = async (leafletId: number): Promise<void> => {
        const response: MedicineLeaflet = await LeafletsService.getLeafletMarkdown(leafletId);
        AnalyticsService.medicineLeafletClick(response);
        window.fbq('track', 'ViewContent', { content_name: response.name, content_type: 'SMART_BULA' });
        ContentRatingService.sendContentRating({ name: response.name!, contentId: response.id!, type: ContentType.MEDICINE_LEAFLET });
        this.props.addInRecents({ name: response.name!, contentId: response.id!, type: ContentType.MEDICINE_LEAFLET });
        this.setState({
            leaflet: response
        });
    };
    private onChangeFontSize = (fontSize: number): void => {
        this.setState({
            fontSize
        });
    };
    private onShowAnnotation = (): void => {
        this.setState({
            showAnnotation: !this.state.showAnnotation
        });
    };
    private onBack = (): void => {
        this.props.history.goBack();
    };

    render(): JSX.Element {
        const { leaflet, fontSize, showAnnotation } = this.state;
        if (leaflet == null || leaflet.content == null) {
            return (
                <div className="disease-screen-container">
                    <Loading />
                </div>
            );
        }
        const markdown: string = Base64.decode(leaflet.content.rawMarkdownAvailable);
        const leafletName = leaflet.composition || String(markdown.match(/^# ([a-zA-Z].*)/g)![0]).replace('# ', '');
        // console.log('markdown render diseasename', { markdown, leafletName });
        return (
            <div className="disease-screen-container">
                <SmartLeafletTab
                    leaflet={leaflet}
                    title={leafletName}
                    onBack={this.onBack}
                    markdown={
                        <SmartLeafletTabContent
                            {...this.props}
                            markdown={markdown}
                            fontSize={fontSize}
                            leaflet={leaflet}
                            showAnnotation={showAnnotation}
                            onShowAnnotation={this.onShowAnnotation}
                            hasSubscription={this.props.hasSubscription}
                        />
                    }
                    onChangeFontSize={this.onChangeFontSize}
                    onShowAnnotation={this.onShowAnnotation}
                    annotationIsOpen={this.state.fromAnnotation}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    isAuthenticated: authentication.isAuthenticated,
    hasSubscription:
        authentication.account && authentication.account.customer ? authentication.account.customer.hasSubscription : undefined,
    logout: logoutRequest
});

const mapDispatchToProps = {
    addInRecents: addInRecents
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SmartLeafletScreen) as React.ComponentType<any>;
