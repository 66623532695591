export interface Plan {
    id: number;
    price: string;
    period: PlanPeriod;
    planType: PlanType;
    active: boolean;
}

export enum PlanPeriod {
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
    TWO_YEARLY = 'TWO_YEARLY',
    UNDEFINED = 'UNDEFINED'
}
export enum PlanType {
    ANDROID = 'ANDROID',
    IOS = 'IOS',
    WEB = 'WEB',
    COURTESY = 'COURTESY',
    FREE = 'FREE'
}