import * as React from 'react';
import './simple-radio.scss';

interface Props {
    id: string;
    placeholder?: string;
    checked: boolean;
    onChange: () => void;
}

export const SimpleRadio = (props: Props) => {
    const callOnChange = () => {
        props.onChange();
    };

    return (
        <div className={`radio-click-area--${props.checked ? 'on' : 'off'}`} onClick={callOnChange} style={{ position: 'relative' }}>
            <div className={'radio-item-container'}>
                <div className={`check-radio--${props.checked ? 'on' : 'off'}`} />
                <span className={'radio-placeholder'}>{props.placeholder}</span>
            </div>
        </div>
    );
};
