import { AxiosResponse } from 'axios';
import interactionsApi from '../api/interactionsApi';
import HttpStatus from '../model/enums/httpStatus';
import { Interaction } from '../model/interaction';
import { MedicineLeaflet } from '../model/medicine-leaflet';

export const InteractionsService = () => {
    const getInteractionResult = async (params: MedicineLeaflet[]): Promise<Interaction[]> => {
        try {
            const result: AxiosResponse<Interaction[]> = await interactionsApi.getInteractionResults(params);
            console.log('result ', result);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    return {
        getInteractionResult
    };
};

export default InteractionsService();
