import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import App from './App';
import DevTools from './config/devtools';
import i18n from './config/i18n';
import './index.css';
import configureStore from './reducer/configureStore';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './shared/error/error-boundary';

const devTools = process.env.NODE_ENV === 'development' ? <DevTools /> : null;

const initialState = window.INITIAL_REDUX_STATE;
export const store = configureStore(initialState);

const rootEl = document.getElementById('root');

declare global {
    interface Window {
        fbq:any;
    }
}

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <div>
                    {/* If this slows down the app in dev disable it and enable when required  */}
                    {/* {devTools} */}
                    <App />
                </div>
            </I18nextProvider>
        </Provider>
    </ErrorBoundary>,
    rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
