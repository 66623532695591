import { AxiosResponse } from 'axios';
import illustratedProcedureApi from '../api/illustratedProcedureApi';
import HttpStatus from '../model/enums/httpStatus';
import { IllustratedProcedure } from '../model/illustratedProcedure';
import { PageableResponse } from '../model/pageable';

export const IllustratedProcedureService = () => {
    const getIllustratedProcedures = async (name?: string): Promise<IllustratedProcedure[]> => {
        try {
            const result: AxiosResponse<IllustratedProcedure[]> = await illustratedProcedureApi.getIllustratedProcedures(name);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

        const getFreeIllustratedProcedures = async (): Promise<IllustratedProcedure[]> => {
        try {
            const result: AxiosResponse<PageableResponse<IllustratedProcedure>> = await illustratedProcedureApi.getFreeIllustratedProcedures();
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data.content);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const getIllustratedProcedure = async (id: number): Promise<IllustratedProcedure> => {
        try {
            const result: AxiosResponse<IllustratedProcedure> = await illustratedProcedureApi.getIllustratedProcedure(id);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    return {
        getIllustratedProcedure,
        getFreeIllustratedProcedures,
        getIllustratedProcedures
    };
};

export default IllustratedProcedureService();
