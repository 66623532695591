import { Moment } from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import ContentType from '../../model/enums/contentType';
import MedicalCriteriaParamType from '../../model/enums/medical-criteria-param-type';
import { FormulaResult } from '../../model/medical-calculator/formula-result';
import { MedicalCriteria, MedicalCriteriaSimple } from '../../model/medical-criteria/medical-criteria';
import ContentRatingService from '../../services/contentRatingService';
import MedicalCriteriaService from '../../services/medical-criteria.service';
import { MedicalCriteriaParamGroup } from '../../model/medical-criteria/medical-criteria-param-group';
import { MedicalCriteriaParam } from '../../model/medical-criteria/medical-criteria-param';
import _ from 'lodash';

interface MedicalCriteriaProviderProps {
    children: any;
}

interface MedicalCriteriaProviderData {
    paramValues: ParamValues;
    result: FormulaResult;
    fontSize: number;
    showAnnotation: boolean;
    showResizerModal: boolean;
    isLoading: boolean;
    showFavoriteSuccessToast: boolean;
    medicalCriteriaList: MedicalCriteriaSimple[];
    medicalCriteria?: MedicalCriteria;
    setParamValues: (value: ParamValues) => void;
    setResult: (value: FormulaResult) => void;
    setFontSize: (value: number) => void;
    setShowAnnotation: (value: boolean) => void;
    setShowResizerModal: (value: boolean) => void;
    setShowFavoriteSuccessToast: (value: boolean) => void;
    setMedicalCriteria: (dose: MedicalCriteria) => void;
    setNewCurrentMedicalCriteria: (dose: MedicalCriteria) => void;
}

const MedicalCriteriaContext = createContext<MedicalCriteriaProviderData>(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as MedicalCriteriaProviderData
);

type ParamValues = Record<string, string | undefined | Moment | any>;

export const MedicalCriteriaProvider = ({ children }: MedicalCriteriaProviderProps) => {
    const [fontSize, setFontSize] = useState<number>(14);
    const [showAnnotation, setShowAnnotation] = useState<boolean>(false);
    const [showResizerModal, setShowResizerModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [medicalCriteriaList, setMedicalCriteriaList] = useState<MedicalCriteriaSimple[]>([]);
    const [showFavoriteSuccessToast, setShowFavoriteSuccessToast] = useState<boolean>(false);
    const [medicalCriteria, setMedicalCriteria] = useState<MedicalCriteria | undefined>(undefined);
    const [paramValues, setParamValues] = useState<ParamValues>({});
    const [result, setResult] = useState<FormulaResult>({
        resultText: 'Preencha todos os campos acima para o resultado'
    });

    useEffect(() => {
        MedicalCriteriaService.search().then(setMedicalCriteriaList);
    }, []);

    const setNewCurrentMedicalCriteria = (criteria: MedicalCriteriaSimple) => {
        setIsLoading(true);
        setShowAnnotation(false);
        setShowFavoriteSuccessToast(false);
        setResult({
            resultText: 'Preencha todos os campos acima para o resultado'
        });
        MedicalCriteriaService.getInfo(criteria.id!)
            .then(setMedicalCriteria)
            .finally(() => {
                setIsLoading(false);
            });
        window.fbq('track', 'ViewContent', {
            content_name: criteria.name,
            content_type: 'MEDICAL_CRITERIA'
        });
        ContentRatingService.sendContentRating({
            name: criteria.name!,
            contentId: criteria.id!,
            type: ContentType.MEDICAL_CRITERIA
        });
    };

    useEffect(() => {
        if (medicalCriteria != null) {
            const newValues: ParamValues = {};
            let formulaParams = [...medicalCriteria?.formulaParams!, ...medicalCriteria?.formulaParamGroups!];
            _.sortBy(formulaParams, 'position').forEach(it => {
                if ('formulaParams' in it) {
                    const formulaParamGroup = it as MedicalCriteriaParamGroup;
                    _.sortBy(formulaParamGroup.formulaParams, 'position').map(param => {
                        newValues[param.code!] = getDefaultValue(param);
                    });
                } else {
                    newValues[(it as MedicalCriteriaParam).code!] = getDefaultValue(it);
                }
                console.log({ newValues });
            });
            setParamValues(newValues);
        }
    }, [medicalCriteria]);

    const getDefaultValue = (param: MedicalCriteriaParam) => {
        let value;
        switch (param.inputType) {
            case MedicalCriteriaParamType.MULTI_ANSWER_SELECT:
                value = { answers: [] };
                break;
            case MedicalCriteriaParamType.INPUT:
                if (param?.code === 'LIMITE_SUPERIOR') {
                    value = '200';
                } else {
                    value = 0;
                }
                break;
        }
        return value;
    };

    return (
        <MedicalCriteriaContext.Provider
            value={{
                setFontSize,
                fontSize,
                medicalCriteriaList,
                showAnnotation,
                setShowAnnotation,
                setNewCurrentMedicalCriteria,
                setMedicalCriteria,
                showResizerModal,
                setShowResizerModal,
                medicalCriteria,
                isLoading,
                showFavoriteSuccessToast,
                setShowFavoriteSuccessToast,
                paramValues,
                setParamValues,
                result,
                setResult
            }}
        >
            {children}
        </MedicalCriteriaContext.Provider>
    );
};

export const useMedicalCriteriaContext = () => useContext(MedicalCriteriaContext);
