import { AxiosResponse } from 'axios';
import diseaseGroupsApi from '../api/diseaseGroupsApi';
import { DiseaseTree, GroupsDiseases } from '../model/apiResponses/GroupsDiseases';
import HttpStatus from '../model/enums/httpStatus';
import { SpecialtyType } from '../model/SpecialtyType';

export const DiseaseGroupsService = () => {
    const getGroupsDiseasesBySpecialty = async (specialtyType: SpecialtyType): Promise<GroupsDiseases> => {
        try {
            const result: AxiosResponse<GroupsDiseases> = await diseaseGroupsApi.getGroupsAndDiseasesBySpecialty(specialtyType);
            console.log('result ', result);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };    
    const getGroupsDiseasesByGroup = async (groupId: number): Promise<GroupsDiseases> => {
        try {
            const result: AxiosResponse<GroupsDiseases> = await diseaseGroupsApi.getGroupsAndDiseasesByGroups(groupId);
            console.log('result ', result);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const getDiseaseTree = async (diseaseId: number, category: SpecialtyType): Promise<DiseaseTree> => {
        try {
            const result: AxiosResponse<DiseaseTree> = await diseaseGroupsApi.getDiseaseTree(diseaseId, category);
            if (result.status === HttpStatus.OK && result.data != null) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    return {
        getGroupsDiseasesBySpecialty,
        getGroupsDiseasesByGroup,
        getDiseaseTree
    };
};

export default DiseaseGroupsService();
