import { AxiosResponse } from 'axios';
import { PediatricDose } from './../model/pediatric-dose/pediatric-dose';
import { api } from './api';

const PediatricDoseApi = () => {
  const getInfo = (id: number): Promise<AxiosResponse<PediatricDose>> => {
    return api.get<PediatricDose>(`/pediatric-doses/info/${id}`);
  };

  const search = (text?: string): Promise<AxiosResponse<PediatricDose[]>> => {
    const query = text !== undefined ? `?searchText=${text}` : '';
    return api.get<PediatricDose[]>(`/pediatric-doses/search${query}`);
  };

  return {
    getInfo,
    search
  };
};

export default PediatricDoseApi();
