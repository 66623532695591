import { AxiosResponse } from 'axios';
import { Interaction } from '../model/interaction';
import { MedicineLeaflet } from '../model/medicine-leaflet';
import { api } from './api';

export const interactionsApi = () => {
    const getInteractionResults = (params: MedicineLeaflet[]): Promise<AxiosResponse<Interaction[]>> => {
        return api.post<Interaction[]>(`/interactions/result`, params);
    };

    return {
        getInteractionResults
    };
};

export default interactionsApi();
