import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { Interaction } from '../../model/interaction';
import { MedicineLeaflet } from '../../model/medicine-leaflet';
import { IRootState } from '../../reducer';
import InteractionsService from '../../services/interactionsService';
import InteractionResults from './interaction-results';
import './medicine-interactions.scss';
import SelectMedicines from './select-medicines';

export interface MedicineInteractionsProps extends RouteComponentProps<{}> {
    t: (path: string, params?: any) => string;
}

export interface MedicineInteractionsState {
    interactions?: Interaction[];
}

export class MedicineInteractions extends React.Component<MedicineInteractionsProps, MedicineInteractionsState> {
    constructor(props: Readonly<MedicineInteractionsProps>, context?: any) {
        super(props, context);

        this.state = {};
    }
    // Api Calls
    private getInteractions = async (medicineLeaflets: MedicineLeaflet[]): Promise<void> => {
        if (medicineLeaflets.length === 0) {
            return;
        }
        const response: Interaction[] = await InteractionsService.getInteractionResult(medicineLeaflets);
        this.setState({ interactions: response });
    };
    // Handlers
    private goBack = (): void => {
        this.setState({
            interactions: undefined
        })
    }

    // Renders
    render() {
        const { interactions } = this.state;
        return (
            <div className="medicine-interactions-screen">
                {interactions == null ? (
                    <SelectMedicines getInteractions={this.getInteractions} />
                ) : (
                    <InteractionResults interactions={interactions} goBack={this.goBack} />
                )}
            </div>
        );
    }
}

const mapStateToProps = ({}: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(MedicineInteractions) as React.ComponentType<any>;
