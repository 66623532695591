import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import '../flashcard.scss';
import { PerformanceData } from '../../../model/flashcard/performance-data';
import FlashcardPerformanceAnswers from './flashcard-performance-answers';
import FlashcardPerformanceChart from './flashcard-performance-chart';
import FlashcardPerformanceData from './flashcard-performance-data';
import { DataContainer, MainContainer } from './flashcard-performance.styles';

interface Props extends RouteComponentProps<{}, {}, {}>, WithTranslation {
    data: PerformanceData;
    resetStatistic?: () => void;
}

const FlashcardPerformance = (props: Props) => {

    return (
        <MainContainer>
            <DataContainer>
                <div className="frame-6">
                    <FlashcardPerformanceChart height={300} width={300} {...props} />
                    <FlashcardPerformanceAnswers {...props} />
                </div>
                <FlashcardPerformanceData {...props} />
            </DataContainer>
            {props.resetStatistic != null && <div className="frame-15" onClick={props.resetStatistic}>
                <button className="buttons-cta">
                    <span className="reset-statistics">Resetar estatísticas</span>
                </button>
            </div>}
        </MainContainer>
    );
};

export default withTranslation()(FlashcardPerformance);
