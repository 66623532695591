import jwt_decode from "jwt-decode";
import { AUTHORITIES } from "../config/constants";

class AuthUtil {
    private AUTH_TOKEN_KEY = 'jhi-authenticationToken';
    private VERSION_CODE = 'jhi-version-code';
    private CURRENT_VERSION_CODE = 8;

    isAuthenticated(): boolean {
        return localStorage.getItem(this.AUTH_TOKEN_KEY) !== null;
    }

    manageVersionCode() {
        const versionCode = localStorage.getItem(this.VERSION_CODE);
        if(versionCode == null || Number(versionCode) < this.CURRENT_VERSION_CODE) {
            localStorage.setItem(this.VERSION_CODE, String(this.CURRENT_VERSION_CODE));
            this.removeToken();
        }
    }

    getToken(): string | null {
        return localStorage.getItem(this.AUTH_TOKEN_KEY);
    }

    setToken(token: string){
        localStorage.setItem(this.AUTH_TOKEN_KEY, token);
    }

    hasCompanyManagerPermission(): boolean {
        const token = localStorage.getItem(this.AUTH_TOKEN_KEY)!;
        const decodeJwt: any = jwt_decode(token);
        return decodeJwt.auth.includes(AUTHORITIES.COMPANY_MANAGER);
    }

    removeToken() {
     localStorage.removeItem(this.AUTH_TOKEN_KEY);
    }
}

export default new AuthUtil();