enum LaboratoryTube {
    RED_TUBE = 'RED_TUBE',
    YELLOW_TUBE = 'YELLOW_TUBE',
    GREY_TUBE = 'GREY_TUBE',
    PURPLE_TUBE = 'PURPLE_TUBE',
    GREEN_TUBE = 'GREEN_TUBE',
    BLUE_TUBE = 'BLUE_TUBE',
    WHITE_TUBE = 'WHITE_TUBE',
    DARK_BLUE_TUBE = 'DARK_BLUE_TUBE',
}

export const tubeColors = ['vermelho', 'amarelo', 'cinza', 'roxo', 'verde', 'azul', 'azul escuro', 'branco'];

export function hasTube(text?: string): boolean {
    if (text != null) {
        if (LABORATORY_TUBE_REGEX.test(text)) {
            return true;
        }
        return false;
    }
    return false;
}

export function getEnum(color?: string): LaboratoryTube {
    switch (color) {
        case 'vermelho':
            return LaboratoryTube.RED_TUBE;
        case 'amarelo':
            return LaboratoryTube.YELLOW_TUBE;
        case 'cinza':
            return LaboratoryTube.GREY_TUBE;
        case 'roxo':
            return LaboratoryTube.PURPLE_TUBE;
        case 'verde':
            return LaboratoryTube.GREEN_TUBE;
        case 'azul':
            return LaboratoryTube.BLUE_TUBE;
        case 'azul escuro':
            return LaboratoryTube.DARK_BLUE_TUBE;
        case 'branco':
            return LaboratoryTube.WHITE_TUBE;
        default:
            return LaboratoryTube.RED_TUBE;
    }
}


export function getImage(code: String) {
    switch (code.trim()) {
        case LaboratoryTube.RED_TUBE:
            return 'tube-red';
        case LaboratoryTube.YELLOW_TUBE:
            return 'tube-yellow';
        case LaboratoryTube.GREY_TUBE:
            return 'tube-grey';
        case LaboratoryTube.PURPLE_TUBE:
            return 'tube-purple';
        case LaboratoryTube.GREEN_TUBE:
            return 'tube-green';
        case LaboratoryTube.BLUE_TUBE:
            return 'tube-blue';
        case LaboratoryTube.WHITE_TUBE:
            return 'tube-white';
        case LaboratoryTube.DARK_BLUE_TUBE:
            return 'tube-dark_blue';
        default:
            return 'tube-red';
    }
}

export const LABORATORY_TUBE_REGEX = /RED_TUBE|YELLOW_TUBE|GREY_TUBE|PURPLE_TUBE|GREEN_TUBE|BLUE_TUBE|WHITE_TUBE|DARK_BLUE_TUBE/ig;
export default LaboratoryTube;
