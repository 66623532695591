import { AxiosResponse } from 'axios';
import contentRatingApi from '../api/contentRatingApi';
import { ContentRating } from '../model/contentRating';
import HttpStatus from '../model/enums/httpStatus';
import { PageableResponse } from '../model/pageable';

export const ContentRatingService = () => {

    const sendContentRating = async (newContent: ContentRating): Promise<void> => {
        try {
            await contentRatingApi.sendContentRating(newContent);
            Promise.resolve();
        } catch (error) {
            console.error(error);
            return Promise.reject(error as AxiosResponse);
        }
    }

    const getTopTwoByType = async (): Promise<ContentRating[]> => {
        try {
            const result: AxiosResponse<PageableResponse<ContentRating>> = await contentRatingApi.getTopTwoByType();
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data.content);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error as AxiosResponse);
        }
    };
    return {
        getTopTwoByType,
        sendContentRating
    };
};

export default ContentRatingService();
