import { AxiosResponse } from 'axios';
import { Authentication } from '../model/authentication';
import { User } from '../model/user';
import { api } from './api';

export const authenticate = () => {
  const login = (authentication: Authentication): Promise<AxiosResponse<Authentication>> => {
    return api.post<Authentication>('/authenticate', authentication);
  };

  const sendAuthenticationAudit = (authentication: any): Promise<AxiosResponse<void>> => {
    return api.post<void>('/authentication-audits', authentication);
  };

  const getSession = (): Promise<AxiosResponse<User>> => {
    return api.get<User>('/account/me');
  };

  return {
    login,
    sendAuthenticationAudit,
    getSession
  };
};

export default authenticate();
