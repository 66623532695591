import _ from 'lodash';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import IconInput from '../../components/icon-input/icon-input';
import ContentType from '../../model/enums/contentType';
import { MedicalScore } from '../../model/medical-score/medical-score';
import { RecentHistory } from '../../model/recentHistory';
import { addInRecents } from '../../reducer/recentHistory/actions';
import { useMedicalScoreContext } from './medical-score.provider';
import './medical-score.scss';

interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
    hasSubscription?: boolean;
    openPaidModal: () => void;
    addInRecents: (recent: RecentHistory) => void;
}

const MedicalScoreList = (props: Props) => {
    const { medicalScoreList, setNewCurrentMedicalScore } = useMedicalScoreContext();
    const [searchText, setSearchText] = useState<string>('');

    const onPressScore = (score: MedicalScore) => {
        if (props.hasSubscription === false && score.isAvailableForFree !== true) {
            props.openPaidModal();
            return;
        }
        setNewCurrentMedicalScore(score);
        props.addInRecents({ name: score.name!, contentId: score.id!, type: ContentType.MEDICAL_SCORE });
    };

    const newList = medicalScoreList.filter(
        score =>
            score.normalizedName?.includes(searchText) ||
            (score.synonyms?.filter(synonym => synonym.normalizedName?.includes(searchText))?.length ?? 0) > 0
    );

    return (
        <div className={'pediatrc-dose-container'}>
            <span className={'search-title'}>{'Scores'}</span>
            <div className={'dose-search-container'}>
                <IconInput value={searchText} onChangeText={text => setSearchText(text.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))} />
                <div className={'dose-content-container'}>
                    {_.sortBy(newList, 'normalizedName').map(score => (
                        <div className={'calculator-container'} onClick={() => onPressScore(score)} key={score.id ?? ''}>
                            <span className={'dose-name'}>{score?.name ?? ''}</span>
                            {props.hasSubscription === false && score.isAvailableForFree !== true && <div className="lock-img" />}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    addInRecents: addInRecents
};

export default compose(connect(null, mapDispatchToProps), withTranslation())(MedicalScoreList) as React.ComponentType<any>;
