import _ from 'lodash';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import IconInput from '../../components/icon-input/icon-input';
import ContentType from '../../model/enums/contentType';
import { MedicalCriteria } from '../../model/medical-criteria/medical-criteria';
import { RecentHistory } from '../../model/recentHistory';
import { addInRecents } from '../../reducer/recentHistory/actions';
import { useMedicalCriteriaContext } from './medical-criteria.provider';
import './medical-criteria.scss';

interface Props extends RouteComponentProps<{}, {}, {}> {
  t: any;
  hasSubscription?: boolean;
  openPaidModal: () => void;
  addInRecents: (recent: RecentHistory) => void;
}

const MedicalCriteriaList = (props: Props) => {
  const { medicalCriteriaList, setNewCurrentMedicalCriteria } =
    useMedicalCriteriaContext();
  const [searchText, setSearchText] = useState<string>('');

  const onPressCriteria = (criteria: MedicalCriteria) => {
    if (
      props.hasSubscription === false &&
      criteria.isAvailableForFree !== true
    ) {
      props.openPaidModal();
      return;
    }
    setNewCurrentMedicalCriteria(criteria);
    props.addInRecents({
      name: criteria.name!,
      contentId: criteria.id!,
      type: ContentType.MEDICAL_CRITERIA,
    });
  };

  const newList = medicalCriteriaList.filter(
    (criteria) =>
      criteria.normalizedName?.includes(searchText) ??
      (criteria.synonyms?.filter(
        (synonym) => synonym.normalizedName?.includes(searchText)
      )?.length ?? 0) > 0
  );

  return (
    <div className={'pediatrc-dose-container'}>
      <span className={'search-title'}>{'Critérios Diagnósticos'}</span>
      <div className={'dose-search-container'}>
        <IconInput
          value={searchText}
          onChangeText={(text) => {
            setSearchText(
              text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            );
          }}
        />
        <div className={'dose-content-container'}>
          {_.sortBy(newList, 'normalizedName').map((criteria) => (
            <div
              className={'calculator-container'}
              onClick={() => {
                onPressCriteria(criteria);
              }}
              key={criteria.id ?? ''}
            >
              <span className={'dose-name'}>{criteria?.name ?? ''}</span>
              {props.hasSubscription === false &&
                criteria.isAvailableForFree !== true && (
                  <div className="lock-img" />
                )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  addInRecents,
};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation()
)(MedicalCriteriaList) as React.ComponentType<any>;
