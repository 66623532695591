import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { store } from '..';
import { logoutRequest } from '../reducer/authentication/actions';
import { openAccessedFromAnotherDeviceModal, openExpiredHourModal } from '../reducer/globalModal/actions';
import AuthUtil from './authUtil';

const createApiInstance = (): AxiosInstance => {
    const config: AxiosRequestConfig = {
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 1 * 60 * 5000
    };

    const instance = axios.create(config);
    instance.interceptors.request.use(async requestConfig => {
        const token = AuthUtil.getToken();
        if (token) {
            requestConfig.headers.Authorization = `Bearer ${token}`;
        }
        return requestConfig;
    });
    instance.interceptors.response.use(response => {
        return response;
    }, (responseError) => {
        if (responseError.response.status === 401 || responseError.response.status === 403) {
            if (responseError.response.data?.detail === 'error.account.access.another.device' || responseError.response.data?.detail === 'Account accessed from another device') {
                AuthUtil.removeToken();
                store.dispatch(logoutRequest());
                if (!store.getState().globalModal.accessedFromAnotherDevice) {
                    store.dispatch(openAccessedFromAnotherDeviceModal());
                }
                return Promise.reject(responseError);
            }
        }
        if (responseError.response.status === 401) {
            if (responseError.response.data && responseError.response.data.detail === 'error.expired.token.after.one.hour') {
                AuthUtil.removeToken();
                store.dispatch(logoutRequest());
                if (!store.getState().globalModal.expiredHourModal) {
                    store.dispatch(openExpiredHourModal());
                }
                return Promise.reject(responseError);
            }
        }
        if (responseError.response.status === 401) {
            if (responseError.response.data?.detail === 'Full authentication is required to access this resource') {
                AuthUtil.removeToken();
                store.dispatch(logoutRequest());
                return Promise.reject(responseError);
            }
        }
        return Promise.reject(responseError);

    });

    return instance;
};

export const api = createApiInstance();
export default {
    api
};
