import * as React from 'react';
import './radio-field-meds.scss';
import { TextField, InputAdornment } from '@material-ui/core';
import ReactDOM from 'react-dom';

export interface IRadioFieldMedsProps {
    id: string;
    placeholder?: string;
    checked: boolean;
    onChange: () => void;
}

export interface IRadioFieldMedsState {}

export default class RadioFieldMeds extends React.Component<IRadioFieldMedsProps, IRadioFieldMedsState> {
    constructor(props: any) {
        super(props);

        this.state = {
            placeholder: this.props.placeholder ? this.props.placeholder : '',
            checked: this.props.checked ? this.props.checked : ''
        };
    }

    callOnChange = () => {
        this.props.onChange();
    };

    render() {
        return (
            <div className="radio-click-area" onClick={this.callOnChange.bind(this)} style={{ position: 'relative'}}>
                <TextField
                    style={{width: '95%'}}
                    className={this.props.checked ? 'validate filled' : 'action'}
                    id={this.props.id}
                    type="text"
                    autoComplete="off"
                    disabled={true}
                    error={this.props.checked}
                    InputProps={
                        this.props.checked
                            ? {
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <div className="check-radio" />
                                      </InputAdornment>
                                  )
                              }
                            : {
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <div className="check-radio-blank" />
                                      </InputAdornment>
                                  )
                              }
                    }
                    label={this.props.placeholder}
                    variant="filled"
                />
            </div>
        );
    }
}
