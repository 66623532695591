import jwt_decode from 'jwt-decode';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Dispatch, bindActionCreators, compose } from 'redux';
import './App.scss';
import AuthUtil from './api/authUtil';
import AnotherAccessModal from './components/another_access-modal/another_access-modal';
import ErrorPaymentModal from './components/error-payment-modal/error-payment-modal';
import ExpiredHourModal from './components/expired-hour-modal/expired-hour-modal';
import PaidDiseaseModal from './components/paid-disease-modal/paid-disease-modal';
import PaidFlashcardModal from './components/paid-flashcard-modal/paid-flashcard-modal';
import { AUTHORITIES } from './config/constants';
import Firebase from './config/firebase';
import ImageModal from './features/disease/image-modal';
import Feedback from './features/feedback/feedback';
import Footer from './features/footer/footer';
import Header from './features/menu/header/header';
import { User } from './model/user';
import { IRootState } from './reducer';
import { getSessionRequest } from './reducer/authentication/actions';
import { GlobalModalState } from './reducer/globalModal/types';
import { setRecents } from './reducer/recentHistory/actions';
import PageNotFound from './shared/error/page-not-found';
import AppRoutes from './shared/routes/routes';
import { Toaster } from 'react-hot-toast';

const baseHref = '/';
export interface AppProps extends RouteComponentProps<{}> {
    t: (path: string, params?: any) => void;

    getSession: () => void;
    setRecent: () => void;

    isAuthenticated: boolean;
    account?: User;
    globalModal: GlobalModalState;

    location: any;
    match: any;
    history: any;
}

const App = (props: AppProps) => {
    const { globalModal } = props;
    useEffect(() => {
        Firebase.initialize();
        AuthUtil.manageVersionCode();
        if (AuthUtil.isAuthenticated()) {
            const decodeJwt: any = jwt_decode(AuthUtil.getToken()!);
            if (decodeJwt.auth.includes(AUTHORITIES.CUSTOMER)) {
                props.getSession();
                props.setRecent();
                return;
            }
        }
    }, []);

    return (
        <div className="page-container">
            <Router basename={baseHref}>
                <Switch>
                    <Route
                        path=""
                        render={router => {
                            const isAuthenticated: boolean =
                                props.isAuthenticated &&
                                router.location.pathname !== '/subscription' &&
                                router.location.pathname !== '/register' &&
                                router.location.pathname !== '/';
                            return (
                                <div style={{ position: 'relative', height: '100%' }}>
                                    {isAuthenticated && <Header {...props} {...router} />}
                                    <Route path={`/`} component={AppRoutes} />
                                    <PaidDiseaseModal open={globalModal.paidDiseaseModalOpen} history={router.history} />
                                    <PaidFlashcardModal open={globalModal.paidFlashcardModalOpen} history={router.history} />
                                    <ExpiredHourModal open={globalModal.expiredHourModal} history={router.history} />
                                    <ErrorPaymentModal open={globalModal.errorPaymentModal} history={router.history} />
                                    <AnotherAccessModal open={globalModal.accessedFromAnotherDevice} history={router.history} />
                                    <ImageModal open={globalModal.imageModal.isOpen} image={globalModal.imageModal.imageSrc} />
                                    <Toaster position="top-right" reverseOrder={false} />
                                    {isAuthenticated && <Feedback showModal={globalModal.feedbackModal} {...router} />}
                                    {isAuthenticated &&
                                        router.location.pathname !== '/company-dashboard' &&
                                        router.location.pathname !== '/company-create-login' && <Footer />}
                                </div>
                            );
                        }}
                    />
                    <Route path="*" component={PageNotFound} />
                </Switch>
            </Router>
        </div>
    );
};

const mapStateToProps = (state: IRootState): Pick<AppProps, 'isAuthenticated' | 'account' | 'globalModal'> => ({
    isAuthenticated: state.authentication.isAuthenticated,
    account: state.authentication.account,
    globalModal: state.globalModal
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<AppProps, 'getSession' | 'setRecent'> => {
    return {
        getSession: bindActionCreators(getSessionRequest, dispatch),
        setRecent: bindActionCreators(setRecents, dispatch)
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(App) as React.ComponentType<any>;
