import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import CancelSubscriptionModal from '../../../components/cancel-subscription-modal/cancel-subscription-modal';
import { PlanType } from '../../../model/plan';
import { Subscription } from '../../../model/subscription';
import { User } from '../../../model/user';
import { IRootState } from '../../../reducer';
import './components.scss';

export interface MySubscriptionProps extends RouteComponentProps<{}> {
    t: (path: string, param?: any) => string;
    history: any;
    user?: User;
}
export interface MySubscriptionState {
    showCancelSubscriptionModal: boolean;
    cancelSubscriptionAppShop: string;
}

const FeatureRow = ({ text, unavailable }: { text: string; unavailable?: boolean }): JSX.Element => {
    const iconClass: string = unavailable
        ? 'subscription-plans__card__feature-list__row__x-icon'
        : 'subscription-plans__card__feature-list__row__check-icon';
    const textClass: string = unavailable
        ? 'subscription-plans__card__feature-list__row__text subscription-plans__card__feature-list__row__text--disable'
        : 'subscription-plans__card__feature-list__row__text';
    return (
        <div className="subscription-plans__card__feature-list__row">
            <div className={iconClass} />
            <span className={textClass}>{text}</span>
        </div>
    );
};
export class MySubscription extends React.Component<MySubscriptionProps, MySubscriptionState> {
    private freeFeatures1: string[] = [
        this.props.t('preferences.mySubscriptionComponent.freeFeatures.1'),
        this.props.t('preferences.mySubscriptionComponent.freeFeatures.2'),
        this.props.t('preferences.mySubscriptionComponent.freeFeatures.3'),
        this.props.t('preferences.mySubscriptionComponent.freeFeatures.4')
    ];
    private freeFeatures2: string[] = [
        this.props.t('preferences.mySubscriptionComponent.freeFeatures.5'),
        this.props.t('preferences.mySubscriptionComponent.freeFeatures.6'),
        this.props.t('preferences.mySubscriptionComponent.freeFeatures.7'),
        this.props.t('preferences.mySubscriptionComponent.freeFeatures.8')
    ];
    private premiumFeatures: string[] = [
        this.props.t('preferences.mySubscriptionComponent.premiumFeatures.1'),
        this.props.t('preferences.mySubscriptionComponent.premiumFeatures.2'),
        this.props.t('preferences.mySubscriptionComponent.premiumFeatures.3'),
        this.props.t('preferences.mySubscriptionComponent.premiumFeatures.4'),
        this.props.t('preferences.mySubscriptionComponent.premiumFeatures.5'),
        this.props.t('preferences.mySubscriptionComponent.premiumFeatures.6'),
        this.props.t('preferences.mySubscriptionComponent.premiumFeatures.7'),
        this.props.t('preferences.mySubscriptionComponent.premiumFeatures.8')
    ];
    constructor(props: Readonly<MySubscriptionProps>, context?: any) {
        super(props, context);

        this.state = {
            showCancelSubscriptionModal: false,
            cancelSubscriptionAppShop: ''
        };
    }
    // Handlers
    private onCancelSubscription = () => {
        const { user } = this.props;
        if (user && user.customer && user.customer.subscription) {
            const { subscription } = user.customer;
            switch(subscription.plan.planType) {
                case (PlanType.WEB):
                    // to do iugu
                    break;
                case (PlanType.ANDROID):
                    this.setState({
                        showCancelSubscriptionModal: true,
                        cancelSubscriptionAppShop: this.props.t('cancelSubscriptionModal.androidAppShop')
                    });
                    break;
                case (PlanType.IOS):
                    this.setState({
                        showCancelSubscriptionModal: true,
                        cancelSubscriptionAppShop: this.props.t('cancelSubscriptionModal.iosAppShop')
                    });
                    break;
                default:
                    break;
            }
        }

    };
    private closeModal = () => {
        this.setState({
            showCancelSubscriptionModal: false
        });
    }
    private onPremiumPress = () => {
        this.props.history.push('/subscription');
    };
    // Renders

    render() {
        const { cancelSubscriptionAppShop, showCancelSubscriptionModal } = this.state;
        const { user } = this.props;
        const hasSubscription: boolean = user && user.customer && user.customer.hasSubscription ? true : false;
        const subscription: Subscription | undefined = user && user.customer ? user.customer.subscription : undefined;
        return (
            <div className="component" style={{ paddingTop: 0 }}>
                <div className="subscription-plans">
                    <div className="subscription-plans__card-container">
                        {!hasSubscription && (
                            <div className="subscription-plans__active-header">
                                <span className="subscription-plans__active-header__text">
                                    {this.props.t('preferences.mySubscriptionComponent.mySubscription').toUpperCase()}
                                </span>
                                <div className="subscription-plans__active-header__triangle" />
                            </div>
                        )}
                        <div
                            className={`subscription-plans__card ${hasSubscription ? '' : 'subscription-plans__card--active'}`}
                            style={hasSubscription ? { height: 585 } : { height: 636 }}
                        >
                            <div className="subscription-plans__card__free-logo" />
                            <div className="subscription-plans__card__line" />
                            <div className="subscription-plans__card__feature-list">
                                {this.freeFeatures1.map((it, index) => (
                                    <FeatureRow key={index} text={it} />
                                ))}
                                {this.freeFeatures2.map((it, index) => (
                                    <FeatureRow key={index} text={it} unavailable />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="subscription-plans__card-container">
                        {hasSubscription && (
                            <div className="subscription-plans__active-header">
                                <span className="subscription-plans__active-header__text">
                                    {this.props.t('preferences.mySubscriptionComponent.mySubscription').toUpperCase()}
                                </span>
                                <div className="subscription-plans__active-header__triangle" />
                            </div>
                        )}
                        <div
                            className={`subscription-plans__card ${hasSubscription ? 'subscription-plans__card--active' : ''}`}
                            style={hasSubscription ? { height: 585 } : { height: 636 }}
                        >
                            <div className="subscription-plans__card__premium-logo" />
                            {subscription && subscription.plan && subscription.plan.planType === PlanType.COURTESY ? (
                                <div className="subscription-plans__card__subtitle">
                                    <span className="subscription-plans__card__subtitle__text">
                                        {this.props.t('preferences.mySubscriptionComponent.availableTo', {
                                            value: moment(subscription.endDate).format('DD/MM/YYYY')
                                        })}
                                    </span>
                                </div>
                            ) : (
                                subscription && (
                                    <div className="subscription-plans__card__subtitle">
                                        <span className="subscription-plans__card__subtitle__text">
                                            {this.props.t('preferences.mySubscriptionComponent.renewAt', {
                                                value: moment(subscription.endDate).format('DD/MM/YYYY')
                                            })}
                                        </span>
                                    </div>
                                )
                            )}
                            <div className="subscription-plans__card__line" />
                            <div className="subscription-plans__card__feature-list">
                                {this.premiumFeatures.map((it, index) => (
                                    <FeatureRow key={index} text={it} />
                                ))}
                            </div>
                            {hasSubscription ? (
                                subscription &&
                                subscription.plan.planType !== PlanType.COURTESY && (
                                    <div className="subscription-plans__card__cancel-button" onClick={this.onCancelSubscription}>
                                        <span className="subscription-plans__card__cancel-button__label">
                                            {this.props.t('preferences.mySubscriptionComponent.cancelButtonLabel')}
                                        </span>
                                    </div>
                                )
                            ) : (
                                <div className="subscription-plans__card__subscribe-button-container">
                                    <div className="subscription-plans__card__subscribe-button" onClick={this.onPremiumPress}>
                                        <span className="subscription-plans__card__subscribe-button__label">
                                            {this.props.t('preferences.mySubscriptionComponent.subscribeButtonLabel').toUpperCase()}
                                        </span>
                                    </div>
                                    <span style={{ width: 242, textAlign: 'center', height: 40, lineHeight: '14px' }}>
                                        <span className="subscription-plans__card__subscribe-button-container__subtitle subscription-plans__card__subscribe-button-container__subtitle--bold">
                                            {this.props.t('preferences.mySubscriptionComponent.subscribeButtonSubtitle1')}
                                        </span>
                                        <span className="subscription-plans__card__subscribe-button-container__subtitle">
                                            {this.props.t('preferences.mySubscriptionComponent.subscribeButtonSubtitle2')}
                                        </span>
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <CancelSubscriptionModal open={showCancelSubscriptionModal} appShop={cancelSubscriptionAppShop} t={this.props.t} closeModal={this.closeModal}/>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    user: authentication.account
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(MySubscription) as React.ComponentType<any>;
