import styled from 'styled-components';

export const BarContainer = styled.div`
    height: 8px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #BEEEED50;
`;

export const ProgressBar = styled.div<{barWidth: number}>`
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: ${props => props.barWidth}%;
    background: linear-gradient(270deg, #47E6E4 25.53%, #5AB0AE 100%);
`;