import { AxiosResponse } from 'axios';
import { FeedbackRequest } from '../model/feedback';
import { api } from './api';

export const feedbackApi = () => {
    const sendFeedback = (params: FeedbackRequest): Promise<AxiosResponse<void>> => {
        return api.post<void>(`/feedback`, params);
    };

    return {
        sendFeedback
    };
};

export default feedbackApi();
