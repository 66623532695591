import { AxiosResponse } from 'axios';
import HttpStatus from '../model/enums/httpStatus';
import PediatricDoseApi from './../api/pediatric-dose.api';
import { PediatricDose, PediatricDoseSimple } from './../model/pediatric-dose/pediatric-dose';

export const PediatricDoseService = () => {
    const getInfo = async (id: number): Promise<PediatricDose> => {
        try {
            const result: AxiosResponse<PediatricDose> = await PediatricDoseApi.getInfo(id);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const search = async (text?: string): Promise<PediatricDoseSimple[]> => {
        try {
            const result: AxiosResponse<PediatricDoseSimple[]> = await PediatricDoseApi.search(text);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    return {
        getInfo,
        search
    };
};

export default PediatricDoseService();
