import { AxiosResponse } from 'axios';
import { ContentRating } from '../model/contentRating';
import { PageableResponse } from '../model/pageable';
import { api } from './api';

export const contentRatingApi = () => {
    const getTopTwoByType = (): Promise<AxiosResponse<PageableResponse<ContentRating>>> => {
        return api.get<PageableResponse<ContentRating>>(`/content-rating?shortResponse=true`);
    };

    const sendContentRating = (contentRating: ContentRating): Promise<AxiosResponse<ContentRating>> => {
        return api.post<ContentRating>(`/content-rating`, contentRating);
    };


    return {
        getTopTwoByType,
        sendContentRating
    };
};

export default contentRatingApi();
