import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { DiagnAppSearchParams } from '../../../model/diagnAppSearchParams';
import { Symptom } from '../../../model/symptom';
import { IRootState } from '../../../reducer';
import '../diagnapp.scss';
import DropdownSearchSymptoms from '../dropdown-search-symptoms';

export interface SymptomComponentProps extends RouteComponentProps<{}> {
    t: (path: string, params?: any) => string;
    onChangeInput: (symptoms: Symptom[]) => void;
    getSearchParams: () => DiagnAppSearchParams;
    symptoms?: Symptom[];
}

export interface SymptomComponentState {
    symptoms: Symptom[];
}

export class SymptomComponent extends React.Component<SymptomComponentProps, SymptomComponentState> {
    constructor(props: Readonly<SymptomComponentProps>, context?: any) {
        super(props, context);

        this.state = {
            symptoms: props.symptoms && props.symptoms.length >= 1 ? [...props.symptoms, {}] : [{}]
        };
    }

    // Handlers
    private onSelectSymptom = (item: any): void => {
        let symptoms: any[] = this.state.symptoms;
        if (symptoms.length === 2) {
            if (_.isEmpty(symptoms[0])) {
                symptoms =  [item, {}];
            } else {
                symptoms =  [symptoms[0], item, {}];
            }
        } else {
            symptoms[symptoms.length - 1] = item;
            symptoms = symptoms.length === 5 ? symptoms : symptoms.concat({});
        }
        this.setState({
            symptoms
        }, () => {
            this.props.onChangeInput(this.state.symptoms.filter((it) => it.name));
        })
    };
    private onRemoveSymptom = (index: number): void => {
        const { symptoms } = this.state;
        symptoms.splice(index, 1);
        this.setState({
            symptoms
        }, () => {
            this.props.onChangeInput(this.state.symptoms.filter((it) => it.name));
        });
    };

    // Renders
    render() {
        const { getSearchParams } = this.props;
        const { symptoms } = this.state;
        return (
            <div className="symptom-component">
                <p className="symptom-component__title">{this.props.t('diagnApp.symptomComponent.title').toUpperCase()}</p>
                <p className="symptom-component__subtitle">{this.props.t('diagnApp.symptomComponent.subtitle')}</p>
                <div className="symptom-component__dropdowns">
                    {symptoms.map((it, index) => (
                        <DropdownSearchSymptoms
                            key={index}
                            inputNumber={index + 1}
                            onClickItem={this.onSelectSymptom}
                            onRemove={() => this.onRemoveSymptom(index)}
                            getSearchParams={getSearchParams}
                            selectedItem={it.id ? it : undefined}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({}: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SymptomComponent) as React.ComponentType<any>;
