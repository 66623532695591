import { AxiosResponse } from 'axios';
import { Disease, DiseaseName } from '../model/disease';
import { FreeDiseases } from '../model/freeDiseases';
import { Pageable, PageableResponse } from '../model/pageable';
import { api } from './api';

export const diseaseApi = () => {
    const getDiseaseNameById = (id: number): Promise<AxiosResponse<DiseaseName>> => {
        return api.get<DiseaseName>(`/diseases/simple/${id}`);
    };
    const getFreeDiseases = (): Promise<AxiosResponse<FreeDiseases>> => {
        return api.get<FreeDiseases>('/free-diseases');
    };
    const getDisease = (diseaseId: number): Promise<AxiosResponse<Disease>> => {
        return api.get<Disease>(`/diseases/markdown/${diseaseId}`);
    };
    const getSimpleDiseasesByName = (page: Pageable, name?: string): Promise<AxiosResponse<PageableResponse<Disease>>> => {
        const query = name ? `&name=${name}` : '';
        return api.get<PageableResponse<Disease>>(`/diseases/simple-search?size=${page.size}&page=${page.number}${query}`);
    };

    return {
        getSimpleDiseasesByName,
        getDiseaseNameById,
        getFreeDiseases,
        getDisease
    };
};

export default diseaseApi();
