import { AxiosResponse } from 'axios';
import { SusProcedure } from '../model/susProcedure';
import { SusGroupApiResponse } from '../model/susProcedureGroup';
import { SusTree } from '../model/susTree';
import { api } from './api';
// import Mocker from './mockApi';
// import susGroups from './mocks/susGroups.json';
// import susProcedure from './mocks/susProcedure.json';
// import susProcedures from './mocks/susProcedures.json';
// import susSubGroups from './mocks/susSubGroups.json';

export const susApi = () => {
  const getGroups = (parentId?: number): Promise<AxiosResponse<SusGroupApiResponse>> => {
      return api.get<SusGroupApiResponse>(`/sus-procedure-group/parent/${parentId ? parentId : ''}`);
  };  
  const getProcedure = (procedureId: number): Promise<AxiosResponse<SusProcedure>> => {
      // return new Mocker<SusProcedure>().mock(susProcedure as any);
      return api.get<SusProcedure>(`/sus-procedures/${procedureId}`);
  };  
  const getSusTree = (procedureId: number): Promise<AxiosResponse<SusTree>> => {
      // return new Mocker<SusProcedure>().mock(susProcedure as any);
      return api.get<SusTree>(`/sus-procedures/tree/${procedureId}`);
  };  
  return {
    getGroups,
    getProcedure,
    getSusTree
  };
};

export default susApi();
