import _ from 'lodash';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import IconInput from '../../components/icon-input/icon-input';
import ContentType from '../../model/enums/contentType';
import { MedicalCalculator } from '../../model/medical-calculator/medical-calculator';
import { RecentHistory } from '../../model/recentHistory';
import { addInRecents } from '../../reducer/recentHistory/actions';
import { useMedicalCalculatorContext } from './medical-calculator.provider';
import './medical-calculator.scss';

interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
    hasSubscription?: boolean;
    openPaidModal: () => void;
    addInRecents: (recent: RecentHistory) => void;
}

const MedicalCalculatorList = (props: Props) => {
    const { medicalCalculatorList, setNewCurrentMedicalCalculator } = useMedicalCalculatorContext();
    const [searchText, setSearchText] = useState<string>('');

    const onPressCalculator = (calculator: MedicalCalculator) => {
        if (props.hasSubscription === false && calculator.isAvailableForFree !== true) {
            props.openPaidModal();
            return;
        }
        setNewCurrentMedicalCalculator(calculator);
        props.addInRecents({ name: calculator.name!, contentId: calculator.id!, type: ContentType.MEDICAL_CALCULATOR });
    }

    const newList = medicalCalculatorList.filter(calculator => calculator.normalizedName?.includes(searchText)
        || (calculator.synonyms?.filter(synonym => synonym.normalizedName?.includes(searchText))?.length ?? 0) > 0)

    return (
        <div className={'pediatrc-dose-container'}>
            <span className={'search-title'}>{'Calculadoras'}</span>
            <div className={'dose-search-container'}>
                <IconInput value={searchText} onChangeText={text => setSearchText(text.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))} />
                <div className={'dose-content-container'}>
                    {_.sortBy(newList, 'normalizedName').map(calculator => (
                        <div className={'calculator-container'} onClick={() => onPressCalculator(calculator)} key={calculator.id ?? ''}>
                            <span className={'dose-name'}>{calculator?.name ?? ''}</span>
                            {(props.hasSubscription === false && calculator.isAvailableForFree !== true) && <div className="lock-img" />}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    addInRecents: addInRecents
};

export default compose(connect(null, mapDispatchToProps), withTranslation())(MedicalCalculatorList) as React.ComponentType<any>;
