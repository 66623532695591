import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { IRootState } from '../../reducer';
import Card from './card';
import { CardList } from './multiple-card-list';
import './multiple-card-list.scss';

export interface SingleCardListProps extends RouteComponentProps<{}> {
    t: any;
    cardList: CardList;
    color: string;
    onGetNewList: (list: CardList, listIndex: number, childrenAmmount?: number) => void;
    listIndex: number;
    firstColumnCardStyle?: string;
    noCardExtension?: boolean;
}

export interface SingleCardListState {
    selectedCardIndex?: number;
}

export class SingleCardList extends React.Component<SingleCardListProps, SingleCardListState> {
    constructor(props: Readonly<SingleCardListProps>, context?: any) {
        super(props, context);

        this.state = {
            selectedCardIndex: _.findIndex(props.cardList.items, ['isSelected', true])
        };
    }
    componentWillReceiveProps(newProps: SingleCardListProps) {
        if (newProps.cardList !== this.props.cardList) {
            const selectedItemsIndexes: number[] = newProps.cardList.items.filter((it) => it.isSelected).map((it, index) => index);
            this.setState({
                selectedCardIndex: selectedItemsIndexes.length > 0 ? selectedItemsIndexes[0] : undefined
            });
            this.forceUpdate();
        }
    }

    private onGetNewList = (list: CardList, cardIndex?: number, childrenAmmount?: number) => {
        this.props.onGetNewList(list, this.props.listIndex, childrenAmmount);
        this.setState({ selectedCardIndex: cardIndex });
    };
    render() {
        const { selectedCardIndex } = this.state;
        const { cardList, color, noCardExtension, firstColumnCardStyle } = this.props;
        if (cardList == null) {
            return <div />;
        }
        return (
            <div className="card-list">
                <div className="card-list__title">
                    <p className="card-list__title__text">{cardList.title.toUpperCase()}</p>
                </div>
                <div className="card-list__list-container">
                    {cardList.items.map((it, index) => (
                        <Card
                            key={index}
                            index={index}
                            card={it}
                            firstColumnCardStyle={firstColumnCardStyle}
                            color={color}
                            onGetNewList={this.onGetNewList}
                            isSelected={index === selectedCardIndex}
                            noExtension={noCardExtension}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SingleCardList) as React.ComponentType<any>;
