import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import MultipleCardList, { CardItem, CardList } from '../../components/multiple-card-list/multiple-card-list';
import { Laboratory } from '../../model/laboratory/laboratory';
import { LaboratoryCategory, LaboratoryGroup } from '../../model/laboratory/laboratory-group';
import { IRootState } from '../../reducer';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import LaboratoryService from '../../services/laboratoryService';
import { CardItemContent } from '../specialties/specialty-card-item';
import LaboratoryCardItem from './laboratory-card-item';
import './laboratory.scss';

export interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
    hasSubscription?: boolean;
    openPaidModal: () => void;
}

const LaboratoriesScreen = (props: Props): JSX.Element => {
    const [firstCardList, setFirstCardList] = useState<CardList | undefined>();

    useEffect(() => {
        if(props.hasSubscription != null) {
            getLaboratories();
        }
    }, [props.hasSubscription]);


    const onItemPress = async (laboratory: Laboratory) => {
        if (laboratory.isAvailableForFree === true || props.hasSubscription === true) {
            props.history.push({
                pathname: `/laboratory/${laboratory.id}`,
                state: {
                    screenToBack: '/laboratory'
                }
            });
        } else {
            props.openPaidModal();
        }
    };

    const getGroupsByCategory = async (category: LaboratoryCategory): Promise<CardList | void> => {
        const result: LaboratoryGroup[] = await LaboratoryService.getByCategory(category);
        const color: string = result?.[0].hexColor ?? '#00C9B8';
        const cardList: CardList = {
            title: props.t(`enum.laboratoryGroupCategory.${category}`),
            items: mapGroupsToCardItem({ children: result }),
            color
        };
        return cardList;
    };

    const getGroupsByGroup = async (group: LaboratoryGroup): Promise<CardList | void> => {
        const result: LaboratoryGroup = await LaboratoryService.getGroupByParentId(group.id!);
        const color: string = result?.hexColor ?? '#00C9B8';
        const cardList: CardList = {
            title: group.name!,
            items: mapGroupsToCardItem(result),
            color
        };
        return cardList;
    };


    const mapGroupsToCardItem = (group: LaboratoryGroup): CardItem[] => {
        const groups: CardItem[] = group.children?.map<CardItem>((item, index) => ({
            renderItem: () => <LaboratoryCardItem item={mapCardGroupContent(item)} />,
            getChildren: () => getGroupsByGroup(item),
            childrenAmount: (item.children?.length ?? 0) + (item.laboratories?.length ?? 0),
            isSelected: item.selected === true,
            name: item.name
        })) ?? [];

        const laboratories: CardItem[] = group.laboratories?.map<CardItem>((item, index) => ({
            renderItem: () => <LaboratoryCardItem item={mapCardLaboratory(item)} />,
            getChildren: () => onItemPress(item),
            name: item.name
        })) ?? [];

        const cardItems: CardItem[] = groups.concat(laboratories).sort((a, b) => a.name?.localeCompare(b.name!) ?? 0);
        return cardItems;
    };

    const mapCardGroupContent = (taxonomy: any): CardItemContent => {
        return {
            icon: taxonomy.icon ? taxonomy.icon.content : undefined,
            title: taxonomy.name
        };
    };

    const mapCardLaboratory = (taxonomy: any): CardItemContent => {
        return {
            title: taxonomy.name,
            isPaid: taxonomy.isAvailableForFree !== true && props.hasSubscription === false
        };
    };

    const mapLaboratories = (): CardItem[] => [
        {
            renderItem: () => (
                <img src={require('../../images/laboratory/blood.svg')} className={'semiology-card'} />
            ),
            getChildren: () => getGroupsByCategory(LaboratoryCategory.BLOOD)
        },
        {
            renderItem: () => (
                <img src={require('../../images/laboratory/urine.svg')} className={'semiology-card'} />
            ),
            getChildren: () => getGroupsByCategory(LaboratoryCategory.URINE)
        },
        {
            renderItem: () => (
                <img src={require('../../images/laboratory/other.svg')} className={'semiology-card'} />
            ),
            getChildren: () => getGroupsByCategory(LaboratoryCategory.OTHERS)
        },
    ];

    const getLaboratories = () => {
        setFirstCardList({
            title: props.t('laboratories.title'),
            items: mapLaboratories()
        });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px', marginTop: '10px' }}>
                <img src={require('../../images/laboratory/fast-reference.svg')} className={'fast-reference'} onClick={() => onItemPress({ isAvailableForFree: true, id: 322 })} />
            </div>
            <div className="specialty-screen-container" style={{ position: 'relative' }}>
                <div style={{ alignItems: 'flex-start', position: 'relative', width: '100%', height: '100%' }}>
                    {firstCardList != null && <MultipleCardList firstCardList={firstCardList} noCardExtension hasExtraFirstColumn firstColumnCardStyle={'card-item_content'} />}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account?.customer?.hasSubscription
});

const mapDispatchToProps = {
    openPaidModal: openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LaboratoriesScreen) as React.ComponentType<any>;
