import moment, { Moment } from 'moment';
import React, { createContext, ReactNode, useContext, useState } from 'react';

interface DividerOnDutyProviderProps {
    children: ReactNode;
}

interface DividerOnDutyProviderData {
    startTime: Moment;
    endTime: Moment;
    doctors: OnDutyDoctor[];
    currentStep: DividerOnDutyStep;
    setStartTime: (value: Moment) => void;
    setEndTime: (value: Moment) => void;
    setDoctors: (value: OnDutyDoctor[]) => void;
    onChangeStep: (value: DividerOnDutyStep) => void;
}

export enum DividerOnDutyStep {
    NAME_STEP = 0,
    HOURS_STEP = 1,
    SHARE_STEP = 2
}

export interface OnDutyDoctor {
    index: number;
    name: string;
}

const DividerOnDutyContext = createContext<DividerOnDutyProviderData>({} as DividerOnDutyProviderData);

export const DividerOnDutyProvider = ({ children }: DividerOnDutyProviderProps) => {
    const [currentStep, setCurrentStep] = useState<DividerOnDutyStep>(DividerOnDutyStep.NAME_STEP);
    const [doctors, setDoctors] = useState<OnDutyDoctor[]>([]);
    const [startTime, setStartTime] = useState<Moment>(moment());
    const [endTime, setEndTime] = useState<Moment>(moment().add(6, 'hours'));

    const onChangeStep = (newStep: DividerOnDutyStep) => {
        if (currentStep === DividerOnDutyStep.NAME_STEP && doctors.length < 2) {
            alert('Adicione pelo menos dois plantonistas na lista');
            return;
        }
        setCurrentStep(newStep);
    }

    return (
        <DividerOnDutyContext.Provider value={{ currentStep, onChangeStep, doctors, setDoctors, startTime, setStartTime, endTime, setEndTime }}>
            {children}
        </DividerOnDutyContext.Provider>
    );
};

export const useDividerOnDutyContext = () => useContext(DividerOnDutyContext);
