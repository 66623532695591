enum Region {
    NORTH = 'NORTH',
    NORTHEAST = 'NORTHEAST',
    MIDWEST = 'MIDWEST',
    SOUTHEAST = 'SOUTHEAST',
    SOUTH = 'SOUTH',
    LATIN_AMERICA = 'LATIN_AMERICA',
    CENTRAL_AMERICA = 'CENTRAL_AMERICA',
    NORTH_AMERICA = 'NORTH_AMERICA',
    EUROPE = 'EUROPE',
    ASIA = 'ASIA',
    AFRICA = 'AFRICA',
    OCEANIA = 'OCEANIA'
}

export default Region;
