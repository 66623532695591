export enum OperatingSystem {
    WINDOWS_PHONE = 'Windows Phone',
    ANDROID = 'Android',
    IOS = 'iOS',
    UNKNOWN = 'Unknown'
}
/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = (): OperatingSystem => {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return OperatingSystem.WINDOWS_PHONE;
    }

    if (/android/i.test(userAgent)) {
        return OperatingSystem.ANDROID;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return OperatingSystem.IOS;
    }

    return OperatingSystem.UNKNOWN;
};

export const convertToTitleCase = (str: string) => {
    const PREPOSITIONS: string[] = [
        'a',
        'as',
        'à',
        'às',
        'o',
        'os',
        'ao',
        'aos',
        'da',
        'das',
        'do',
        'dos',
        'de',
        'nele',
        'neles',
        'em',
        'na',
        'nas',
        'no',
        'nos',
        'com',
        'para',
        'per',
        'perante',
        'por',
        'pela',
        'pelo',
        'mais',
        'menos',
        'após',
        'ante',
        'até',
        'de',
        'desde',
        'entre',
        'sem',
        'sob',
        'sobre',
        'sinal',
        'trás',
        'um',
        'uma',
        'contra',
        'ou',
        'não',
        '()',
        '"',
        '/',
        '-',
        'positivo',
        'negativo',
        '-',
        '+',
        'fenômeno',
    ];

    if (!str) {
        return '';
    }

    return str
        .toLowerCase()
        .split(' ')
        .map((word, i) => {
            return PREPOSITIONS.includes(word) && i != 0 ? word : word.charAt(0).toUpperCase().concat(word.substr(1));
        })
        .join(' ');
}

export function getNumberOrOne(value: number) {
    if (value === 0) {
        return 1;
    }
    return value;
}

export function shuffle(array: any[]) {
    return array.sort(() => Math.random() - 0.5);
}

export function getDateNowTimestamp() {
    return 1 * (new Date() as unknown as number);
}

export const validateEmail = (email: string): boolean => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email != null && email.length > 0 && re.test(String(email).toLowerCase());
};

export const setNumberSign = (value: number): string => {
    if (value === 0) {
        return '0';
    }

    return value > 0 ? `+${value}` : `${value}`;
};

export const validateCreditCard = (cardNumber: string): boolean => {
    // const cards = [
    //     /^4[0-9]{12}(?:[0-9]{3})/, // visa
    //     /^5[1-5][0-9]{14}/, // Mastercard
    //     /^3[47][0-9]{13}/, // Amex
    //     /^3(?:0[0-5]|[68][0-9])[0-9]{11}/, // DinersClub
    //     /^6(?:011|5[0-9]{2})[0-9]{12}/, // Discover
    //     /^(?:2131|1800|35\d{3})\d{11}/ // JCB
    // ];
    // const cards = [
    //     /^4[0-9]{12}(?:[0-9]{3})/,
    //     /^5[1-5][0-9]{14}/,
    //     /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    //     /^3[47][0-9]{13}/,
    //     /^6(?:011|5[0-9]{2})[0-9]{12}/,
    //     /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    //     /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    //     /^(?:2131|1800|35\d{3})\d{11}/,
    //     /^(5078\d{2})(\d{2})(\d{11})$/
    // ];

    // console.log('card filter', { card: cards.filter(it => cardNumber.match(it))[0], teste: cardNumber.match(cards[0]) });
    // if (cards.filter(it => (cardNumber.match(it) ? cardNumber.match(it)![0].length === cardNumber.length : false)).length > 0) {
    //     return true;
    // }
    return true;
};
