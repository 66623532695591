import * as React from 'react';
import DropdownSearch from '../../components/dropdown-search/dropdown-search';
import { MedicineLeaflet } from '../../model/medicine-leaflet';
import { PageableResponse } from '../../model/pageable';
import LeafletsService from '../../services/leafletsService';
import './medicine-interactions.scss';

export interface IDropdownSelectSearchProps {
    inputNumber: number;
    onClickItem: (item: any) => void;
    onRemove: () => void;
    item: any;
    close?: boolean;
    onOpen?: () => void;
}
export interface IDropdownSelectSearchState {
    selectedItem?: any;
    medicineSearchResults: any[];
}

export default class DropdownSelectSearch extends React.Component<IDropdownSelectSearchProps, IDropdownSelectSearchState> {
    private currentLetter: string = '';
    constructor(props) {
        super(props);

        this.state = {
            medicineSearchResults: []
        };
    }
    componentWillReceiveProps(newProps: IDropdownSelectSearchProps) {
        if (this.state.selectedItem != null && newProps.item !== this.state.selectedItem) {
            this.setState({
                selectedItem: newProps.item && newProps.item.name ? newProps.item : undefined
            })
        }
    }
    // Api Calls
    private search = async (value: string): Promise<any> => {
        const result: PageableResponse<MedicineLeaflet> = await LeafletsService.searchLeaflets({ name: value });
        this.setState({
            medicineSearchResults: result.content
        });
    };

    // Handlers
    private onClickItem = (item: any) => {
        this.setState({ selectedItem: item }, () => {
            this.props.onClickItem(item);
        });
    };
    private onRemove = () => {
        this.setState({
            selectedItem: undefined
        });
        this.props.onRemove();
    };

    // Renders
    render() {
        const { selectedItem, medicineSearchResults } = this.state;
        const { inputNumber, close, onOpen } = this.props;
        return (
            <div className="dropdown-select-search">
                <DropdownSearch
                    data={medicineSearchResults}
                    getData={this.search}
                    onClickItem={this.onClickItem}
                    title={`Selecione o medicamento ${inputNumber}`}
                    style={{ width: 317, backgroundColor: '#f6f6f6' }}
                    close={close}
                    onOpen={onOpen}
                    selectedRender={selectedItem ? () =>
                        (
                            <div className="dropdown-select-search__selected">
                                <div className="dropdown-select-search__selected__left">
                                    <div className="dropdown-select-search__selected__rect" />
                                    <div className="dropdown-select-search__selected__text">
                                        <p className="dropdown-select-search__selected__title">{selectedItem.name}</p>
                                        <p className="dropdown-select-search__selected__subtitle">{selectedItem.composition}</p>
                                    </div>
                                </div>
                                <div className="dropdown-select-search__selected__remove-icon" onClick={this.onRemove} />
                            </div>
                        )
                        : undefined
                    }
                />
            </div>
        );
    }
}
