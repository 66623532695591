import { AxiosResponse } from 'axios';
import semiologyApi from '../api/semiologyApi';
import HttpStatus from '../model/enums/httpStatus';
import SemiologyCategory from '../model/enums/semiologyCategory';
import { PageableResponse } from '../model/pageable';
import { Semiology } from '../model/semiology/semiology';

const SemiologyService = () => {
    const getByCategory = async (category: SemiologyCategory): Promise<Semiology[]> => {
        try {
            const result: AxiosResponse<Semiology[]> = await semiologyApi.getByCategory(category);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const getSemiology = async (id: number): Promise<Semiology> => {
        try {
            const result: AxiosResponse<Semiology> = await semiologyApi.getSemiology(id);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const getAvailableForFree = async (): Promise<Semiology[]> => {
        try {
            const result: AxiosResponse<PageableResponse<Semiology>> = await semiologyApi.getAvailableForFreeSemiology();
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data.content);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    return {
        getAvailableForFree,
        getByCategory,
        getSemiology
    };
};

export default SemiologyService();
