import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../../components/loading/loading';
import ContentType from '../../../model/enums/contentType';
import IllustratedProcedureType from '../../../model/enums/illustratedProcedureType';
import { IllustratedProcedure } from '../../../model/illustratedProcedure';
import { RecentHistory } from '../../../model/recentHistory';
import { User } from '../../../model/user';
import { IRootState } from '../../../reducer';
import { addInRecents } from '../../../reducer/recentHistory/actions';
import AnalyticsService from '../../../services/analytics-service';
import ContentRatingService from '../../../services/contentRatingService';
import IllustratedProcedureService from '../../../services/illustratedProcedureService';
import { getDecodedMarkdownByType } from '../../../shared/util/illustratedProcedureUtils';
import IllustratedProcedureTab from './illustrated-procedure-tab';
import IllustratedProcedureTabContent from './illustrated-procedure-tab-content';
import './illustrated-procedure.scss';

interface Props extends RouteComponentProps<{}, {}, { fromAnnotation?: boolean }> {
    t: any;
    hasSubscription?: boolean;
    account?: User;
    addInRecents: (recent: RecentHistory) => void;
}

interface State {
    illustratedProcedure?: IllustratedProcedure;
    fontSize?: number;
    hasSubscription?: boolean;
    showAnnotation?: boolean;
    materialHeadingIndex?: number;
    generalHeadingIndex?: number;
    complementHeadingIndex?: number;
    technicanHeadingIndex?: number;
    fromAnnotation?: boolean;
}

export class IllustratedProcedureScreen extends React.Component<Props, State> {
    constructor(props: Readonly<Props>, context?: any) {
        super(props, context);

        this.state = {
            hasSubscription: props.account && props.account.customer && props.account.customer.hasSubscription
        };
    }

    componentDidMount(): void {
        const {
            match: { params }
        } = this.props;
        const illustratedProcedureId: number = (params as any).procedureId;
        const fromAnnotation: boolean | undefined = this.props.location?.state?.fromAnnotation;
        this.getIllustratedProcedure(illustratedProcedureId);
        this.setState({ fromAnnotation });
    }
    componentDidUpdate(prevProps: Props, prevState: State): void {
        if (prevProps.match !== this.props.match) {
            const {
                match: { params }
            } = this.props;
            const illustratedProcedureId: number = (params as any).illustratedProcedureId;
            this.getIllustratedProcedure(illustratedProcedureId);
        }
    }

    private getIllustratedProcedure = async (illustratedProcedureId: number): Promise<void> => {
        const response: IllustratedProcedure = await IllustratedProcedureService.getIllustratedProcedure(illustratedProcedureId);
        AnalyticsService.procedureClick(response);
        window.fbq('track', 'ViewContent', { content_name: response.name, content_type: 'ILLUSTRATED_PROCEDURE' });
        ContentRatingService.sendContentRating({
            name: response.name!,
            contentId: illustratedProcedureId,
            type: ContentType.ILLUSTRATED_PROCEDURE
        });
        this.props.addInRecents({ name: response.name!, contentId: response.id!, type: ContentType.ILLUSTRATED_PROCEDURE });
        this.setState({
            illustratedProcedure: response
        });
    };
    private onChangeFontSize = (fontSize: number): void => {
        this.setState({
            fontSize
        });
    };
    private onShowAnnotation = (): void => {
        this.setState({
            showAnnotation: !this.state.showAnnotation
        });
    };

    private onBack = (): void => {
        this.props.history.push({
            pathname: '/procedure'
        });
    };

    private onChangeIndex = (type: IllustratedProcedureType) => {
        switch (type) {
            case IllustratedProcedureType.GENERAL:
                this.setState({
                    generalHeadingIndex: 0,
                    materialHeadingIndex: undefined,
                    technicanHeadingIndex: undefined,
                    complementHeadingIndex: undefined
                });
                break;
            case IllustratedProcedureType.MATERIALS:
                this.setState({
                    generalHeadingIndex: undefined,
                    materialHeadingIndex: 0,
                    technicanHeadingIndex: undefined,
                    complementHeadingIndex: undefined
                });
                break;
            case IllustratedProcedureType.TECHNICIAN:
                this.setState({
                    generalHeadingIndex: undefined,
                    materialHeadingIndex: undefined,
                    technicanHeadingIndex: 0,
                    complementHeadingIndex: undefined
                });
                break;
            case IllustratedProcedureType.COMPLEMENT:
                this.setState({
                    generalHeadingIndex: undefined,
                    materialHeadingIndex: undefined,
                    technicanHeadingIndex: undefined,
                    complementHeadingIndex: 0
                });
                break;
        }
    };

    render(): JSX.Element {
        const {
            illustratedProcedure,
            fontSize,
            showAnnotation,
            generalHeadingIndex,
            materialHeadingIndex,
            technicanHeadingIndex,
            complementHeadingIndex
        } = this.state;
        if (illustratedProcedure == null) {
            return (
                <div className="disease-screen-container">
                    <Loading />
                </div>
            );
        }
        const generalMarkdown: string = getDecodedMarkdownByType(
            IllustratedProcedureType.GENERAL,
            illustratedProcedure.illustratedProcedureContents
        );
        const materialMarkdown: string = getDecodedMarkdownByType(
            IllustratedProcedureType.MATERIALS,
            illustratedProcedure.illustratedProcedureContents
        );
        const technicianMarkdown: string = getDecodedMarkdownByType(
            IllustratedProcedureType.TECHNICIAN,
            illustratedProcedure.illustratedProcedureContents
        );
        const complementMarkdown: string = getDecodedMarkdownByType(
            IllustratedProcedureType.COMPLEMENT,
            illustratedProcedure.illustratedProcedureContents
        );

        let name: any = illustratedProcedure.name || generalMarkdown.match(/^# ([a-zA-Z].*)/g);
        if (!illustratedProcedure.name && name && name[0]) {
            name = String(name[0]).replace('# ', '');
        }

        return (
            <div className="disease-screen-container">
                <div className="screen__title">
                    <div className="screen__title__back" onClick={this.onBack}>
                        <div className="screen__title__back__img" />
                    </div>
                    <span className="screen__title__text">{name}</span>
                </div>
                <IllustratedProcedureTab
                    illustratedProcedure={illustratedProcedure}
                    // title={name}
                    onBack={this.onBack}
                    general={() => (
                        <IllustratedProcedureTabContent
                            {...this.props}
                            hasSubscription={this.props.hasSubscription}
                            illustratedProcedure={illustratedProcedure}
                            applicationPictures={illustratedProcedure.applicationPictures}
                            markdown={generalMarkdown}
                            key={'GENERAL'}
                            tab={IllustratedProcedureType.GENERAL}
                            fontSize={fontSize}
                            showAnnotation={showAnnotation}
                            onShowAnnotation={this.onShowAnnotation}
                            selectedHeadingIndex={generalHeadingIndex}
                        />
                    )}
                    material={() => (
                        <IllustratedProcedureTabContent
                            {...this.props}
                            hasSubscription={this.props.hasSubscription}
                            key={'MATERIALS'}
                            illustratedProcedure={illustratedProcedure}
                            applicationPictures={illustratedProcedure.applicationPictures}
                            markdown={materialMarkdown}
                            tab={IllustratedProcedureType.MATERIALS}
                            fontSize={fontSize}
                            showAnnotation={showAnnotation}
                            onShowAnnotation={this.onShowAnnotation}
                            selectedHeadingIndex={materialHeadingIndex}
                        />
                    )}
                    technician={() => (
                        <IllustratedProcedureTabContent
                            {...this.props}
                            hasSubscription={this.props.hasSubscription}
                            illustratedProcedure={illustratedProcedure}
                            applicationPictures={illustratedProcedure.applicationPictures}
                            markdown={technicianMarkdown}
                            key={'TECHNICIAN'}
                            tab={IllustratedProcedureType.TECHNICIAN}
                            fontSize={fontSize}
                            showAnnotation={showAnnotation}
                            onShowAnnotation={this.onShowAnnotation}
                            selectedHeadingIndex={technicanHeadingIndex}
                        />
                    )}
                    complement={() => (
                        <IllustratedProcedureTabContent
                            {...this.props}
                            hasSubscription={this.props.hasSubscription}
                            illustratedProcedure={illustratedProcedure}
                            applicationPictures={illustratedProcedure.applicationPictures}
                            markdown={complementMarkdown}
                            key={'COMPLEMENT'}
                            tab={IllustratedProcedureType.COMPLEMENT}
                            fontSize={fontSize}
                            showAnnotation={showAnnotation}
                            onShowAnnotation={this.onShowAnnotation}
                            selectedHeadingIndex={complementHeadingIndex}
                        />
                    )}
                    onChangeFontSize={this.onChangeFontSize}
                    onShowAnnotation={this.onShowAnnotation}
                    onChangeTab={this.onChangeIndex}
                    annotationIsOpen={this.state.fromAnnotation}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    isAuthenticated: authentication.isAuthenticated,
    hasSubscription: authentication.account && authentication.account.customer ? authentication.account.customer.hasSubscription : undefined
});

const mapDispatchToProps = {
    addInRecents: addInRecents
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(IllustratedProcedureScreen) as React.ComponentType<
    any
>;
