import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import '../../flashcard.scss';
import { Cell, Label, Pie, PieChart } from 'recharts';
import { FlashcardRunMetric } from '../../../../model/flashcard/flashcard-run';
import {
    CardContainer,
    ChartContainer, CompleteIcon, PadlockIcon,
    QuantityText,
    Subtitle,
    Title,
    TitleContainer
} from './flashcard-run-card.styles';

interface Props extends WithTranslation {
    key: string;
    title: string;
    subtitle: string;
    isCheckpoint: boolean;
    isDisabled: boolean;
    metric: FlashcardRunMetric;
    totalCards?: number;
    onClick: () => void;
}

const FlashcardRunCard = (props: Props) => {
    const { metric, isDisabled, isCheckpoint, key } = props;

    const renderChart = () => {
        if (isCheckpoint && metric.isFinished) {
            return <CompleteIcon className={'orange-percentage-circle-img'} />;
        } else if (metric.isFinished) {
            return <CompleteIcon className={'green-percentage-circle-img'} />;
        } else if (isDisabled) {
            return <PadlockIcon className={isCheckpoint ? 'orange-blocked-padlock-img' : 'blocked-padlock-img'} />;
        } else {
            const width = 70;
            const height = 70;
            const progressPercent =
                metric.remainingQuestions !== 0 ? Math.floor((metric.answers.length * 100) / (metric.remainingQuestions + metric.answers.length)) : 0;
            const COLORS = isCheckpoint ? ['#F4F5F5', '#ef7d00'] : ['#F4F5F5', '#4D9FC3'];
            const chartData = [
                { name: 'Não respondidos', value: 100 - progressPercent },
                { name: 'Respondidos', value: progressPercent }
            ];
            return (
                <PieChart width={width} height={height} style={{ zIndex: 2 }}>
                    <Pie
                        data={chartData}
                        dataKey="value"
                        labelLine={false}
                        isAnimationActive={false}
                        cx={width / 2 - 5}
                        cy={height / 2 - 5}
                        innerRadius={25}
                        outerRadius={30}
                        blendStroke
                        cornerRadius={50}
                        paddingAngle={-10}
                    >
                        <Label width={30} position="center"
                               style={{ fontWeight: 600, fontSize: 15, fontFamily: 'Raleway', color: '#414E52' }}>
                            {`${progressPercent}%`}
                        </Label>
                        {chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            );
        }
    };

    const totalCards = props.totalCards != null ? props.totalCards : (metric.remainingQuestions ?? 0) + (metric.answers.length ?? 0);
    return (
        <CardContainer className={'flashcard-run-card-container'} key={key} isCheckpoint={isCheckpoint} onClick={!isDisabled ? props.onClick : () => null}>
            <TitleContainer>
                <Title>
                    {props.title}
                </Title>
                <Subtitle>
                    {props.subtitle}
                </Subtitle>
            </TitleContainer>
            <ChartContainer>
                {renderChart()}
                {!isDisabled && <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: 3 }}>
                    <QuantityText color={'#414E52'} style={{ marginRight: 5 }}>{metric.answers.length}</QuantityText>
                    <QuantityText color={'#A3B1B5'}>{` / ${totalCards > 0 ? totalCards : 100}`}</QuantityText>
                </div>}
            </ChartContainer>
        </CardContainer>
    );
};

export default withTranslation()(FlashcardRunCard);
