export enum ResultType {
    NONE = 'NONE',
    NORMAL = 'NORMAL',
    WARNING = 'WARNING',
    DANGER = 'DANGER'
}

export const getBackgroundColorToResult = (type: ResultType): string => {
    switch (type) {
        case ResultType.NONE:
            return '#4E6F7A05';
        case ResultType.NORMAL:
            return '#4E6F7A05';
        case ResultType.WARNING:
            return '#F59E2410';
        case ResultType.DANGER:
            return '#E0444410';
        default:
            return '#4E6F7A05';
    }
}

export const getBackgroundColorToResultBorder = (type: ResultType): string => {
    switch (type) {
        case ResultType.NONE:
            return '#5AB0AE12';
        case ResultType.NORMAL:
            return '#5AB0AE12';
        case ResultType.WARNING:
            return '#F59E2415';
        case ResultType.DANGER:
            return '#E0444415';
        default:
            return '#5AB0AE12';
    }
}

export const getBackgroundColorToType = (type: ResultType): string => {
    switch (type) {
        case ResultType.NONE:
            return '#5ab0ae';
        case ResultType.NORMAL:
            return '#5ab0ae';
        case ResultType.WARNING:
            return '#f59e24';
        case ResultType.DANGER:
            return '#e04444';
        default:
            return '#5ab0ae';
    }
}