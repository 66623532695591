import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import '../../../App.scss';
import FontResizer from '../../../components/font-resizer/font-resizer';
import IllustratedProcedureType from '../../../model/enums/illustratedProcedureType';
import { Favorite, FavoriteType } from '../../../model/favorite';
import { IllustratedProcedure } from '../../../model/illustratedProcedure';
import { IRootState } from '../../../reducer';
import FavoritesService from '../../../services/favoritesService';
import DiseaseSearch from './illustrated-procedure-search';

interface Props extends RouteComponentProps<{}> {
    t: any;
    illustratedProcedure: IllustratedProcedure;
    general: () => JSX.Element;
    material: () => JSX.Element;
    technician: () => JSX.Element;
    complement: () => JSX.Element;

    onChangeFontSize: (fontSize: number) => void;
    onShowAnnotation: () => void;
    onChangeTab: (tab: IllustratedProcedureType) => void;

    onBack: () => void;

    annotationIsOpen?: boolean;
}

interface State {
    currentTab: IllustratedProcedureType;

    showFontSlider?: boolean;
    fontSize: number;

    isFavorited?: boolean;
    showFavoriteSuccessToast?: boolean;
}

export class IllustratedProcedureTab extends React.Component<Props, State> {
    private sliderRef;
    constructor(props: Readonly<Props>, context?: any) {
        super(props, context);

        this.state = {
            currentTab: IllustratedProcedureType.GENERAL,
            fontSize: 13,
            isFavorited: props.illustratedProcedure.favorite && props.illustratedProcedure.favorite.isActive
        };
    }
    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    private handleClickOutside = (event: any): void => {
        if (this.sliderRef && !this.sliderRef.contains(event.target) && this.state.showFontSlider) {
            this.setState({
                showFontSlider: false
            });
        }
    };
    private onClickTab = (tab: IllustratedProcedureType) => {
        this.setState({
            currentTab: tab
        });
        this.props.onChangeTab(tab);
    };
    private onChangeSlider = (value: number): void => {
        this.setState({ fontSize: value });
        this.props.onChangeFontSize(value);
    };
    private onResizeFont = () => {
        this.setState({
            showFontSlider: !this.state.showFontSlider
        });
    };
    private onFavorite = async (): Promise<void> => {
        const { isFavorited } = this.state;
        const { illustratedProcedure } = this.props;
        const params: Favorite = {
            type: FavoriteType.ILLUSTRATED_PROCEDURE,
            illustratedProcedure: {
                id: illustratedProcedure.id
            },
            isActive: isFavorited ? false : undefined
        };
        FavoritesService.favorite(params).then(() => {
            this.setState(
                {
                    isFavorited: !isFavorited,
                    showFavoriteSuccessToast: !isFavorited
                },
                () => {
                    setTimeout(() => this.setState({ showFavoriteSuccessToast: false }), 3000);
                }
            );
        });
    };
    private onAnnotation = () => {
        this.props.onShowAnnotation();
    };

    private getCorrectRender = (): JSX.Element => {
        switch (this.state.currentTab) {
            case IllustratedProcedureType.GENERAL:
                return this.props.general();
            case IllustratedProcedureType.MATERIALS:
                return this.props.material();
            case IllustratedProcedureType.TECHNICIAN:
                return this.props.technician();
            case IllustratedProcedureType.COMPLEMENT:
                return this.props.complement();
            default:
                return <div />;
        }
    }

    private renderTab = (type: IllustratedProcedureType) => {
        return (
            <a
                className={`tab__menu__tab ${this.state.currentTab === type ? 'tab__menu__tab--selected' : ''}`}
                onClick={() => this.onClickTab(type)}
            >
                <span className="tab__menu__tab__text">{this.props.t(`enum.illustratedProcedureType.${type}`)}</span>
            </a>
        )
    }

    render(): JSX.Element {
        const { illustratedProcedure, onBack } = this.props;
        const { showFontSlider, showFavoriteSuccessToast, isFavorited } = this.state;

        return (
            <div className="tab">
                <div className="tab__menu-containter">
                    <div className="tab__menu">
                        <div className="tab__menu__left">
                            <div className="tab__menu__left__tabs">
                                {this.renderTab(IllustratedProcedureType.GENERAL)}
                                {this.renderTab(IllustratedProcedureType.MATERIALS)}
                                {this.renderTab(IllustratedProcedureType.TECHNICIAN)}
                                {this.renderTab(IllustratedProcedureType.COMPLEMENT)}
                            </div>
                        </div>
                        <DiseaseSearch
                            fontSliderIsOpen={this.state.showFontSlider}
                            annotationIsOpen={this.props.annotationIsOpen}
                            isFavorited={isFavorited}
                            onResizeFont={this.onResizeFont}
                            onFavorite={this.onFavorite}
                            onAnnotation={this.onAnnotation}
                            illustratedProcedure={illustratedProcedure}
                        />
                        {showFontSlider && (
                            <div className="disease-content__slider-container" ref={ref => (this.sliderRef = ref)}>
                                <FontResizer
                                    changeFontSize={this.onChangeSlider}
                                    fontSize={this.state.fontSize}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="tab__content">{this.getCorrectRender()}</div>

                {showFavoriteSuccessToast && (
                    <div className="disease-content__favorite-success-toast">
                        <div className="disease-content__white-star-img" />
                        <span className="disease-content__favorite-success-msg">{this.props.t('cid.favoriteSuccessMessage')}</span>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(IllustratedProcedureTab) as React.ComponentType<any>;
