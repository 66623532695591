import React, { useCallback, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import FlashcardAnswerValue from '../../../model/enums/flashcard-answer-value';
import { FlashcardView } from '../../../model/flashcard/flashcard';
import { PerformanceData } from '../../../model/flashcard/performance-data';
import { useFlashcardViewContext } from '../flashcard-carousel/flashcard.provider';
import '../flashcard.scss';
import {
    AnswerQuantityContainer, AnswersQuantityContainer, AnswersQuantityLabel,
    CategoriesContainer, CategoriesData, CategoriesTitle,
    CategoryContainer,
    Icon,
    MainContainer, QuantityContainer, QuantityLabel
} from './flashcard-performance.styles';

interface Props extends WithTranslation {
    data: PerformanceData;
}

const FlashcardPerformanceAnswers = (props: Props) => {
    const {t, data} = props;
    const [answersData, setAnswersData] = useState<{rightCount: number; almostCount: number; wrongCount: number; total: number}>({
        rightCount: 0,
        almostCount: 0,
        wrongCount: 0,
        total: 0,
    });

    useEffect(() => {
        if (data != null) {
            const rightCount = data.answers.filter(it => it.answer === FlashcardAnswerValue.RIGHT).length;
            const almostCount = data.answers.filter(it => it.answer === FlashcardAnswerValue.ALMOST).length;
            const wrongCount = data.answers.filter(it => it.answer === FlashcardAnswerValue.WRONG).length;
            setAnswersData({rightCount, almostCount, wrongCount, total: rightCount + almostCount + wrongCount});
        }
    }, [data]);

    return (
        <AnswersQuantityContainer>
            <AnswerQuantityContainer>
                <QuantityContainer style={{ backgroundColor: '#2572B626' }}>
                    <QuantityLabel style={{ color: '#2572B6'}}>{answersData.rightCount}</QuantityLabel>
                </QuantityContainer>
                <AnswersQuantityLabel>{t('flashcardPerformanceAnswers.right')}</AnswersQuantityLabel>
            </AnswerQuantityContainer>
            <AnswerQuantityContainer>
                <QuantityContainer style={{ backgroundColor: '#FFBE4626' }}>
                    <QuantityLabel style={{ color: '#CC8F00'}}>{answersData.almostCount}</QuantityLabel>
                </QuantityContainer>
                <AnswersQuantityLabel>{t('flashcardPerformanceAnswers.almost')}</AnswersQuantityLabel>
            </AnswerQuantityContainer>
            <AnswerQuantityContainer>
                <QuantityContainer style={{ backgroundColor: '#E0444426' }}>
                    <QuantityLabel style={{ color: '#E04444'}}>{answersData.wrongCount}</QuantityLabel>
                </QuantityContainer>
                <AnswersQuantityLabel>{t('flashcardPerformanceAnswers.wrong')}</AnswersQuantityLabel>
            </AnswerQuantityContainer>
        </AnswersQuantityContainer>
    );
};

export default withTranslation()(FlashcardPerformanceAnswers);
