import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import '../flashcard.scss';
import FlashcardAnswerValue from '../../../model/enums/flashcard-answer-value';
import { PerformanceData } from '../../../model/flashcard/performance-data';
import {
    ChartPercentageAccuracyText,
    ChartPercentageContainer,
    ChartPercentageNumberContainer,
    ChartPercentageNumberText,
    ChartPercentageText
} from './flashcard-performance.styles';

interface Props extends RouteComponentProps<{}, {}, {}>, WithTranslation {
    height: number;
    width: number;
    data: PerformanceData;
}

const FlashcardPerformanceChart = (props: Props) => {
    const { t, width, height, data } = props;
    const COLORS = ['#2572B6', '#FFBE46', '#E04444'];
    const [currentVisualizationIndex, setCurrentVisualizationIndex] = useState<number | null>(null);
    const [chartData, setChartData] = React.useState<{ name: string, value: number }[]>([
        { name: 'Acertei!', value: 0 },
        { name: 'Quase', value: 1 },
        { name: 'Não sei', value: 0 }
    ]);


    const increaseCurrentVisualizationIndex = () => {
        if (currentVisualizationIndex == null) {
            setCurrentVisualizationIndex(0);
            return;
        }
        if (currentVisualizationIndex === 2) {
            setCurrentVisualizationIndex(null);
            return;
        }

        setCurrentVisualizationIndex(currentVisualizationIndex + 1);
    };

    useEffect(() => {
        if (data != null) {
            const rightCount = data.answers.filter(it => it.answer === FlashcardAnswerValue.RIGHT).length;
            const almostCount = data.answers.filter(it => it.answer === FlashcardAnswerValue.ALMOST).length;
            const wrongCount = data.answers.filter(it => it.answer === FlashcardAnswerValue.WRONG).length;
            const allParametersIsZero = rightCount + almostCount + wrongCount === 0;
            setChartData([
                { name: 'Acertei!', value: rightCount },
                { name: 'Quase', value: allParametersIsZero ? 1 : almostCount },
                { name: 'Não sei', value: wrongCount }
            ]);
        }
    }, [data]);

    const series =
        currentVisualizationIndex != null
            ? [currentVisualizationIndex === 0 ? chartData[currentVisualizationIndex] : { name: 'Acertei!', value: 0 },
                currentVisualizationIndex === 1 ? chartData[currentVisualizationIndex] : { name: 'Quase!', value: 0 },
                currentVisualizationIndex === 2 ? chartData[currentVisualizationIndex] : { name: 'Não sei!', value: 0 }]
            : chartData;
    const subtitle = currentVisualizationIndex != null ? chartData[currentVisualizationIndex].name : 'de acertos';
    const numberResult =
        currentVisualizationIndex != null
            ? chartData[currentVisualizationIndex].value
            : Math.round((chartData[0].value / (chartData[1].value + chartData[0].value + chartData[2].value)) * 100);
    return (
        <div onClick={() => increaseCurrentVisualizationIndex()} style={{ cursor: 'pointer' }}>
            <ChartPercentageContainer style={{ width: width + 5, height }}>
                <ChartPercentageNumberContainer>
                    <ChartPercentageNumberText>{numberResult}</ChartPercentageNumberText>
                    {currentVisualizationIndex == null && <ChartPercentageText>%</ChartPercentageText>}
                </ChartPercentageNumberContainer>
                <ChartPercentageAccuracyText>{subtitle}</ChartPercentageAccuracyText>
            </ChartPercentageContainer>
            <PieChart width={width} height={height} style={{ zIndex: 2 }}>
                <Pie
                    data={series}
                    dataKey="value"
                    labelLine={false}
                    cx={width / 2}
                    cy={height / 2}
                    innerRadius={width / 3}
                    outerRadius={width / 3 + 15}
                    blendStroke
                    cornerRadius={50}
                    paddingAngle={-10}
                >
                    {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
        </div>

    );
};

export default withTranslation()(FlashcardPerformanceChart);
