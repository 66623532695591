import { AxiosResponse } from 'axios';
import Category from '../model/enums/category';
import { FlashcardView } from '../model/flashcard/flashcard';
import { FlashcardNode } from '../model/flashcard/flashcard-node';
import { api } from './api';

const FlashcardApi = () => {
    const getByDiseasesId = (ids: number[], includeMyFlashcards: boolean): Promise<AxiosResponse<FlashcardView[]>> => {
        return api.get<FlashcardView[]>(`/flashcards/specialities?ids=${ids.join(',')}&includeMyFlashcards=${includeMyFlashcards}`);
    };

    const getFavoriteFlashcards = (): Promise<AxiosResponse<FlashcardView[]>> => {
        return api.get<FlashcardView[]>('/flashcards/favorites');
    };

    const getFlashcardByIds = (ids: number[]): Promise<AxiosResponse<FlashcardView[]>> => {
        return api.get<FlashcardView[]>(`/flashcards/info?ids=${ids.join(',')}`);
    };

    const getFlashcardsNodeGroupInfo = (category?: Category, groupId?: number): Promise<AxiosResponse<FlashcardNode[]>> => {
        let param = '?';
        if(category != null) {
            param += `category=${category}`;
        } else {
            param += `groupId=${groupId}`;
        }
        return api.get<FlashcardNode[]>(`/flashcards/node-group/info${param}`);
    };

    const getFlashcardsNodeInfo = (category?: Category, groupId?: number): Promise<AxiosResponse<FlashcardNode[]>> => {
        let param = '?';
        if(category != null) {
            param += `category=${category}`;
        } else {
            param += `groupId=${groupId}`;
        }
        return api.get<FlashcardNode[]>(`/flashcards/node/info${param}`);
    };


    return {
        getFlashcardByIds,
        getByDiseasesId,
        getFavoriteFlashcards,
        getFlashcardsNodeGroupInfo,
        getFlashcardsNodeInfo
    };
};

export default FlashcardApi();
