import { ApplicationPicture } from '../applicationPicture';
import { Laboratory } from './laboratory';

export interface LaboratoryGroup {
    id?: number;
    name?: string;
    hexColor?: string;
    selected?: boolean;
    icon?: ApplicationPicture | null;
    children?: LaboratoryGroup[];
    laboratories?: Laboratory[];
}

export enum LaboratoryCategory {
    BLOOD = 'BLOOD',
    URINE = 'URINE',
    OTHERS = 'OTHERS'
}