import _ from 'lodash';
import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../../components/loading/loading';
import Category from '../../../model/enums/category';
import { FlashcardNode } from '../../../model/flashcard/flashcard-node';
import FlashcardService from '../../../services/flashcard.service';
import { CardContainer } from '../flashcard-carousel/flashcard-carousel.styles';
import { ArrowBack, Container, HeaderContainer, HeaderSubtitle, HeaderText, MainContainer } from '../flashcard-styles';
import '../flashcard.scss';
import {
    AccordionArrow, ButtonContainer, ButtonLabel,
    CheckBox, CheckBoxContainer,
    CheckBoxLabel, CountLabel,
    LineContainer,
    ListContainer,
    OptionsContainer, Title
} from './flashcard-custom-styles';

interface Props extends RouteComponentProps<{}, {}, {}>, WithTranslation {
    hasSubscription?: boolean;
    openPaidModal: () => void;
    onPressPerformanceButton?: () => void;
    hideCreateCardButton?: boolean;
}

const FlashcardCustom = (props: Props) => {
    const [totalCards, setTotalCards] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedSpecialities, setSelectedSpecialities] = useState<FlashcardNode[]>([]);
    const [flashcardNodes, setFlashcardNodes] = useState<FlashcardNode[]>([
        {
            key: 'CATEGORY_1',
            title: props.t('specialties.surgery'),
            category: Category.SURGERY,
            type: 'GROUP'
        },
        {
            key: 'CATEGORY_2',
            title: props.t('specialties.clinic'),
            category: Category.CLINIC,
            type: 'GROUP'
        },
        {
            key: 'CATEGORY_3',
            title: props.t('specialties.gynecologic'),
            category: Category.GYNECOLOGIC,
            type: 'GROUP'
        },
        {
            key: 'CATEGORY_4',
            title: props.t('specialties.obstetrics'),
            category: Category.OBSTETRICS,
            type: 'GROUP'
        },
        {
            key: 'CATEGORY_5',
            title: props.t('specialties.pediatrics'),
            category: Category.PEDIATRICS,
            type: 'GROUP'
        },
        {
            key: 'CATEGORY_6',
            title: props.t('specialties.preventive'),
            category: Category.PREVENTIVE,
            type: 'GROUP'
        }
    ]);

    const onBack = () => {
        props.history.goBack();
    };

    const onPressSpeciality = (item: FlashcardNode) => {
        const newItem = item;
        newItem.isSelected = !item.isSelected;
        if (newItem.isSelected) {
            addSelectedSpeciality(item);
        } else {
            removeSelectedSpeciality(item);
        }
        updateNode(newItem);
    };

    const addSelectedSpeciality = (flashcardNode: FlashcardNode) => {
        const newSelectedSpecialities = [...selectedSpecialities];
        if (!newSelectedSpecialities.some(it => it.id === flashcardNode.id)) {
            setTotalCards(totalCards + (flashcardNode.flashcardAmount ?? 0));
            newSelectedSpecialities.push(flashcardNode);
            setSelectedSpecialities(newSelectedSpecialities);
        }
    };

    const removeSelectedSpeciality = (flashcardNode: FlashcardNode) => {
        const newSelectedSpecialities = [...selectedSpecialities];
        const index = newSelectedSpecialities.findIndex(it => it.id === flashcardNode.id);
        if (index > -1) {
            setTotalCards(totalCards - (flashcardNode.flashcardAmount ?? 0));
            newSelectedSpecialities.splice(index, 1);
            setSelectedSpecialities(newSelectedSpecialities);
        }
    };

    const onCheckGroup = async (item: FlashcardNode) => {
        const deselectChildren = (node: FlashcardNode) =>
            node.items?.forEach(it => {
                if (it.type === 'GROUP') {
                    deselectChildren(it);
                }
                it.isSelected = newItem.isSelected;
                updateNode(it);
            });
        setIsLoading(true);
        let newItem = item;
        newItem.isSelected = !item.isSelected;
        updateNode(newItem);
        deselectChildren(item);
        const newSelectedSpecialities = [...selectedSpecialities];
        let newTotalCards = totalCards;
        if (newItem.specialitiesSimple == null) {
            if (newItem.category != null) {
                newItem = await FlashcardService.findAllSpecialityIdsByCategory(newItem);
            } else {
                newItem = await FlashcardService.findAllSpecialityIdsByGroupId(newItem);
            }
        }
        if (newItem.isSelected) {
            newItem.specialitiesSimple?.forEach(node => {
                if (!newSelectedSpecialities.some(it => it.id === node.id)) {
                    const flashcardAmount = node.flashcardAmount ?? 0;
                    newTotalCards += flashcardAmount;
                    newSelectedSpecialities.push(node as FlashcardNode);
                }
            });
        } else {
            newItem.specialitiesSimple?.forEach(node => {
                const index = newSelectedSpecialities.findIndex(it => it.id === node.id);
                if (index > -1) {
                    const flashcardAmount = node.flashcardAmount ?? 0;
                    newTotalCards -= flashcardAmount;
                    newSelectedSpecialities.splice(index, 1);
                }
            });
        }
        setTotalCards(newTotalCards);
        setSelectedSpecialities(newSelectedSpecialities);
        setIsLoading(false);
        updateNode(newItem);
    };

    const onPressGroup = (item: FlashcardNode) => {
        const newItem = item;
        newItem.show = !item.show;
        if (item.category != null && item.items == null) {
            FlashcardService.findAllSpecialityAndGroupsFlashcardsByCategory(item.category!).then(newItems => {
                newItem.items = newItems.map(it => {
                    it.isSelected = newItem.isSelected;
                    return it;
                });
                updateNode(newItem);
            });
        } else if (item.items == null) {
            FlashcardService.findAllByGroupId(item.id!).then(newItems => {
                newItem.items = newItems.map(it => {
                    it.isSelected = newItem.isSelected;
                    return it;
                });

                updateNode(newItem);
            });
        } else {
            updateNode(newItem);
        }
    };

    const updateNode = (newNodeData: FlashcardNode) => {
        const newNodes = [...flashcardNodes];
        for (let i = 0; i < newNodes.length; i++) {
            newNodes[i] = updateItemByKey(newNodeData.key, newNodes[i], newNodeData);
        }
        setFlashcardNodes(newNodes);
    };

    const updateItemByKey = (key: string, data: FlashcardNode, newData: FlashcardNode) => {
        if (data.key === key) {
            // tslint:disable-next-line:no-parameter-reassignment
            data = newData;
        }
        if (data.items !== undefined && data.items.length > 0) {
            for (let i = 0; i < data.items.length; i++) {
                data.items[i] = updateItemByKey(key, data.items[i], newData);
            }
        }

        return data;
    };

    const renderCheckBox = (item: FlashcardNode) => {
        const checkBoxImage = item.isSelected ? 'check-on-img' : 'check-off-img';
        const isGroup = item.type === 'GROUP';
        const onPressCheckbox = isGroup ? onCheckGroup : onPressSpeciality;
        const onPressItem = isGroup ? onPressGroup : onPressSpeciality;
        return (
            <OptionsContainer>
                <LineContainer>
                    <CheckBoxContainer>
                        <CheckBox className={checkBoxImage} onClick={() => onPressCheckbox(item)} />
                        <CheckBoxLabel onClick={() => onPressItem(item)}
                                       style={item.category != null ? { fontWeight: 500 } : {}}>
                            {_.truncate(item.title, { length: 40 })}
                        </CheckBoxLabel>
                    </CheckBoxContainer>
                    {isGroup && <AccordionArrow onClick={() => onPressItem(item)} className={'grey-arrow-img'} />}
                </LineContainer>
                {item.items && item.show &&
                    <div style={{ marginLeft: 20 }}>{_.orderBy(item.items, 'title').map(renderCheckBox)}</div>}
            </OptionsContainer>
        );
    };

    return (
        <MainContainer>
            <Container>
                <HeaderContainer>
                    <ArrowBack className={'black-caret-left-img'} onClick={onBack} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <HeaderSubtitle>{props.t('flashcardCustom.title')}</HeaderSubtitle>
                        <HeaderText>{props.t('flashcardCustom.subtitle')}</HeaderText>
                    </div>
                </HeaderContainer>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CardContainer>
                        <Title>
                            {props.t('flashcardCustom.cardTitle')}
                        </Title>
                        <ListContainer>
                            {flashcardNodes.map(node => renderCheckBox(node))}
                        </ListContainer>
                        {totalCards > 0 && <CountLabel>
                            {props.t('flashcardCustom.flashcardCount', { count: totalCards })}
                        </CountLabel>}
                        {totalCards > 0 && <ButtonContainer onClick={() => props.history.push('/flashcard-custom/practice', {selectedSpecialities: selectedSpecialities.map(it => it.id)})}>
                            <ButtonLabel>
                                {isLoading ? <Loading
                                    style={{ backgroundColor: 'transparent' }} /> : props.t('flashcardCustom.start')}
                            </ButtonLabel>
                        </ButtonContainer>}
                    </CardContainer>
                </div>
            </Container>
        </MainContainer>
    );
};

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(FlashcardCustom) as React.ComponentType<any>;
