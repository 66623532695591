import _ from 'lodash';
import React from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { SearchType } from '../../model/search';
import { SearchResult } from '../../model/searchResult';
import './header/header.scss';

export const NavDropdown = props => (
    <UncontrolledDropdown nav inNavbar id={props.id}>
        <DropdownToggle nav caret className="d-flex align-items-center">
            <span id="dropdown-label">{props.name}</span>
        </DropdownToggle>
        <DropdownMenu right style={props.style}>
            {props.children}
        </DropdownMenu>
    </UncontrolledDropdown>
);

const ResultItemTypeLabel = ({ type }: { type: SearchType }): JSX.Element => {
    switch (type) {
        case SearchType.GENERAL:
            return (
                <div className="result-item__type">
                    <span className="result-item__type__text">{'GERAL'}</span>
                </div>
            );
        case SearchType.SMART_BULAS:
            return (
                <div className="result-item__type">
                    <span className="result-item__type__text">{'SMART BULAS'}</span>
                </div>
            );
        case SearchType.SEMIOLOGY:
            return (
                <div className="result-item__type">
                    <span className="result-item__type__text">{'SEMIOLOGIA'}</span>
                </div>
            );
        case SearchType.MEDICAL_SCORE:
            return (
                <div className="result-item__type">
                    <span className="result-item__type__text">{'SCORES'}</span>
                </div>
            );
        case SearchType.MEDICAL_CRITERIA:
            return (
                <div className="result-item__type">
                    <span className="result-item__type__text">{'CRITÉRIOS'}</span>
                </div>
            );
        case SearchType.LABORATORY:
            return (
                <div className="result-item__type">
                    <span className="result-item__type__text">{'LABORATÓRIO'}</span>
                </div>
            );
        case SearchType.PEDIATRIC_DOSE:
            return (
                <div className="result-item__type">
                    <span className="result-item__type__text">{'DOSE PEDIÁTRICA'}</span>
                </div>
            );
        case SearchType.SPECIALTY:
            return (
                <div className="result-item__type">
                    <span className="result-item__type__text">{'ESPECIALIDADE'}</span>
                </div>
            );
        case SearchType.MEDICAL_CALCULATOR:
            return (
                <div className="result-item__type">
                    <span className="result-item__type__text">{'CALCULADORA'}</span>
                </div>
            );
        case SearchType.ILLUSTRATED_PROCEDURE:
            return (
                <div className="result-item__type">
                    <span className="result-item__type__text">{'PROCEDIMENTOS ILUSTRADO'}</span>
                </div>
            );
        case SearchType.CID:
            return (
                <div className="result-item__type">
                    <span className="result-item__type__text">{'CID'}</span>
                </div>
            );
        case SearchType.SUS:
            return (
                <div className="result-item__type">
                    <span className="result-item__type__text">{'SUS'}</span>
                </div>
            );
        default:
            return <div className="result-item__type" />;
    }
};
export const ResultItem = ({
    result,
    index,
    isLastItem,
    onClick,
    hasSubscription
}: {
    result: SearchResult;
    index: number;
    isLastItem?: boolean;
    onClick: (result) => void;
    hasSubscription?: boolean;
}): JSX.Element => {
    return (
        <a key={index} className="result-item" style={isLastItem ? { border: 'none' } : {}} onClick={() => onClick(result)}>
            <div className="result-item__left">
                <ResultItemTypeLabel type={result.type} />
                <div>
                    <p className="result-item__text">{_.upperFirst(_.truncate(result.title, { length: 100 }).toLowerCase())}</p>
                    {result.subtitle && (
                        <p className="result-item__subtitle">{_.upperFirst(_.truncate(result.subtitle, { length: 100 }).toLowerCase())}</p>
                    )}
                </div>
            </div>
            <div className="result-item__right">{result.isPaid && !hasSubscription && <div className="result-item__lock-icn" />}</div>
        </a>
    );
};
