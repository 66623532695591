import React from 'react';

export interface Props {
    checked: boolean;
    label: string;
    onChange: () => void;
}

export const Checkbox = (props: Props) => {
    return (
        <div className="register-subscription__checkbox-container">
            {props.checked ? (
                <div className="register-subscription__checkbox register-subscription__checkbox--active" onClick={props.onChange}>
                    <div className="register-subscription__checkbox--active-icon"/>
                </div>
            ) : (
                <div className="register-subscription__checkbox" onClick={props.onChange} />
            )}
            <span
                className={`register-subscription__checkbox__label ${
                    props.checked ? 'register-subscription__checkbox__label--active' : ''
                }`}
            >{props.label}</span>
        </div>
    );
};
