import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { IRootState } from '../../reducer';
import './multiple-card-list.scss';
import SingleCardList from './single-card-list';

export interface CardList {
    title: string;
    items: CardItem[];
    color?: string;
}
export interface CardItem {
    renderItem: () => JSX.Element;
    getChildren?: (item: CardItem) => Promise<any>;
    childrenAmount?: number;
    data?: any;
    isSelected?: boolean;
    name?: string;
}
export interface MultipleCardListProps extends RouteComponentProps<{}> {
    t: any;
    firstCardList: CardList;
    color?: string;
    renderLastColumn?: (data: any) => JSX.Element;
    noCardExtension?: boolean;
    hasExtraFirstColumn?: boolean;
    firstColumnCardStyle?: string;

    allLists?: CardList[];
    lastColumnData?: any;
}

export interface MultipleCardListState {
    cardLists: CardList[];
    lastColumnData?: any;
}

export class MultipleCardList extends React.Component<MultipleCardListProps, MultipleCardListState> {
    constructor(props: Readonly<MultipleCardListProps>, context?: any) {
        super(props, context);
        console.log('contructor', props);
        this.state = {
            cardLists: props.allLists ? props.allLists : [props.firstCardList],
            lastColumnData: props.lastColumnData
        };
        if (props.allLists) {
            setTimeout(this.scrollToEnd, 1000)
        }
    }

    componentWillReceiveProps(newProps: MultipleCardListProps): void {
        if (newProps.allLists !== this.props.allLists) {
            this.setState(
                {
                    cardLists: newProps.allLists ? newProps.allLists : [newProps.firstCardList]
                },
                () => setTimeout(this.scrollToEnd, 1000)
            );
        }
        if (newProps.lastColumnData !== this.props.lastColumnData) {
            this.setState(
                {
                    lastColumnData: newProps.lastColumnData
                },
                () => setTimeout(this.scrollToEnd, 1000)
            );
        }
    }
    private scrollToEnd = (): void => {
        const multipleCardListDiv = document.querySelector('.multiple-card-list');
        if (multipleCardListDiv) {
            multipleCardListDiv.scrollTo(multipleCardListDiv.scrollWidth, 0);
        }
    }
    private onGetNewList = (list: CardList | any, listIndex: number, childrenAmmount?: number) => {
        if ((childrenAmmount != null && childrenAmmount > 0) || (this.props.hasExtraFirstColumn && listIndex === 0)) {
            const cardLists: CardList[] = this.state.cardLists.slice(0, listIndex + 1);
            this.setState(
                {
                    cardLists: cardLists.concat(list),
                    lastColumnData: undefined
                },
                () => this.scrollToEnd()
            );
        } else {
            this.setState({
                lastColumnData: list
            });
        }
    };

    render() {
        const { cardLists, lastColumnData } = this.state;
        const { renderLastColumn, noCardExtension } = this.props;
        const lastList: CardList = cardLists[cardLists.length - 1];
        const lastListChildrenAmmount = lastList.items.filter(it => it.childrenAmount != null && it.childrenAmount > 0);
        const showLastColumn: boolean =
            lastColumnData != null || (lastListChildrenAmmount.length === 0 && cardLists.length > 1 && renderLastColumn == null);
        return (
            <div className="multiple-card-list">
                <div className="multiple-card-list-container">
                    {cardLists.map((it, index) => (
                        <SingleCardList
                            key={it.title + index}
                            listIndex={index}
                            firstColumnCardStyle={index === 0 ? this.props.firstColumnCardStyle : null}
                            cardList={it}
                            color={this.props.color || it.color}
                            onGetNewList={this.onGetNewList}
                            noCardExtension={noCardExtension}
                        />
                    ))}
                    {!showLastColumn ? (
                        <div className="card-list-empty">
                            <div className="card-list-empty__list-img" />
                            <span className="card-list-empty__text">{this.props.t('global.cardList.emptyText')}</span>
                        </div>
                    ) : (
                        <div />
                    )}
                    {showLastColumn && renderLastColumn ? renderLastColumn(lastColumnData) : <div />}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(MultipleCardList) as React.ComponentType<any>;
