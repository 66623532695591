import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Button from '../../components/button/button';
import { Interaction } from '../../model/interaction';
import { IRootState } from '../../reducer';
import './medicine-interactions.scss';

export interface InteractionResultsProps extends RouteComponentProps<{}> {
    t: (path: string, params?: any) => string;
    interactions: Interaction[];
    goBack: () => void;
}

// tslint:disable-next-line: no-empty-interface
export interface InteractionResultsState {}

export class InteractionResults extends React.Component<InteractionResultsProps, InteractionResultsState> {
    constructor(props: Readonly<InteractionResultsProps>, context?: any) {
        super(props, context);

        this.state = {};
        window.fbq('track', 'ViewContent', { content_type: 'MEDICAL_INTERACTION' });
    }
    // Handlers
    private onBack = () => {
        this.props.goBack();
    };

    // Renders
    private renderResult = (interaction: Interaction, index: number): JSX.Element => {
        return (
            <div className="interaction" key={index}>
                <div className="interaction__left">
                    <div className="interaction__orange-connect" />
                    <div className="interaction__leaflets">
                        <div
                            className="dropdown-select-search__selected dropdown-select-search__selected-2"
                            style={{ position: 'relative', marginBottom: 12 }}
                        >
                            <div className="dropdown-select-search__selected__left">
                                <div className="dropdown-select-search__selected__rect" />
                                <div className="dropdown-select-search__selected__text">
                                    <p className="dropdown-select-search__selected__title">{interaction.medicineLeaflet.name}</p>
                                    <p className="dropdown-select-search__selected__subtitle">{interaction.medicineLeaflet.composition}</p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="dropdown-select-search__selected dropdown-select-search__selected-2"
                            style={{ position: 'relative' }}
                        >
                            <div className="dropdown-select-search__selected__left">
                                <div className="dropdown-select-search__selected__rect" />
                                <div className="dropdown-select-search__selected__text">
                                    <p className="dropdown-select-search__selected__title">{interaction.interactionMedicineLeaflet.name}</p>
                                    <p className="dropdown-select-search__selected__subtitle">
                                        {interaction.interactionMedicineLeaflet.composition}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="interaction__results-container">
                    <div className="interaction__result">
                        <p className="interaction__result__title">{this.props.t('medicineInteractions.results.result')}</p>
                        <p className="interaction__result__text">{_.upperFirst(interaction.result.result)}</p>
                    </div>
                    <div className="interaction__result">
                        <p className="interaction__result__title">{this.props.t('medicineInteractions.results.conduct')}</p>
                        <p className="interaction__result__text">{_.upperFirst(interaction.result.conduct)}</p>
                    </div>
                </div>
            </div>
        );
    };
    render() {
        const { interactions } = this.props;
        return (
            <div className="medicine-interactions-screen__content">
                <div className="screen__title">
                    <div className="screen__title__back" onClick={this.onBack}>
                        <div className="screen__title__back__img" />
                    </div>
                    <span className="screen__title__text">{this.props.t('medicineInteractions.results.title')}</span>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    {interactions.length > 0 ? (
                        <div className="interaction-results">{interactions.map((it, index) => this.renderResult(it, index))}</div>
                    ) : (
                        <div className="interaction-no-results">
                            <div className="interaction-no-results__img" />
                            <p className="interaction-no-results__text">{this.props.t('medicineInteractions.results.noResults')}</p>
                        </div>
                    )}
                    <div className="redo-button-container">
                        <Button label={this.props.t('medicineInteractions.results.redoInteraction')} onPress={this.onBack} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({}: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(InteractionResults) as React.ComponentType<any>;
