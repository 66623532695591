import { FormControl } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import Button from '../../../components/button/button';
import TextFieldMeds from '../../../components/text-field-meds/text-field-meds';
import { Authentication } from '../../../model/authentication';
import { CreateSubscription } from '../../../model/createSubscription';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { IRootState } from '../../../reducer';
import { loginRequest } from '../../../reducer/authentication/actions';
import { validateEmail } from '../../../shared/util/utils';

export interface Props {
    t: (path: string, params?: any) => string;
    createSubscription: CreateSubscription;
    onNextStep: (createSubscription: CreateSubscription, hasErrors: boolean, messageError?: string) => void;
    checkIfEmailAlreadyRegistered: (email: string) => Promise<boolean>;
    showErrors?: boolean;
    login: (authenticate: Authentication) => void;
    loginStatus: HttpRequestStatus;
}

export const LoginStep = (props: Props): JSX.Element => {
    const { user } = props.createSubscription;
    const [emailAlreadyExists, setEmailAlreadyExists] = useState<boolean | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(user.email);
    const [emailError, setEmailError] = useState<boolean | undefined>(undefined);
    const [name, setName] = useState<string | undefined>(user.customer ? user.customer.name : undefined);
    const [nameError, setNameError] = useState<boolean | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(user.password);
    const [passwordError, setPasswordError] = useState<boolean | undefined>(undefined);
    const [showPassword, setShowPassword] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (props.loginStatus === HttpRequestStatus.SUCCESS) {
            onNextStep();
        } else if (props.loginStatus === HttpRequestStatus.ERROR) {
            setPasswordError(undefined);
            setEmailError(undefined);
            props.onNextStep(props.createSubscription, true, props.t('registerSubscription.loginStep.errors.password2'));
        }
    }, [props.loginStatus]);

    const onChangeEmail = (value: string): void => {
        setEmail(value);
    };
    const validateEmailInput = (value: string): boolean => {
        const hasError: boolean = !validateEmail(value);
        setEmailError(hasError);
        return hasError;
    };
    const onChangeName = (value: string): void => {
        setName(value);
    };
    const validateName = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length <= 3;
        setNameError(hasError);
        return hasError;
    };
    const onChangePassword = (value: string): void => {
        setPassword(value);
    };
    const validatePassword = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length < 4;
        setPasswordError(hasError);
        return hasError;
    };
    const checkIfEmailAlreadyRegistereds = () => {
        if (!validateEmail(email || '')) {
            setEmailError(true);
            return;
        }
        props.checkIfEmailAlreadyRegistered(email ?? '').then((it: boolean) => {
            setEmailAlreadyExists(it);
            setShowPassword(true);
            // if (it) {
            //     toaster.notify(
            //         callback => (
            //             <CustomToast
            //                 title={props.t('registerSubscription.loginStep.alreadyRegistered')}
            //                 onClose={callback.onClose}
            //                 t={props.t}
            //             />
            //         ),
            //         {
            //             duration: 3000,
            //             position: 'bottom'
            //         }
            //     );
            //     onNextStep(it);
            // }
        });
    };
    const onNextStep = (): void => {
        console.log('onNextStep', { emailAlreadyExists });
        if (emailAlreadyExists && props.loginStatus !== HttpRequestStatus.SUCCESS) {
            console.log('if', { email, password });
            if (!validateEmailInput(email || '') && !validatePassword(password)) {
                console.log('validated', { email, password });
                const authenticate: Authentication = {
                    username: email,
                    password
                };
                props.login(authenticate);
            }
        } else {
            const hasError: boolean =
                emailAlreadyExists === true ? false : validateEmailInput(email || '') || validateName(name) || validatePassword(password);
            const createSubscription: CreateSubscription = {
                ...props.createSubscription,
                newUser: !emailAlreadyExists,
                user: {
                    ...props.createSubscription.user,
                    email,
                    password,
                    customer: {
                        ...props.createSubscription.user.customer,
                        name
                    }
                }
            };

            props.onNextStep(createSubscription, hasError);
        }
    };
    return (
        <div className="login-step">
            <Row>
                <Col md={{ size: '6', offset: '3' }} xs={{ size: '12' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="premium-logo" />
                    </div>
                    <div className="login-step__inputs-mobile">
                        <div style={{ display: 'flex' }}>
                            <div className="login-step__inputs-mobile--icon-active" />
                            <div className="login-step__inputs-mobile--text">{props.t('register.loginStep.title')}</div>
                        </div>
                        <div className="login-step__inputs-mobile--icon-arrow" />
                    </div>
                    <div className="login-step__inputs">
                        <FormControl className="form" style={{ width: '100%' }}>
                            <div style={{ display: 'none' }}>
                                <input type="password" tabIndex={-1} />
                            </div>
                            <TextFieldMeds
                                onChange={onChangeEmail}
                                id="email"
                                isEmail
                                value={email}
                                errorText={props.t('registerSubscription.loginStep.errors.email')}
                                label={props.t('register.form.username')}
                                placeholder={props.t('register.form.usernamePlaceholder')}
                                error={emailError}
                                onBlur={() => validateEmailInput(email || '')}
                            />
                            {emailAlreadyExists === false && (
                                <TextFieldMeds
                                    onChange={onChangeName}
                                    id="name"
                                    value={name}
                                    errorText={props.t('registerSubscription.loginStep.errors.name')}
                                    label={props.t('register.form.name')}
                                    placeholder={props.t('register.form.namePlaceholder')}
                                    error={nameError}
                                    onBlur={() => validateName(name)}
                                />
                            )}
                            {showPassword && (
                                <TextFieldMeds
                                    onChange={onChangePassword}
                                    id="password"
                                    value={password}
                                    isPassword
                                    helpText={props.t('register.form.helpPass')}
                                    errorText={props.t('registerSubscription.loginStep.errors.password')}
                                    label={props.t('register.form.password')}
                                    placeholder={
                                        emailAlreadyExists
                                            ? props.t('register.form.passwordPlaceholder2')
                                            : props.t('register.form.passwordPlaceholder')
                                    }
                                    onEnterPress={onNextStep}
                                    error={passwordError}
                                    onBlur={() => validatePassword(password)}
                                    InputProps={{
                                        autComplete: 'off'
                                    }}
                                />
                            )}
                        </FormControl>
                    </div>
                </Col>
            </Row>
            <div className="steps__footer">
                <Button
                    label={props.t('diagnApp.advance')}
                    onPress={emailAlreadyExists == null ? checkIfEmailAlreadyRegistereds : onNextStep}
                    style={{ width: 151 }}
                    rightIcon={<div className="steps__footer__advance-icon" />}
                />
            </div>
            <div className="login-step__inputs-mobile-disabled--all">
                <div className="login-step__inputs-mobile-disabled">
                    <div className="login-step__inputs-mobile-disabled--text">{props.t('registerSubscription.addressStep.title')}</div>
                    <div className="login-step__inputs-mobile-disabled--icon-arrow" />
                </div>
                <div className="login-step__inputs-mobile-disabled">
                    <div className="login-step__inputs-mobile-disabled--text">{props.t('registerSubscription.paymentStep.title')}</div>
                    <div className="login-step__inputs-mobile-disabled--icon-arrow" />
                </div>
                <div className="login-step__inputs-mobile-disabled">
                    <div className="login-step__inputs-mobile-disabled--text">{props.t('registerSubscription.finishStep.title')}</div>
                    <div className="login-step__inputs-mobile-disabled--icon-arrow" />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: IRootState) => ({
    loginStatus: state.authentication.loginStatus
});
const mapDispatchToProps = {
    login: loginRequest
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LoginStep) as React.ComponentType<
    Omit<Props, 't' | 'loginStatus' | 'login'>
>;
