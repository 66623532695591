import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Annotations from '../../components/annotations/annotations';
import Loading from '../../components/loading/loading';
import { Note, NoteType } from '../../model/note';
import { IRootState } from '../../reducer';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import PediatricDoseService from '../../services/pediatric-dose.service';
import PediatricDoseContent from './pediatric-dose-content';
import PediatricDoseHeader from './pediatric-dose-header';
import PediatricDoseInput from './pediatric-dose-input/pediatric-dose-input';
import PediatricDoseList from './pediatric-dose-list';
import PediatricDoseObservation from './pediatric-dose-observation';
import { usePediatricDoseContext } from './pediatric-dose.provider';
import './pediatric-dose.scss';

interface Props extends RouteComponentProps<{}, {}, { fromAnnotation?: boolean }> {
    t: any;
    hasSubscription?: boolean;
    openPaidModal: () => void;
}

const PediatricDoseDashboard = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showContent, setShowContent] = useState<boolean>(false);
    const {
        currentPediatricDose,
        showAnnotation,
        pediatricDoseList,
        setPediatricDoseList,
        pediatricDoseIsLoading,
        setNewCurrentPediatricDose,
        setShowAnnotation,
        setCurrentPediatricDose
    } = usePediatricDoseContext();

    useEffect(() => {
        PediatricDoseService.search().then(doses => {
            setPediatricDoseList(doses);
            const {
                match: { params }
            } = props;
            const pediatricDoseId: number = (params as any).pediatricDoseId ?? 1;
            setNewCurrentPediatricDose({ id: pediatricDoseId });
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (currentPediatricDose !== undefined) {
            if (props.location?.state?.fromAnnotation != null) {
                const fromAnnotation = props.location?.state?.fromAnnotation ?? false;
                setShowAnnotation(fromAnnotation);
            }
            setShowContent(true);
            setIsLoading(false);
        }
    }, [currentPediatricDose]);

    const onSaveNote = (note: Note) => {
        setCurrentPediatricDose({ ...currentPediatricDose, note });
    };

    return (
        <>
            <div className="disease-screen-container" style={isLoading ? {} : { height: 0, display: 'none' }}>
                <Loading />
            </div>
            <div className={'dose-main-container'} style={isLoading ? { height: 0, display: 'none' } : {}}>
                <div className={'screen-dose-search-container'}>
                    <PediatricDoseList />
                </div>
                <div className={'screen-dose-content-container'} style={{ display: showContent ? 'block' : 'none' }}>
                    <div style={{ display: isLoading ? 'flex' : 'none', height: '100%', alignItems: 'center', justifyItems: 'center' }}>
                        <Loading />
                    </div>
                    <div style={{ display: pediatricDoseIsLoading ? 'none' : 'block', height: '100%' }}>
                        <PediatricDoseHeader {...props} />
                        <PediatricDoseInput {...props} />
                        <div className={'dose-markdowns-container'}>
                            <PediatricDoseContent {...props} />
                            <PediatricDoseObservation {...props} />
                        </div>
                    </div>
                </div>
                <div>
                    {showAnnotation && (
                        <Annotations
                            note={currentPediatricDose?.note}
                            itemId={currentPediatricDose?.id!}
                            noteType={NoteType.PEDIATRIC_DOSE}
                            saveCallback={onSaveNote}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account?.customer?.hasSubscription
});

const mapDispatchToProps = {
    openPaidModal: openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(PediatricDoseDashboard) as React.ComponentType<any>;
