import { AxiosResponse } from 'axios';
import registerApi from '../api/registerApi';
import HttpStatus from '../model/enums/httpStatus';

export const RegisterService = () => {
    const checkIfEmailAlreadyRegistered = async (email: string): Promise<boolean> => {
        try {
            const result: AxiosResponse<boolean> = await registerApi.checkIfEmailAlreadyRegistered(email);
            if (result.status === HttpStatus.OK && result.data != null) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    return {
        checkIfEmailAlreadyRegistered
    };
};

export default RegisterService();
