import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';
import React from 'react';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useDividerOnDutyContext } from '../context/divider-on-duty-provider';
import '../divider-on-duty.scss';
import { Info, InfoContainer, PenContainer, StepMainContainer, TimeContainer, TimeText, TimeTitle } from '../on-duty-styles';

interface Props {
    t: any;
}

const HourStep = (props: Props) => {
    const { startTime, endTime, setStartTime, setEndTime } = useDividerOnDutyContext();

    const renderInput = (props, openCalendar, closeCalendar) => {
        return (
            <PenContainer onClick={openCalendar}>
                <div className={'on-duty-pencil'} />
            </PenContainer>
        );
    }

    const onChangeStartDate = (newDate: Moment | string) => {
        const date = moment(newDate);
        setStartTime(date);
        setEndTime(moment(date).add(24, 'hours'));
    }

    const onChangeEndDate = (newDate: Moment | string) => {
        const date = moment(newDate);
        setEndTime(date);
    }

    return (
        <StepMainContainer>
            <TimeTitle>{props.t(['onDuty.startDate'])}</TimeTitle>
            <TimeContainer>
                <div className={'on-duty-start-clock'} />
                <div style={{ width: '80%' }}>
                    <TimeText>{startTime.format('ddd, DD[/]MM')}</TimeText>
                    <TimeText style={{ marginLeft: '50px' }}>{startTime.format('HH:mm')}</TimeText>
                </div>
                <Datetime
                    value={startTime}
                    closeOnClickOutside
                    renderInput={renderInput}
                    onChange={onChangeStartDate}
                />
            </TimeContainer>
            <TimeTitle>{props.t(['onDuty.endDate'])}</TimeTitle>
            <TimeContainer>
                <div className={'on-duty-end-clock'} />
                <div style={{ width: '80%' }}>
                    <TimeText>{endTime.format('ddd, DD[/]MM')}</TimeText>
                    <TimeText style={{ marginLeft: '50px' }}>{endTime.format('HH:mm')}</TimeText>
                </div>
                <Datetime
                    value={endTime}
                    closeOnClickOutside
                    renderInput={renderInput}
                    onChange={onChangeEndDate}
                    isValidDate={(current) => moment(current).isBetween(moment(startTime).subtract(24, 'hours'), moment(startTime).add(24, 'hours'))}
                />
            </TimeContainer>
            <InfoContainer>
                <div className={'on-duty-info'} />
                <Info>{props.t(['onDuty.timeInfo'])}</Info>
            </InfoContainer>
        </StepMainContainer>
    );
}

export default compose(connect(null, null), withTranslation())(HourStep) as React.ComponentType<any>;