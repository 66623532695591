import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import CriteriaToggle from '../../../components/score-toggle/score-toggle';
import { MedicalCriteriaParam } from '../../../model/medical-criteria/medical-criteria-param';
import { MedicalCriteriaParamOption } from '../../../model/medical-criteria/medical-criteria-param-option';
import { useMedicalCriteriaContext } from '../medical-criteria.provider';

interface Props {
  parameterInfo: MedicalCriteriaParam;
}

const MedicalCriteriaToggleParam = (props: Props) => {
  const { parameterInfo } = props;
  const ordenedOptions = _.sortBy(parameterInfo.options, 'position');
  const [value, setValue] = useState<MedicalCriteriaParamOption>();
  const { paramValues, setParamValues } = useMedicalCriteriaContext();

  useEffect(() => {
    const newValues = { ...paramValues };
    newValues[parameterInfo.code!] = value;
    setParamValues(newValues);
  }, [value]);

  return (
    <CriteriaToggle<MedicalCriteriaParamOption>
      options={ordenedOptions}
      optionSelected={value}
      onChange={setValue}
    />
  );
};

export default MedicalCriteriaToggleParam;
