import { StylesConfig } from 'react-select';
import styled from 'styled-components';

export const CardContainer = styled.div`
    height: 65vh;
    width: 60vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1.2px solid #e9eced;
    border-radius: 8px;
    background-color: #FFFFFF;
    overflow-y: auto;
    padding-top: 20px;
`;

export const FieldQuestionText = styled.span`
    color: #6D7C81;
    text-align: center;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-top: 3vh;
`;

export const FieldQuestionNote = styled.span`
    color: #6D7C81;
    text-align: center;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-top: 0.5vh;
`;

export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    width: 24.5vw;
    border-radius: 28px;
    background: #F7F8F8;
    cursor: pointer;
    margin-top: 2.5vh;
`;

export const DateText = styled.span`
    color: #414E52;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
`;

export const ArrowIcon = styled.div`
    height: 18px;
    width: 18px;
    position: absolute;
    right: 0.5vw;
`;

export const OptionLabel = styled.span`
    color: #414E52;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin-left: 1vw;
`;

export const ButtonContainer = styled.div`
    height: 52px;
    width: 24.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    margin-top: 10vh;
    background: #C25576;
    cursor: pointer;
`;

export const ButtonLabel = styled.span`
    font-family: Raleway;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
`;


export const pickerStyles: StylesConfig<any> = {
    control: styles => ({
        ...styles,
        textAlign: 'center',
        backgroundColor: '#F7F8F8',
        width: '24.5vw',
        border: '1px solid transparent',
        borderRadius: '50px',
        marginTop: '2.5vh',
        boxShadow: '0 0 0 0 transparent',
        minHeight: '52px',
        ':focus': {
            ...styles[':focus'],
            boxShadow: '0 0 0 0 transparent'
        }
    }),
    option: (styles, { isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? '#DEEBFF' : undefined,
            ':active': {
                ...styles[':active'],
                backgroundColor: isSelected ? '#2684FF' : undefined
            }
        };
    },
    indicatorSeparator: styles => ({ ...styles, backgroundColor: 'transparent' }),
    multiValue: styles => ({ ...styles, backgroundColor: 'transparent' })
};

