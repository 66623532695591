import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { User } from '../../model/user';
import { IRootState } from '../../reducer';
import EditPassword from './components/edit-password';
import Faq from './components/faq';
import FreeCalculators from './components/free-calculators';
import FreeDiseases from './components/free-diseases';
import FreeCriteria from './components/free-criteria';
import FreeLaboratories from './components/free-laboratories';
import FreeProcedures from './components/free-procedures';
import FreeScores from './components/free-scores';
import FreeSemiologies from './components/free-semiologies';
import IndicateFriend from './components/indicate-friend/indicate-friend';
import MySubscription from './components/my-subscription';
import Profile from './components/profile';
import TermsOfUse from './components/termsOfUse';
import './preferences.scss';
import SideMenuItem from './side-menu-item';

export interface PreferencesProps extends RouteComponentProps<{}> {
    t: (path: string, param?: any) => string;
    history: any;
    user?: User;
}

export class Preferences extends React.Component<PreferencesProps> {
    constructor(props: Readonly<PreferencesProps>, context?: any) {
        super(props, context);

        this.state = {};
    }

    render() {
        if (!this.props.user) {
            return <div />;
        }
        const hasSubscription: boolean = (this.props.user.customer && this.props.user.customer.hasSubscription) || false;
        return (
            <div className="preferences-screen-container">
                <div className="preferences">
                    <div className="side-menu">
                        <div className="side-menu__title">
                            <span className="side-menu__title__text">{this.props.t('preferences.title')}</span>
                        </div>
                        {!hasSubscription && (
                            <SideMenuItem
                                {...this.props}
                                label={this.props.t('preferences.freeDiseases')}
                                path={'/free-diseases'}
                                icon={require('../../images/bacterium-laranja.svg')}
                            />
                        )}
                        {!hasSubscription && (
                            <SideMenuItem
                                {...this.props}
                                label={this.props.t('preferences.freeProcedures')}
                                path={'/free-procedures'}
                                icon={require('../../images/bacterium-laranja.svg')}
                            />
                        )}
                        {!hasSubscription && (
                            <SideMenuItem
                                {...this.props}
                                label={this.props.t('preferences.freeLaboratories')}
                                path={'/free-laboratories'}
                                icon={require('../../images/bacterium-laranja.svg')}
                            />
                        )}
                        {!hasSubscription && (
                            <SideMenuItem
                                {...this.props}
                                label={this.props.t('preferences.freeCalculators')}
                                path={'/free-calculators'}
                                icon={require('../../images/bacterium-laranja.svg')}
                            />
                        )}
                        {!hasSubscription && (
                            <SideMenuItem
                                {...this.props}
                                label={this.props.t('preferences.freeScores')}
                                path={'/free-scores'}
                                icon={require('../../images/bacterium-laranja.svg')}
                            />
                        )}
                        {!hasSubscription && (
                            <SideMenuItem
                                {...this.props}
                                label={this.props.t('preferences.freeCriteria')}
                                path={'/free-criteria'}
                                icon={require('../../images/bacterium-laranja.svg')}
                            />
                        )}
                        {!hasSubscription && (
                            <SideMenuItem
                                {...this.props}
                                label={this.props.t('preferences.freeSemiologies')}
                                path={'/free-semiologies'}
                                icon={require('../../images/bacterium-laranja.svg')}
                            />
                        )}
                        <span className="side-menu__items-title">{this.props.t('preferences.account')}</span>
                        <SideMenuItem
                            {...this.props}
                            label={this.props.t('preferences.profile')}
                            path={'/profile'}
                            icon={require('../../images/perfil.svg')}
                        />
                        <SideMenuItem
                            {...this.props}
                            label={this.props.t('preferences.editPassword')}
                            path={'/edit-password'}
                            icon={require('../../images/senha.svg')}
                        />
                        <SideMenuItem
                            {...this.props}
                            label={this.props.t('preferences.mySubscription')}
                            path={'/my-subscription'}
                            icon={require('../../images/assinatura.svg')}
                        />
                        <span className="side-menu__items-title">{this.props.t('preferences.askYourQuestions')}</span>
                        <SideMenuItem
                            {...this.props}
                            label={this.props.t('preferences.faq')}
                            path={'/faq'}
                            icon={require('../../images/duvidas.svg')}
                        />
                        <span className="side-menu__items-title">{this.props.t('preferences.aboutWeMeds')}</span>
                        <SideMenuItem
                            {...this.props}
                            label={this.props.t('preferences.termsOfUse')}
                            path={'/terms-of-use'}
                            icon={require('../../images/termos-uso.svg')}
                        />
                        <SideMenuItem
                            {...this.props}
                            label={this.props.t('preferences.shareToAFriend')}
                            path={'/indicate'}
                            icon={require('../../images/indique.svg')}
                        />
                        <SideMenuItem
                            {...this.props}
                            label={this.props.t('preferences.logout')}
                            path={''}
                            icon={require('../../images/sair.svg')}
                        />
                    </div>
                    <div className="preferences__content">
                        <Route exact path={`${this.props.match.path}/free-diseases`} component={FreeDiseases} />
                        <Route exact path={`${this.props.match.path}/free-procedures`} component={FreeProcedures} />
                        <Route exact path={`${this.props.match.path}/free-laboratories`} component={FreeLaboratories} />
                        <Route exact path={`${this.props.match.path}/free-semiologies`} component={FreeSemiologies} />
                        <Route exact path={`${this.props.match.path}/free-calculators`} component={FreeCalculators} />
                        <Route exact path={`${this.props.match.path}/free-criteria`} component={FreeCriteria} />
                        <Route exact path={`${this.props.match.path}/free-scores`} component={FreeScores} />
                        <Route exact path={`${this.props.match.path}/profile`} component={Profile} />
                        <Route exact path={`${this.props.match.path}/edit-password`} component={EditPassword} />
                        <Route exact path={`${this.props.match.path}/my-subscription`} component={MySubscription} />
                        <Route exact path={`${this.props.match.path}/faq`} component={Faq} />
                        <Route exact path={`${this.props.match.path}/terms-of-use`} component={TermsOfUse} />
                        <Route exact path={`${this.props.match.path}/indicate`} component={IndicateFriend} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ search, authentication }: IRootState) => ({
    user: authentication.account
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Preferences) as React.ComponentType<any>;
