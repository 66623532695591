import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { IRootState } from '../../../reducer';
import './components.scss';

export interface TermsOfUseProps extends RouteComponentProps<{}> {
    t: (path: string, param?: any) => string;
    history: any;
}

export class TermsOfUse extends React.Component<TermsOfUseProps> {
    constructor(props: Readonly<TermsOfUseProps>, context?: any) {
        super(props, context);

        this.state = {};
    }
    // Renders
    render() {
        return (
            <div className="component">
                <span className="component__title">{this.props.t('preferences.termsOfUseComponent.title')}</span>
                <span className="termsOfUse__text">
                    <p>
                        <em>Aplicação</em>
                    </p>
                    <p>
                        Este documento aplica-se à relação contratual entre a empresa Meu Plantão Provedor de Conteúdo LTDA, empresa
                        registrada sob o CNPJ N. 31.082.784/0001-78, aqui referida "Parte Contratada", e o usuário do produto oferecido pela
                        companhia supramencionada, aqui referido como "Parte Contratante" . Os produtos e serviços prestados pela empresa
                        Meu Plantão serão regidos pelas cláusulas abaixo.
                    </p>
                    <p>
                        A aceitação eletrônica deste termo, por meio de clique no botão "Concordo com os Termos" disponível quando do acesso
                        ao produto contratado, implica a concordância em submissão, sem reservas, às disposições presentes neste documento,
                        bem como de quaisquer alterações eventuais futuras.
                    </p>
                    <p>
                        <em>Do objeto</em>
                    </p>
                    <p>
                        A parte contratada, mediante pagamento de assinatura, fornecerá acesso à parte contratante do objeto, em formato de
                        aplicativo, chamado "WeMEDS", de conteúdo médico educacional.
                    </p>
                    <p>
                        O conteúdo do aplicativo mencionado não substitui, em hipótese alguma, a experiência da prática profissional médica,
                        muito menos a necessidade de se consultar profissional de saúde habilitado em caso de doença ou suspeita de doença,
                        rastreamento de condições, et cetera. A Parte Contratante, ao aceitar estes termos, será integralmente responsável
                        pela certificação da correção e da atualidade das informações presentes no aplicativo. A Parte Contratante,
                        portanto, isenta a Parte Contratada de quaisquer responsabilidades administrativas, cíveis ou criminais decorrentes
                        do uso do aplicativo, que redundem em perdas e danos ao usuário e/ou terceiros.
                    </p>
                    <p>
                        Em caso de identificação de informação desatualizada ou incorreta, a Parte Contratante compromete-se a notificar,
                        dentro de prazo de 24 horas, a Parte Contratada, mediante mensagem para o correio eletrônico suporte@wemeds.com.br
                    </p>
                    <p>
                        A Parte Contratada disponibilizará, ainda, ferramenta de demonstração didática do processo diagnóstico junto com o
                        conteúdo didático, chamada "DiagnApp". A Parte Contratante entende que a ferramenta mencionada não substitui o
                        diagnóstico clínico profissional. A Parte Contratante assume, do mesmo modo, responsabilidade integral por erros
                        decorrentes da má concepção do uso dessa ferramenta, nos termos deste parágrafo.
                    </p>
                    <p>
                        <em>Das interrupções de serviço</em>
                    </p>
                    <p>
                        A Parte Contratada não se responsabilizará por interrupções de serviço eventuais, decorrentes de responsabilidade de
                        partes terceiras ou, mesmo, da necessidade periódica de atualização do conteúdo didático. A Parte Contratada
                        reservar-se-á o direito de, a fim de atender a novos requisitos ou obrigações, alterar ou suspender serviços e
                        conteúdo, mediante aviso prévio à Parte Contratante, sem que o fato gere quaisquer responsabilidades.
                    </p>
                    <p>
                        <em>Das alterações do presente termo</em>
                    </p>
                    <p>
                        A Parte Contratada, sempre que achar necessário, realizará modificações no presente termo, que estarão vigentes
                        imediatamente após a aceitação eletrônica do novo termo, que se dará mediante o mesmo processo da aceitação do termo
                        original. A Parte Contratada se reserva o direito de interromper o fornecimento do acesso à Parte Contratante
                        enquanto ela não confirmar a aceitação do termo modificado.
                    </p>
                    <p>
                        <em>Das políticas de privacidade</em>
                    </p>
                    <p>
                        Sendo prática corrente no sentido de otimizar os serviços oferecidos, a Parte Contratada reserva-se o direito do uso
                        de informação de "cookies" do usuário. A Parte Contratada compromete-se a usar este conteúdo somente para o
                        benefício do usuário e dentro do âmbito do aplicativo "WeMEDS" , abstendo-se de fornecer o conteúdo a terceiros sem
                        a expressa autorização da Parte Contratante.
                    </p>
                    <p>
                        A Parte Contratada reserva-se, ainda, o direito de utilizar dados eventualmente fornecidos pela Parte Contratante no
                        âmbito do cadastro do aplicativo, tais como contato telefônico, endereço, ocupação, correio eletrônico, entre
                        outros. Do mesmo modo, essa prática visa a aperfeiçoar os serviços oferecidos pela "WeMEDS"
                    </p>
                    <p>
                        A Parte Contratada reserva-se, ainda, o direito de alterar esta Política de Privacidade a qualquer momento,
                        sujeitando a nova política à aprovação eletrônica do usuário, a ser realizada dentro do aplicativo.
                    </p>
                    <p>
                        <em>Da proibição de reprodução ou falsificação de conteúdo e de ferramentas</em>
                    </p>
                    <p>
                        A Parte Contratada deterá a integralidade dos direitos de propriedade intelectual, que compreendem desde o nome do
                        aplicativo até a integralidade de seu conteúdo e de suas ferramentas. A Parte Contratante não fará ou facilitará
                        cópia, reprodução ou falsificação, seja parcial, seja integral, do conteúdo ou das ferramentas do aplicativo
                        "WeMEDS", da mesma forma ficará impedida de compartilhar logins e afins. Em caso de desobediência à presente
                        disposição, a Parte Contratada reserva-se o direito de interromper o serviço contratado, bem como processar a Parte
                        Contratante nos foros administrativos, cíveis e, inclusive, criminais, pelos Crimes contra a Propriedade
                        Intelectual.
                    </p>
                    <p>
                        <em>Da requisição de acesso a documentos</em>
                    </p>
                    <p>
                        A Parte Contratada se reserva o direito de utilizar todos os meios válidos e possíveis para identificar a Parte
                        Contratante em caso de necessidade. Poderá, nesse sentido, a Parte Contratada requerer cópias de documentos do
                        USUÁRIO, podendo suspender o fornecimento de serviços até o recebimento dos documentos solicitados. Caso algum
                        documento seja considerado incoerente com os documentos apresentados, a Parte Contratante terá prazo de 7 dias úteis
                        para efetuar os devidos esclarecimentos, que, em caso de não realização, suscitarão o bloqueio do acesso da Parte
                        Contratada ao aplicativo "WeMEDS"
                    </p>
                    <p>
                        <em>Do escopo contratual</em>
                    </p>
                    <p>
                        O presente termo não gera, sob hipótese alguma, contrato societário, franquia, relação de trabalho ou mandatária
                        entre a Parte Contratante e a Parte Contratada.
                    </p>
                    <p>
                        <em>Da vigência do termo</em>
                    </p>
                    <p>
                        O presente termo estará vigente a partir de sua aceitação eletrônica, etapa necessária para o acesso ao conteúdo
                        educacional do aplicativo "WeMEDS", e vigerá até a data de decisão de rescisão entre as duas partes (ver "da
                        rescisão").
                    </p>
                    <p>
                        <em>Da rescisão</em>
                    </p>
                    <p>
                        A intenção de rescisão será comunicada por ferramenta interna do aplicativo, e será efetivada dentro de prazo de
                        sete dias úteis. A comunicação de rescisão não exime as partes de suas obrigações, e a Parte Contratante deverá,
                        mesmo em caso de rescisão, arcar com a integralidade da assinatura realizada. Por exemplo, se a assinatura for
                        mensal, o uso de apenas sete dias não garantirá reembolso algum ao usuário. Do mesmo modo, uso de apenas dois meses
                        de assinatura anual, por exemplo, obrigará o usuário ao pagamento integral da assinatura anual.
                    </p>
                    <p>
                        <em>De indenização</em>
                    </p>
                    <p>
                        A Parte Contratante, responsável integral por perdas ou danos decorrentes do uso do conteúdo do aplicativo "WeMEDS",
                        procederá com pagamento de indenização à empresa detentora do conteúdo do aplicativo "WeMEDS", em caso de prejuízo
                        de quaisquer naturezas causados a esta companhia por responsabilidade da Parte Contratante.
                    </p>
                    <p>
                        <em>Da política de devolução de dinheiro</em>
                    </p>
                    <p>
                        A Parte Contratante entende que a política de devolução de dinheiro estará sob responsabilidade integral da loja
                        virtual na qual o conteúdo será comercializado.
                    </p>
                    <p>
                        <em>Do foro para redimir controvérsias</em>
                    </p>
                    <p>
                        As Partes elegem o Foro da Central da Comarca da Região Metropolitana de Curitiba, Estado do Paraná, com renúncia a
                        qualquer outro, por mais privilegiado que seja, para redimir quaisquer controvérsias, entendendo que,
                        independentemente de onde estiver localizada a Parte Contratante, a solução de conflitos será realizada com
                        fundamento nas normas presentes na legislação brasileira e no código de ética médico brasileiro.
                    </p>
                </span>
            </div>
        );
    }
}

const mapStateToProps = ({ search }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(TermsOfUse) as React.ComponentType<any>;
