import { Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { compose } from 'redux';
import Button from '../../../components/button/button';
import Dropdown from '../../../components/dropdown/dropdown';
import TextFieldMeds from '../../../components/text-field-meds/text-field-meds';
import { Address } from '../../../model/address';
import { CreateSubscription } from '../../../model/createSubscription';
import { STATES } from '../../../shared/util/states';
import { Col, Row } from 'reactstrap';

const MaskZipCode = props => {
    return <MaskedInput {...props} mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]} guide={false} />;
};
const MaskNumber = props => {
    return <MaskedInput {...props} mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} />;
};
export interface Props {
    t: (path: string, params?: any) => string;
    createSubscription: CreateSubscription;
    onNextStep: (createSubscription: CreateSubscription, hasErrors: boolean, errorMessage?: string) => void;
    onBack: () => void;
    showErrors?: boolean;
}

interface AddressErrors {
    streetError?: boolean;
    streetNumberError?: boolean;
    complementError?: boolean;
    districtError?: boolean;
    cityError?: boolean;
    stateError?: boolean;
    zipCodeError?: boolean;
}
export const AddressStep = (props: Props): JSX.Element => {
    const address: Address = props.createSubscription.user.customer ? props.createSubscription.user.customer.address || {} : {};
    const [errors, setErrors] = useState<AddressErrors>({
        streetError: undefined,
        streetNumberError: undefined,
        complementError: undefined,
        districtError: undefined,
        cityError: undefined,
        stateError: undefined,
        zipCodeError: undefined
    });
    const [street, setStreet] = useState<string | undefined>(address.street || undefined);
    const [streetNumber, setStreetNumber] = useState<string | undefined>(address.streetNumber || undefined);
    const [complement, setComplement] = useState<string | undefined>(address.complement || undefined);
    const [district, setDistrict] = useState<string | undefined>(address.district || undefined);
    const [city, setCity] = useState<string | undefined>(address.city || undefined);
    // tslint:disable-next-line: no-unused-declaration
    const [state, setState] = useState<string | undefined>(address.state || undefined);
    const [zipCode, setZipCode] = useState<string | undefined>(address.zipCode || undefined);
    const [checkbox, setCheckbox] = useState<boolean>(false);
    const [showNote, setShowNote] = useState<boolean>(true);

    useEffect(() => {
        window.fbq('track', 'InitiateCheckout');
    }, []);
    
    const validateAddress = (): boolean => {
        return (
            !validateStreet(street) &&
            !validateStreetNumber(streetNumber) &&
            !validateDistrict(district) &&
            !validateCity(city) &&
            !validateState(state) &&
            !validateZipCode(zipCode)
        );
    };
    const onChangeStreet = (value: string): void => {
        setStreet(value);
    };
    const validateStreet = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length === 0;
        setErrors({ ...errors, streetError: hasError });
        return hasError;
    };
    const onChangeStreetNumber = (value: string): void => {
        setStreetNumber(value);
    };
    const validateStreetNumber = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length === 0;
        setErrors({ ...errors, streetNumberError: hasError });
        return hasError;
    };
    const onChangeComplement = (value: string): void => {
        setComplement(value);
    };
    const validateComplement = (value?: string): boolean => {
        setErrors({ ...errors, complementError: false });
        return false;
    };
    const onChangeDistrict = (value: string): void => {
        setDistrict(value);
    };
    const validateDistrict = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length === 0;
        setErrors({ ...errors, districtError: hasError });
        return hasError;
    };
    const onChangeCity = (value: string): void => {
        setCity(value);
    };
    const validateCity = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length === 0;
        setErrors({ ...errors, cityError: hasError });
        return hasError;
    };
    const onChangeState = (value: string): void => {
        setState(value);
    };
    const validateState = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length === 0;
        setErrors({ ...errors, stateError: hasError });
        return hasError;
    };
    const onChangeZipCode = (value: string): void => {
        setZipCode(value);
    };
    const validateZipCode = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length === 0;
        setErrors({ ...errors, zipCodeError: hasError });
        return hasError;
    };
    const onChangeCheckbox = (event: any): void => {
        const {
            target: { checked }
        } = event;
        setCheckbox(checked);
    };
    const onNextStep = (): void => {
        const isValid: boolean = validateAddress() && checkbox;
        const createSubscription: CreateSubscription = {
            ...props.createSubscription,
            user: {
                ...props.createSubscription.user,
                customer: {
                    ...props.createSubscription.user.customer,
                    address: {
                        ...props.createSubscription.user.customer?.address,
                        street,
                        streetNumber,
                        complement,
                        district,
                        city,
                        state,
                        zipCode
                    }
                }
            }
        };
        props.onNextStep(
            createSubscription,
            !isValid,
            validateAddress() && !checkbox ? props.t('registerSubscription.addressStep.errors.checkbox') : undefined
        );
    };
    const onCloseNote = (): void => {
        setShowNote(false);
    };
    return (
        <div className="address-step">
            <div className="premium-logo" />
            <div className="login-step__inputs-mobile-done">
                <div style={{ display: 'flex', marginLeft: '23px' }}>
                    <div className="login-step__inputs-mobile-done--icon-done" />
                    <div className="login-step__inputs-mobile-done--text">{props.t('register.loginStep.title')}</div>
                </div>
                <div style={{ display: 'flex', marginRight: '23px' }}>
                    <div className="login-step__inputs-mobile-done--edit">{props.t('register.step.edit')}</div>
                    <div className="login-step__inputs-mobile-done--icon-arrow" />
                </div>
            </div>
            <Row style={{ width: '100%' }}>
                <Col>
                    <div className="login-step__inputs-mobile">
                        <div style={{ display: 'flex' }}>
                            <div className="login-step__inputs-mobile--icon-active" />
                            <div className="login-step__inputs-mobile--text">{props.t('registerSubscription.addressStep.title')}</div>
                        </div>
                        <div className="login-step__inputs-mobile--icon-arrow" />
                    </div>
                </Col>
            </Row>
            <div className="address-step__inputs">
                <Row style={{ marginTop: '25px' }}>
                    <Col md={{ size: '4', offset: '3' }} xs={{ size: '8' }}>
                        <TextFieldMeds
                            onChange={onChangeStreet}
                            id="street"
                            value={street}
                            errorText={props.t('preferences.profileComponent.addressError')}
                            label={props.t('preferences.profileComponent.addressPlaceholder')}
                            placeholder={props.t('preferences.profileComponent.address')}
                            error={errors.streetError}
                            onBlur={() => validateStreet(street)}
                        />
                    </Col>
                    <Col md="2" xs="4" style={{ paddingLeft: '0' }}>
                        <TextFieldMeds
                            onChange={onChangeStreetNumber}
                            id="number"
                            value={streetNumber}
                            errorText={props.t('preferences.profileComponent.numberError')}
                            label={props.t('preferences.profileComponent.numberPlaceholder')}
                            placeholder={props.t('preferences.profileComponent.number')}
                            error={errors.streetNumberError}
                            InputProps={{
                                inputComponent: MaskNumber,
                                value: streetNumber
                            }}
                            onBlur={() => validateStreetNumber(streetNumber)}
                        />
                    </Col>
                    <Col>
                        {showNote && (
                            <div className="register-subscription__note">
                                <span className="register-subscription__note__text">
                                    {props.t('registerSubscription.addressStep.sideNoteText')}
                                </span>
                                <div className="register-subscription__note__close-icon" onClick={onCloseNote} />
                            </div>
                        )}
                    </Col>
                </Row>
                <div className="register-subscription__mobile-spacing" />
                <Row style={{ marginTop: '12px' }}>
                    <Col md={{ size: '3', offset: '3' }} style={{ paddingRight: '7.5px' }} xs="6">
                        <TextFieldMeds
                            onChange={onChangeComplement}
                            id="complement"
                            value={complement}
                            errorText={props.t('preferences.profileComponent.complementError')}
                            label={props.t('preferences.profileComponent.complementPlaceholder')}
                            placeholder={props.t('preferences.profileComponent.complement')}
                            error={errors.complementError}
                            onBlur={() => validateComplement(complement)}
                        />
                    </Col>
                    <Col md="3" xs="6" style={{ paddingLeft: '7.5px' }}>
                        <TextFieldMeds
                            onChange={onChangeDistrict}
                            id="district"
                            value={district}
                            errorText={props.t('preferences.profileComponent.neighborhoodError')}
                            label={props.t('preferences.profileComponent.neighborhoodPlaceholder')}
                            placeholder={props.t('preferences.profileComponent.neighborhood')}
                            error={errors.districtError}
                            onBlur={() => validateDistrict(district)}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '25px' }}>
                    <Col md={{ size: '6', offset: '3' }} xs="12">
                        <TextFieldMeds
                            onChange={onChangeCity}
                            id="city"
                            value={city}
                            errorText={props.t('preferences.profileComponent.cityError')}
                            label={props.t('preferences.profileComponent.cityPlaceholder')}
                            placeholder={props.t('preferences.profileComponent.city')}
                            error={errors.cityError}
                            onBlur={() => validateCity(city)}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '25px' }}>
                    <Col md={{ size: '3', offset: '3' }} style={{ paddingRight: '7.5px' }} xs="6">
                        <Dropdown
                            data={STATES.map(it => ({ label: it, value: it }))}
                            onClickItem={onChangeState}
                            title={props.t('preferences.profileComponent.statePlaceholder')}
                            initialValue={address ? address.state : undefined}
                            error={errors.stateError}
                            errorText={props.t('registerSubscription.addressStep.errors.state')}
                        />
                    </Col>
                    <Col md="3" xs="6" style={{ paddingLeft: '7.5px' }}>
                        <TextFieldMeds
                            onChange={onChangeZipCode}
                            id="zipCode"
                            value={zipCode}
                            errorText={props.t('preferences.profileComponent.zipcodeError')}
                            label={props.t('preferences.profileComponent.zipcodePlaceholder')}
                            placeholder={props.t('preferences.profileComponent.zipcode')}
                            error={errors.zipCodeError}
                            InputProps={{
                                inputComponent: MaskZipCode,
                                value: zipCode
                            }}
                            onBlur={() => validateZipCode(zipCode)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: '6', offset: '3' }} xs="12">
                        <div className="address-step__checkbox">
                            <Checkbox onChange={onChangeCheckbox} checked={checkbox} />
                            <span>
                                <span className="address-step__checkbox__label">
                                    {props.t('registerSubscription.addressStep.checkboxLabel1')}
                                </span>
                                <a href="https://wemeds.com.br/termos/" target="_blank" className="address-step__checkbox__label--orange">
                                    {props.t('registerSubscription.addressStep.checkboxLabel2')}
                                </a>
                            </span>
                        </div>
                        <div className="steps__footer">
                            <Button
                                label={props.t('diagnApp.back')}
                                inverse
                                onPress={props.onBack}
                                style={{ width: 151, marginRight: 13 }}
                                leftIcon={<div className="steps__footer__back-icon" />}
                            />
                            <Button
                                label={props.t('diagnApp.advance')}
                                onPress={onNextStep}
                                style={{ width: 151, marginLeft: 13 }}
                                rightIcon={<div className="steps__footer__advance-icon" />}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="login-step__inputs-mobile-disabled--all">
                <div className="login-step__inputs-mobile-disabled">
                    <div className="login-step__inputs-mobile-disabled--text">{props.t('registerSubscription.paymentStep.title')}</div>
                    <div className="login-step__inputs-mobile-disabled--icon-arrow" />
                </div>
                <div className="login-step__inputs-mobile-disabled">
                    <div className="login-step__inputs-mobile-disabled--text">{props.t('registerSubscription.finishStep.title')}</div>
                    <div className="login-step__inputs-mobile-disabled--icon-arrow" />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(AddressStep) as React.ComponentType<
    Omit<Props, 't'>
>;
