import styled from 'styled-components';

export const MainContainer = styled.div`
    height: 30vh;
    width: 35vh;
    left: 40vw;
    top: 35vh;
    position: absolute;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    padding: 32px 16px 16px 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
    gap: 4px;
    width: 220px;
    display: flex;
    cursor: pointer;
    padding: 10px 16px;
    border-radius: 28px;
    background: #EF7D00;
    justify-content: center;
    margin-bottom: 2vh;
`;

export const ButtonText = styled.span`
    color: #FFFFFF;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
`;

export const Title = styled.span`
    color: #414E52;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    font-style: normal;
    font-family: Raleway;
`;

export const CancelText = styled.span`
    color: #414E52;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    font-style: normal;
    font-family: Raleway;
    cursor: pointer;
`;

export const Subtitle = styled.span`
    color: #414E52;
    font-size: 15px;
    font-weight: 500;
    width: 70%;
    text-align: center;
    font-style: normal;
    font-family: Raleway;
`;