enum Specialization {
  GENERELIST = 'GENERELIST',
  ACUNPUNCTURE = 'ACUNPUNCTURE',
  ALLERGOLOGY = 'ALLERGOLOGY',
  ANESTHESIOLOGY = 'ANESTHESIOLOGY',
  CARDIOLOGY = 'CARDIOLOGY',
  CARDIAC_SURGERY = 'CARDIAC_SURGERY',
  HAND_SURGERY = 'HAND_SURGERY',
  HEAD_SURGERY = 'HEAD_SURGERY',
  DIGESTOR_SURGERY = 'DIGESTOR_SURGERY',
  GENERAL_SURGERY = 'GENERAL_SURGERY',
  CHILD_SURGERY = 'CHILD_SURGERY',
  PLASTIC_SURGERY = 'PLASTIC_SURGERY',
  THORACIC_SURGERY = 'THORACIC_SURGERY',
  VASCULAR_SURGERY = 'VASCULAR_SURGERY',
  MEDICINE_CLINIC = 'MEDICINE_CLINIC',
  COLOPROCTOLOGY = 'COLOPROCTOLOGY',
  DERMATOLOGY = 'DERMATOLOGY',
  ENDOCRINOLOGY = 'ENDOCRINOLOGY',
  ENDOSCOPY = 'ENDOSCOPY',
  GASTROENTEROLOGY = 'GASTROENTEROLOGY',
  GENETICS = 'GENETICS',
  GERIATRICS = 'GERIATRICS',
  GYNECOLOGY = 'GYNECOLOGY',
  HEMATOLOGY = 'HEMATOLOGY',
  INFECTIOUS = 'INFECTIOUS',
  MASTOLOGY = 'MASTOLOGY',
  FAMILY_MEDICINE = 'FAMILY_MEDICINE',
  URGENCY_MEDICINE = 'URGENCY_MEDICINE',
  WORK_MEDICINE = 'WORK_MEDICINE',
  TRAFFIC_MEDICINE = 'TRAFFIC_MEDICINE',
  SPORT_MEDICINE = 'SPORT_MEDICINE',
  REHABILITATION_MEDICINE = 'REHABILITATION_MEDICINE',
  NUCLEAR_MEDICINE = 'NUCLEAR_MEDICINE',
  PALLIATIVE = 'PALLIATIVE',
  PREVENTIVE = 'PREVENTIVE',
  NEUROSURGERY = 'NEUROSURGERY',
  NUTRITION = 'NUTRITION',
  ONCOLOGY = 'ONCOLOGY',
  OPHTHALMOLOGY = 'OPHTHALMOLOGY',
  ORTHOPEDICS = 'ORTHOPEDICS',
  OTORHINOLARYNGOLOGY = 'OTORHINOLARYNGOLOGY',
  Pathology = 'Pathology',
  PEDIATRICS = 'PEDIATRICS',
  PNEUMOLOGY = 'PNEUMOLOGY',
  PSYCHIATRY = 'PSYCHIATRY',
  RADIOLOGY = 'RADIOLOGY',
  RADIOTHERAPY = 'RADIOTHERAPY',
  RHEUMATOLOGY = 'RHEUMATOLOGY',
  UROLOGY = 'UROLOGY',
  OHTERS = 'OHTERS'
}

export default Specialization;
