import { AxiosResponse } from 'axios';
import { Favorite } from '../model/favorite';
import { HomeListItem } from '../model/HomeListItem';
import { api } from './api';

export const favoritesApi = () => {
    const favorite = (params: Favorite): Promise<AxiosResponse<any>> => {
        return api.post<any>('/favorites', params);
    };
    const getFavorites = (): Promise<AxiosResponse<HomeListItem[]>> => {
        return api.get<HomeListItem[]>('/favorites/user');
    };

    return {
        favorite,
        getFavorites
    };
};

export default favoritesApi();
