import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import MedicalScoreParamType from '../../../model/enums/medical-score-param-type';
import ScoreSpecificView from '../../../model/enums/score-specific-view';
import { MedicalScoreParam } from '../../../model/medical-score/medical-score-param';
import { useMedicalScoreContext } from '../medical-score.provider';
import '../medical-score.scss';
import MedicalScoreInputParam from './medical-score-input-param';
import { ParamContainer, ParamGroupTitle, ParamTitle } from './medical-score-params-styles';
import MedicalScorePickerParam from './medical-score-picker-param';
import MedicalScoreToggleParam from './medical-score-toggle-param';
import { MedicalScoreParamGroup } from '../../../model/medical-score/medical-score-param-group';

interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
}

const MedicalScoreParams = (props: Props) => {
    const { formulaParams, formulaParamGroups } = useMedicalScoreContext().medicalScore ?? { formulaParams: undefined };
    const { medicalScore } = useMedicalScoreContext();
    const [params, setParams] = useState<(MedicalScoreParam | MedicalScoreParamGroup)[]>([]);

    useEffect(() => {
        if (formulaParams != null || formulaParamGroups != null) {
            let newParams = [...formulaParams!.filter(it => it.medicalScoreParamGroup == null), ...formulaParamGroups!];
            newParams = _.sortBy(newParams, 'position');
            setParams(newParams);
        }
    }, [formulaParams, formulaParamGroups]);

    const renderParamContent = (param: MedicalScoreParam) => {
        switch (param.inputType) {
            case MedicalScoreParamType.INPUT:
            case MedicalScoreParamType.INPUT_INTEGER:
                return <MedicalScoreInputParam parameterInfo={param} />;
            case MedicalScoreParamType.MULTI_SELECT_TOGGLE_FIRST:
                return <MedicalScoreToggleParam parameterInfo={param} />;
            case MedicalScoreParamType.MULTI_SELECT:
            case MedicalScoreParamType.CHECKBOX:
                return <MedicalScorePickerParam isMulti={false} parameterInfo={param} />;
            case MedicalScoreParamType.MULTI_ANSWER_SELECT:
                return <MedicalScorePickerParam isMulti parameterInfo={param} />;
            default:
                return <div />;
        }
    };

    const renderSpecifcParams = () => {
        const params = medicalScore?.formulaParams ?? [];
        switch (medicalScore?.specificView) {
            case ScoreSpecificView.GLASGOW:
            default:
                return <div />;
        }
    };

    const renderParam = (param: MedicalScoreParam) => {
        return (
            <ParamContainer>
                <ParamTitle>{param.presentationName}</ParamTitle>
                {renderParamContent(param)}
            </ParamContainer>
        );
    };

    const renderParamGroup = (paramGroup: MedicalScoreParamGroup) => {
        return (
            <ParamContainer>
                <ParamGroupTitle>{paramGroup.title}</ParamGroupTitle>
                <div style={{ marginLeft: 10, width: '100%' }}>
                    {_.sortBy(paramGroup.formulaParams, 'position').map(param => renderParam(param))}
                </div>
            </ParamContainer>
        );
    };

    return medicalScore?.specificView === null ? (
        renderSpecifcParams()
    ) : (
        <div>
            {_.sortBy(params, 'position').map(param => (
                <div key={param.id!}>
                    {'formulaParams' in param ? renderParamGroup(param as MedicalScoreParamGroup) : renderParam(param as MedicalScoreParam)}
                </div>
            ))}
        </div>
    );
};

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(MedicalScoreParams) as React.ComponentType<any>;
