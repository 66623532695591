import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import FlashcardAnswerValue from '../../../../model/enums/flashcard-answer-value';
import '../../flashcard.scss';
import FlashcardPerformanceAnswers from '../../flashcard-statistic/flashcard-performance-answers';
import {
    BarContainer,
    BarPart,
    BarsContainer,
    ChartLabel,
    CountTipContainer,
    CountTipLabel,
    MainContainer
} from './flashcard-performance-bar-chart.styles';

interface Props extends WithTranslation {
    labels: string[];
    data: TowerData[];
}

export interface TowerData {
    items: FlashcardAnswerValue[];
}

const FlashcardPerformanceBarChart = (props: Props) => {
    const { data, labels } = props;
    const [towerSizes, setTowerSizes] = useState<number[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    useEffect(() => {
        const newTowerSizes: number[] = [];
        const newMaxTowerLength: number = data.map(it => it.items.length).reduce((a, b) => Math.max(a, b));
        data.forEach(it => {
            newTowerSizes.push((it.items.length / newMaxTowerLength) * 22);
        });
        setTowerSizes(newTowerSizes);
    }, [data]);

    const onSelectTower = (index: number) => {
        setSelectedIndex(selectedIndex !== index ? index : null);
    };

    const renderTower = (towerData: TowerData, index: number) => {
        const isSelected = selectedIndex === index;
        const color = selectedIndex === null || isSelected ? '#ef7d00' : '#E9ECED';
        const hasData = towerSizes[index] !== 0;
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                justifyContent: 'flex-end'
            }}
                 onClick={() => (hasData ? onSelectTower(index) : () => null)}>
                {isSelected && (
                    <CountTipContainer>
                        <CountTipLabel>{`${towerData.items.length} cards`}</CountTipLabel>
                    </CountTipContainer>
                )}
                <BarContainer height={towerSizes[index]} color={color}>
                    {isSelected && renderTowerData(towerData)}
                </BarContainer>
                <ChartLabel>{labels[index]}</ChartLabel>
            </div>
        );
    };

    const renderTowerData = (towerData: TowerData) => {
        const getDataHeightByAnswer = (answer: FlashcardAnswerValue) =>
            (towerData.items.filter(it => it === answer).length / towerData.items.length) * 100;
        const rightDataHeight = getDataHeightByAnswer(FlashcardAnswerValue.RIGHT);
        const almostDataHeight = getDataHeightByAnswer(FlashcardAnswerValue.ALMOST);
        const wrongDataHeight = getDataHeightByAnswer(FlashcardAnswerValue.WRONG);
        return (
            <>
                <BarPart color={'#2572B6'} height={rightDataHeight} borderPosition={'TOP'} />
                <BarPart
                    color={'#FFBE46'}
                    height={almostDataHeight}
                    borderPosition={rightDataHeight === 0 ? 'TOP' : wrongDataHeight === 0 ? 'BOTTOM' : 'MIDDLE'}
                />
                <BarPart color={'#e04444'} height={wrongDataHeight} borderPosition={'BOTTOM'} />
            </>
        );
    };

    return (
        <MainContainer>
            <BarsContainer>{data.map((it, index) => renderTower(it, index))}</BarsContainer>
            <div className={'lolol'}>
                {selectedIndex && <FlashcardPerformanceAnswers {...props} data={{
                    answers: data[selectedIndex ?? 0].items.map(it => {
                        return { answer: it };
                    })
                }} />}
            </div>
        </MainContainer>
    );
};

export default withTranslation()(FlashcardPerformanceBarChart);
