import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import MultipleCardList, { CardItem, CardList } from '../../components/multiple-card-list/multiple-card-list';
import { GroupsDiseases } from '../../model/apiResponses/GroupsDiseases';
import { Disease } from '../../model/disease';
import { Group } from '../../model/group';
import { getSpecialtyColor, SpecialtyType } from '../../model/SpecialtyType';
import { IRootState } from '../../reducer';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import DiseaseGroupsService from '../../services/diseaseGroupsService';
import { CardItemContent, default as SusCardItem } from '../specialties/specialty-card-item';
import '../specialties/specialty.scss';

export interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
    hasSubscription?: boolean;
    openPaidDiseaseModal: () => void;
}

const ToxicologyScreen = (props: Props): JSX.Element => {
    const [firstCardList, setFirstCardList] = useState<CardList | undefined>();
    const [isLoadingToxicology, setIsLoadingToxicology] = useState<boolean>(true);

    useEffect(() => {
        getGroupsByGroup({ id: 111, name: props.t('toxicology.title') }, getSpecialtyColor(SpecialtyType.PEDIATRICS)).then(cardList => {
            if (cardList != null) {
                setFirstCardList(cardList);
                setIsLoadingToxicology(false);
            }
        });
    }, []);

    const onDiseasePress = async (disease: Disease) => {
        const { hasSubscription } = props;
        console.log({disease})
        if (disease.free === true || hasSubscription) {
            props.history.push({
                pathname: `/disease/${disease.id}`,
                state: {
                    screenToBack: '/toxicology'
                }
            });
        } else {
            props.openPaidDiseaseModal();
        }
    };

    const getGroupsByGroup = async (group: Group, color: string): Promise<CardList | void> => {
        const result: GroupsDiseases = await DiseaseGroupsService.getGroupsDiseasesByGroup(group.id!);
        const title: string = group.name!;
        const cardList: CardList = {
            title,
            items: mapGroupsToCardItem(result, color),
            color
        };
        return cardList;
    };

    const mapGroupsToCardItem = (apiResponse: GroupsDiseases, color: string): CardItem[] => {
        const diseases: CardItem[] = apiResponse.diseases.map<CardItem>((item, index) => ({
            renderItem: () => <SusCardItem item={mapCardContent(item)} />,
            getChildren: () => onDiseasePress(item),
            name: item.name
        }));
        const groups: CardItem[] = apiResponse.groups.map<CardItem>((item, index) => ({
            renderItem: () => <SusCardItem item={mapCardContent(item)} />,
            getChildren: () => getGroupsByGroup(item, color),
            isSelected: item.selected === true,
            childrenAmount: item.amount,
            name: item.name
        }));
        const cardItems: CardItem[] = diseases.concat(_.orderBy(groups, ['name'], ['asc']));
        return cardItems;
    };

    const mapCardContent = (taxonomy: any): CardItemContent => {
        const { hasSubscription } = props;
        return {
            icon: taxonomy.groupPicture ? taxonomy.groupPicture.content : undefined,
            title: taxonomy.name,
            isPaid: taxonomy.free === false && !hasSubscription
        };
    };

    return (
        <div className="specialty-screen-container" style={{ position: 'relative' }}>
            <div style={{ alignItems: 'flex-start', position: 'relative', width: '100%', height: '100%' }}>
                {firstCardList != null && <MultipleCardList firstCardList={firstCardList} noCardExtension hasExtraFirstColumn />}
                {isLoadingToxicology && (
                    <div className="loading-overlay">
                        <div className="loading-img" />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account && authentication.account.customer ? authentication.account.customer.hasSubscription : undefined
});
const mapDispatchToProps = {
    openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ToxicologyScreen) as React.ComponentType<any>;
