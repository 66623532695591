import React, { useEffect, useState } from 'react';
import {
  OptionPointsContainer,
  OptionPointsText,
} from '../../../components/score-toggle/score-toggle-styles';
import { MedicalCriteriaParam } from '../../../model/medical-criteria/medical-criteria-param';
import { MedicalCriteriaParamOption } from '../../../model/medical-criteria/medical-criteria-param-option';
import { setNumberSign } from '../../../shared/util/utils';
import { useMedicalCriteriaContext } from '../medical-criteria.provider';
import {
  CheckboxContainer,
  OptionCheckboxLabel,
  OptionLabel,
} from './medical-criteria-params-styles';
import { Checkbox, withStyles } from '@material-ui/core';

interface Props {
  parameterInfo: MedicalCriteriaParam;
  showPoints?: boolean;
}

const CheckboxWeMEDS = withStyles({
  root: {
    '& .MuiSvgIcon-root': {
      fill: '#f1870b',
      '&:hover': {
        backgroundColor: '#f1870b',
      },
    },
    '&$checked': {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '& .MuiIconButton-label:after': {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: 'absolute',
        backgroundColor: 'white',
        zIndex: -1,
      },
    },
  },
  checked: {},
})(Checkbox);

const MedicalCriteriaCheckboxParam = (props: Props) => {
  const { parameterInfo, showPoints } = props;
  const [value, setValue] = useState<MedicalCriteriaParamOption | null>();
  const { paramValues, setParamValues } = useMedicalCriteriaContext();

  useEffect(() => {
    const newValues = { ...paramValues };
    newValues[parameterInfo.code!] = value;
    setParamValues(newValues);
  }, [value]);

  const onPressCheckbox = () => {
    console.log(value);
    setValue(value != null ? null : option);
  };

  const option =
    parameterInfo.options != null ? parameterInfo.options[0] : null;
  return (
    <>
      <CheckboxContainer onClick={onPressCheckbox}>
        <>
          {showPoints && option?.points !== 0 && (
            <OptionPointsContainer selected>
              <OptionPointsText selected>
                {option?.points != null ? setNumberSign(option?.points) : 0}
              </OptionPointsText>
            </OptionPointsContainer>
          )}
          <CheckboxWeMEDS style={{ padding: 0 }} checked={value != null} />
          <OptionCheckboxLabel>
            {option?.presentationName ?? ''}
            {option?.observation && (
              <OptionCheckboxLabel
              >{` (${option?.observation})`}</OptionCheckboxLabel>
            )}
          </OptionCheckboxLabel>
        </>
      </CheckboxContainer>
      {parameterInfo?.measurement && (
        <OptionLabel
        >{`${parameterInfo?.measurement}`}</OptionLabel>
      )}
    </>
  );
};

export default MedicalCriteriaCheckboxParam;
