import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import MultipleCardList, { CardItem, CardList } from '../../components/multiple-card-list/multiple-card-list';
import { GroupsDiseases } from '../../model/apiResponses/GroupsDiseases';
import { Disease } from '../../model/disease';
import { Group } from '../../model/group';
import { getSpecialtyColor, SpecialtyType } from '../../model/SpecialtyType';
import { IRootState } from '../../reducer';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import DiseaseGroupsService from '../../services/diseaseGroupsService';
import { CardItemContent, default as SpecialtyCardItem, default as SusCardItem } from './specialty-card-item';
import './specialty.scss';

export interface SpecialtyScreenProps extends RouteComponentProps<{}, {}, { diseaseId: number; category: SpecialtyType }> {
    t: any;
    hasSubscription?: boolean;
    openPaidDiseaseModal: () => void;
}

export interface SpecialtyScreenState {
    firstCardList?: CardList;
    selectedCategory?: SpecialtyType;
    isLoadingSpecialty?: boolean;
    allLists?: any;
}

export class SpecialtyScreen extends React.PureComponent<SpecialtyScreenProps, SpecialtyScreenState> {
    constructor(props: Readonly<SpecialtyScreenProps>, context?: any) {
        super(props, context);

        this.state = {};
    }

    // Component Life Cycle
    componentDidMount(): void {
        this.getSpecialties();
    }

    private getDiseaseTree = async (diseaseId: number, category: SpecialtyType): Promise<void> => {
        await DiseaseGroupsService.getDiseaseTree(diseaseId, category).then(tree => {
            const firstCard: CardList = {
                title: this.props.t('specialties.title'),
                items: this.mapSpecialties()
            };
            const allLists: CardList[] = tree.diseasesAndGroups.map((groupDiseases, index) => {
                const color = getSpecialtyColor(tree.category);
                let title: string = '';
                if (index > 0) {
                    title = tree.diseasesAndGroups[index - 1].groups?.filter(it => it.selected === true)[0]?.name ?? '';
                } else {
                    title = this.props.t(`specialties.${_.camelCase(tree.category)}`);
                }
                const result: CardList = { color, title, items: this.mapGroupsToCardItem(groupDiseases, color) };
                return result;
            });

            this.setState({
                allLists: [firstCard, ...allLists]
            });
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState !== this.state) {
            return true;
        }
        if (nextProps === this.props) {
            return false;
        }
        return true;
    }

    // Api Calls
    private onDiseasePress = async (disease: Disease) => {
        const { hasSubscription } = this.props;
        if (disease.free === true || hasSubscription) {
            this.props.history.push({
                pathname: `/disease/${disease.id}`,
                state: {
                    diseaseId: disease.id,
                    category: this.state.selectedCategory!
                }
            });
        } else {
            this.props.openPaidDiseaseModal();
        }
    };
    private getSpecialties = () => {
        const cardList: CardList = {
            title: this.props.t('specialties.title'),
            items: this.mapSpecialties()
        };
        this.setState(
            {
                firstCardList: cardList
            },
            () => {
                const diseaseId: number = this.props.location?.state?.diseaseId;
                const category: SpecialtyType = this.props.location?.state?.category;

                if (diseaseId != null && category != null) {
                    this.setState({ selectedCategory: category });
                    this.getDiseaseTree(diseaseId, category);
                }
            }
        );
    };
    private getGroupsBySpecialty = async (specialtyType: SpecialtyType, color: string): Promise<CardList | void> => {
        this.setState({ isLoadingSpecialty: true, selectedCategory: specialtyType }, () => {
            setTimeout(() => this.setState({ isLoadingSpecialty: false }), 30000);
        });
        const result: GroupsDiseases = await DiseaseGroupsService.getGroupsDiseasesBySpecialty(specialtyType);
        const title: string = this.props.t(`specialties.${_.camelCase(specialtyType)}`);
        const cardList: CardList = {
            title,
            items: this.mapGroupsToCardItem(result, color),
            color
        };
        this.setState({ isLoadingSpecialty: false });
        return cardList;
    };
    private getGroupsByGroup = async (group: Group, color: string): Promise<CardList | void> => {
        const result: GroupsDiseases = await DiseaseGroupsService.getGroupsDiseasesByGroup(group.id!);
        const title: string = group.name!;
        return {
            title,
            items: this.mapGroupsToCardItem(result, color),
            color
        };
    };

    private mapSpecialties = (): CardItem[] => [
        {
            renderItem: () => <SpecialtyCardItem isSpecialty specialtyImage={require('../../images/card-cirurgia.svg')} />,
            getChildren: () => this.getGroupsBySpecialty(SpecialtyType.SURGERY, '#ef7d00')
        },
        {
            renderItem: () => <SpecialtyCardItem isSpecialty specialtyImage={require('../../images/card-clinica.svg')} />,
            getChildren: () => this.getGroupsBySpecialty(SpecialtyType.CLINIC, '#f59e24')
        },
        {
            renderItem: () => <SpecialtyCardItem isSpecialty specialtyImage={require('../../images/card-ginecologia.svg')} />,
            getChildren: () => this.getGroupsBySpecialty(SpecialtyType.GYNECOLOGIC, '#f5bc14')
        },
        {
            renderItem: () => <SpecialtyCardItem isSpecialty specialtyImage={require('../../images/card-obstetricia.svg')} />,
            getChildren: () => this.getGroupsBySpecialty(SpecialtyType.OBSTETRICS, '#b4af8d')
        },
        {
            renderItem: () => <SpecialtyCardItem isSpecialty specialtyImage={require('../../images/card-odontologia.svg')} />,
            getChildren: () => this.getGroupsBySpecialty(SpecialtyType.DENTISTRY, '#918e7a')
        },
        {
            renderItem: () => <SpecialtyCardItem isSpecialty specialtyImage={require('../../images/card-pediatria.svg')} />,
            getChildren: () => this.getGroupsBySpecialty(SpecialtyType.PEDIATRICS, '#4e9a98')
        },
        {
            renderItem: () => <SpecialtyCardItem isSpecialty specialtyImage={require('../../images/card-preventiva.svg')} />,
            getChildren: () => this.getGroupsBySpecialty(SpecialtyType.PREVENTIVE, '#1a3c47')
        }
    ];

    private mapGroupsToCardItem = (apiResponse: GroupsDiseases, color: string): CardItem[] => {
        const groups: CardItem[] = apiResponse.groups.map<CardItem>((item, index) => ({
            renderItem: () => <SusCardItem item={this.mapCardContent(item)} />,
            getChildren: () => this.getGroupsByGroup(item, color),
            isSelected: item.selected === true,
            childrenAmount: item.amount,
            name: item.name
        }));

        const diseases: CardItem[] = apiResponse.diseases.map<CardItem>((item, index) => ({
            renderItem: () => <SusCardItem item={this.mapCardContent(item)} />,
            getChildren: () => this.onDiseasePress(item),
            name: item.name
        }));

        const cardItems: CardItem[] = _.orderBy(groups.concat(diseases), ['name'], ['asc']);
        return cardItems;
    };
    private mapCardContent = (taxonomy: any): CardItemContent => {
        const { hasSubscription } = this.props;
        return {
            icon: taxonomy.groupPicture ? taxonomy.groupPicture.content : undefined,
            title: taxonomy.name,
            isPaid: taxonomy.free === false && !hasSubscription
        };
    };

    // Renders
    render() {
        const { firstCardList, isLoadingSpecialty, allLists } = this.state;

        if (firstCardList == null) {
            return <div />;
        }
        return (
            <div className="specialty-screen-container" style={{ position: 'relative' }}>
                <div style={{ alignItems: 'flex-start', position: 'relative', width: '100%', height: '100%' }}>
                    <MultipleCardList firstCardList={firstCardList} noCardExtension hasExtraFirstColumn allLists={allLists} />
                    {isLoadingSpecialty && (
                        <div className="loading-overlay">
                            <div className="loading-img" />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account && authentication.account.customer ? authentication.account.customer.hasSubscription : undefined
});
const mapDispatchToProps = {
    openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SpecialtyScreen) as React.ComponentType<any>;
