import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { closePaidFlashcardModal } from '../../reducer/globalModal/actions';
import Button from '../button/button';
import './paid-flashcard-modal.scss';

export interface Props {
    t: (path: string, params?: any) => string;
    history: any;

    open: boolean;
    closeModal: () => void;
}

const PaidFlashcardModal = (props: Props) => {
    const { open } = props;

    const onClose = () => {
        props.closeModal();
    };

    const onButtonPressDiseases = () => {
        props.closeModal();
        props.history.push('/flashcard-random');
    };

    const onBannerPress = () => {
        props.closeModal();
        props.history.push('/subscription');
    };

    if (!open) {
        return <div />;
    }

    return (
        <div className="modal">
            <div className="modal__full-content">
                <a className="close-btn" onClick={onClose}>
                    <div className="close-btn__icon" />
                </a>
                <div className="modal__content">
                    <div className="top-content">
                        <div className="wemeds-logo-img" />
                        <span className="top-content__title">{props.t('paidFlashcardModal.title')}</span>
                        <span className="top-content__subtitle">{props.t('paidFlashcardModal.subtitle')}</span>
                    </div>
                    <div className="middle-banner">
                        <a className="middle-banner__img" onClick={onBannerPress} />
                    </div>
                    <div className="bottom-content">
                            <span className="bottom-content__text-container">
                                <span
                                    className="bottom-content__text">{props.t('paidFlashcardModal.bottomDescription1')}</span>
                                <span className="bottom-content__text bottom-content__text--bold">
                                    {props.t('paidFlashcardModal.bottomDescription2')}
                                </span>
                                <span
                                    className="bottom-content__text">{props.t('paidFlashcardModal.bottomDescription3')}</span>
                            </span>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: 30 }}>
                                <div className="bottom-content__btn-container">
                                    <Button
                                        label={props.t('paidFlashcardModal.buttonLabel').toUpperCase()}
                                        onPress={onButtonPressDiseases}
                                        labelStyle={{ fontSize: 12 }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    closeModal: closePaidFlashcardModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(PaidFlashcardModal) as React.ComponentType<any>;
