import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../../../components/loading/loading';
import { FlashcardView } from '../../../../model/flashcard/flashcard';
import { PerformanceData } from '../../../../model/flashcard/performance-data';
import MyFlashcardService from '../../../../services/my-flashcard.service';
import { getDateNowTimestamp } from '../../../../shared/util/utils';
import FlashcardHeader from '../../components/flashcard-header';
import FlashcardToolBar from '../../components/flashcard-tool-bar';
import FlashcardCarousel from '../../flashcard-carousel/flashcard-carousel';
import { useFlashcardViewContext } from '../../flashcard-carousel/flashcard.provider';
import FlashcardList from '../../flashcard-list/flashcard-list';
import FlashcardPerformance from '../../flashcard-statistic/flashcard-performance';
import { Container, MainContainer } from '../../flashcard-styles';
import '../../flashcard.scss';


interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
    hasSubscription?: boolean;
    openPaidModal: () => void;
}

const MyFlashcard = (props: Props) => {
    const { setCards, cards, startTimestamp, fontSize, updateCard } = useFlashcardViewContext();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFinished, setIsFinished] = useState<boolean>(false);
    const [performanceData, setPerformanceData] = useState<PerformanceData>({ answers: [] });
    const [viewMode, setViewMode] = useState<'LIST' | 'PERFORMANCE' | 'CARD'>('CARD');

    useEffect(() => {
        MyFlashcardService.getMyFlashcards().then(response => {
            const newCards = response.sort((a, b) => a.id! - b.id!);
            setCards(newCards as FlashcardView[]);
        }).finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        const allCardsAnswered = cards.filter(it => it.answer != null).length === cards.length;
        if (allCardsAnswered && cards.length > 0 && !isLoading) {
            finishReview();
        }
    }, [cards]);

    const onBack = () => {
        props.history.goBack();
    };

    const updatedPerformanceData = () => {
        const secondsSpent = (getDateNowTimestamp() - startTimestamp) / 1000;
        const newAnswers = cards
            .filter(it => it.answer != null)
            .map(it => {
                return { flashcardId: it.id, answer: it.answer };
            });
        const answeredQuestions = newAnswers.length;
        const remainingQuestions = cards.length - answeredQuestions;
        const result: PerformanceData = {
            secondsSpent,
            remainingQuestions,
            answeredQuestions,
            answers: newAnswers
        };
        setPerformanceData(result);
    };

    const goToPerformanceScreen = () => {
        updatedPerformanceData();
        setViewMode('PERFORMANCE');
    };

    const finishReview = () => {
        updatedPerformanceData();
        setIsFinished(true);
        setViewMode('PERFORMANCE');
    };

    const onPressBack = (viewMode === 'PERFORMANCE' || viewMode === 'LIST') && !isFinished ? () => setViewMode('CARD') : () => onBack();
    return isLoading ? (
        <div className="disease-screen-container">
            <Loading />
        </div>
    ) : (
        <MainContainer>
            <Container>
                <FlashcardHeader
                    {...props}
                    title={props.t('myFlashcards.subtitle')}
                    subtitle={props.t('myFlashcards.title')}
                    viewMode={viewMode}
                    onPressBack={onPressBack}
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {viewMode !== 'PERFORMANCE' && <FlashcardToolBar
                        t={props.t}
                        history={props.history}
                        location={props.location}
                        match={props.match}
                        viewMode={viewMode}
                        hideFavoriteButton
                        onPressConsultButton={() => setViewMode(viewMode === 'LIST' ? 'CARD' : 'LIST')}
                        onPressFinishButton={() => finishReview()}
                    />}
                    {viewMode === 'CARD' &&
                        <FlashcardCarousel {...props}
                                           fontSize={fontSize}
                                           onPressPerformanceButton={() => goToPerformanceScreen()} />}
                    {viewMode === 'LIST' && <FlashcardList {...props} fontSize={fontSize} />}
                    {viewMode === 'PERFORMANCE' &&
                        <FlashcardPerformance {...props} data={performanceData} />}

                </div>
            </Container>
        </MainContainer>
    );
};

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(MyFlashcard) as React.ComponentType<any>;
