import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Collapse from '../../../components/collapse/collapse';
import { IRootState } from '../../../reducer';
import './components.scss';

export interface FaqProps extends RouteComponentProps<{}> {
    t: (path: string, param?: any) => string;
    history: any;
}
interface FaqQuestion {
    title: string;
    content: string;
}
const faqQuestions: FaqQuestion[] = [
    {
        title: 'Qual o principal objetivo do WeMEDS?',
        content: 'A plataforma WeMEDS busca romper o paradigma da medicina maçante e inacessível, imposta por catedráticos, que defendem que a medicina para ser eficaz deve ser sacrificante. Ao nosso ver, a medicina deve ser leve, direta e eficaz – tendo no paciente o pilar fundamental de todo o processo. Partindo desses princípios, objetivou-se trazer as principais condições médicas para um âmbito extremamente didático e de fácil acessibilidade; desmistificando diagnósticos e tratamentos tidos como complexos.'
    },
    {
        title: 'Para quem se destina a plataforma WeMEDS?',
        content: 'O WeMEDS foi formulado com objetivo de atender principalmente médicos e estudantes de medicina, uma vez que sabemos da carência na formação acadêmica atual, com professores que estão cada vez mais preocupados com o ego, do que transmitir conhecimento com qualidade. A plataforma possui assim linguagem intencionalmente didática, que prioriza, além da simplicidade e da precisão, a capacidade de entendimento rápido e preciso do leitor, seja ele estudante, seja ele profissional médico. De toda forma, outros profissionais da saúde também podem utilizar a plataforma de maneira que amplie seus conhecimentos.'
    },
    {
        title: 'O que é, e qual o objetivo do "DiagnApp"',
        content: 'O DiagnApp é uma ferramenta auxiliar no processo de dúvidas diagnósticas. Tem como base um sistema de inteligência artificial, que objetiva oferecer diagnósticos diferenciais importantes a serem pensados a partir de sinais e sintomas conjugados. É uma verdadeira mão na roda para não deixar passar nenhum diagnóstico em branco.'
    },
    {
        title: 'O que é Smartbulas?',
        content: 'A funcionalidade Smartbulas, como o nome sugere, é um bulário inteligente. Baseada na melhor literatura farmacológica mundial, cada bula foi feita de forma individual e manual, seguindo os rigorosos padrões de qualidade inerentes ao WeMEDS. Isso para prover ao usuário informações rápidas e diretas de medicamentos, como a posologia, diluições, mecanismo de ação, uso na gestação/lactação e até mesmo o número de comprimidos em cada caixa. Simplesmente não há nada igual.'
    },
    {
        title: 'Para que serve as “Interações Medicamentosas”?',
        content: 'Interações medicamentosas são riscos potenciais que estamos expostos diariamente. Na carência de uma ferramenta otimizada em língua portuguesa, o WeMEDS construiu o banco de dados mais robusto de interações medicamentosas existente. Não só isso, coloca isso de forma extremamente prática: basta selecionar os medicamentos de uma lista e automaticamente o sistema avalia possíveis interações entre eles - e ainda sugere as condutas a serem tomadas.'
    },
    {
        title: 'Código CID e Procedimento SUS?',
        content: 'Sabemos como o seu tempo é valioso e infelizmente a burocracia acaba por nos desfocar do que realmente importa. Partindo disso, otimizamos a busca e a estruturação do código CID e procedimentos SUS, para que acesse rapidamente e economize tempo no preenchimento das diversas guias existentes.'
    },
    {
        title: 'Qual a equipe por trás do WeMEDS?',
        content: 'Os idealizadores/desenvolvedores do aplicativo são principalmente médicos, formados e pós graduados pelas principais escolas médicas do país – como USP, UNICAMP e UFPR. Sabem, portanto, das principais dificuldades e anseios com que se defrontam os estudantes e profissionais médicos do Brasil e, tendo em mente tudo isso, buscaram a excelência na confecção de uma ferramenta ágil, precisa e dinâmica.'
    },
    {
        title: 'Quais as referências bibliográficas?',
        content: 'A equipe do WeMEDS busca utilizar as mais atuais e melhores referências mundiais para escrever o conteúdo didático presente na plataforma: artigos, revisões e livros-textos. Em cada condição, as referências são devidamente colocadas como um tópico à parte. Nosso lema é a medicina baseada em evidências. Nada de água com limão.'
    },
    {
        title: 'Por que o WeMEDS tem um custo baixo apesar da excelente qualidade?',
        content: 'Acreditamos que ao prover um preço justo, podemos atingir um maior número de médicos e estudantes de medicina. A democratização do conhecimento de forma didática só tem a acrescentar na melhora do sistema de saúde brasileiro.'
    },
    {
        title: 'Qual o investimento para ter acesso à plataforma WeMEDS?',
        content: 'O preço base do WeMEDS é de R$419,80 por ano, porém promocionalmente optamos pela redução de 30% desse valor, ficando em apenas R$293,88 - podendo ser parcelado em até 12 vezes sem juros, o que se reflete em um valor inferior a 25 reais ao mês.  A assinatura é recorrente, e caso o usuário queira cancelar a recorrência, basta acessar o menu ao lado “minha assinatura” e clicar em cancelar. Nessa situação, o acesso ficará liberado até o fim do período contratado.'
    },
    {
        title: 'O que é a garantia incondicional WeMEDS?',
        content: 'Confiamos muito no WeMEDS. Por isso, caso o usuário queira cancelar a assinatura nos primeiros 7 dias, devolvemos integralmente o pagamento, sem questionamento.'
    },
    {
        title: 'Em quantos sistemas (smartphone, tablet, web) cada login pode ser usado?',
        content: 'Cada login é intransferível e deve ser usado apenas por um usuário. Assim, com cada login é possível acessar um smartphone, um tablet e a web simultaneamente. Dessa maneira, não é possível acessar dois smartphones distintos com o mesmo login - o mesmo valendo para tablet e versão web. Mas o porquê disso? Já deixamos um preço acessível, e caso o compartilhamento fosse possível, a plataforma perderia qualidade por ficar com menos recursos para investir em conteúdo e inovação.'
    },
    {
        title: 'As versões para smartphone e tablet funcionam offline?',
        content: 'Sim. Sabemos da dificuldade de acesso de internet em alguns locais e situações, assim todo o conteúdo da plataforma WeMEDS pode ser acessado de forma offline nas versões de smartphone e tablet. Os aplicativos estão disponíveis nas lojas da AppStore (sistema IOS) e GooglePlay (sistema Android), basta buscar por WeMEDS. As atualizações são realizadas de forma automática quando o sistema detecta conexão com a internet.'
    }
]
export class Faq extends React.Component<FaqProps> {
    constructor(props: Readonly<FaqProps>, context?: any) {
        super(props, context);

        this.state = { };
    }
    // Renders
    render() {
        return (
            <div className="component">
                <span className="component__title">{this.props.t('preferences.faqComponent.title')}</span>
                {faqQuestions.map((question, index) => (
                    <Collapse key={index} {...question} titleStyle={{ fontWeight: 'normal' }}/>
                ))}
            </div>
        );
    }
}

const mapStateToProps = ({ search }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Faq) as React.ComponentType<any>;
