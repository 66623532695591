import styled from 'styled-components';

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: relative;
    width: 50vw;
    height: 60vh;
    margin-left: 40px;
    padding: 24px 32px 32px 32px;
    background-color: #ffffff;
    border: 1.2px solid #e9eced;
    border-radius: 8px;
`;

export const CardBottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%
    gap: 20px;
`;

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 24px;
    margin: 0 auto;
`;

export const CardSeparator = styled.div<{marginVertical?: number}>`
    width: 100%;
    margin-top: ${props => props.marginVertical ?? 0}px;
    margin-bottom: ${props => props.marginVertical ?? 0}px;
    height: 2px;
    background-color: #f59e24;
`;

export const PaginationTextIndex = styled.div`
    flex-shrink: 0;
    flex-basis: auto;
    width: 7px;
    height: 17px;
    color: #404e52;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
`;

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
`;

export const MarkdownContainer = styled.div`
    margin-left: 10%;
    margin-right: 10%;
`;

export const AnswersContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 18px;
    padding: 0 18px 0 18px;
`;

export const SeeAnswerButton = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    width: 20vw;
    padding: 12px 16px 12px 16px;
    cursor: pointer;
    background: linear-gradient(90deg, #f56f3d, #f0870b);
    border: none;
    border-radius: 28px;
`;

export const IconsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    width: 96px;
`;

export const SeeAnswerText = styled.span`
    color: #ffffff;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    font-family: Raleway;
`;

export const SeeContentText = styled.span`
    flex-shrink: 0;
    color: #2472b5;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
`;

export const SeeContentContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 6px;
    position: relative;
    width: 146px;
    height: 36px;
    padding: 8px 12px 8px 12px;
    cursor: pointer;
    background: rgba(36, 114, 181, 0.15);
    border: none;
    z-index: 1;
    overflow: hidden;
    border-radius: 80px;
`;

export const ArrowUpRightIcon = styled.div`
    width: 18px;
    height: 18px;
    background-size: cover;
`;

export const ChartLineIcon = styled.div`
    width: 32px;
    height: 32px;
    background-size: cover;
    cursor: pointer;
`;

export const MathPlusIcon = styled.div`
    width: 48px;
    height: 48px;
    background-size: cover;
    cursor: pointer;
`;
