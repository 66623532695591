import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import Loading from '../../../../components/loading/loading';
import { FlashcardCustomer } from '../../../../model/flashcard/flashcard-customer';
import { PerformanceData } from '../../../../model/flashcard/performance-data';
import FlashcardCustomerService from '../../../../services/flashcard-customer.service';
import FlashcardPerformanceBarChart, {
    TowerData
} from '../../components/flashcard-performance-bar-chart/flashcard-performance-bar-chart';
import FlashcardPerformanceAnswers from '../../flashcard-statistic/flashcard-performance-answers';
import FlashcardPerformanceChart from '../../flashcard-statistic/flashcard-performance-chart';
import FlashcardPerformanceData from '../../flashcard-statistic/flashcard-performance-data';
import {
    ArrowBack,
    CardContainer,
    Container,
    ContentPartContainer,
    HeaderContainer,
    HeaderText,
    MainContainer,
    TabHeaderContainer,
    TabHeaderTitle,
    TabLineHeader,
    TabsHeaderContainer
} from './flashcard-dashboard-statistic-styles';
import './flashcard-dashboard-statistic.scss';

interface Props extends RouteComponentProps<{}, {}, {}>, WithTranslation {
}

interface TabData {
    today: PerformanceData;
    week: PerformanceData;
    ever: PerformanceData;
}

const FlashcardDashboardStatistic = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [tabRoutes, setTabRoutes] = useState<any>({
        index: 0,
        routes: [
            { key: 'today', title: props.t(['flashcardDashboardStatistic', 'today']) },
            { key: 'week', title: props.t(['flashcardDashboardStatistic', 'week']) },
            { key: 'ever', title: props.t(['flashcardDashboardStatistic', 'ever']) }
        ]
    });
    const [performanceData, setPerformanceData] = useState<TabData>({
        today: { answers: [] },
        week: { answers: [] },
        ever: { answers: [] }
    });

    useEffect(() => {
        setInitialData().finally(() => setIsLoading(false));
    }, []);

    const setInitialData = async () => {
        const getSumOfTimeSpent = (cards: FlashcardCustomer[]): number =>
            cards
                .filter(it => it.timeSpent != null)
                .map(it => it.timeSpent)
                .reduce((a, b) => a! + b!, 0) ?? 0;
        const allFlashcardCustomers = await FlashcardCustomerService.getFlashcardCustomers();
        const todayFlashcards = await getPerformanceDataByDate(allFlashcardCustomers, moment().utc());
        const weekFlashcards = await getPerformanceDataByDate(allFlashcardCustomers,moment().subtract(7, 'days').utc(), moment().utc());
        const everFlashcards = await getPerformanceDataByDate(allFlashcardCustomers,undefined, moment().utc());
        const answersToday = getMappedAnswers(todayFlashcards);
        const answersWeek = getMappedAnswers(weekFlashcards);
        const answersEver = getMappedAnswers(everFlashcards);
        setPerformanceData({
            today: {
                answers: answersToday,
                answeredQuestions: answersToday.length,
                secondsSpent: Math.round(getSumOfTimeSpent(todayFlashcards))
            },
            week: {
                answers: answersWeek,
                answeredQuestions: answersWeek.length,
                secondsSpent: Math.round(getSumOfTimeSpent(weekFlashcards))
            },
            ever: {
                answers: answersEver,
                answeredQuestions: answersEver.length,
                secondsSpent: Math.round(getSumOfTimeSpent(everFlashcards))
            }
        });
    };

    const getMappedAnswers = (answers: FlashcardCustomer[]) => {
        const result: any[] = [];
        answers.forEach(it => {
            for (let i = 0; i < (it.timesAnswered ?? 1); i++) {
                result.push({
                    flashcardId: it.flashcardId,
                    answer: it.answer,
                    lastModifiedDate: moment(it.lastModifiedDate!)
                });
            }
        });
        return result;
    };

    const getPerformanceDataByDate = async (allFlashcardCustomers: FlashcardCustomer[], startDate?: Moment, endDate?: Moment) => {
        let formatedStartDate = startDate ?? moment().subtract(10, 'years');
        let formatedEndDate = endDate ?? moment().add(10, 'years');
        formatedStartDate = formatedStartDate.startOf('day');
        formatedEndDate = formatedEndDate.endOf('day');
        return allFlashcardCustomers.filter(it => moment(it.lastModifiedDate!).isBetween(formatedStartDate, formatedEndDate));
    };

    const renderHeaderTab = (index: number, title: string) => {
        const isActive = tabRoutes.index === index;
        return (
            <TabHeaderContainer onClick={() => setTabRoutes({ ...tabRoutes, index })}>
                <TabHeaderTitle isActive={isActive}>{props.t(`flashcardDashboardStatistic.${title}`)}</TabHeaderTitle>
                <TabLineHeader isActive={isActive} />
            </TabHeaderContainer>
        );
    };

    const renderHeadersTab = () => {
        return (
            <TabsHeaderContainer>
                {renderHeaderTab(0, 'today')}
                {renderHeaderTab(1, 'week')}
                {renderHeaderTab(2, 'ever')}
            </TabsHeaderContainer>
        );
    };

    const renderToday = () => {
        return (
            <>
                <ContentPartContainer>
                    <FlashcardPerformanceChart {...props} height={250} width={250} data={performanceData.today} />
                    <FlashcardPerformanceAnswers {...props} data={performanceData.today} />
                </ContentPartContainer>
                <ContentPartContainer>
                    <FlashcardPerformanceData {...props} data={performanceData.today} width={100} />
                </ContentPartContainer>
            </>
        );
    };

    const renderWeek = () => {
        const getAnswersByDayWeek = (weekDay: number) =>
            performanceData.week.answers.filter(it => moment(it.lastModifiedDate!).weekday() === weekDay).map(it => it.answer!);
        const barData: TowerData[] = [
            {items: getAnswersByDayWeek(1)},
            {items: getAnswersByDayWeek(2)},
            {items: getAnswersByDayWeek(3)},
            {items: getAnswersByDayWeek(4)},
            {items: getAnswersByDayWeek(5)},
            {items: getAnswersByDayWeek(6)},
            {items: getAnswersByDayWeek(0)},
        ];
        return (
            <>
                <ContentPartContainer>
                    <FlashcardPerformanceBarChart {...props}
                                                  labels={['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom']}
                                                  data={barData} />
                </ContentPartContainer>
                <ContentPartContainer>
                    <FlashcardPerformanceData {...props} data={performanceData.week} width={100} />
                </ContentPartContainer>
            </>
        );
    };

    const renderMonth = () => {
        const getAnswersByMonth = (month: number) =>
            performanceData.ever.answers.filter(it => moment(it.lastModifiedDate!).month() === month).map(it => it.answer!);
        const barData: TowerData[] = [
            { items: getAnswersByMonth(0) },
            { items: getAnswersByMonth(1) },
            { items: getAnswersByMonth(2) },
            { items: getAnswersByMonth(3) },
            { items: getAnswersByMonth(4) },
            { items: getAnswersByMonth(5) },
            { items: getAnswersByMonth(6) },
            { items: getAnswersByMonth(7) },
            { items: getAnswersByMonth(8) },
            { items: getAnswersByMonth(9) },
            { items: getAnswersByMonth(10) },
            { items: getAnswersByMonth(11) }
        ];
        return (
            <>
                <ContentPartContainer>
                    <FlashcardPerformanceBarChart {...props}
                                                  labels={['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']}
                                                  data={barData} />
                </ContentPartContainer>
                <ContentPartContainer>
                    <FlashcardPerformanceData {...props} data={performanceData.ever} width={100} />
                </ContentPartContainer>
            </>
        );
    };

    return isLoading ? (
        <div className="disease-screen-container">
            <Loading />
        </div>
    ) : (
        <MainContainer>
            <Container>
                <HeaderContainer>
                    <ArrowBack className={'black-caret-left-img'} onClick={() => props.history.goBack()} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <HeaderText>{props.t('flashcardDashboard.title')}</HeaderText>
                    </div>
                </HeaderContainer>
                <CardContainer>
                    {renderHeadersTab()}
                    {tabRoutes.index === 0 && renderToday()}
                    {tabRoutes.index === 1 && renderWeek()}
                    {tabRoutes.index === 2 && renderMonth()}
                </CardContainer>
            </Container>
        </MainContainer>
    );
};

export default withTranslation()(FlashcardDashboardStatistic);
