import React, { useState } from 'react';
import { HeaderContainer, HeaderItem, HeaderItemTitle, TabsContainer } from './tabs-styles';

interface Props {
    tabs: TabHeaderItem[];
    children: JSX.Element[] | JSX.Element;
}

export interface TabHeaderItem {
    index: number;
    title: string;
}

const TabHeader = (props: Props) => {
    const { tabs, children } = props;
    const [activeTabIndex, setActiveTabIndex] = useState<number>(props.tabs[0].index);

    const renderHeaderItem = (tab: TabHeaderItem) => {
        const isActive = tab.index === activeTabIndex;
        return (
            <HeaderItem key={tab.index} isActive={isActive} onClick={() => setActiveTabIndex(tab.index)}>
                <HeaderItemTitle isActive={isActive}>{tab.title}</HeaderItemTitle>
            </HeaderItem>
        );
    };

    return (
        <>
            <HeaderContainer>{tabs.map(tab => renderHeaderItem(tab))}</HeaderContainer>
            <TabsContainer>
                {(children as JSX.Element[]).map((child, index) => (
                    <div style={{ display: activeTabIndex === index ? 'flex' : 'none' }}>{child}</div>
                ))}
            </TabsContainer>
        </>
    );
};

export default TabHeader;
