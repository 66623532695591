import { AxiosResponse } from 'axios';
import laboratoryApi from '../api/laboratoryApi';
import HttpStatus from '../model/enums/httpStatus';
import { PageableResponse } from '../model/pageable';
import { Laboratory } from './../model/laboratory/laboratory';
import { LaboratoryCategory, LaboratoryGroup } from './../model/laboratory/laboratory-group';

const LaboratoryService = () => {
    const getLaboratory = async (id: number): Promise<Laboratory> => {
        try {
            const result: AxiosResponse<Laboratory> = await laboratoryApi.getLaboratory(id);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const getByCategory = async (category: LaboratoryCategory): Promise<LaboratoryGroup[]> => {
        try {
            const result: AxiosResponse<LaboratoryGroup[]> = await laboratoryApi.getByCategory(category);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const getGroupByParentId = async (id: number): Promise<LaboratoryGroup> => {
        try {
            const result: AxiosResponse<LaboratoryGroup> = await laboratoryApi.getGroupByParentId(id);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const getAvailableForFree = async (): Promise<Laboratory[]> => {
        try {
            const result: AxiosResponse<PageableResponse<Laboratory>> = await laboratoryApi.getAvailableForFree();
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data.content);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    return {
        getAvailableForFree,
        getGroupByParentId,
        getLaboratory,
        getByCategory
    };
};

export default LaboratoryService();
