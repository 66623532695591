import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { Disease } from '../../../model/disease';
import { IRootState } from '../../../reducer';
import DiseaseService from '../../../services/diseaseService';
import './components.scss';

export interface FreeDiseasesProps extends RouteComponentProps<{}> {
    t: (path: string, param?: any) => string;
    history: any;
}

export interface FreeDiseasesState {
    diseases?: Disease[];
}
export class FreeDiseases extends React.Component<FreeDiseasesProps, FreeDiseasesState> {
    constructor(props: Readonly<FreeDiseasesProps>, context?: any) {
        super(props, context);

        this.state = {};
    }
    // Component Life Cycle
    async componentDidMount(): Promise<void> {
        const diseases: Disease[] = await DiseaseService.getFreeDiseases();
        this.setState({
            diseases
        });
    }

    // Renders
    render() {
        const { diseases } = this.state;
        if (diseases == null || diseases.length === 0) {
            return <div />;
        }
        return (
            <div className="component">
                <h1 className="component__title">{this.props.t('preferences.freeDiseasesComponent.title')}</h1>
                <div className="component__content">
                    {diseases.map((it, index) => (
                        <a key={index} className="component__card-item" href={`/disease/${it.id}`}>
                            <div className="component__card-item__content">
                                <div className="component__card-item__content__row">
                                    <span className="component__card-item__label">{it.name}</span>
                                    <div className="arrow-right-img" />
                                </div>
                            </div>
                            <div className="component__card-item__extension"/>
                        </a>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(FreeDiseases) as React.ComponentType<any>;
