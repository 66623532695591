import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { ConversionViews } from '../../model/enums/calculator-specific-view';
import MedicalCalculatorParamType from '../../model/enums/medical-calculator-param-type';
import { FormulaResult } from '../../model/medical-calculator/formula-result';
import { ResultType } from '../../model/medical-calculator/result-type';
import { createFunctionByString } from '../../shared/util/markdown-utils';
import {
    CalculatorResultContainer,
    MainContainer,
    ResultContainer,
    ResultText,
    ResultTypeContainer,
    ResultTypeText,
    Title
} from './medical-calculator-styles';
import { useMedicalCalculatorContext } from './medical-calculator.provider';
import './medical-calculator.scss';

interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
}

const MedicalCalculatorResult = (props: Props) => {
    const { result, setResult, paramValues, medicalCalculator } = useMedicalCalculatorContext();

    useEffect(() => {
        if (medicalCalculator !== undefined) {
            const allParamsFilled = Object.values(paramValues).every(x => x !== undefined);

            if (allParamsFilled) {
                try {
                    const params = _.sortBy(medicalCalculator.formulaParams, 'position').map(it => {
                        // tslint:disable-next-line: switch-default
                        switch (it.inputType) {
                            case MedicalCalculatorParamType.INPUT:
                                return Number(_.replace(paramValues[it.code!] as string, ',', '.'));
                            case MedicalCalculatorParamType.MULTI_SELECT:
                                return `'${paramValues[it.code!]!}'`;
                            case MedicalCalculatorParamType.TOGGLE:
                                return `'${paramValues[it.code!]!}'`;
                            case MedicalCalculatorParamType.DATE:
                                return `'${moment(paramValues[it.code!]!)
                                    .startOf('day')
                                    .format('YYYY-MM-DD')}'`;
                        }
                        return paramValues[it.code!];
                    });
                    console.log({params});
                    let newResult = createFunctionByString(`var run1 = ${medicalCalculator.formula!}; run1(${params});`);
                    newResult = addConversionValueIfHas(newResult);
                    setResult(newResult);
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }, [paramValues]);

    const addConversionValueIfHas = (newResult: FormulaResult) => {
        const currentView = medicalCalculator?.specificView;
        const params = _.sortBy(medicalCalculator?.formulaParams, 'position');
        if (ConversionViews.some(it => it === currentView)) {
            const resultConversionName =
                params?.[2].options?.find(it => it.code === paramValues[params?.[2].code!])?.presentationName ?? '';
            newResult.resultText = `${newResult.resultText} de ${resultConversionName}`;
        }
        return newResult;
    };

    return (
        <MainContainer>
            {result.resultText !== undefined ? (
                <CalculatorResultContainer>
                    <Title>{props.t(['medicalCalculator.result'])}</Title>
                    <ResultContainer type={result.resultType?.type ?? ResultType.NONE}>
                        <ResultText>{result.resultText}</ResultText>
                        {result.resultType != null && result.resultType?.type !== ResultType.NONE && (
                            <ResultTypeContainer type={result.resultType?.type ?? ResultType.NORMAL}>
                                <ResultTypeText>{result.resultType?.title ?? ''}</ResultTypeText>
                            </ResultTypeContainer>
                        )}
                    </ResultContainer>
                </CalculatorResultContainer>
            ) : (
                <div />
            )}
        </MainContainer>
    );
};

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(MedicalCalculatorResult) as React.ComponentType<any>;
