import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ScoreToggle from '../../../components/score-toggle/score-toggle';
import { MedicalScoreParam } from '../../../model/medical-score/medical-score-param';
import { MedicalScoreParamOption } from '../../../model/medical-score/medical-score-param-option';
import { useMedicalScoreContext } from '../medical-score.provider';

interface Props {
    parameterInfo: MedicalScoreParam;
}

const MedicalScoreToggleParam = (props: Props) => {
    const { parameterInfo } = props;
    const ordenedOptions = _.sortBy(parameterInfo.options, 'position');
    const [value, setValue] = useState<MedicalScoreParamOption>();
    const { paramValues, setParamValues } = useMedicalScoreContext();

    useEffect(() => {
        const newValues = { ...paramValues };
        newValues[parameterInfo.code!] = value;
        setParamValues(newValues);
    }, [value]);

    return (
        <ScoreToggle<MedicalScoreParamOption>
            options={ordenedOptions}
            optionSelected={value}
            onChange={setValue}
        />
    );
};

export default MedicalScoreToggleParam;
