import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: relative;
    width: 70vw;
    max-width: 950px;
    height: 60vh;
    margin-left: 40px;
    padding: 5% 7% 3% 1%;
    background-color: #ffffff;
    border: 1.2px solid #e9eced;
    border-radius: 8px;
    overflow-y: auto;
    margin-bottom: 10px;
    @media (max-width: 1080px) {
        width: 650px;
        margin-left: 0;
    }
`;

export const DataContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 2vh;
`;

export const Icon = styled.div`
    width: 44px;
    height: 44px;
    position: relative;
`;

export const AnswersQuantityContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 0 12px;
    gap: 20px;
`;

export const AnswerQuantityContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
`;

export const QuantityContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3vh;
    height: 3vh;
    border-radius: 46px;
`;

export const QuantityLabel = styled.span`
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
`;

export const AnswersQuantityLabel = styled.span`
    position: relative;
    color: #404e52;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    text-align: center;
`;

export const CategoriesContainer = styled.div<{width?: number}>`
    display: flex;
    justify-content: center;
    align-content: space-between;
    flex-wrap: wrap;
    width: ${props => props.width ?? 50}%;
    height: 90%;
    padding-top: 4vh;
`;

export const CategoriesTitle = styled.span`
    color: #A3B1B5;
    font-family: Raleway;
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 0.6vh;
    text-align: center;
    white-space: nowrap;
`;

export const CategoriesData = styled.span`
    color: #414E52;
    font-family: Raleway;
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 28.6px;
    text-align: center;
    white-space: nowrap;
`;

export const CategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    gap: 10px;
    @media (max-width: 1080px) {
        width: 50%;
    }
`;

export const ChartPercentageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 14;
`;

export const ChartPercentageNumberContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: nowrap;
`;

export const ChartPercentageNumberText = styled.span`
    color: #404e52;
    font-family: Raleway;
    font-size: 45px;
    font-weight: 500;
    line-height: 52px;
    text-align: center;
    letter-spacing: -0.8px;
    z-index: 16;
`;

export const ChartPercentageText = styled.span`
    color: #404e52;
    font-family: Raleway;
    font-size: 35px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    z-index: 17;
`;

export const ChartPercentageAccuracyText = styled.span`
    color: #404e52;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 600;
    line-height: 18px;
`;

