import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { IRootState } from '../../reducer';
import './specialty.scss';

export interface CardItemContent {
    icon?: any;
    title: string;
    color?: string;
    isPaid?: boolean;
}
export interface SpecialtyCardItemProps extends RouteComponentProps<{}> {
    t: any;
    item?: CardItemContent;
    isSpecialty?: boolean;
    specialtyImage?: any;
}

export class SpecialtyCardItem extends React.Component<SpecialtyCardItemProps> {
    constructor(props: Readonly<SpecialtyCardItemProps>, context?: any) {
        super(props, context);

        this.state = {};
    }
    render() {
        const { item, isSpecialty, specialtyImage } = this.props;
        if (isSpecialty && specialtyImage) {
            return <img src={specialtyImage} style={{ width: 231, height: 46, objectFit: 'cover' }} />;
        }
        if (item == null) {
            return <div />;
        }
        return (
            <div className="specialty-card-content">
                <div className="specialty-card-content__left">
                    {item.icon && <img src={`data:image/png;base64, ${item.icon}`} className="specialty-card-content__icon" style={{}} />}
                    <span className="specialty-card-content__title">{item.title}</span>
                </div>
                {item.isPaid ? (
                    <div className="lock-img" />
                ) : (
                    <div className="arrow-right-img" />
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SpecialtyCardItem) as React.ComponentType<any>;
