import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import DropdownSearch from '../../components/dropdown-search/dropdown-search';
import { IllustratedProcedure } from '../../model/illustratedProcedure';
import { User } from '../../model/user';
import { IRootState } from '../../reducer';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import IllustratedProcedureService from '../../services/illustratedProcedureService';
import './illustrated-procedures.scss';

export interface IllustratedProceduresProps extends RouteComponentProps<{}> {
    t: (path: string, params?: any) => string;
    openPaidDiseaseModal: () => void;
    hasSubscription: boolean;
}

const IllustratedProcedures = (props: IllustratedProceduresProps) => {
    const [procedures, setProcedures] = useState<IllustratedProcedure[]>([]);

    const getProcedures = async (name?: string): Promise<void> => {
        const response: IllustratedProcedure[] = await IllustratedProcedureService.getIllustratedProcedures(name);
        setProcedures(response);
    };

    useEffect(() => {
        getProcedures();
    }, []);

    const onPressProcedure = (item) => {
        console.log({item});
        if (props.hasSubscription || item.free) {
            props.history.push(`/procedure/${item.id}`);
        } else {
            props.openPaidDiseaseModal();
        }
    }

    return (
        <div className="illustrated-procedures-screen">
            <div className="illustrated-procedures-screen__content">
                <div style={{ marginBottom: 35, marginLeft: 150 }}>
                    <h1 className="screen-title">{props.t('illustratedProcedures.title')}</h1>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div className="select-illustrated-procedures">
                        <p className="select-illustrated-procedures__title">
                            {props.t('illustratedProcedures.subtitle').toUpperCase()}
                        </p>
                        <p className="select-illustrated-procedures__subtitle">{props.t('illustratedProcedures.subtitle2')}</p>
                        <div className="select-illustrated-procedures__inputs">
                            <DropdownSearch
                                data={procedures}
                                getData={getProcedures}
                                title={props.t('illustratedProcedures.dropdownTitle')}
                                onClickItem={onPressProcedure}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account && authentication.account.customer && authentication.account.customer.hasSubscription === true
});

const mapDispatchToProps = {
    openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(IllustratedProcedures) as React.ComponentType<any>;
