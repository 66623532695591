import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import { NAVS } from './header';
import './header.scss';

interface Props {
    t: (path: string, params?: any) => string;
    pathname: string;
    onChangeActiveNav: (nav: string) => void;
}

export const HeaderMenu = (props: Props): JSX.Element => {
    return (
        <Row className="justify-content-center main-nav-container fade-top">
            <div className="main-nav">
                <Link
                    className={props.pathname === '/home' ? 'active-nav' : 'link-nav-main'}
                    onClick={() => props.onChangeActiveNav(NAVS.HOME)}
                    to="/home"
                >
                    {props.t('global.navs.home')}
                </Link>

                <Link
                    className={props.pathname === '/specialty' ? 'active-nav' : 'link-nav-main'}
                    onClick={() => props.onChangeActiveNav(NAVS.SPEC)}
                    to="/specialty"
                >
                    {props.t('global.navs.specialty')}
                </Link>

                <Link
                    className={props.pathname === '/diagnapp' ? 'active-nav' : 'link-nav-main'}
                    onClick={() => props.onChangeActiveNav(NAVS.DIAG)}
                    to="/diagnapp"
                >
                    {props.t('global.navs.diag')}
                </Link>
                <Link
                    className={props.pathname === '/smart-bulas' ? 'active-nav' : 'link-nav-main'}
                    onClick={() => props.onChangeActiveNav(NAVS.SMART)}
                    to="/smart-bulas"
                >
                    {props.t('global.navs.smartBulas')}
                </Link>

                <Link
                    className={props.pathname === '/interaction' ? 'active-nav' : 'link-nav-main'}
                    onClick={() => props.onChangeActiveNav(NAVS.INTER)}
                    to="/interaction"
                >
                    {props.t('global.navs.interactions')}
                </Link>

                <Link
                    className={props.pathname === '/cid' ? 'active-nav' : 'link-nav-main'}
                    onClick={() => props.onChangeActiveNav(NAVS.CID)}
                    to="/cid"
                >
                    {props.t('global.navs.cid')}
                </Link>

                <Link
                    className={props.pathname === '/sus' ? 'active-nav' : 'link-nav-main'}
                    onClick={() => props.onChangeActiveNav(NAVS.SUS)}
                    to="/sus"
                >
                    {props.t('global.navs.sus')}
                </Link>

                <Link
                    className={props.pathname === '/protocols' ? 'active-nav' : 'link-nav-main'}
                    onClick={() => props.onChangeActiveNav(NAVS.PROTOCOLS)}
                    to="/protocols"
                >
                    {props.t('global.navs.protocols')}
                </Link>

                <Link
                    className={props.pathname === '/vaccination' ? 'active-nav' : 'link-nav-main'}
                    onClick={() => props.onChangeActiveNav(NAVS.VACCINATION)}
                    to="/vaccination"
                >
                    {props.t('global.navs.vaccination')}
                </Link>

                <Link
                    className={props.pathname === '/toxicology' ? 'active-nav' : 'link-nav-main'}
                    onClick={() => props.onChangeActiveNav(NAVS.TOXICOLOGY)}
                    to="/toxicology"
                >
                    {props.t('global.navs.toxicology')}
                </Link>

                <Link
                    className={props.pathname === '/laboratory' ? 'active-nav' : 'link-nav-main'}
                    onClick={() => props.onChangeActiveNav(NAVS.LABORATORY)}
                    to="/laboratory"
                >
                    {props.t('global.navs.laboratory')}
                </Link>

                <Link
                    className={props.pathname === '/semiology' ? 'active-nav' : 'link-nav-main'}
                    onClick={() => props.onChangeActiveNav(NAVS.SEMIOLOGY)}
                    to="/semiology"
                >
                    {props.t('global.navs.semiology')}
                </Link>

                <Link
                    className={props.pathname === '/procedure' ? 'active-nav' : 'link-nav-main'}
                    onClick={() => props.onChangeActiveNav(NAVS.PROCEDURE)}
                    to="/procedure"
                >
                    {props.t('global.navs.procedure')}
                </Link>
            </div>
        </Row>
    );
};
