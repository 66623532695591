import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import { OptionPointsContainer, OptionPointsText } from '../../../components/score-toggle/score-toggle-styles';
import { MedicalCriteriaParam } from '../../../model/medical-criteria/medical-criteria-param';
import { MedicalCriteriaParamOption } from '../../../model/medical-criteria/medical-criteria-param-option';
import { setNumberSign } from '../../../shared/util/utils';
import { useMedicalCriteriaContext } from '../medical-criteria.provider';
import { OptionLabel, pickerStyles } from './medical-criteria-params-styles';
import MedicalCriteriaParamType from '../../../model/enums/medical-criteria-param-type';

interface Props {
    parameterInfo: MedicalCriteriaParam;
    isMulti: boolean;
}

const MedicalCriteriaPickerParam = (props: Props) => {
    const { parameterInfo, isMulti } = props;
    const options = _.sortBy(parameterInfo?.options, 'position').map(it => {
        return { ...it, value: it.id };
    });
    const { paramValues, setParamValues } = useMedicalCriteriaContext();

    const handleSelectOption = (item: MedicalCriteriaParamOption | MedicalCriteriaParamOption[]) => {
        const newValues = { ...paramValues };
        if (parameterInfo.inputType === MedicalCriteriaParamType.MULTI_ANSWER_SELECT) {
            newValues[parameterInfo.code!]!.answers = item;
        } else {
            if ((item as MedicalCriteriaParamOption).presentationName === 'Nenhum') {
                newValues[parameterInfo.code!] = undefined;
            } else {
                newValues[parameterInfo.code!] = item;
            }
        }
        setParamValues(newValues);
    };

    const formatOptionLabel = ({ presentationName, points, observation }) => (
        <div style={{ display: 'flex', minWidth: '200px' }} key={presentationName}>
            <OptionPointsContainer selected>
                <OptionPointsText selected>{points != null ? setNumberSign(points) : 0}</OptionPointsText>
            </OptionPointsContainer>

            <OptionLabel>
                {presentationName}
                {observation && <OptionLabel style={{ fontWeight: 600 }}>{` (${observation})`}</OptionLabel>}
            </OptionLabel>
        </div>
    );

    return (
        <Select
            isSearchable={false}
            styles={pickerStyles}
            isMulti={isMulti}
            options={[{ presentationName: 'Nenhum' }, ...options]}
            isClearable={false}
            onChange={e => {
                handleSelectOption(e);
            }}
            placeholder={'Clique para selecionar...'}
            formatOptionLabel={data => formatOptionLabel(data)}
        />
    );
};

export default MedicalCriteriaPickerParam;
