import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import ContentType from '../../model/enums/contentType';
import MedicalCalculatorParamType from '../../model/enums/medical-calculator-param-type';
import { FormulaResult } from '../../model/medical-calculator/formula-result';
import { MedicalCalculator, MedicalCalculatorSimple } from '../../model/medical-calculator/medical-calculator';
import ContentRatingService from '../../services/contentRatingService';
import MedicalCalculatorService from '../../services/medical-calculator.service';

interface MedicalCalculatorProviderProps {
    children: any;
}

interface MedicalCalculatorProviderData {
    paramValues: ParamValues;
    result: FormulaResult;
    fontSize: number;
    showAnnotation: boolean;
    showResizerModal: boolean;
    isLoading: boolean;
    showFavoriteSuccessToast: boolean;
    medicalCalculatorList: MedicalCalculatorSimple[];
    medicalCalculator?: MedicalCalculator;
    setParamValues: (value: ParamValues) => void;
    setResult: (value: FormulaResult) => void;
    setFontSize: (value: number) => void;
    setShowAnnotation: (value: boolean) => void;
    setShowResizerModal: (value: boolean) => void;
    setShowFavoriteSuccessToast: (value: boolean) => void;
    setMedicalCalculator: (dose: MedicalCalculator) => void;
    setNewCurrentMedicalCalculator: (dose: MedicalCalculator) => void;
}

const MedicalCalculatorContext = createContext<MedicalCalculatorProviderData>({} as MedicalCalculatorProviderData);

interface ParamValues {
    [index: string]: string | undefined | Moment;
}

export const MedicalCalculatorProvider = ({ children }: MedicalCalculatorProviderProps) => {
    const [fontSize, setFontSize] = useState<number>(14);
    const [showAnnotation, setShowAnnotation] = useState<boolean>(false);
    const [showResizerModal, setShowResizerModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [medicalCalculatorList, setMedicalCalculatorList] = useState<MedicalCalculatorSimple[]>([]);
    const [showFavoriteSuccessToast, setShowFavoriteSuccessToast] = useState<boolean>(false);
    const [medicalCalculator, setMedicalCalculator] = useState<MedicalCalculator | undefined>(undefined);
    const [paramValues, setParamValues] = useState<ParamValues>({});
    const [result, setResult] = useState<FormulaResult>({ resultText: 'Preencha todos os campos acima para o resultado' });

    useEffect(() => {
        MedicalCalculatorService.search().then(setMedicalCalculatorList);
    }, []);

    const setNewCurrentMedicalCalculator = (calculator: MedicalCalculatorSimple) => {
        setIsLoading(true);
        setShowAnnotation(false);
        setShowFavoriteSuccessToast(false);
        setResult({ resultText: 'Preencha todos os campos acima para o resultado' });
        MedicalCalculatorService.getInfo(calculator.id!)
            .then(setMedicalCalculator)
            .finally(() => setIsLoading(false));
        window.fbq('track', 'ViewContent', { content_name: calculator.name, content_type: 'MEDICAL_CALCULATOR' });
        ContentRatingService.sendContentRating({ name: calculator.name!, contentId: calculator.id!, type: ContentType.MEDICAL_CALCULATOR });
    };

    useEffect(() => {
        const newValues: ParamValues = {};
        medicalCalculator?.formulaParams?.map(it => {
            if (it.inputType === MedicalCalculatorParamType.MULTI_SELECT || it.inputType === MedicalCalculatorParamType.TOGGLE) {
                newValues[it.code!] = _.sortBy(it.options, 'order')[0].code!;
            } else if (it.inputType === MedicalCalculatorParamType.DATE && it.position === 1) {
                newValues[it.code!] = moment();
            } else {
                newValues[it.code!] = undefined;
            }
        });
        setParamValues(newValues);
    }, [medicalCalculator]);

    return (
        <MedicalCalculatorContext.Provider
            value={{
                setFontSize,
                fontSize,
                medicalCalculatorList,
                showAnnotation,
                setShowAnnotation,
                setNewCurrentMedicalCalculator,
                setMedicalCalculator,
                showResizerModal,
                setShowResizerModal,
                medicalCalculator,
                isLoading,
                showFavoriteSuccessToast,
                setShowFavoriteSuccessToast,
                paramValues,
                setParamValues,
                result,
                setResult
            }}
        >
            {children}
        </MedicalCalculatorContext.Provider>
    );
};

export const useMedicalCalculatorContext = () => useContext(MedicalCalculatorContext);
