import React, { ChangeEvent } from 'react';
import './icon-input.scss';

interface Props {
    value: string;
    onChangeText: (newText: string) => void;
}

const IconInput = (props: Props) => {
    const onChangeText = (event: ChangeEvent<HTMLInputElement>) => {
        props.onChangeText(event.target.value);
    };

    const onPressXIcon = () => {
        props.onChangeText('');
    };

    return (
        <div style={{ width: '80%' }}>
            <div className={'input-icon-container'}>
                <div className={'input-icon-magnifying-glass'} />
                <input className={'input-icon-input'} type={'text'} name={'icon-input'} value={props.value} onChange={onChangeText} />
                <div className={'input-icon-x-circle'} style={{ display: props.value === '' ? 'none' : 'block' }} onClick={onPressXIcon} />
            </div>
        </div>
    );
};

export default IconInput;
