import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import DropdownSearch from '../../components/dropdown-search/dropdown-search';
import MultipleCardList, { CardItem, CardList } from '../../components/multiple-card-list/multiple-card-list';
import { GroupsLeaflets } from '../../model/apiResponses/GroupsLeaflets';
import { MedicineLeaflet } from '../../model/medicine-leaflet';
import { MedicineGroup } from '../../model/medicineGroup';
import { PageableResponse } from '../../model/pageable';
import { IRootState } from '../../reducer';
import LeafletsService from '../../services/leafletsService';
import LeafletCardItem, { CardItemContent } from './leaflet-card-item';
import './smart-leaflets.scss';

export interface SmartLeafletsProps extends RouteComponentProps<{}> {
    t: any;
}

export interface SmartLeafletsState {
    firstCardList?: CardList;

    leafletGroups?: MedicineGroup[];
    groupSearchResults: any[];
    medicineSearchResults: any[];

    selectedDropdown?: number;
}

export class SmartLeaflets extends React.Component<SmartLeafletsProps, SmartLeafletsState> {
    constructor(props: Readonly<SmartLeafletsProps>, context?: any) {
        super(props, context);

        this.state = {
            groupSearchResults: [],
            medicineSearchResults: []
        };
    }
    // Component Life Cycle
    componentDidMount(): void {
        this.searchMedicineGroups();
    }

    // Api Calls
    private searchMedicineGroups = async (value?: string): Promise<any> => {
        const result: PageableResponse<MedicineGroup> = await LeafletsService.searchGroups({
            name: value,
            size: value == null ? '200' : undefined
        });
        this.setState({
            leafletGroups: this.state.leafletGroups ? this.state.leafletGroups : _.orderBy(result.content, ['name', 'asc']),
            groupSearchResults: result.content
        });
    };
    private searchLeaflets = async (value: string): Promise<any> => {
        const result: PageableResponse<MedicineLeaflet> = await LeafletsService.searchLeaflets({ name: value });
        this.setState({
            medicineSearchResults: result.content
        });
    };
    private getGroups = async (parentId: number, parentTitle: string): Promise<CardList> => {
        const result: GroupsLeaflets = await LeafletsService.getLeafletsAndGroups(parentId);
        const cardList: CardList = {
            title: parentTitle,
            items: this.mapGroupsToCardItem(result)
        };
        if (this.state.firstCardList == null) {
            this.setState({
                firstCardList: cardList
            });
        }
        return cardList;
    };

    // Mappers
    private mapGroupsToCardItem = (apiResponse: GroupsLeaflets): CardItem[] => {
        let array: any[] = [];
        array = apiResponse.medicineGroups.map<CardItem>((item, index) => ({
            renderItem: () => <LeafletCardItem item={this.mapCardContent(item)} />,
            getChildren: () => this.getGroups(item.id, item.name),
            childrenAmount: item.amount,
            name: item.name
        }));
        array = array.concat(
            apiResponse.medicineLeaflets.map<CardItem>((item, index) => ({
                renderItem: () => <LeafletCardItem item={this.mapCardContent(item)} />,
                getChildren: () => this.onClickLeaflet(item),
                childrenAmount: undefined,
                name: item.name
            }))
        );
        return _.orderBy(array, ['name'], ['asc']);
    };
    private mapCardContent = (taxonomy: Omit<MedicineGroup & MedicineLeaflet, 'amount'>): CardItemContent => {
        return {
            title: taxonomy.name,
            subtitle: taxonomy.composition
        };
    };

    // Handlers
    private onClickMedicineGroup = (group: MedicineGroup): void => {
        this.getGroups(group.id, group.name);
    };
    private onClickLeaflet = async (leaflet: MedicineLeaflet): Promise<void> => {
        this.props.history.push(`/smart-bulas/${leaflet.id}`);
    };
    private onOpenDropdown = (index: number): void => {
        this.setState({
            selectedDropdown: index
        });
    };

    // Renders
    private renderGroup = (group: MedicineGroup, index: number): JSX.Element => {
        return (
            <div className="leaflets-group" key={index} onClick={() => this.onClickMedicineGroup(group)}>
                <img src={require(`../../images/rectangle-${(index % 24) + 1}.svg`)} className="leaflets-group__img" />
                <p className="leaflets-group__title">{group.name.toUpperCase()}</p>
            </div>
        );
    };
    private onBack = (): void => {
        this.setState({
            firstCardList: undefined
        });
    };

    render() {
        const { firstCardList, leafletGroups, groupSearchResults, medicineSearchResults, selectedDropdown } = this.state;
        if (leafletGroups == null) {
            return <div />;
        }
        return (
            <div className="smart-leaflets-screen" style={firstCardList ? { height: 'calc(100vh - 80px - 50px)', paddingBottom: 50 } : {}}>
                <div style={{ alignItems: 'flex-start', paddingLeft: 20, width: '100%', height: '100%' }}>
                    <div className="screen-title">
                        {firstCardList && (
                            <div className="screen-title__back" onClick={this.onBack}>
                                <div className="screen-title__back__img" />
                            </div>
                        )}
                        <h1 className="screen-title__text">{this.props.t('smartLeaflets.title')}</h1>
                    </div>
                    {firstCardList ? (
                        <MultipleCardList
                            firstCardList={firstCardList}
                            color={'#1c1c1c'}
                            // renderLastColumn={(sus: SusProcedure) => <SusLastColumn data={sus} />}
                        />
                    ) : (
                        <div className="leaflets-content">
                            <div className="leaflets-content__side-menu">
                                <DropdownSearch
                                    data={groupSearchResults}
                                    getData={this.searchMedicineGroups}
                                    title={this.props.t('smartLeaflets.dropdownTitle1')}
                                    onClickItem={this.onClickMedicineGroup}
                                    onOpen={() => this.onOpenDropdown(0)}
                                    close={selectedDropdown === 1}
                                />

                                <DropdownSearch
                                    data={medicineSearchResults}
                                    getData={this.searchLeaflets}
                                    title={this.props.t('smartLeaflets.dropdownTitle2')}
                                    style={{ marginTop: 26 }}
                                    onClickItem={this.onClickLeaflet}
                                    onOpen={() => this.onOpenDropdown(1)}
                                    close={selectedDropdown === 0}
                                />
                            </div>
                            <div className="leaflets-content__groups">{leafletGroups.map((it, index) => this.renderGroup(it, index))}</div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SmartLeaflets) as React.ComponentType<any>;
