import moment from 'moment';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Favorite, FavoriteType } from '../../../model/favorite';
import { FlashcardView } from '../../../model/flashcard/flashcard';
import { FlashcardCustomer } from '../../../model/flashcard/flashcard-customer';
import { PerformanceData } from '../../../model/flashcard/performance-data';
import FavoritesService from '../../../services/favoritesService';
import FlashcardCustomerService from '../../../services/flashcard-customer.service';
import { getDateNowTimestamp, shuffle } from '../../../shared/util/utils';

interface FlashcardProviderProps {
    children: ReactNode;
}

interface FlashcardViewProviderData {
    diseaseId: number;
    isInfinite: boolean;
    startTimestamp: any;
    currentIndex: number;
    fontSize: number;
    cards: FlashcardView[];
    randomModeStatus: boolean;
    setFontSize: (value: number) => void;
    favoriteFlashcard: (index?: number) => void;
    setRandomModeStatus: (value: boolean) => void;
    setDiseaseId: (id: number) => void;
    setCards: (cards: FlashcardView[]) => void;
    setIsInfinite: (isInfinite: boolean) => void;
    setRandomMode: (newStatus: boolean) => void;
    setCurrentIndex: (currentIndex: number) => void;
    updateCard: (index: number, card: FlashcardView) => void;
    setOriginalSortFunc: (value: any) => void;
    onAnswerFlashcard: (card: FlashcardView, startCardTimestamp: number) => void;
    updatedPerformanceData: () => void;
    performanceData: PerformanceData;
}

const FlashcardContext = createContext<FlashcardViewProviderData>({} as FlashcardViewProviderData);

export const FlashcardProvider = ({ children }: FlashcardProviderProps) => {
    const [cards, setCards] = useState<FlashcardView[]>([]);
    const [diseaseId, setDiseaseId] = useState<number>(0);
    const [startTimestamp] = useState(getDateNowTimestamp());
    const [isInfinite, setIsInfinite] = useState<boolean>(false);
    const [originalSortFunc, setOriginalSortFunc] = useState<any>(() => (a: any, b: any) => (a?.position ?? 0) - (b?.position ?? 0));
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [randomModeStatus, setRandomModeStatus] = useState<boolean>(false);
    const [fontSize, setFontSize] = useState<number>(15);
    const [performanceData, setPerformanceData] = useState<PerformanceData>({ answers: [] });

    const updateCard = (index: number, card: FlashcardView) => {
        const newCards = [...cards];
        newCards[index] = card;
        setCards(newCards);
    };

    const onAnswerFlashcard = (card: FlashcardView, startCardTimestamp: number) => {
        const secondsSpent = (getDateNowTimestamp() - startCardTimestamp) / 1000;
        const flashcardCustomer: FlashcardCustomer = {
            id: { flashcard: {id: card.id} },
            answer: card.answer,
            timeSpent: secondsSpent
        }
        FlashcardCustomerService.saveFlashcardCustomer(flashcardCustomer);
    };

    const favoriteFlashcard = (index?: number) => {
        const card = cards[index ?? currentIndex];
        const newFavorite: Favorite = {
            ...card.favorite,
            type: FavoriteType.FLASHCARD,
            date: new Date().toISOString(),
            flashcard: {
                id: card.id!
            },
            isActive: !card?.favorite?.isActive,
        };
        card.favorite = newFavorite;
        updateCard(currentIndex, card);
        FavoritesService.favorite(newFavorite).catch((err: Error) => {
            card.favorite = null;
            updateCard(currentIndex, card);
        });
    };

    const setRandomMode = (newStatus: boolean) => {
        setRandomModeStatus(newStatus);
        if (newStatus) {
            setCards(shuffle([...cards]));
        } else {
            setCards([...cards].sort((a, b) => originalSortFunc(a, b)));
        }
        setTimeout(() => {
            setCurrentIndex(1);
            setCurrentIndex(0);
        }, 200);
    };

    const updatedPerformanceData = () => {
        const secondsSpent = (getDateNowTimestamp() - startTimestamp) / 1000;
        const newAnswers = cards
            .filter(it => it.answer != null)
            .map(it => {
                return {flashcardId: it.id, answer: it.answer};
            });
        const answeredQuestions = newAnswers.length;
        const remainingQuestions = cards.length - answeredQuestions;
        const result: PerformanceData = {
            secondsSpent,
            remainingQuestions,
            answeredQuestions,
            answers: newAnswers,
        };
        setPerformanceData(result);
    };

    return (
        <FlashcardContext.Provider
            value={{
                cards,
                setCards,
                startTimestamp,
                diseaseId,
                setDiseaseId,
                updateCard,
                randomModeStatus,
                setOriginalSortFunc,
                setRandomMode,
                currentIndex,
                setCurrentIndex,
                setRandomModeStatus,
                favoriteFlashcard,
                onAnswerFlashcard,
                isInfinite,
                setIsInfinite,
                fontSize,
                setFontSize,
                performanceData,
                updatedPerformanceData
            }}
        >
            {children}
        </FlashcardContext.Provider>
    );
};

export const useFlashcardViewContext = () => useContext(FlashcardContext);
