import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Select, { StylesConfig } from 'react-select';
import { OptionLabel, pickerStyles } from '../../flashcard-intensive-dashboard/flashcard-intensive-create-styles';

interface Props extends WithTranslation {
    value: number;
    onChange: (value: number) => void;
}

const IntensiveDaySelect = (props: Props) => {
    const options = [30, 60, 100, 200].map(it => {
        return { value: it };
    });

    const handleSelectOption = (item: { value: number }) => {
        props.onChange(item.value)
    };

    const formatOptionLabel = ({ value }) => (
        <div style={{ display: 'flex', minWidth: '200px' }} key={value}>
            <OptionLabel>
                {props.t('flashcardIntensiveCreate.questionsPerDayValue', {questionsPerDay: value})}
            </OptionLabel>
        </div>
    );

    return (
        <Select
            isSearchable={false}
            styles={pickerStyles}
            isMulti={false}
            options={options}
            isClearable={false}
            defaultValue={{value: props.value}}
            onChange={e => handleSelectOption(e)}
            placeholder={'Clique para selecionar...'}
            formatOptionLabel={data => formatOptionLabel(data)}
        />
    );
};

export default withTranslation()(IntensiveDaySelect);
