import { AxiosResponse } from 'axios';
import { Laboratory } from '../model/laboratory/laboratory';
import { PageableResponse } from '../model/pageable';
import { LaboratoryCategory, LaboratoryGroup } from './../model/laboratory/laboratory-group';
import { api } from './api';

export const laboratoryApi = () => {
  const getLaboratory = (id: number): Promise<AxiosResponse<Laboratory>> => {
    return api.get<Laboratory>(`/laboratories/info/${id}`);
  };

  const getByCategory = (category: LaboratoryCategory): Promise<AxiosResponse<LaboratoryGroup[]>> => {
    return api.get<LaboratoryGroup[]>(`/laboratory-groups/${category}/category`);
  };

  const getGroupByParentId = (id: number): Promise<AxiosResponse<LaboratoryGroup>> => {
    return api.get<LaboratoryGroup>(`/laboratory-groups/${id}/content`);
  };

  const getAvailableForFree = (): Promise<AxiosResponse<PageableResponse<Laboratory>>> => {
    return api.get<PageableResponse<Laboratory>>(`/laboratories/free?size=100`);
  };


  return {
    getAvailableForFree,
    getGroupByParentId,
    getLaboratory,
    getByCategory
  };
};

export default laboratoryApi();
