import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import MultipleCardList, { CardItem, CardList } from '../../components/multiple-card-list/multiple-card-list';
import SemiologyCategory from '../../model/enums/semiologyCategory';
import { Semiology } from '../../model/semiology/semiology';
import { IRootState } from '../../reducer';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import SemiologyService from '../../services/semiologyService';
import { CardItemContent, default as SusCardItem } from '../specialties/specialty-card-item';
import '../specialties/specialty.scss';
import './semiology.scss';

export interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
    hasSubscription?: boolean;
    openPaidModal: () => void;
}

const SemiologiesScreen = (props: Props): JSX.Element => {
    const [firstCardList, setFirstCardList] = useState<CardList | undefined>();

    useEffect(() => {
        getSemiologies();
    }, []);

    const onItemPress = async (semiology: Semiology) => {
        const { hasSubscription } = props;
        if (semiology.isAvailableForFree === true || hasSubscription) {
            props.history.push({
                pathname: `/semiology/${semiology.id}`,
                state: {
                    screenToBack: '/semiologies'
                }
            });
        } else {
            props.openPaidModal();
        }
    };

    const getGroups = async (category: SemiologyCategory): Promise<CardList | void> => {
        const result: Semiology[] = await SemiologyService.getByCategory(category);
        const color: string = category === SemiologyCategory.BASIC ? '#EC4C81' : '#00C9B8';
        const cardList: CardList = {
            title: props.t(`enum.semiologyCategory.${category}`),
            items: mapGroupsToCardItem(result, color),
            color
        };
        return cardList;
    };

    const mapGroupsToCardItem = (semiologies: Semiology[], color: string): CardItem[] => {
        const cards: CardItem[] = semiologies.map<CardItem>((item, index) => ({
            renderItem: () => <SusCardItem item={mapCardContent(item)} />,
            getChildren: () => onItemPress(item),
            name: item.name
        }));
        const cardItems: CardItem[] = _.orderBy(cards, ['name'], ['asc']);
        return cardItems;
    };

    const mapCardContent = (taxonomy: any): CardItemContent => {
        const { hasSubscription } = props;
        return {
            icon: taxonomy.icon ? taxonomy.icon.content : undefined,
            title: taxonomy.name,
            isPaid: taxonomy.isAvailableForFree === false && !hasSubscription
        };
    };

    const mapSemiologies = (): CardItem[] => [
        {
            renderItem: () => (
                <img src={require('../../images/semiology/semiology-basic.svg')} className={'semiology-card'} />
            ),
            getChildren: () => getGroups(SemiologyCategory.BASIC)
        },
        {
            renderItem: () => (
                <img src={require('../../images/semiology/semiology-specific.svg')} className={'semiology-card'} />
            ),
            getChildren: () => getGroups(SemiologyCategory.SPECIFIC)
        },
    ];

    const getSemiologies = () => {
        setFirstCardList({
            title: props.t('semiologies.title'),
            items: mapSemiologies()
        });
    };

    return (
        <div className="specialty-screen-container" style={{ position: 'relative' }}>
            <div style={{ alignItems: 'flex-start', position: 'relative', width: '100%', height: '100%' }}>
                {firstCardList != null && <MultipleCardList firstCardList={firstCardList} noCardExtension hasExtraFirstColumn firstColumnCardStyle={'card-item_content'} />}
            </div>
        </div>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account && authentication.account.customer ? authentication.account.customer.hasSubscription : undefined
});
const mapDispatchToProps = {
    openPaidModal: openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SemiologiesScreen) as React.ComponentType<any>;
