import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import FlashcardApi from '../../../api/flashcard.api';
import Loading from '../../../components/loading/loading';
import FlashcardHeader from '../components/flashcard-header';
import { useFlashcardViewContext } from '../flashcard-carousel/flashcard.provider';
import FlashcardList from '../flashcard-list/flashcard-list';
import {
    Container,
    EmptyIcon,
    EmptyText,
    MainContainer,
    PracticeButton,
    PracticeIcon,
    PracticeText
} from '../flashcard-styles';
import '../flashcard.scss';

interface Props extends RouteComponentProps<{}, {}, { id?: number }>, WithTranslation {
    hasSubscription?: boolean;
    openPaidModal: () => void;
    onPressPerformanceButton?: () => void;
    hideCreateCardButton?: boolean;
}

const FlashcardFavorites = (props: Props) => {
    const { setCards, cards, fontSize } = useFlashcardViewContext();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        FlashcardApi.getFavoriteFlashcards().then(response => {
            let newCards = response.data;
            newCards = newCards.sort((a, b) => a.position! - b.position!);
            setCards(newCards);
        }).finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        if(cards.some(it => it.favorite?.isActive === false)) {
        setCards(cards.filter(it => it.favorite?.isActive === true));
        }
    }, [cards]);


    const onBack = () => {
        props.history.goBack();
    };

    const listIsEmpty = cards.length === 0;

    return isLoading ? (
        <div className="disease-screen-container">
            <Loading />
        </div>
    ) : (
        <MainContainer>
            <Container>
                <FlashcardHeader
                    {...props}
                    title={props.t('flashcardFavorite.subtitle')}
                    subtitle={props.t('flashcardFavorite.title')}
                    viewMode={listIsEmpty ? 'CARD' : 'LIST'}
                    onPressBack={onBack}
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FlashcardList {...props} width={55} fontSize={fontSize}>
                        {listIsEmpty &&<div className={'favorite-empty-list-container'}>
                            <EmptyIcon className={'empty-list-img'} />
                            <EmptyText>{props.t('flashcardFavorite.emptyList')}</EmptyText>
                        </div>}
                        {!listIsEmpty &&
                            <PracticeButton onClick={() => props.history.push('/flashcard-favorite/practice')}>
                                <div style={{ height: 56 }} />
                                <PracticeText>
                                    {props.t('flashcardFavorite.practice')}
                                </PracticeText>
                                <PracticeIcon className={'orange-play-img'} />
                            </PracticeButton>}
                    </FlashcardList>
                </div>
            </Container>
        </MainContainer>
    );
};

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(FlashcardFavorites) as React.ComponentType<any>;
