import { AxiosResponse } from 'axios';
import { HomeListItem } from '../model/HomeListItem';
import { Note } from '../model/note';
import { api } from './api';

export const noteApi = () => {
  const save = (note: Note): Promise<AxiosResponse<any>> => {
    return api.post<any>(`/notes/`, note);
  };  
  const getNotes = (): Promise<AxiosResponse<HomeListItem[]>> => {
    return api.get<HomeListItem[]>(`/notes/user`);
  };

  return {
    save,
    getNotes
  };
};

export default noteApi();
