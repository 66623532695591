import * as React from 'react';
import './toggle-button.scss';

interface Props {
    inError: boolean;
    optionOneText: string;
    optionTwoText: string;
    optionSelected: number;
    onPress: (option: number) => void;
}

const ToggleButton = (props: Props) => {
    const { optionOneText, optionTwoText, optionSelected, inError } = props;
    const optionOneTextClass: string = optionSelected === 0 ? 'toggle-button__option__dark-text' : 'toggle-button__option__white-text';
    const optionTwoTextClass: string = optionSelected === 1 ? 'toggle-button__option__dark-text' : 'toggle-button__option__white-text';

    return (
        <div className="toggle-button" onClick={() => props.onPress(optionSelected == 1 ? 0 : 1)}>
            <div className="toggle-button__option">
                <div className="toggle-button__option__content">
                    <span className={optionOneTextClass}>{optionOneText}</span>
                </div>
            </div>
            <div className="toggle-button__option">
                <div className="toggle-button__option__content">
                    <span className={optionTwoTextClass}>{optionTwoText}</span>
                </div>
            </div>
            <div className={`toggle-button__white-oval${inError ? '--inError' : ''}`} style={optionSelected === 0 ? { left: 2 } : { right: 2 }} />
        </div>
    );
}

export default ToggleButton;