import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import htmlParser from 'react-markdown/plugins/html-parser';
import ReactMarkdown from 'react-markdown/with-html';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ApplicationPicture } from '../../../model/applicationPicture';
import { FlashcardView } from '../../../model/flashcard/flashcard';
import { openImageModal } from '../../../reducer/globalModal/actions';
import '../flashcard.scss';
import { ImageModal } from '../../disease/image-modal';
import { CardSeparator } from './flashcard-carousel.styles';

interface Props extends WithTranslation {
    card: FlashcardView;
    // openImageModal: (imageSrc: string) => void;
    fontSize?: number;
    scrollOff?: boolean;
    seeAnswer: boolean;
}

const parseHtml = htmlParser({
    isValidNode: node => node.type !== 'script',
    processingInstructions: [
        /* ... */
    ]
});

const FlashcardContent = (props: Props) => {
    const { card, scrollOff, fontSize, seeAnswer } = props;
    const [imageWithHover, setImageWithHover] = useState<string | null>(null);
    const [imageModalProps, setImageModalProps] = useState<{
        imageModalIsVisible: boolean,
        image: string | undefined
    }>({
        imageModalIsVisible: false,
        image: undefined
    });

    const onPressImage = (image: string): void => {
        setImageModalProps({ imageModalIsVisible: true, image: image });
    };

    const onImageHover = (src: string) => {
        if (imageWithHover == null) {
            setImageWithHover(src);
        }
    };
    const onImageLeave = (): void => {
        setImageWithHover(null);
    };

    const transformImageUri = (uri: string): string => {
        const { applicationPictures } = card;
        if (applicationPictures == null) {
            return '';
        }
        const picture: ApplicationPicture | undefined = _.find(applicationPictures, ['referenceName', uri]);
        if (picture != null) {
            return `data:image/png;base64,${picture.content}`;
        }
        return '';
    };

    const renderMarkdown = (contentText: string, fontWeight: number) => {
        return (
            <ReactMarkdown
                source={contentText}
                className="flashcard-markdown"
                escapeHtml={false}
                astPlugins={[parseHtml]}
                transformImageUri={transformImageUri}
                renderers={{
                    paragraph: markdownProps => (
                        <div className="flashcard-markdown__paragraph" style={{ fontSize: fontSize ?? 20, fontWeight }}>
                            {markdownProps.children.map(it => it)}
                        </div>
                    ),
                    image: imageProps => (
                        <div
                            className="flashcard-markdown__image-container"
                            onClick={() => onPressImage(imageProps.src)}
                            onMouseOver={() => onImageHover(imageProps.src)}
                            onMouseLeave={onImageLeave}
                        >
                            <img
                                src={imageProps.src}
                                className="flashcard-markdown__image"
                                style={imageProps.src === imageWithHover ? { opacity: 0.31 } : {}}
                                alt={'Imagem'}
                            />
                            <div className="flashcard-markdown__image__zoom-icon" />
                        </div>
                    ),
                    strong: markdownProps => <span
                        className="flashcard-markdown__strong">{markdownProps.children.map(it => it)}</span>,
                    emphasis: markdownProps => (
                        <span className="flashcard-markdown__emphasis">{markdownProps.children.map(it => it)}</span>
                    ),
                    listItem: markdownProps => (
                        <li className="flashcard-markdown__list-item" style={{ fontSize: fontSize ?? 20, fontWeight }}>
                            {markdownProps.children.map(it => it)}
                        </li>
                    ),
                    code: markdownProps => <p className="flashcard-markdown__code">{markdownProps.value}</p>,
                    blockquote: markdownProps => (
                        <div className="flashcard-markdown__blockquote">
                            <div
                                className="flashcard-markdown__blockquote__content">{markdownProps.children.map(it => it)}</div>
                        </div>
                    )
                }}
            />
        );
    };

    return (
        <div className={scrollOff ? 'flashcard-content-full' : 'flashcard-content'}>
            {renderMarkdown(card.questionText ?? '', 500)}
            {seeAnswer && (
                <>
                    <CardSeparator />
                    {renderMarkdown(card.answerText ?? '', 600)}
                </>
            )}
            {imageModalProps.image != null &&
                <ImageModal t={props.t} open={imageModalProps.imageModalIsVisible} image={imageModalProps.image}
                            onClose={() => setImageModalProps({ imageModalIsVisible: false, image: undefined })} />}
        </div>
    );
};

const mapDispatchToProps = {
    openImageModal
};

export default compose(connect(null, mapDispatchToProps), withTranslation())(FlashcardContent) as React.ComponentType<any>;
