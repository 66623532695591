import { AxiosResponse } from 'axios';
import CompanyUserApi from '../api/company-user.api';
import { Customer } from '../model/customer';
import HttpStatus from '../model/enums/httpStatus';
import { Pageable, PageableResponse } from '../model/pageable';
import { CompanyDashboardData } from '../model/company-dashboard-data';

export const CompanyUserService = () => {
    const getDashboardData = async (): Promise<CompanyDashboardData> => {
        try {
            const result: AxiosResponse<CompanyDashboardData> = await CompanyUserApi.getCompanyDashboardData();
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };


    const getCompanyUsers = async (searchText: string, page: Pageable): Promise<PageableResponse<Customer>> => {
        try {
            const result: AxiosResponse<PageableResponse<Customer>> = await CompanyUserApi.getCompanyUsers(searchText, page);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const saveCompanyUser = async (customer: Customer): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await CompanyUserApi.saveCompanyUser(customer);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve();
            } else {
                console.log({ result, request: result.request, statusText: result.statusText });
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (e) {
            const error = e as any;
            return Promise.reject({ status: error.status, statusText: error.response.data.title } as AxiosResponse);
        }
    };

    const deleteCustomer = async (customer: Customer): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await CompanyUserApi.deleteCustomer(customer);
            if (result.status === HttpStatus.OK && result.data != null) {
                return Promise.resolve(result.data);
            } else{
                return Promise.resolve(result.data);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    return {
        getDashboardData,
        getCompanyUsers,
        deleteCustomer,
        saveCompanyUser
    };
};

export default CompanyUserService();
