import { Base64 } from 'js-base64';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import htmlParser from 'react-markdown/plugins/html-parser';
import ReactMarkdown from 'react-markdown/with-html';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { cleanMarkdownSyntax } from '../../shared/util/markdown-utils';
import { usePediatricDoseContext } from './pediatric-dose.provider';
import './pediatric-dose.scss';

interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
}

const parseHtml = htmlParser({
    isValidNode: node => node.type !== 'script',
    processingInstructions: [
        /* ... */
    ]
});

const PediatricDoseObservation = (props: Props) => {
    const [observationText, setObservationText] = useState<string>('');
    const { currentPediatricDose, fontSize } = usePediatricDoseContext();

    useEffect(() => {
        if (currentPediatricDose !== undefined) {
            setObservationText(cleanMarkdownSyntax(Base64.decode(currentPediatricDose?.observations!)));
        }
    }, [currentPediatricDose]);

    return (
        <>
            <span className={'dose-observations'}>{'Observações'}</span>
            <ReactMarkdown
                source={observationText}
                className="dose-markdown"
                escapeHtml={false}
                astPlugins={[parseHtml]}
                renderers={{
                    paragraph: markdownProps => (
                        <div className="dose-markdown__paragraph" style={{ fontSize }}>
                            {markdownProps.children.map(it => it)}
                        </div>
                    ),
                    strong: markdownProps => <span className="dose-markdown__strong">{markdownProps.children.map(it => it)}</span>,
                    emphasis: markdownProps => <span className="dose-markdown__emphasis">{markdownProps.children.map(it => it)}</span>,
                    listItem: markdownProps => <li className="dose-observation-markdown__list-item">{markdownProps.children.map(it => it)}</li>,
                    code: markdownProps => <p className="dose-markdown__code">{markdownProps.value}</p>,
                    blockquote: markdownProps => (
                        <div className="dose-markdown__blockquote">
                            <div className="dose-markdown__blockquote__content">{markdownProps.children.map(it => it)}</div>
                        </div>
                    ),
                }}
            />
        </>
    );
}

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(PediatricDoseObservation) as React.ComponentType<any>;
