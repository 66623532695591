import * as React from 'react';
import './inline-select.scss';

export interface SelectOption {
    label: string;
    value: unknown;
}
export interface IInlineSelectProps {
    startIndex?: number;
    options: SelectOption[];
    onChange: (value: unknown) => void;
}

export interface IInlineSelectState {
    selectedOption: SelectOption;
    optionsOpen: boolean;
}

export default class InlineSelect extends React.Component<IInlineSelectProps, IInlineSelectState> {
    private selectRef;
    constructor(props: any) {
        super(props);

        this.state = {
            selectedOption: props.options[props.startIndex || 0],
            optionsOpen: false
        };
    }
    // Component Life Cycle
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    // Handlers
    private handleClickOutside = event => {
        if (this.selectRef && !this.selectRef.contains(event.target)) {
            this.setState({
                optionsOpen: false
            });
        }
    };
    private onChange = (option: SelectOption) => {
        this.setState({
            selectedOption: option,
            optionsOpen: false
        });
        this.props.onChange(option.value);
    };
    private onExpand = () => {
        this.setState({
            optionsOpen: !this.state.optionsOpen
        });
    };
    // Renders
    private renderOption = (option: SelectOption, index: number) => {
        return (
            <a className="select__option" onClick={() => this.onChange(option)}>
                <span className="select__option__label">{option.label}</span>
            </a>
        );
    };
    render() {
        const { options } = this.props;
        const { selectedOption, optionsOpen } = this.state;
        return (
            <div className="select" onBlur={() => this.setState({ optionsOpen: false })} ref={ref => (this.selectRef = ref)}>
                <a className="select__title" onClick={this.onExpand}>
                    <div className="select__title__row">
                        <span className="select__title__text">{selectedOption.label}</span>
                        <div className="select__title__arrow-icn" />
                    </div>
                </a>
                {optionsOpen && (
                    <div className="select__options">
                        {options.filter(opt => opt.label !== selectedOption.label).map((opt, index) => this.renderOption(opt, index))}
                    </div>
                )}
            </div>
        );
    }
}
