import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { closeAccessedFromAnotherDeviceModal } from '../../reducer/globalModal/actions';
import Button from '../button/button';
import './another_access-modal.scss';

export interface IAnotherAccessModalProps {
    t: (path: string, params?: any) => string;
    history: any;

    open: boolean;
    closeModal: () => void;
}

const AnotherAccessModal = (props: IAnotherAccessModalProps) => {
    const onClose = () => {
        props.closeModal();
        props.history.push('/');
    };

    const onButtonPress = () => {
        props.closeModal();
        props.history.push('/');
    };

    const { open } = props;
    if (!open) {
        return <div />;
    }
    return (
        <div className="expired-hour-modal">
            <div className="expired-hour-modal__full-content">
                <a className="close-btn" onClick={onClose}>
                    <div className="close-btn__icon" />
                </a>
                <div className="expired-hour-modal__content">
                    <div className="top-content">
                        <div className="top-content__logo">
                            <div className="top-content__logo__img" />
                        </div>
                        <span className="top-content__title">{props.t('anotherAccessModal.title')}</span>
                        <span className="top-content__subtitle">{props.t('anotherAccessModal.subtitle')}</span>
                    </div>
                    <div className="bottom-content">
                        <div className="bottom-content__btn-container">
                            <Button
                                label={props.t('anotherAccessModal.buttonLabel').toUpperCase()}
                                onPress={onButtonPress}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    closeModal: closeAccessedFromAnotherDeviceModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(AnotherAccessModal) as React.ComponentType<any>;
