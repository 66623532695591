import { AxiosResponse } from 'axios';
import { PageableResponse } from '../model/pageable';
import { MedicalCalculator } from './../model/medical-calculator/medical-calculator';
import { api } from './api';

const MedicalCalculatorApi = () => {
  const getInfo = (id: number): Promise<AxiosResponse<MedicalCalculator>> => {
    return api.get<MedicalCalculator>(`/medical-calculators/info/${id}`);
  };

  const search = (text?: string): Promise<AxiosResponse<PageableResponse<MedicalCalculator>>> => {
    const query = text !== undefined ? `&name=${text}` : '';
    return api.get<PageableResponse<MedicalCalculator>>(`/medical-calculators/search?size=1000${query}`);
  };

  const getAvailableForFreeCalculators = (): Promise<AxiosResponse<PageableResponse<MedicalCalculator>>> => {
    return api.get<PageableResponse<MedicalCalculator>>(`/medical-calculators/free?size=100`);
  };

  return {
    getInfo,
    getAvailableForFreeCalculators,
    search
  };
};

export default MedicalCalculatorApi();
