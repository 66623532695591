import styled from 'styled-components';

export const MainContainer = styled.div`
    height: calc(100vh - 80px - 50px);
    display: flex;
    padding-top: 25px;
    justify-content: center;
    background-color: #f9fafa;
`;

export const Container = styled.div`
    height: 100%;
    width: 60vw;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    margin-left: 60px;
`;

export const ArrowBack = styled.div`
    height: 24px;
    width: 24px;
    margin-right: 10px;
    cursor: pointer;
`;

export const HeaderContainer = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
`;


export const Line = styled.div`
    height: 2px;
    width: 100%;
    background-color: #E9ECED;
    margin-top: 10%;
    margin-bottom: 10%;
`;

export const HeaderText = styled.span`
    color: #181f22;
    font-size: 28px;
    font-family: Raleway;
    font-weight: 600;
    line-height: 31.2px;
    letter-spacing: 0.48px;
    word-wrap: break-word;
`;

export const CategoriesTitle = styled.span`
    color: #414E52;
    font-size: 1.4rem;
    font-family: Raleway;
    font-weight: 600;
    line-height: 31.2px;
    letter-spacing: 0.48px;
    word-wrap: break-word;
    margin-bottom: 5%;
    @media (max-width: 1080px) {
        font-size: 1rem;
    }
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: relative;
    width: 70vw;
    max-width: 1050px;
    height: 60vh;
    padding: 24px 32px 32px 32px;
    background-color: #ffffff;
    border: 1.2px solid #e9eced;
    border-radius: 8px;
    overflow-y: auto;
    @media (max-width: 1080px) {
        width: 650px;
        margin-left: 0;
    }
`;

export const CategoriesContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
    @media (max-width: 1080px) {
        justify-content: flex-end;
        gap: 16px;
    }
`;

export const CategoryContainer = styled.div<{color: string}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 9vw;
    height: 9vw;
    border-radius: 12px;
    background-color: ${props => props.color};
    cursor: pointer;
    @media (max-width: 1600px), (max-width: 1366px) {
        width: 13vw;
        height: 13vw;
    }
    @media (max-width: 1280px) {
        width: 12vw;
        height: 12vw;
    }
    @media (max-width: 1024px) {
        width: 8.5vw;
        height: 9vw;
    }
`;

export const DataIcon = styled.div`
    height: 32px;
    width: 32px;
    margin-right: 10px;
`;

export const ItemIcon = styled.div`
    height: 3vh;
    width: 3vh;
`;

export const CategoryIcon = styled.div`
    height: 7vh;
    width: 7vh;
`;

export const ArrowIcon = styled.div`
    height: 16px;
    width: 16px;
    margin-left: 6px;
`;

export const CategoryTitle = styled.span`
    color: #ffffff;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 600;
    line-height: 31.2px;
    letter-spacing: 0.48px;
    word-wrap: break-word;
    @media (max-width: 1080px) {
        font-size: 0.7rem;
    }
`;

export const DataValue = styled.span`
    color: #414E52;
    font-size: 22px;
    font-family: Raleway;
    font-weight: 600;
`;

export const DataSubtitle = styled.span`
    color: #A3B1B5;
    font-size: 12px;
    font-family: Raleway;
    font-weight: 500;
`;

export const ItemTitle = styled.span`
    color: #414E52;
    font-size: 12px;
    margin-top: 6px;
    font-weight: 600;
    font-family: Raleway;
    line-height: 14.40px;
`;

export const SeeMoreLabel = styled.span`
    color: #6D7C81;
    font-size: 12px;
    font-family: Raleway;
    font-weight: 600;
    line-height: 14.40px;
    word-wrap: break-word;
`;

export const ItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6vw;
    height: 4.7vh;
    border-radius: 36px;
    background-color: #ffffff;
    border: 1px solid #D2D9DC;
`;

export const ItemsContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 8%;
    padding-left: 8%;
    padding-right: 8%;
    align-items: center;
    justify-content: space-between;
`;

export const DatasContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;
    margin-right: 8%;
`;

export const SeeMoreContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const RightCardSideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    @media (max-width: 1080px) {
        width: 35%;
        text-align: center;
    }
`;


