import * as React from 'react';
import './show-data-input.scss';

export interface IShowDataInputProps {
    title: string;
    value?: string;
    style?: any;
}


export default class ShowDataInput extends React.Component<IShowDataInputProps> {
    constructor(props) {
        super(props);

        this.state = { };
    }
    render() {
        const { title, value, style } = this.props; 
        return (
            <div className="input" style={style}>
                <span className="input__title">{title}</span>
                <p className="input__value">{value || ''}</p>
            </div>
        );
    }
}
