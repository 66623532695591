import { AxiosResponse } from 'axios';
import myFlashcardApi from '../api/my-flashcard.api';
import MyFlashcards from '../features/flashcard/my-flashcards/my-flashcards';
import HttpStatus from '../model/enums/httpStatus';
import { MyFlashcard } from '../model/flashcard/my-flashcard';

export const MyFlashcardService = () => {
    const save = async (myFlashcard: MyFlashcard): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await myFlashcardApi.save(myFlashcard);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const getMyFlashcards = async (): Promise<MyFlashcard[]> => {
        try {
            const result: AxiosResponse<MyFlashcard[]> = await myFlashcardApi.getMyFlashcards();
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const getMyFlashcardById = async (id: number): Promise<MyFlashcard> => {
        try {
            const result: AxiosResponse<MyFlashcard> = await myFlashcardApi.getMyFlashcardById(id);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    return {
        save,
        getMyFlashcards,
        getMyFlashcardById
    };
};

export default MyFlashcardService();
