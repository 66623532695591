import * as React from 'react';
import './switch-button.scss';

interface Props {
    onPress: () => void;
    optionOneText: string;
    optionOneIcon: string;
    optionTwoText: string;
    optionTwoIcon: string;
    optionSelected: number;
    disabled?: boolean;
    iconStyle?: any;
}

export default class SwitchButton extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { optionOneText, optionOneIcon, optionTwoText, optionTwoIcon, optionSelected, iconStyle } = this.props;
        const optionOneIconClass: string =
            optionSelected === 0 ? 'switch-button__option__icon switch-button__option__icon--selected' : 'switch-button__option__icon';
        const optionOneTextClass: string = optionSelected === 0 ? 'switch-button__option__dark-text' : 'switch-button__option__white-text';
        const optionTwoIconClass: string =
            optionSelected === 1 ? 'switch-button__option__icon switch-button__option__icon--selected' : 'switch-button__option__icon';
        const optionTwoTextClass: string = optionSelected === 1 ? 'switch-button__option__dark-text' : 'switch-button__option__white-text';
        return (
            <div className="switch-button" onClick={this.props.onPress}>
                <div className="switch-button__option">
                    <div className="switch-button__option__content">
                        <img src={optionOneIcon} className={optionOneIconClass} style={iconStyle} />
                        <span className={optionOneTextClass}>{optionOneText}</span>
                    </div>
                </div>
                <div className="switch-button__option">
                    <div className="switch-button__option__content">
                        <img src={optionTwoIcon} className={optionTwoIconClass} style={iconStyle} />
                        <span className={optionTwoTextClass}>{optionTwoText}</span>
                    </div>
                </div>
                <div className="switch-button__white-oval" style={optionSelected === 0 ? { left: 2 } : { right: 2 }} />
            </div>
        );
    }
}
