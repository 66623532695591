import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Button from '../../../components/button/button';
import TextFieldMeds from '../../../components/text-field-meds/text-field-meds';
import { EditPasswordParams } from '../../../model/editPassword';
import { IRootState } from '../../../reducer';
import CustomerService from '../../../services/customerService';
import './components.scss';

export interface EditPasswordProps extends RouteComponentProps<{}> {
    t: (path: string, param?: any) => string;
    history: any;
}

export interface EditPasswordState {
    hasError?: boolean;
    showSuccessMessage?: boolean;

    currentPassword?: string;
    currentPasswordError?: string;
    newPassword?: string;
    newPasswordError?: string;
    newPasswordConfirmation?: string;
    newPasswordConfirmationError?: string;
}

export class EditPassword extends React.Component<EditPasswordProps, EditPasswordState> {
    constructor(props: Readonly<EditPasswordProps>, context?: any) {
        super(props, context);

        this.state = {};
    }
    // Component Life Cycle
    componentDidMount(): void {
        //
    }
    // Handlers
    private onSavePress = async (): Promise<void> => {
        const { currentPassword, newPassword, newPasswordConfirmation } = this.state;
        if (currentPassword == null || newPassword == null || newPasswordConfirmation == null) {
            return;
        }
        if (newPassword !== newPasswordConfirmation) {
            this.setState({
                newPasswordConfirmationError: this.props.t('preferences.editPasswordComponent.passwordConfirmationError')
            });
            return;
        } else {
            this.setState({
                newPasswordConfirmationError: undefined
            });
        }
        const editPassword: EditPasswordParams = {
            currentPassword,
            newPassword
        };
        CustomerService.editPassword(editPassword)
            .then(() => {
                this.setState(
                    {
                        showSuccessMessage: true,
                        currentPasswordError: undefined
                    },
                    () => {
                        setTimeout(this.onCloseSuccessPopup, 3000);
                    }
                );
            })
            .catch(e => {
                console.log('catch', e);
                if (e.data.code === 'error.update.password.wrong.current.password') {
                    this.setState(
                        {
                            currentPasswordError: this.props.t('preferences.editPasswordComponent.currentPasswordError')
                        },
                        () => {
                            this.forceUpdate();
                        }
                    );
                } else {
                    // general error
                }
            });
    };
    private onChangeCurrentPassword = (value: string): void => {
        this.setState({ currentPassword: value });
    };
    private onBlurInput = (input?: string, key?: string): void => {
        if (key == null) {
            return;
        }
        let error: string | undefined;
        if (input == null || input.trim().length === 0) {
            error = this.props.t('preferences.editPasswordComponent.passwordRequired');
        } else if (input.length <= 4) {
            error = this.props.t('preferences.editPasswordComponent.passwordMinLength');
        } else if (
            this.state[key] != null &&
            this.state[key] !== this.props.t('preferences.editPasswordComponent.passwordRequired') &&
            this.state[key] !== this.props.t('preferences.editPasswordComponent.passwordMinLength')
        ) {
            error = this.state[key];
        }
        this.setState({
            [key]: error
        });
    };
    private onChangeNewPassword = (value: string): void => {
        this.setState({ newPassword: value });
    };
    private onChangeNewPasswordConfirmation = (value: string): void => {
        this.setState({ newPasswordConfirmation: value });
    };
    private onSetError = (value: boolean) => {
        this.setState({
            hasError: value
        });
    };
    private onCloseSuccessPopup = () => {
        this.setState({
            showSuccessMessage: false
        });
    };

    // Renders
    render() {
        const {
            currentPassword,
            newPassword,
            newPasswordConfirmation,
            currentPasswordError,
            newPasswordError,
            newPasswordConfirmationError,
            showSuccessMessage
        } = this.state;
        return (
            <div className="component">
                <div className="profile-title-row">
                    <span className="component__title">{this.props.t('preferences.editPasswordComponent.title')}</span>
                    {showSuccessMessage && (
                        <div className="profile-success-popup">
                            <span className="profile-success-popup__text">
                                {this.props.t('preferences.editPasswordComponent.successMessage')}
                            </span>
                            <a className="profile-success-popup__close-icon" onClick={this.onCloseSuccessPopup} />
                        </div>
                    )}
                </div>
                <div className="edit-password-content">
                    <div className="edit-password-inputs">
                        <TextFieldMeds
                            onChange={this.onChangeCurrentPassword}
                            id="current-password"
                            value={currentPassword}
                            onError={this.onSetError}
                            errorText={currentPasswordError}
                            error={currentPasswordError != null}
                            label={this.props.t('preferences.editPasswordComponent.currentPasswordPlaceholder')}
                            placeholder={this.props.t('preferences.editPasswordComponent.currentPasswordPlaceholder')}
                            isPassword
                            helpText={this.props.t('preferences.editPasswordComponent.passwordHelp')}
                            onBlur={() => this.onBlurInput(this.state.currentPassword, 'currentPasswordError')}
                        />
                        <TextFieldMeds
                            onChange={this.onChangeNewPassword}
                            id="new-password"
                            value={newPassword}
                            onError={this.onSetError}
                            errorText={newPasswordError}
                            error={newPasswordError != null}
                            label={this.props.t('preferences.editPasswordComponent.newPasswordPlaceholder')}
                            placeholder={this.props.t('preferences.editPasswordComponent.newPasswordPlaceholder')}
                            isPassword
                            style={{ marginTop: 25 }}
                            onBlur={() => this.onBlurInput(this.state.newPassword, 'newPasswordError')}
                        />
                        <TextFieldMeds
                            onChange={this.onChangeNewPasswordConfirmation}
                            id="new-password-confirmation"
                            value={newPasswordConfirmation}
                            onError={this.onSetError}
                            errorText={newPasswordConfirmationError}
                            error={newPasswordConfirmationError != null}
                            label={this.props.t('preferences.editPasswordComponent.newPasswordConfirmationPlaceholder')}
                            placeholder={this.props.t('preferences.editPasswordComponent.newPasswordConfirmationPlaceholder')}
                            isPassword
                            style={{ marginTop: 25 }}
                            onBlur={() => this.onBlurInput(this.state.newPasswordConfirmation, 'newPasswordConfirmationError')}
                        />
                    </div>
                    <div className="edit-password__button-container">
                        <Button
                            label={this.props.t('preferences.editPasswordComponent.save')}
                            onPress={this.onSavePress}
                            style={{ width: 191, height: 51, marginTop: 36 }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(EditPassword) as React.ComponentType<any>;
