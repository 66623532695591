import { Base64 } from 'js-base64';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../../components/loading/loading';
import ContentType from '../../../model/enums/contentType';
import LaboratoryContent from '../../../model/enums/laboratoryContent';
import { Laboratory } from '../../../model/laboratory/laboratory';
import { RecentHistory } from '../../../model/recentHistory';
import { User } from '../../../model/user';
import { IRootState } from '../../../reducer';
import { addInRecents } from '../../../reducer/recentHistory/actions';
import AnalyticsService from '../../../services/analytics-service';
import ContentRatingService from '../../../services/contentRatingService';
import laboratoryService from '../../../services/laboratoryService';
import LaboratoryTabContent from './laboratory-info-content';
import LaboratoryTab from './laboratory-info-tab';
import './laboratory-info.scss';

interface Props extends RouteComponentProps<{}, {}, { fromAnnotation?: boolean }> {
    t: any;
    hasSubscription?: boolean;
    account?: User;
    addInRecents: (recent: RecentHistory) => void;
}

interface State {
    laboratory?: Laboratory;
    fontSize?: number;
    hasSubscription?: boolean;
    showAnnotation?: boolean;
    observationsHeadingIndex?: number;
    changesHeadingIndex?: number;
    collectHeadingIndex?: number;
    generalHeadingIndex?: number;
    fromAnnotation?: boolean;
}

export class LaboratoryInfoScreen extends React.Component<Props, State> {
    constructor(props: Readonly<Props>, context?: any) {
        super(props, context);

        this.state = {
            hasSubscription: props.account && props.account.customer && props.account.customer.hasSubscription
        };
    }

    componentDidMount(): void {
        const {
            match: { params }
        } = this.props;
        const laboratoryId: number = (params as any).laboratoryId;
        const fromAnnotation: boolean | undefined = this.props.location?.state?.fromAnnotation;
        this.getLaboratory(laboratoryId);
        this.setState({ fromAnnotation });
    }

    componentDidUpdate(prevProps: Props, prevState: State): void {
        if (prevProps.match !== this.props.match) {
            const {
                match: { params }
            } = this.props;
            const laboratoryId: number = (params as any).laboratoryId;
            this.getLaboratory(laboratoryId);
        }
    }

    private getLaboratory = async (laboratoryId: number): Promise<void> => {
        const laboratory = await laboratoryService.getLaboratory(laboratoryId);
        AnalyticsService.laboratoryClick(laboratory);
        window.fbq('track', 'ViewContent', { content_name: laboratory.name, content_type: 'LABORATORY' });
        ContentRatingService.sendContentRating({ name: laboratory.name!, contentId: laboratoryId, type: ContentType.LABORATORY });
        this.props.addInRecents({ name: laboratory.name!, contentId: laboratoryId, type: ContentType.LABORATORY });
        this.setState({
            laboratory
        });
    };
    private onChangeFontSize = (fontSize: number): void => {
        this.setState({
            fontSize
        });
    };
    private onShowAnnotation = (): void => {
        this.setState({
            showAnnotation: !this.state.showAnnotation
        });
    };

    private onBack = (): void => {
        this.props.history.push({
            pathname: '/laboratory'
        });
    };

    private onChangeIndex = (type: LaboratoryContent) => {
        switch (type) {
            case LaboratoryContent.GENERAL:
                this.setState({
                    generalHeadingIndex: 0,
                    collectHeadingIndex: undefined,
                    changesHeadingIndex: undefined,
                    observationsHeadingIndex: undefined
                });
                break;
            case LaboratoryContent.COLLECT:
                this.setState({
                    generalHeadingIndex: undefined,
                    collectHeadingIndex: 0,
                    changesHeadingIndex: undefined,
                    observationsHeadingIndex: undefined
                });
                break;
            case LaboratoryContent.CHANGES:
                this.setState({
                    generalHeadingIndex: undefined,
                    collectHeadingIndex: undefined,
                    changesHeadingIndex: 0,
                    observationsHeadingIndex: undefined
                });
                break;
            case LaboratoryContent.OBSERVATIONS:
                this.setState({
                    generalHeadingIndex: undefined,
                    collectHeadingIndex: undefined,
                    changesHeadingIndex: undefined,
                    observationsHeadingIndex: 0
                });
                break;
        }
    };

    render(): JSX.Element {
        const {
            laboratory,
            fontSize,
            showAnnotation,
            generalHeadingIndex,
            collectHeadingIndex,
            changesHeadingIndex,
            observationsHeadingIndex
        } = this.state;
        if (laboratory == null) {
            return (
                <div className="disease-screen-container">
                    <Loading />
                </div>
            );
        }

        const generalMarkdown: string = laboratory.generalContent ? Base64.decode(laboratory.generalContent?.rawMarkdownAvailable) : '';
        const collectMarkdown: string = laboratory.collectContent ? Base64.decode(laboratory.collectContent?.rawMarkdownAvailable) : '';
        const changesMarkdown: string = laboratory.changesContent ? Base64.decode(laboratory.changesContent?.rawMarkdownAvailable) : '';
        const observationsMarkdown: string = laboratory.observationsContent
            ? Base64.decode(laboratory.observationsContent?.rawMarkdownAvailable)
            : '';

        let name: any = laboratory.name || generalMarkdown.match(/^# ([a-zA-Z].*)/g);
        if (!laboratory.name && name && name[0]) {
            name = String(name[0]).replace('# ', '');
        }

        return (
            <div className="disease-screen-container">
                <div className="screen__title">
                    <div className="screen__title__back" onClick={this.onBack}>
                        <div className="screen__title__back__img" />
                    </div>
                    <span className="screen__title__text">{name}</span>
                </div>
                <LaboratoryTab
                    laboratory={laboratory}
                    onBack={this.onBack}
                    general={() => (
                        <LaboratoryTabContent
                            {...this.props}
                            hasSubscription={this.props.hasSubscription}
                            key={'GENERAL'}
                            fontSize={fontSize}
                            laboratory={laboratory}
                            markdown={generalMarkdown}
                            tab={LaboratoryContent.GENERAL}
                            showAnnotation={showAnnotation}
                            onShowAnnotation={this.onShowAnnotation}
                            selectedHeadingIndex={generalHeadingIndex}
                            applicationPictures={laboratory.applicationPictures}
                        />
                    )}
                    collect={() => (
                        <LaboratoryTabContent
                            {...this.props}
                            hasSubscription={this.props.hasSubscription}
                            key={'COLLECT'}
                            fontSize={fontSize}
                            laboratory={laboratory}
                            markdown={collectMarkdown}
                            tab={LaboratoryContent.COLLECT}
                            showAnnotation={showAnnotation}
                            onShowAnnotation={this.onShowAnnotation}
                            selectedHeadingIndex={collectHeadingIndex}
                            applicationPictures={laboratory.applicationPictures}
                        />
                    )}
                    changes={() => (
                        <LaboratoryTabContent
                            {...this.props}
                            hasSubscription={this.props.hasSubscription}
                            key={'CHANGES'}
                            fontSize={fontSize}
                            laboratory={laboratory}
                            markdown={changesMarkdown}
                            tab={LaboratoryContent.CHANGES}
                            showAnnotation={showAnnotation}
                            onShowAnnotation={this.onShowAnnotation}
                            selectedHeadingIndex={changesHeadingIndex}
                            applicationPictures={laboratory.applicationPictures}
                        />
                    )}
                    observations={() => (
                        <LaboratoryTabContent
                            fontSize={fontSize}
                            key={'OBSERVATIONS'}
                            laboratory={laboratory}
                            markdown={observationsMarkdown}
                            showAnnotation={showAnnotation}
                            tab={LaboratoryContent.OBSERVATIONS}
                            onShowAnnotation={this.onShowAnnotation}
                            selectedHeadingIndex={observationsHeadingIndex}
                            applicationPictures={laboratory.applicationPictures}
                        />
                    )}
                    onChangeFontSize={this.onChangeFontSize}
                    onShowAnnotation={this.onShowAnnotation}
                    onChangeTab={this.onChangeIndex}
                    annotationIsOpen={this.state.fromAnnotation}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    isAuthenticated: authentication.isAuthenticated,
    hasSubscription: authentication.account && authentication.account.customer ? authentication.account.customer.hasSubscription : undefined
});

const mapDispatchToProps = {
    addInRecents: addInRecents
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LaboratoryInfoScreen) as React.ComponentType<any>;
