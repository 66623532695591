import { AxiosResponse } from 'axios';
import feedbackApi from '../api/feedbackApi';
import HttpStatus from '../model/enums/httpStatus';
import { FeedbackRequest } from '../model/feedback';

export const FeedbackService = () => {
    const sendFeedback = async (params: FeedbackRequest): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await feedbackApi.sendFeedback(params);
            console.log('result ', result);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve();
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    return {
        sendFeedback
    };
};

export default FeedbackService();
