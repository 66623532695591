enum CalculatorSpecificView {
    NONE = 'NONE',
    USG = 'USG',
    BENZO_CONVERSION = 'BENZO_CONVERSION',
    MINE_CONVERSION = 'MINE_CONVERSION',
    GLICO_CONVERSION = 'GLICO_CONVERSION',
    OPIOD_CONVERSION = 'OPIOD_CONVERSION'
}

export const ConversionViews: CalculatorSpecificView[] = [
    CalculatorSpecificView.BENZO_CONVERSION,
    CalculatorSpecificView.MINE_CONVERSION,
    CalculatorSpecificView.GLICO_CONVERSION,
    CalculatorSpecificView.OPIOD_CONVERSION
];

export default CalculatorSpecificView;