import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import CalculatorSpecificView from '../../../model/enums/calculator-specific-view';
import MedicalCalculatorParamType from '../../../model/enums/medical-calculator-param-type';
import { MedicalCalculatorParam } from '../../../model/medical-calculator/medical-calculator-param';
import { useMedicalCalculatorContext } from '../medical-calculator.provider';
import '../medical-calculator.scss';
import MedicalCalculatorConversionView from './medical-calculator-conversion-view';
import MedicalCalculatorDateParam from './medical-calculator-date-param';
import MedicalCalculatorInputParam from './medical-calculator-input-param';
import { ParamContainer, ParamTitle } from './medical-calculator-params-styles';
import MedicalCalculatorPickerParam from './medical-calculator-picker-param';
import MedicalCalculatorToggleParam from './medical-calculator-toggle-param';
import MedicalCalculatorUsgView from './medical-calculator-usg-view';

interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
}

const MedicalCalculatorParams = (props: Props) => {
    const { formulaParams } = useMedicalCalculatorContext().medicalCalculator ?? { formulaParams: undefined };
    const { medicalCalculator } = useMedicalCalculatorContext();

    const renderParam = (param: MedicalCalculatorParam) => {
        switch (param.inputType) {
            case MedicalCalculatorParamType.INPUT:
                return <MedicalCalculatorInputParam parameterInfo={param} />;
            case MedicalCalculatorParamType.TOGGLE:
                return <MedicalCalculatorToggleParam parameterInfo={param} />;
            case MedicalCalculatorParamType.DATE:
                return <MedicalCalculatorDateParam t={props.t} parameterInfo={param} />;
            case MedicalCalculatorParamType.MULTI_SELECT:
                return <MedicalCalculatorPickerParam parameterInfo={param} />;
            default:
                return <div />
        }
    }

    const renderSpecifcParams = () => {
        const params = medicalCalculator?.formulaParams ?? [];
        switch (medicalCalculator?.specificView) {
            case CalculatorSpecificView.GLICO_CONVERSION:
            case CalculatorSpecificView.OPIOD_CONVERSION:
            case CalculatorSpecificView.BENZO_CONVERSION:
            case CalculatorSpecificView.MINE_CONVERSION:
                return <MedicalCalculatorConversionView params={_.sortBy(params, 'position')} />;
            case CalculatorSpecificView.USG:
                return <MedicalCalculatorUsgView params={_.sortBy(params, 'position')} />;
            default:
                return <div />
        }
    }

    return medicalCalculator?.specificView !== CalculatorSpecificView.NONE ?
        renderSpecifcParams() : (
            <div>
                {_.sortBy(formulaParams, 'position').map(formulaParam => (
                    <div key={formulaParam.id!}>
                        <ParamContainer>
                            <ParamTitle>{formulaParam.presentationName}</ParamTitle>
                            {renderParam(formulaParam)}
                        </ParamContainer>
                    </div>
                ))}
            </div>
    );
}

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(MedicalCalculatorParams) as React.ComponentType<any>;
