enum FieldOfWork {
  BIOMEDICINE = 'BIOMEDICINE',
  NURSING = 'NURSING',
  PHARMACY = 'PHARMACY',
  PHYSIOTHERAPY = 'PHYSIOTHERAPY',
  SPEECH_THERAPY = 'SPEECH_THERAPY',
  MEDICINE = 'MEDICINE',
  NUTRITION = 'NUTRITION',
  DENTISTRY = 'DENTISTRY',
  PSYCHOLOGY = 'PSYCHOLOGY',
  OTHER = 'OTHER'
}

export default FieldOfWork;
