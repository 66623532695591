import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import AutoCompleteMeds from '../../../components/auto-complete-meds/auto-complete-meds';
import InlineSelect, { SelectOption } from '../../../components/inline-select/inline-select';
import { Search, SearchType } from '../../../model/search';
import { SearchResult } from '../../../model/searchResult';
import { User } from '../../../model/user';
import SearchService from '../../../services/searchService';
import { ResultItem } from '../menu-components';

export interface Props {
    t: (path: string, params?: any) => string;
    account: User;
    history: any;
    saveSearch: (search: Search) => void;
    openPaidDiseaseModal: () => void;
    setNavActive: (nav: any) => void;
}

export const HeaderSearch = (props: Props): JSX.Element => {
    const [category, setCategory] = useState<SearchType>(SearchType.GENERAL);
    const [search, setSearch] = useState<string>('');
    const [results, setResults] = useState<SearchResult[] | undefined>();
    const [searchIsBlurred, setSearchIsBlurred] = useState<boolean | undefined>();
    let typingWaiting;

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            window.removeEventListener('mousemove', handleClickOutside);
        };
    }, []);
    const handleClickOutside = event => {
        const searchDiv = document.getElementById('search-bar-container');
        if (searchDiv && !searchDiv.contains(event.target)) {
            setSearchIsBlurred(true);
        }
    };
    const mapSearchOptions = () => [
        { label: props.t('global.navs.all'), value: SearchType.GENERAL },
        { label: props.t('global.navs.specialty'), value: SearchType.SPECIALTY },
        { label: props.t('global.navs.laboratory'), value: SearchType.LABORATORY },
        { label: props.t('global.navs.semiology'), value: SearchType.SEMIOLOGY },
        { label: props.t('global.navs.specialty'), value: SearchType.SPECIALTY },
        { label: props.t('global.navs.score'), value: SearchType.MEDICAL_SCORE },
        { label: props.t('global.navs.criteria'), value: SearchType.MEDICAL_CRITERIA },
        { label: props.t('global.navs.smartBulas'), value: SearchType.SMART_BULAS },
        { label: props.t('global.navs.cid'), value: SearchType.CID },
        { label: props.t('global.navs.sus'), value: SearchType.SUS },
        { label: props.t('global.navs.procedure'), value: SearchType.ILLUSTRATED_PROCEDURE },
        { label: props.t('global.navs.calculator'), value: SearchType.MEDICAL_CALCULATOR }
    ];
    const onChangeCategory = (value: unknown) => {
        setCategory(value as SearchType);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const searchParam: Search = {
                search: search,
                type: category,
                limit: 5
            };
            getSearchResult(searchParam);
        }, 1500);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    const getSearchResult = async (searchParam: Search) => {
        const result: SearchResult[] = await SearchService.search(searchParam);
        setResults(result);
    };

    const onSearchEnter = () => {
        const searchParam: Search = {
            search,
            type: category,
            limit: 5
        };
        props.saveSearch(searchParam);

        props.history.push('/search');
        setSearchIsBlurred(true);
        setResults(undefined);
        setSearch('');
        props.setNavActive(undefined);
    };
    const onSearchBlur = () => {
        setTimeout(() => setSearchIsBlurred(true), 300);
    };
    const onSearchFocus = () => {
        setSearchIsBlurred(false);
    };
    const onClickSearchItem = (item: SearchResult) => {
        setResults(undefined);
        setSearch('');
        if (item.isPaid && !(props.account.customer && props.account.customer.hasSubscription)) {
            props.openPaidDiseaseModal();
            return;
        }
        switch (item.type) {
            case SearchType.SPECIALTY:
                // Para conseguir resetar o state da tela.
                props.history.push(`/s`);
                setTimeout(() => props.history.replace(`/disease/${item.id}`), 50);
                break;
            case SearchType.SMART_BULAS:
                // Para conseguir resetar o state da tela.
                props.history.push(`/s`);
                setTimeout(() => props.history.push(`/smart-bulas/${item.id}`), 50);
                break;
            case SearchType.CID:
                // Para conseguir resetar o state da tela.
                props.history.push(`/s`);
                setTimeout(() => props.history.push(`/cid/${item.id}`), 50);
                break;
            case SearchType.SUS:
                // Para conseguir resetar o state da tela.
                props.history.push(`/s`);
                setTimeout(() => props.history.push(`/sus/${item.id}`), 50);
                break;
            case SearchType.ILLUSTRATED_PROCEDURE:
                // Para conseguir resetar o state da tela.
                props.history.push(`/s`);
                setTimeout(() => props.history.push(`/procedure/${item.id}`), 50);
                break;
            case SearchType.MEDICAL_SCORE:
                // Para conseguir resetar o state da tela.
                props.history.push(`/s`);
                setTimeout(() => props.history.push(`/scores/${item.id}`), 50);
                break;
            case SearchType.PEDIATRIC_DOSE:
                // Para conseguir resetar o state da tela.
                props.history.push(`/s`);
                setTimeout(() => props.history.push(`/pediatric-dose/${item.id}`), 50);
                break;
            case SearchType.MEDICAL_CRITERIA:
                // Para conseguir resetar o state da tela.
                props.history.push(`/s`);
                setTimeout(() => props.history.push(`/criteria/${item.id}`), 50);
                break;
            case SearchType.MEDICAL_CALCULATOR:
                // Para conseguir resetar o state da tela.
                props.history.push(`/s`);
                setTimeout(() => props.history.push(`/calculator/${item.id}`), 50);
                break;
            default:
                props.history.push('/construction');
        }
    };

    const options: SelectOption[] = mapSearchOptions();

    const showResults = search != null && search.length > 0 && !searchIsBlurred;
    return (
        <div id="search-bar-container" className="search-bar-container">
            <div className="search-bar">
                <InlineSelect options={options} onChange={onChangeCategory} />
                <div className="search-container">
                    <AutoCompleteMeds
                        id="search-bar-input"
                        value={search}
                        onChange={setSearch}
                        placeholder={props.t(`global.action.searchFor${_.upperFirst(_.camelCase(category))}`)}
                        onEnterPress={onSearchEnter}
                        onBlur={onSearchBlur}
                        onFocus={onSearchFocus}
                    />
                </div>
            </div>
            {showResults && (
                <div className="search-results">
                    <a className="search-results__description" onClick={onSearchEnter}>
                        <span>
                            <span className="search-results__description__text">{props.t('header.searchFor1')}</span>
                            <span className="search-results__description__text search-results__description__text--bold">{search}</span>
                            <span className="search-results__description__text">
                                {props.t(`header.searchFor${_.upperFirst(_.camelCase(category))}`)}
                            </span>
                        </span>
                    </a>
                    {results != null && (
                        <div>
                            {results.map((it, index) => (
                                <ResultItem
                                    key={index}
                                    result={it}
                                    index={index}
                                    isLastItem={index === results.length - 1}
                                    onClick={onClickSearchItem}
                                    hasSubscription={props.account.customer && props.account.customer.hasSubscription}
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
