import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import '../../../App.scss';
import FontResizer from '../../../components/font-resizer/font-resizer';
import SemiologyContent from '../../../model/enums/SemiologyContent';
import { Favorite, FavoriteType } from '../../../model/favorite';
import { Semiology } from '../../../model/semiology/semiology';
import { IRootState } from '../../../reducer';
import FavoritesService from '../../../services/favoritesService';
import SemiologySearch from './semiology-info-search';

interface Props extends RouteComponentProps<{}> {
    t: any;
    semiology: Semiology;
    anamnesis: () => JSX.Element;
    physicalExam: () => JSX.Element;
    changes: () => JSX.Element;

    onChangeFontSize: (fontSize: number) => void;
    onShowAnnotation: () => void;
    onChangeTab: (tab: SemiologyContent) => void;

    onBack: () => void;

    annotationIsOpen?: boolean;
}

interface State {
    currentTab: SemiologyContent;

    showFontSlider?: boolean;
    fontSize: number;

    isFavorited?: boolean;
    showFavoriteSuccessToast?: boolean;
}

export class SemiologyTab extends React.Component<Props, State> {
    private sliderRef;
    constructor(props: Readonly<Props>, context?: any) {
        super(props, context);

        this.state = {
            currentTab: SemiologyContent.ANAMNESIS,
            fontSize: 13,
            isFavorited: props.semiology?.favorite && props.semiology?.favorite?.isActive
        };
    }
    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    private handleClickOutside = (event: any): void => {
        if (this.sliderRef && !this.sliderRef.contains(event.target) && this.state.showFontSlider) {
            this.setState({
                showFontSlider: false
            });
        }
    };
    private onClickTab = (tab: SemiologyContent) => {
        this.setState({
            currentTab: tab
        });
        this.props.onChangeTab(tab);
    };
    private onChangeSlider = (value: number): void => {
        this.setState({ fontSize: value});
        this.props.onChangeFontSize(value);
    };
    private onResizeFont = () => {
        this.setState({
            showFontSlider: !this.state.showFontSlider
        });
    };
    private onFavorite = async (): Promise<void> => {
        const { isFavorited } = this.state;
        const { semiology } = this.props;
        const params: Favorite = {
            type: FavoriteType.SEMIOLOGY,
            semiology: {
                id: semiology.id!
            },
            isActive: isFavorited ? false : undefined
        };
        FavoritesService.favorite(params).then(() => {
            this.setState(
                {
                    isFavorited: !isFavorited,
                    showFavoriteSuccessToast: !isFavorited
                },
                () => {
                    setTimeout(() => this.setState({ showFavoriteSuccessToast: false }), 3000);
                }
            );
        });
    };
    private onAnnotation = () => {
        this.props.onShowAnnotation();
    };

    private getCorrectRender = (): JSX.Element => {
        switch (this.state.currentTab) {
            case SemiologyContent.ANAMNESIS:
                return this.props.anamnesis();
            case SemiologyContent.PHYSICAL_EXAM:
                return this.props.physicalExam();
            case SemiologyContent.CHANGES:
                return this.props.changes();
            default:
                return <div />;
        }
    }

    private renderTab = (type: SemiologyContent) => {
        return (
            <a
                className={`tab__menu__tab ${this.state.currentTab === type ? 'tab__menu__tab--selected' : ''}`}
                onClick={() => this.onClickTab(type)}
            >
                <span className="tab__menu__tab__text">{this.props.t(`enum.semiologyContent.${type}`)}</span>
            </a>
        )
    }

    render(): JSX.Element {
        const { semiology, onBack } = this.props;
        const { showFontSlider, showFavoriteSuccessToast, isFavorited } = this.state;

        return (
            <div className="tab">
                <div className="tab__menu-containter">
                    <div className="tab__menu">
                        <div className="tab__menu__left">
                            <div className="tab__menu__left__tabs">
                                {semiology.anamnesisContent != null && this.renderTab(SemiologyContent.ANAMNESIS)}
                                {semiology.physicalExamContent != null && this.renderTab(SemiologyContent.PHYSICAL_EXAM)}
                                {semiology.changesContent != null && this.renderTab(SemiologyContent.CHANGES)}
                            </div>
                        </div>
                        <SemiologySearch
                            fontSliderIsOpen={this.state.showFontSlider}
                            annotationIsOpen={this.props.annotationIsOpen}
                            isFavorited={isFavorited}
                            onResizeFont={this.onResizeFont}
                            onFavorite={this.onFavorite}
                            onAnnotation={this.onAnnotation}
                            semiology={semiology}
                        />
                        {showFontSlider && (
                            <div className="disease-content__slider-container" ref={ref => (this.sliderRef = ref)}>
                                <FontResizer
                                    changeFontSize={this.onChangeSlider}
                                    fontSize={this.state.fontSize}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="tab__content">{this.getCorrectRender()}</div>

                {showFavoriteSuccessToast && (
                    <div className="disease-content__favorite-success-toast">
                        <div className="disease-content__white-star-img" />
                        <span className="disease-content__favorite-success-msg">{this.props.t('cid.favoriteSuccessMessage')}</span>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SemiologyTab) as React.ComponentType<any>;
