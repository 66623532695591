import { AxiosResponse } from 'axios';
import editorContentApi from '../api/editorContentApi';
import { EditorContent } from '../model/editorContent';
import HttpStatus from '../model/enums/httpStatus';
import { PageableResponse } from '../model/pageable';

export const EditorContentService = () => {
    const getEditorContents = async (): Promise<EditorContent[]> => {
        try {
            const result: AxiosResponse<PageableResponse<EditorContent>> = await editorContentApi.getEditorContents();
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data.content);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error as AxiosResponse);
        }
    };
    return {
        getEditorContents
    };
};

export default EditorContentService();
