import { AxiosResponse } from 'axios';
import flashcardCustomerApi from '../api/flashcard-customer.api';
import HttpStatus from '../model/enums/httpStatus';
import { FlashcardCustomer } from '../model/flashcard/flashcard-customer';

const FlashcardCustomerService = () => {
    const getFlashcardCustomers = async (): Promise<FlashcardCustomer[]> => {
        try {
            const result: AxiosResponse<FlashcardCustomer[]> = await flashcardCustomerApi.getMeFlashcardCustomers();
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const saveFlashcardCustomer = async (flashcardCustomer: FlashcardCustomer): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await flashcardCustomerApi.saveFlashcardCustomer(flashcardCustomer);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve();
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    return {
        getFlashcardCustomers,
        saveFlashcardCustomer
    };
};

export default FlashcardCustomerService();
