import { AxiosResponse } from 'axios';
import leafletsApi from '../api/leafletsApi';
import { GroupsLeaflets } from '../model/apiResponses/GroupsLeaflets';
import HttpStatus from '../model/enums/httpStatus';
import { MedicineLeaflet } from '../model/medicine-leaflet';
import { MedicineGroup } from '../model/medicineGroup';
import { PageableResponse } from '../model/pageable';

export const LeafletsService = () => {
    const searchGroups = async (params: any): Promise<PageableResponse<MedicineGroup>> => {
        try {
            const result: AxiosResponse<PageableResponse<MedicineGroup>> = await leafletsApi.searchGroups(params);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };    
    const searchLeaflets = async (params: any): Promise<any> => {
        try {
            const result: AxiosResponse<any> = await leafletsApi.searchLeaflets(params);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };    
    const getLeafletMarkdown = async (leafletId: number): Promise<MedicineLeaflet> => {
        try {
            const result: AxiosResponse<MedicineLeaflet> = await leafletsApi.getLeafletMarkdown(leafletId);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };    
    const getLeafletsAndGroups = async (parentId?: number): Promise<GroupsLeaflets> => {
        try {
            const result: AxiosResponse<GroupsLeaflets> = await leafletsApi.getLeafletsAndGroups(parentId);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    return {
        searchGroups,
        searchLeaflets,
        getLeafletMarkdown,
        getLeafletsAndGroups
    };
};

export default LeafletsService();
