export interface HomeListItem {
    id: number;
    itemId: number;
    name: string;
    isFree: boolean;
    subtitle: string;
    type: HomeListItemType;
}

export enum HomeListItemType {
    CID = "CID",
    DISEASE = "DISEASE",
    SCORE = "MEDICAL_SCORE",
    SEMIOLOGY = "SEMIOLOGY",
    FLASHCARD = "FLASHCARD",
    CRITERIA = 'MEDICAL_CRITERIA',
    LABORATORY = "LABORATORY",
    MEDICAL_CALCULATOR = "MEDICAL_CALCULATOR",
    PEDIATRIC_DOSE = "PEDIATRIC_DOSE",
    ILLUSTRATED_PROCEDURE = "ILLUSTRATED_PROCEDURE",
    SUS = "SUS",
    MEDICINE_LEAFLET = "MEDICINE_LEAFLET",
}