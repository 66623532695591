import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Annotations from '../../components/annotations/annotations';
import Loading from '../../components/loading/loading';
import TabHeader, { TabHeaderItem } from '../../components/tabs/tab-header';
import { Note, NoteType } from '../../model/note';
import { IRootState } from '../../reducer';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import MedicalCriteriaHeader from './medical-criteria-header';
import MedicalCriteriaList from './medical-criteria-list';
import MedicalCriteriaMarkdown from './medical-criteria-markdown';
import MedicalCriteriaParams from './medical-criteria-params/medical-criteria-params';
import MedicalCriteriaResult from './medical-criteria-result';
import { useMedicalCriteriaContext } from './medical-criteria.provider';
import './medical-criteria.scss';

interface Props
  extends RouteComponentProps<{}, {}, { fromAnnotation?: boolean }> {
  t: any;
  hasSubscription?: boolean;
  openPaidModal: () => void;
}

const MedicalCriteriaDashboard = (props: Props) => {
  const [tabItems] = useState<TabHeaderItem[]>([
    { index: 0, title: props.t('medicalCalculator.tabs.calculator') },
    { index: 1, title: props.t('medicalCalculator.tabs.interpretation') },
    { index: 2, title: props.t('medicalCalculator.tabs.evidence') },
  ]);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(true);
  const {
    medicalCriteria,
    showAnnotation,
    medicalCriteriaList,
    isLoading,
    setNewCurrentMedicalCriteria,
    setShowAnnotation,
    setMedicalCriteria,
  } = useMedicalCriteriaContext();

  useEffect(() => {
    if (medicalCriteria !== undefined) {
      if (props.location?.state?.fromAnnotation != null) {
        const fromAnnotation = props.location?.state?.fromAnnotation ?? false;
        setShowAnnotation(fromAnnotation);
      }
      setShowContent(true);
      setIsLoadingScreen(false);
    }
  }, [medicalCriteria]);

  useEffect(() => {
    if (medicalCriteriaList.length > 0) {
      const {
        match: { params },
      } = props;
      const medicalCriteriaId: number = (params as any).criteriaId ?? 1;
      setNewCurrentMedicalCriteria({ id: medicalCriteriaId });
    }
  }, [medicalCriteriaList]);

  const onSaveNote = (note: Note) => {
    setMedicalCriteria({ ...medicalCriteria, note });
  };

  return isLoadingScreen ? (
    <div className="disease-screen-container">
      <Loading />
    </div>
  ) : (
    <div className={'dose-main-container'}>
      <div className={'screen-dose-search-container'}>
        <MedicalCriteriaList {...props} />
      </div>
      <div
        className={'screen-dose-content-container'}
        style={{ display: showContent ? 'block' : 'none' }}
      >
        <div
          style={{
            display: isLoading ? 'flex' : 'none',
            height: '100%',
            alignItems: 'center',
            justifyItems: 'center',
          }}
        >
          <Loading />
        </div>
        <div style={{ display: isLoading ? 'none' : 'block', height: '100%' }}>
          <MedicalCriteriaHeader {...props} />
          <TabHeader tabs={tabItems}>
            <div>
              <MedicalCriteriaParams />
              <MedicalCriteriaResult />
            </div>
            <MedicalCriteriaMarkdown
              {...props}
              hasSubscription={props.hasSubscription}
              markdownContent={medicalCriteria?.interpretationText}
            />
            <MedicalCriteriaMarkdown
              {...props}
              hasSubscription={props.hasSubscription}
              markdownContent={medicalCriteria?.evidenceText}
            />
          </TabHeader>
        </div>
      </div>
      <div>
        {showAnnotation && (
          <Annotations
            note={medicalCriteria?.note}
            itemId={medicalCriteria?.id ?? 0}
            noteType={NoteType.MEDICAL_CRITERIA}
            saveCallback={onSaveNote}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  hasSubscription: authentication.account?.customer?.hasSubscription === true,
});

const mapDispatchToProps = {
  openPaidModal: openPaidDiseaseModal,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(MedicalCriteriaDashboard) as React.ComponentType<any>;
