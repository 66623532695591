import * as React from 'react';
import './custom-toggle-button.scss';

interface Props<T> {
    firstOption: T;
    secondOption: T;
    optionSelected: T;
    onChange: (option: T) => void;
}

interface Option {
    id?: number | null;
    presentationName?: string | null;
}

const CustomToggleButton = <T extends Option>(props: Props<T>) => {
    const { firstOption, secondOption, optionSelected } = props;
    const optionOneTextClass: string = optionSelected.id === firstOption.id ? 'custom-toggle-button__option__dark-text' : 'custom-toggle-button__option__white-text';
    const optionTwoTextClass: string = optionSelected.id === secondOption.id ? 'custom-toggle-button__option__dark-text' : 'custom-toggle-button__option__white-text';

    const onPress = () => {
        props.onChange(optionSelected.id === firstOption.id ? secondOption : firstOption);
    };

    return (
        <div className="custom-toggle-button" onClick={onPress}>
            <div className="custom-toggle-button__option">
                <div className="custom-toggle-button__option__content">
                    <span className={optionOneTextClass}>{firstOption.presentationName}</span>
                </div>
            </div>
            <div className="custom-toggle-button__option">
                <div className="custom-toggle-button__option__content">
                    <span className={optionTwoTextClass}>{secondOption.presentationName}</span>
                </div>
            </div>
            <div className={`custom-toggle-button__white-oval`} style={optionSelected.id === firstOption.id ? { left: 2 } : { right: 2 }} />
        </div>
    );
}

export default CustomToggleButton;