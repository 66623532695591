import { FormControl } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import toaster from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import '../../../App.scss';
import Button from '../../../components/button/button';
import CustomToast from '../../../components/custom-toast/custom-toast';
import TextFieldMeds from '../../../components/text-field-meds/text-field-meds';
import { Authentication } from '../../../model/authentication';
import { CustomerRegister } from '../../../model/customer-register';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { loginRequest } from '../../../reducer/authentication/actions';
import RegisterService from '../../../services/registerService';
import authUtil from '../../../api/authUtil';

export interface LoginStepState {
    email?: string;
    password?: string;
    name?: string;
    emailAlreadyExists?: boolean;
    hasErrorLogin: boolean;
    errorMessage?: string;
}

export interface LoginStepProps extends RouteComponentProps<{}> {
    register: (customer: CustomerRegister) => void;
    handleFinishedFirstStep: (customerRegister: CustomerRegister) => void;
    login: (authentication: Authentication) => void;
    setShowRegisterScreen: (show: boolean) => void;
    loginStatus: HttpRequestStatus;
    t: any;
}

export class LoginStep extends React.Component<LoginStepProps, LoginStepState> {
    constructor(props: Readonly<LoginStepProps>, context?: any) {
        super(props, context);
        this.state = {
            hasErrorLogin: false
        };
    }

    componentWillReceiveProps(newProps: LoginStepProps) {
        if (newProps.loginStatus === HttpRequestStatus.SUCCESS) {
            if (authUtil.hasCompanyManagerPermission()) {
                this.props.history.push('/company-dashboard');
            } else {
                this.props.history.push('/home');
            }
        }
        if (newProps.loginStatus === HttpRequestStatus.ERROR) {
            this.setState({
                hasErrorLogin: true,
                errorMessage: this.props.t('login.form.badCredentials')
            });
        }
    }

    private checkIfEmailAlreadyRegistered = () => {
        RegisterService.checkIfEmailAlreadyRegistered(this.state.email ?? '').then((it: boolean) => {
            this.props.setShowRegisterScreen(!it);
            this.setState({
                emailAlreadyExists: it
            });
        });
    };

    private onClickNextButton = () => {
        switch (this.state.emailAlreadyExists) {
            case true:
                this.handleLogin();
                break;
            case false:
                this.handleSubmit();
                break;
            default:
                this.checkIfEmailAlreadyRegistered();
        }
    };

    private handleLogin = () => {
        if (!this.state.hasErrorLogin && !_.isEmpty(this.state.email) && !_.isEmpty(this.state.password)) {
            const authentication: Authentication = {
                username: this.state.email,
                password: this.state.password
            };
            this.props.login(authentication);
        } else {
            toaster.notify(
                callback => <CustomToast title={this.props.t('register.error.step-1')} onClose={callback.onClose} t={this.props.t} />,
                {
                    duration: 3000,
                    position: 'bottom'
                }
            );
        }
    };

    private handleSubmit = () => {
        const { email, password, name } = this.state;
        if (!this.state.hasErrorLogin && !_.isEmpty(email) && !_.isEmpty(password) && !_.isEmpty(name)) {
            const customerRegister: CustomerRegister = {
                email: email!,
                password: password!,
                customer: {
                    name
                }
            };
            this.props.handleFinishedFirstStep(customerRegister);
        } else {
            toaster.notify(
                callback => <CustomToast title={this.props.t('login.errorData')} onClose={callback.onClose} t={this.props.t} />,
                {
                    duration: 3000,
                    position: 'bottom'
                }
            );
        }
    };

    private onSetErrorLogin = (value: boolean) => {
        this.setState({
            hasErrorLogin: value,
            errorMessage: undefined
        });
    };

    render() {
        return (
            <div>
                <div className="register-container">
                    <Row className="justity-content-center">
                        <b>
                            <p className={'main-text'}>{this.props.t('register.welcome')}</p>
                        </b>
                    </Row>
                    <Row className="justity-content-center">
                        <p className={'sub-text'}>{this.props.t('register.text1')}</p>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md="8">
                            <FormControl className="form" style={{ width: '100%' }}>
                                <TextFieldMeds
                                    onChange={(text: string) =>
                                        this.setState({
                                            email: text
                                        })
                                    }
                                    id={'username'}
                                    onError={this.onSetErrorLogin}
                                    error={this.state.hasErrorLogin}
                                    isEmail
                                    value={this.state.email}
                                    errorText={this.state.errorMessage ?? this.props.t('register.form.errorEmail')}
                                    label={this.props.t('register.form.username')}
                                    placeholder={this.props.t('register.form.usernamePlaceholder')}
                                />

                                {this.state.emailAlreadyExists === false && (
                                    <TextFieldMeds
                                        id={'name'}
                                        onChange={(text: string) =>
                                            this.setState({
                                                name: text
                                            })
                                        }
                                        value={this.state.name}
                                        errorText={this.props.t('register.form.errorEmail')}
                                        label={this.props.t('register.form.name')}
                                        placeholder={this.props.t('register.form.namePlaceholder')}
                                    />
                                )}

                                {this.state.emailAlreadyExists != null && (
                                    <TextFieldMeds
                                        id={'password'}
                                        value={this.state.password}
                                        onChange={(text: string) =>
                                            this.setState({
                                                password: text
                                            })
                                        }
                                        isPassword
                                        helpText={this.props.t('register.form.helpPass')}
                                        label={this.props.t('register.form.password')}
                                        placeholder={this.props.t('register.form.passwordPlaceholder')}
                                    />
                                )}
                            </FormControl>
                        </Col>
                    </Row>

                    <div className="button-container">
                        <Row className="justify-content-center">
                            <Button
                                label={this.props.t('global.button.nextStep')}
                                onPress={this.onClickNextButton}
                                style={{ justifyContent: 'center', display: 'flex', width: '170px' }}
                                rightIcon={<div className="steps__footer__advance-icon" />}
                            />
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    login: loginRequest
};

export default compose(connect(null, mapDispatchToProps), withTranslation())(LoginStep) as React.ComponentType<any>;
