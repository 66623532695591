import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Button from '../../components/button/button';
import { Interaction } from '../../model/interaction';
import { MedicineLeaflet } from '../../model/medicine-leaflet';
import { User } from '../../model/user';
import { IRootState } from '../../reducer';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import DropdownSelectSearch from './dropdown-search';
import './medicine-interactions.scss';

export interface SelectMedicinesProps extends RouteComponentProps<{}> {
    t: (path: string, params?: any) => string;
    getInteractions: (medicineLeaflets: MedicineLeaflet[]) => Promise<void>;

    user?: User;
    openPaidDiseaseModal: () => void;
}

export interface SelectMedicinesState {
    selectedDropdown?: number;
    dropdownInputs: any[];
    showButtons: boolean;

    interactions?: Interaction[];
}

export class SelectMedicines extends React.Component<SelectMedicinesProps, SelectMedicinesState> {
    constructor(props: Readonly<SelectMedicinesProps>, context?: any) {
        super(props, context);

        this.state = {
            dropdownInputs: [{}, {}],
            showButtons: false
        };
    }
    // Handlers
    private onSelectMedicine = (item: any): void => {
        const dropdownInputs: any[] = this.state.dropdownInputs;
        if (dropdownInputs.length === 2) {
            if (_.isEmpty(dropdownInputs[0])) {
                this.setState({
                    dropdownInputs: [item, {}]
                });
            } else {
                this.setState({
                    dropdownInputs: [dropdownInputs[0], item, {}],
                    showButtons: true
                });
            }
        } else {
            dropdownInputs[dropdownInputs.length - 1] = item;
            this.setState({
                dropdownInputs: dropdownInputs.concat({})
            });
        }
    };
    private onRemoveMedicine = (index: number): void => {
        const { dropdownInputs } = this.state;
        if (dropdownInputs.length > 2) {
            dropdownInputs.splice(index, 1);
        } else {
            dropdownInputs[index] = {};
        }
        this.setState({
            dropdownInputs,
            showButtons: dropdownInputs.length >= 3
        });
    };
    private onCleanFilters = (): void => {
        this.setState({
            dropdownInputs: [{}, {}],
            showButtons: false
        });
    };
    private onCheckInteraction = (): void => {
        const { user } = this.props;
        const { dropdownInputs } = this.state;
        const hasSubscription: boolean = user && user.customer && user.customer.hasSubscription || false;
        if (hasSubscription) {
            this.props.getInteractions(dropdownInputs.filter(it => it.id).map(it => ({ id: it.id, name: it.name, composition: it.composition })));
        } else {
            this.props.openPaidDiseaseModal();
        }
    };
    private onOpenDropdown = (index: number): void => {
        this.setState({
            selectedDropdown: index
        });
    }

    // Renders
    render() {
        const { dropdownInputs, showButtons, selectedDropdown } = this.state;
        return (
                <div className="medicine-interactions-screen__content">
                    <div style={{ marginBottom: 35 }}>
                        <h1 className="screen-title">{this.props.t('medicineInteractions.title')}</h1>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <div className="select-medicines">
                            <p className="select-medicines__title">
                                {this.props.t('medicineInteractions.selectMedicines.title').toUpperCase()}
                            </p>
                            <p className="select-medicines__subtitle">{this.props.t('medicineInteractions.selectMedicines.subtitle')}</p>
                            <div className="select-medicines__inputs">
                                {dropdownInputs.map((it, index) => (
                                    <DropdownSelectSearch
                                        key={index}
                                        item={it}
                                        inputNumber={index + 1}
                                        onClickItem={this.onSelectMedicine}
                                        onRemove={() => this.onRemoveMedicine(index)}
                                        onOpen={() => this.onOpenDropdown(index)}
                                        close={index !== selectedDropdown}
                                    />
                                ))}
                            </div>
                            {showButtons && (
                                <div className="select-medicines__buttons">
                                    <p className="select-medicines__buttons__clean-filters" onClick={this.onCleanFilters}>
                                        {this.props.t('medicineInteractions.selectMedicines.cleanFilters')}
                                    </p>
                                    <Button
                                        label={this.props.t('medicineInteractions.selectMedicines.checkInteractionButton')}
                                        onPress={this.onCheckInteraction}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    user: authentication.account
});

const mapDispatchToProps = {
    openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SelectMedicines) as React.ComponentType<any>;
