import React, { createContext, useContext, useEffect, useState } from 'react';
import ContentType from '../../model/enums/contentType';
import { PediatricDose, PediatricDoseSimple } from '../../model/pediatric-dose/pediatric-dose';
import ContentRatingService from '../../services/contentRatingService';
import PediatricDoseService from '../../services/pediatric-dose.service';

interface PediatricDoseProviderProps {
    children: any;
}

interface PediatricDoseProviderData {
    inError: boolean;
    inputValue: string | null;
    fontSize: number;
    showAnnotation: boolean;
    showResizerModal: boolean;
    pediatricDoseIsLoading: boolean;
    showFavoriteSuccessToast: boolean;
    pediatricDoseList: PediatricDose[];
    currentPediatricDose?: PediatricDose;
    setFontSize: (value: number) => void;
    setInError: (inError: boolean) => void;
    setInputValue: (value: string | null) => void;
    setShowAnnotation: (value: boolean) => void;
    setShowResizerModal: (value: boolean) => void;
    setShowFavoriteSuccessToast: (value: boolean) => void;
    setCurrentPediatricDose: (dose: PediatricDose) => void;
    setPediatricDoseList: (doses: PediatricDoseSimple[]) => void;
    setNewCurrentPediatricDose: (dose: PediatricDose) => void;
    setInput: (newValue: string | null, valueIsValid: boolean) => void;
}

const PediatricDoseContext = createContext<PediatricDoseProviderData>({} as PediatricDoseProviderData);

export const PediatricDoseProvider = ({ children }: PediatricDoseProviderProps) => {
    const [inError, setInError] = useState<boolean>(false);
    const [fontSize, setFontSize] = useState<number>(14);
    const [showAnnotation, setShowAnnotation] = useState<boolean>(false);
    const [showResizerModal, setShowResizerModal] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string | null>(null);
    const [pediatricDoseIsLoading, setPediatricDoseIsLoading] = useState<boolean>(false);
    const [pediatricDoseList, setPediatricDoseList] = useState<PediatricDoseSimple[]>([]);
    const [showFavoriteSuccessToast, setShowFavoriteSuccessToast] = useState<boolean>(false);
    const [currentPediatricDose, setCurrentPediatricDose] = useState<PediatricDose | undefined>();

    const setInput = (newValue: string | null, valueIsValid: boolean) => {
        setInputValue(valueIsValid ? newValue : null);
        setInError(!valueIsValid);
    };

    const setNewCurrentPediatricDose = (pediatricDose: PediatricDoseSimple) => {
        setPediatricDoseIsLoading(true);
        setShowAnnotation(false);
        setShowFavoriteSuccessToast(false);
        PediatricDoseService.getInfo(pediatricDose.id!)
            .then(newPediatricDose => {
                if(newPediatricDose.inputType != currentPediatricDose?.inputType) {
                    setInput('', true);
                }
                setCurrentPediatricDose(newPediatricDose);
            })
            .finally(() => setPediatricDoseIsLoading(false));
        window.fbq('track', 'ViewContent', { content_name: pediatricDose.name, content_type: 'PEDIATRIC_DOSE' });
        ContentRatingService.sendContentRating({ name: pediatricDose.name!, contentId: pediatricDose.id!, type: ContentType.PEDIATRIC_DOSE });
    };

    return (
        <PediatricDoseContext.Provider
            value={{
                setInput,
                setFontSize,
                inError,
                setInError,
                fontSize,
                inputValue,
                setInputValue,
                pediatricDoseList,
                setPediatricDoseList,
                showAnnotation,
                setShowAnnotation,
                currentPediatricDose,
                setNewCurrentPediatricDose,
                setCurrentPediatricDose,
                showResizerModal,
                setShowResizerModal,
                pediatricDoseIsLoading,
                showFavoriteSuccessToast,
                setShowFavoriteSuccessToast
            }}
        >
            {children}
        </PediatricDoseContext.Provider>
    );
};

export const usePediatricDoseContext = () => useContext(PediatricDoseContext);
