import { Base64 } from 'js-base64';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../components/loading/loading';
import { Disease } from '../../model/disease';
import ContentType from '../../model/enums/contentType';
import { RecentHistory } from '../../model/recentHistory';
import { SpecialtyType } from '../../model/SpecialtyType';
import { IRootState } from '../../reducer';
import { logoutRequest } from '../../reducer/authentication/actions';
import { addInRecents } from '../../reducer/recentHistory/actions';
import AnalyticsService from '../../services/analytics-service';
import ContentRatingService from '../../services/contentRatingService';
import DiseaseService from '../../services/diseaseService';
import DiseaseTab, { DiseaseTabEnum } from './disease-tab';
import DiseaseTabContent from './disease-tab-content';
import './disease.scss';

export interface DiseaseScreenProps
    extends RouteComponentProps<{}, {}, { diseaseId: number; category: SpecialtyType; fromAnnotation?: boolean }> {
    logout: () => void;
    t: any;
    hasSubscription?: boolean;
    addInRecents: (recent: RecentHistory) => void;
}

export interface DiseaseScreenState {
    disease?: Disease;
    category?: SpecialtyType;
    screenToBack?: string;
    fontSize?: number;
    showAnnotation?: boolean;
    fromAnnotation?: boolean;
    diagnosticHeadingIndex?: number;
    treatmentHeadingIndex?: number;
}

export class DiseaseScreen extends React.Component<DiseaseScreenProps, DiseaseScreenState> {
    constructor(props: Readonly<DiseaseScreenProps>, context?: any) {
        super(props, context);

        this.state = {};
    }

    componentDidMount(): void {
        const {
            match: { params },
            location: { state }
        } = this.props;
        const diseaseId: number = (params as any).diseaseId;
        const category: SpecialtyType | undefined = this.props.location?.state?.category;
        const fromAnnotation: boolean | undefined = this.props.location?.state?.fromAnnotation;
        const screenToBack: string | undefined = (state as any)?.screenToBack;
        this.getDisease(diseaseId);
        this.setState({ category, screenToBack, fromAnnotation });
    }

    componentDidUpdate(prevProps: DiseaseScreenProps, prevState: DiseaseScreenState): void {
        if (prevProps.match !== this.props.match) {
            const {
                match: { params }
            } = this.props;
            const diseaseId: number = (params as any).diseaseId;
            this.getDisease(diseaseId);
        }
    }

    private getDisease = async (diseaseId: number): Promise<void> => {
        const response: Disease = await DiseaseService.getDisease(diseaseId);
        AnalyticsService.diseaseClick(response);
        window.fbq('track', 'ViewContent', { content_name: response.name, content_type: 'SPECIALITY' });
        ContentRatingService.sendContentRating({ name: response.name!, contentId: response.id!, type: ContentType.DISEASE });
        this.props.addInRecents({ name: response.name!, contentId: diseaseId, type: ContentType.DISEASE });
        this.setState({
            disease: response
        });
    };
    private onChangeFontSize = (fontSize: number): void => {
        this.setState({
            fontSize
        });
    };
    private onShowAnnotation = (): void => {
        this.setState({
            showAnnotation: !this.state.showAnnotation
        });
    };

    private onBack = (): void => {
        this.props.history.push({
            pathname: this.state.screenToBack ?? '/specialty',
            state: {
                diseaseId: this.state.disease?.id!,
                category: this.state.category!
            }
        });
    };

    private onChangeIndex = (tab: DiseaseTabEnum) => {
        if (tab === DiseaseTabEnum.DIAGNOSTIC) {
            this.setState({
                diagnosticHeadingIndex: 0,
                treatmentHeadingIndex: undefined
            });
        } else {
            this.setState({
                treatmentHeadingIndex: 0,
                diagnosticHeadingIndex: undefined
            });
        }
    };

    render(): JSX.Element {
        const { disease, fontSize, showAnnotation, diagnosticHeadingIndex, treatmentHeadingIndex } = this.state;
        if (disease == null) {
            return (
                <div className="disease-screen-container">
                    <Loading />
                </div>
            );
        }
        const diagnosticMarkdown: string = disease.diagnostic ? Base64.decode(disease.diagnostic.content.rawMarkdownAvailable) : '';
        const treatmentMarkdown: string = disease.treatment ? Base64.decode(disease.treatment.content.rawMarkdownAvailable) : '';

        let diseaseName: any = disease.name || diagnosticMarkdown.match(/^# ([a-zA-Z].*)/g);
        if (!disease.name && diseaseName && diseaseName[0]) {
            diseaseName = String(diseaseName[0]).replace('# ', '');
        }

        let cid: any = disease.cid || diagnosticMarkdown.match(/CID: (.*)(?=`)/g);
        if (!disease.cid && cid && cid[0]) {
            cid = String(cid[0]).replace('CID: ', '');
        }
        return (
            <div className="disease-screen-container">
                <DiseaseTab
                    {...this.props}
                    disease={disease}
                    title={diseaseName}
                    cid={cid}
                    onBack={this.onBack}
                    diagnostic={() => (
                        <DiseaseTabContent
                            {...this.props}
                            disease={disease}
                            applicationPictures={disease.applicationPictures}
                            markdown={diagnosticMarkdown}
                            tab={DiseaseTabEnum.DIAGNOSTIC}
                            fontSize={fontSize}
                            showAnnotation={showAnnotation}
                            onShowAnnotation={this.onShowAnnotation}
                            hasSubscription={this.props.hasSubscription}
                            selectedHeadingIndex={diagnosticHeadingIndex}
                        />
                    )}
                    treatment={() => (
                        <DiseaseTabContent
                            {...this.props}
                            disease={disease}
                            applicationPictures={disease.applicationPictures}
                            markdown={treatmentMarkdown}
                            tab={DiseaseTabEnum.TREATMENT}
                            fontSize={fontSize}
                            showAnnotation={showAnnotation}
                            onShowAnnotation={this.onShowAnnotation}
                            hasSubscription={this.props.hasSubscription}
                            selectedHeadingIndex={treatmentHeadingIndex}
                        />
                    )}
                    onChangeFontSize={this.onChangeFontSize}
                    onShowAnnotation={this.onShowAnnotation}
                    onChangeTab={this.onChangeIndex}
                    annotationIsOpen={this.state.fromAnnotation}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    isAuthenticated: authentication.isAuthenticated,
    hasSubscription: authentication.account && authentication.account.customer ? authentication.account.customer.hasSubscription : undefined,
    logout: logoutRequest
});

const mapDispatchToProps = {
    addInRecents: addInRecents
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(DiseaseScreen) as React.ComponentType<any>;
