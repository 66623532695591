import Mark from 'mark.js';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import '../../../App.scss';
import { Laboratory } from '../../../model/laboratory/laboratory';
import { IRootState } from '../../../reducer';

export interface Props extends RouteComponentProps<{}> {
    t: any;
    isFavorited?: boolean;
    annotationIsOpen?: boolean;
    fontSliderIsOpen?: boolean;
    onFavorite: () => void;
    onResizeFont: () => void;
    onAnnotation: () => void;
    laboratory?: Laboratory;
}

export interface State {
    fontClassName: string;
    starClassName: string;
    pencilClassName: string;

    isFavorited?: boolean;
    isShowingFontSlider?: boolean;
    annotationIsOpen?: boolean;

    searchInput: string;
}

export class LaboratorySearch extends React.Component<Props, State> {
    constructor(props: Readonly<Props>, context?: any) {
        super(props, context);

        this.state = {
            fontClassName: props.fontSliderIsOpen ? 'disease-search__font-img disease-search__font-img--orange' : 'disease-search__font-img',
            starClassName: props.isFavorited ? 'disease-search__star-img disease-search__star-img--orange' : 'disease-search__star-img',
            pencilClassName: props.annotationIsOpen ? 'disease-search__pencil-img disease-search__pencil-img--orange' : 'disease-search__pencil-img',
            annotationIsOpen: props.annotationIsOpen,
            searchInput: ''
        };

        props.annotationIsOpen && this.props.onAnnotation();
    }

    componentDidMount = () => {
        if (this.props.laboratory?.note?.content) {
            this.setState({
                pencilClassName: 'disease-search__pencil-img disease-search__pencil-img--orange' 
            })
        }
    }

    componentWillReceiveProps(newProps: Props): void {
        if (newProps.isFavorited !== this.props.isFavorited) {
            this.setState({
                starClassName: newProps.isFavorited ? 'disease-search__star-img disease-search__star-img--orange' : 'disease-search__star-img'
            })
        }
        if (newProps.fontSliderIsOpen !== this.props.fontSliderIsOpen) {
            this.setState({
                fontClassName: newProps.fontSliderIsOpen ? 'disease-search__font-img disease-search__font-img--orange' : 'disease-search__font-img'
            })
        }
    }
    private onChangeSearch = (event): void => {
        const value: string = event.target.value || '';
        this.setState(
            {
                searchInput: value
            },
            () => {
                const { searchInput } = this.state;
                const instance = new Mark(document.querySelector('div.markdown'));
                if (searchInput.trim().length > 0) {
                    instance.unmark();
                    instance.mark(searchInput, {
                        element: 'span',
                        className: 'markdown__highlight'
                    });
                } else {
                    instance.unmark();
                }
            }
        );
    };

    private onResizeFont = (): void => {
        const { isShowingFontSlider } = this.state;
        this.setState({
            fontClassName: isShowingFontSlider ? 'disease-search__font-img' : 'disease-search__font-img disease-search__font-img--orange',
            isShowingFontSlider: !isShowingFontSlider
        });
        this.props.onResizeFont();
    };
    private onAnnotation = (): void => {
        const { annotationIsOpen } = this.state;
        this.setState({
            pencilClassName: annotationIsOpen ? 'disease-search__pencil-img' : 'disease-search__pencil-img disease-search__pencil-img--orange',
            annotationIsOpen: !annotationIsOpen
        });
        this.props.onAnnotation();
    };
    private onFavorite = (): void => {
        this.props.onFavorite();
    };

    render(): JSX.Element {
        const { fontClassName, pencilClassName, starClassName } = this.state;
        return (
            <div className="disease-search">
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="disease-search__search-icon" />
                    <input placeholder={'Buscar no texto'} className="disease-search__text-input" onChange={this.onChangeSearch} />
                </div>
                <div className="disease-search__menu">
                    <a className={fontClassName} onClick={this.onResizeFont} />
                    <a className={pencilClassName} onClick={this.onAnnotation} />
                    <a className={starClassName} onClick={this.onFavorite} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LaboratorySearch) as React.ComponentType<any>;
