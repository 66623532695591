import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import DiagnAppRadio, { RadioOption } from '../../../components/diagnapp-radio/diagnapp-radio';
import SwitchButton from '../../../components/switch-button/switch-button';
import Gender from '../../../model/enums/gender';
import Pregnant from '../../../model/enums/pregnant';
import { IRootState } from '../../../reducer';
import '../diagnapp.scss';

export interface GenderComponentProps extends RouteComponentProps<{}> {
    t: (path: string, params?: any) => string;
    onChangeInput: (gender: Gender, pregnantType?: Pregnant) => void;
    hasPregnantOptions: () => boolean;
    gender?: Gender;
    pregnantType?: Pregnant;
}

export interface GenderComponentState {
    gender: Gender;
    pregnantType?: Pregnant;
}

export class GenderComponent extends React.Component<GenderComponentProps, GenderComponentState> {
    private pregnantOptions: RadioOption[] = [
        { label: this.props.t('diagnApp.genderComponent.pregnant'), data: Pregnant.PREGNANT },
        { label: this.props.t('diagnApp.genderComponent.notPregnant'), data: Pregnant.NOT_PREGNANT },
        { label: this.props.t('diagnApp.genderComponent.undefined'), data: Pregnant.UNDEFINED }
    ];

    constructor(props: Readonly<GenderComponentProps>, context?: any) {
        super(props, context);

        this.state = {
            gender: props.gender || Gender.FEMALE,
            pregnantType: props.pregnantType
        };
    }

    // Handlers
    private onChangeGender = (): void => {
        this.setState(
            {
                gender: this.state.gender === Gender.FEMALE ? Gender.MALE : Gender.FEMALE
            },
            () => {
                this.props.onChangeInput(this.state.gender, this.state.pregnantType);
            }
        );
    };
    private onChangePregnant = (pregnantType: Pregnant): void => {
        this.setState({ pregnantType }, () => {
            this.props.onChangeInput(this.state.gender, this.state.pregnantType);
        });
    };

    // Renders
    render() {
        const { hasPregnantOptions } = this.props;
        const { gender, pregnantType } = this.state;
        const showPregnantOptions: boolean = hasPregnantOptions() && gender === Gender.FEMALE;
        console.log('render gender', { props: this.props, state: this.state });
        return (
            <div className="gender-component">
                <p className="gender-component__title">{this.props.t('diagnApp.genderComponent.title').toUpperCase()}</p>
                <SwitchButton
                    onPress={this.onChangeGender}
                    optionOneText={this.props.t('diagnApp.genderComponent.female')}
                    optionOneIcon={require('../../../images/female-2.svg')}
                    optionTwoText={this.props.t('diagnApp.genderComponent.male')}
                    optionTwoIcon={require('../../../images/male-2.svg')}
                    optionSelected={gender === Gender.FEMALE ? 0 : 1}
                    iconStyle={{ width: 24, height: 24, marginRight: 6 }}
                />
                {showPregnantOptions && (
                    <div className="gender-component__pregnant">
                        <DiagnAppRadio
                            options={this.pregnantOptions}
                            onPress={this.onChangePregnant}
                            centeredOption
                            initialIndex={
                                pregnantType === Pregnant.PREGNANT
                                    ? 0
                                    : pregnantType === Pregnant.NOT_PREGNANT
                                    ? 1
                                    : pregnantType === Pregnant.UNDEFINED
                                    ? 2
                                    : undefined
                            }
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({}: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(GenderComponent) as React.ComponentType<any>;
