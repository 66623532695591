import _ from 'lodash';
import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createFileName, useScreenshot } from 'use-react-screenshot';
import { OnDutyDoctor, useDividerOnDutyContext } from '../context/divider-on-duty-provider';
import '../divider-on-duty.scss';
import { DoctorIndexContainer, Index, Info, InfoContainer, LineContainer, NameInput, ScaleTime, StepMainContainer } from '../on-duty-styles';
import ScalePrint from './scale-print';

interface Props {
    t: any;
    downloadScale: boolean;
    setDownloadScale: (value: boolean) => void;
}

export interface DoctorsScale {
    doctor: OnDutyDoctor;
    startHour: Moment;
    endHour: Moment;
}

const ShareStep = (props: Props) => {
    const ref = useRef<any>(null);
    const [image, takeScreenShot] = useScreenshot({
        type: "image/png",
        quality: 2.0
    });
    // const viewShotRef: any = useRef(null);
    const [scales, setScales] = useState<DoctorsScale[]>([]);
    const { startTime, endTime, doctors } = useDividerOnDutyContext();
    const [doctorsWithMinutesLeft, setDoctorsWithMinutesLeft] = useState<OnDutyDoctor[]>([]);

    useEffect(() => {
        const newScales: DoctorsScale[] = [];
        const minutesDiff = Math.round(moment.duration(endTime.diff(startTime)).asMinutes());
        const minutesPerDoctor = Math.floor(minutesDiff / doctors.length);
        let newMinutesLeft = Math.floor(minutesDiff % doctors.length);
        const newDoctorsWithMinutesLeft: OnDutyDoctor[] = [];
        _.sortBy(doctors, 'index').forEach((doctor, index) => {
            const startHour = index === 0 ? moment(startTime) : newScales[index - 1].endHour;
            let endHour = moment(startHour).add(minutesPerDoctor, 'minutes');
            if (newMinutesLeft > 0) {
                newDoctorsWithMinutesLeft.push(doctor);
                endHour = moment(startHour).add(minutesPerDoctor + 1, 'minutes');
                newMinutesLeft -= 1;
            }
            const scale: DoctorsScale = { doctor, startHour, endHour };
            newScales.push(scale);
        });
        setDoctorsWithMinutesLeft(newDoctorsWithMinutesLeft);
        setScales(newScales);
    }, []);

    useEffect(() => {
        console.log('oi');
        if (props.downloadScale) {
            console.log('teste');
            takeScreenShot(ref.current).then(download);
            props.setDownloadScale(false);
        }
    }, [props.downloadScale])

    const download = (image, { name = "img", extension = "jpg" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    const doctorsNameWithMinutesLeft = doctorsWithMinutesLeft.map(it => it.name).join(doctorsWithMinutesLeft.length === 2 ? ' e ' : ', ');

    return (
        <StepMainContainer style={{ marginTop: '20px' }}>
            {scales.map(scale => (
                <LineContainer key={scale.doctor.index} >
                    <div className={'on-duty-dots'} />
                    <DoctorIndexContainer>
                        <Index style={{ color: 'white' }}>{scale.doctor.index + 1}</Index>
                    </DoctorIndexContainer>
                    <NameInput value={scale.doctor.name} readOnly />
                    <div style={{ width: '70%' }}>
                        <ScaleTime>{`${scale.startHour.format('HH[:]mm')} > ${scale.endHour.format('HH[:]mm')}`}</ScaleTime>
                    </div>
                </LineContainer>
            ))}
            {doctorsWithMinutesLeft.length > 0 && <InfoContainer>
                <div className={'on-duty-info'} />
                <Info>
                    {props.t([`onDuty.${doctorsWithMinutesLeft.length > 1 ? 'minutesLefts' : 'minutesLeft'}`], { name: doctorsNameWithMinutesLeft })}
                </Info>
            </InfoContainer>}
            <div style={{ position: 'absolute', top: '5000px' }}>
                <div ref={ref}>
                    <ScalePrint doctorsWithMinutesLeft={doctorsWithMinutesLeft} scales={scales} />
                </div>
            </div>
        </StepMainContainer>
    );
}

export default compose(connect(null, null), withTranslation())(ShareStep) as React.ComponentType<any>;