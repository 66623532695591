import { AxiosResponse } from 'axios';
import searchApi from '../api/searchApi';
import HttpStatus from '../model/enums/httpStatus';
import { Search } from '../model/search';
import { SearchResult } from '../model/searchResult';

export const SearchService = () => {
    const search = async (params: Search): Promise<SearchResult[]> => {
        try {
            const result: AxiosResponse<SearchResult[]> = await searchApi.search(params);
            console.log('result ', result);
            if (result.status === HttpStatus.OK) {
                const fullResult: SearchResult[] = result.data.filter((it) => it.title.trim().length > 0);
                return Promise.resolve(fullResult);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    return {
        search
    };
};

export default SearchService();
