import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import FlashcardApi from '../../../../api/flashcard.api';
import Loading from '../../../../components/loading/loading';
import { PerformanceData } from '../../../../model/flashcard/performance-data';
import { IRootState } from '../../../../reducer';
import { openPaidDiseaseModal } from '../../../../reducer/globalModal/actions';
import FlashcardRunService from '../../../../services/flashcard-run.service';
import { getDateNowTimestamp, shuffle } from '../../../../shared/util/utils';
import FlashcardHeader from '../../components/flashcard-header';
import FlashcardModalReset from '../../components/flashcard-modal-reset/flashcard-modal-reset';
import FlashcardToolBar from '../../components/flashcard-tool-bar';
import FlashcardCarousel from '../../flashcard-carousel/flashcard-carousel';
import { useFlashcardViewContext } from '../../flashcard-carousel/flashcard.provider';
import FlashcardPerformance from '../../flashcard-statistic/flashcard-performance';
import { Container, MainContainer } from '../../flashcard-styles';
import '../../flashcard.scss';

interface Props extends RouteComponentProps<{}, {}, {}> {
    hasSubscription?: boolean;
    t: any;
}

const FlashcardRandom = (props: Props) => {
    const { setCards, cards, startTimestamp, fontSize, setIsInfinite } = useFlashcardViewContext();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFinished, setIsFinished] = useState<boolean>(false);
    const [showFreeUserModalInfo, setShowFreeUserModalInfo] = useState<boolean>(false);
    const [performanceData, setPerformanceData] = useState<PerformanceData>({ answers: [] });
    const [viewMode, setViewMode] = useState<'LIST' | 'PERFORMANCE' | 'CARD'>('CARD');

    useEffect(() => {
        setFlashcardsInformation()
            .finally(() => {
                if (!props.hasSubscription) {
                    setShowFreeUserModalInfo(true);
                }
                setIsLoading(false);
            });
    }, []);

    const setFlashcardsInformation = async () => {
        let ids = [
            5173, 5895, 371, 5224, 7511, 6956, 916, 1347, 4126, 212, 25, 6516, 7241, 7271, 8369, 8549, 6484, 5461, 5228, 1388, 862, 863, 865,
            4164, 7560, 7935, 8370, 8370, 5407, 7306, 5115, 877, 6542, 7307, 8042, 8409, 213, 3593, 5728, 6447, 7274, 7827, 7979, 8411, 2595,
            688, 6564, 7290, 8523, 7060, 4911, 7246, 7242, 633, 5250, 552, 7133, 8366, 8412, 1922, 80, 2970, 7007, 8289, 8361, 8362, 8363,
            7243, 5159, 4319, 1333, 6577, 7277, 4784, 8215, 8373, 7424, 7425, 7426, 7427, 5161, 4658, 7017, 1348, 7680, 6562, 7537, 6557,
            7760, 7325, 6629, 6629, 7584, 6543, 7291, 7445, 7105, 5250, 7446, 8274
        ];
        if (props.hasSubscription) {
            setIsInfinite(true);
            ids = await FlashcardRunService.getFlashcardIdsByRepetitionAlgorithm(200);
        }
        const response = await FlashcardApi.getFlashcardByIds(ids);
        const newCards = response.data;
        setCards([...shuffle(newCards)]);
    };

    useEffect(() => {
        const allCardsAnswered = cards.filter(it => it.answer != null).length === cards.length;
        if (allCardsAnswered && cards.length > 0 && !isLoading) {
            finishReview();
        }
    }, [cards]);


    const onBack = () => {
        props.history.goBack();
    };

    const updatedPerformanceData = () => {
        const secondsSpent = (getDateNowTimestamp() - startTimestamp) / 1000;
        const newAnswers = cards
            .filter(it => it.answer != null)
            .map(it => {
                return { flashcardId: it.id, answer: it.answer };
            });
        const answeredQuestions = newAnswers.length;
        const remainingQuestions = cards.length - answeredQuestions;
        const result: PerformanceData = {
            secondsSpent,
            remainingQuestions,
            answeredQuestions,
            answers: newAnswers
        };
        setPerformanceData(result);
    };

    const goToPerformanceScreen = () => {
        updatedPerformanceData();
        setViewMode('PERFORMANCE');
    };

    const finishReview = () => {
        updatedPerformanceData();
        setIsFinished(true);
        setViewMode('PERFORMANCE');
    };

    const onPressBack = (viewMode === 'PERFORMANCE' || viewMode === 'LIST') && !isFinished ? () => setViewMode('CARD') : () => onBack();
    return isLoading ? (
        <div className="disease-screen-container">
            <Loading />
        </div>
    ) : (
        <MainContainer>
            <Container>
                <FlashcardHeader
                    {...props}
                    title={props.t('flashcardRandom.subtitle')}
                    subtitle={props.t('flashcardRandom.title')}
                    viewMode={viewMode}
                    onPressBack={onPressBack}
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {viewMode !== 'PERFORMANCE' && <FlashcardToolBar
                        t={props.t}
                        history={props.history}
                        location={props.location}
                        match={props.match}
                        viewMode={viewMode}
                        hideFavoriteButton={false}
                        hideRandomButton
                        hideQueryButton
                        onPressConsultButton={() => setViewMode(viewMode === 'LIST' ? 'CARD' : 'LIST')}
                        onPressFinishButton={() => finishReview()}
                    />}
                    {viewMode === 'CARD' &&
                        <FlashcardCarousel {...props}
                                           fontSize={fontSize}
                                           onPressPerformanceButton={() => goToPerformanceScreen()} />}
                    {viewMode === 'PERFORMANCE' &&
                        <FlashcardPerformance {...props} data={performanceData} />}

                </div>
            </Container>
            <FlashcardModalReset
                hideCancelButton
                show={showFreeUserModalInfo}
                onPressConfirm={() => setShowFreeUserModalInfo(false)}
                onClose={() => setShowFreeUserModalInfo(false)}
                title={props.t('flashcardRandom.modalTitle')}
                subtitle={props.t('flashcardRandom.modalSubtitle')}
                confirmLabel={props.t('flashcardRandom.modalConfirmButton')}
            />
        </MainContainer>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account?.customer?.hasSubscription
});

const mapDispatchToProps = {
    openPaidModal: openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(FlashcardRandom) as React.ComponentType<any>;
