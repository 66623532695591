import React from 'react';
import { MedicalCalculatorParam } from '../../../model/medical-calculator/medical-calculator-param';
import MedicalCalculatorInputParam from './medical-calculator-input-param';
import { ParamContainer, ParamTitle } from './medical-calculator-params-styles';
import MedicalCalculatorPickerParam from './medical-calculator-picker-param';

interface Props {
    params: MedicalCalculatorParam[];
}

const MedicalCalculatorConversionView = (props: Props) => {
    const { params } = props;

    return (
        <>
            <ParamContainer>
                <ParamTitle>{params[1].presentationName}</ParamTitle>
                <MedicalCalculatorInputParam parameterInfo={params[0]} />
                <MedicalCalculatorPickerParam parameterInfo={params[1]} />
            </ParamContainer>
            <ParamContainer>
                <ParamTitle>{params[2].presentationName}</ParamTitle>
                <MedicalCalculatorPickerParam parameterInfo={params[2]} />
            </ParamContainer>
        </>
    );
};

export default MedicalCalculatorConversionView;
