import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { StepsHeader } from './steps-header';

export interface Props {
    t: (path: string, params?: any) => string;
    steps: StepItem[];
    currentIndex: number;
}
export interface StepItem {
    title: string;
    render: () => JSX.Element;
}

export const Steps = (props: Props): JSX.Element => {
    const currentRender: () => JSX.Element = props.steps[props.currentIndex].render;
    return (
        <div className="steps">
            <StepsHeader currentIndex={props.currentIndex} steps={props.steps.map(it => it.title)} />
            <div className="steps__content container">{currentRender()}</div>
        </div>
    );
};

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Steps) as React.ComponentType<Omit<Props, 't'>>;
