export interface Search {
    search?: string;
    type?: SearchType;
    limit?: number | null;
}

export enum SearchType {
    GENERAL = 'GENERAL',  
    SMART_BULAS = 'SMART_BULAS',
    PEDIATRIC_DOSE = 'PEDIATRIC_DOSE',
    ILLUSTRATED_PROCEDURE = 'ILLUSTRATED_PROCEDURE',
    MEDICAL_CALCULATOR = 'MEDICAL_CALCULATOR',
    MEDICAL_SCORE = 'MEDICAL_SCORE',
    LABORATORY = 'LABORATORY',
    SEMIOLOGY = 'SEMIOLOGY',
    CID = 'CID', 
    SUS = 'SUS', 
    SPECIALTY = 'SPECIALTY',
    MEDICAL_CRITERIA = 'MEDICAL_CRITERIA',
}