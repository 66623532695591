import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { PremiumHeader } from '../../../components/premium-header/premium-header';
import { Search } from '../../../model/search';
import { User } from '../../../model/user';
import { IRootState } from '../../../reducer';
import { logoutRequest } from '../../../reducer/authentication/actions';
import { openPaidDiseaseModal } from '../../../reducer/globalModal/actions';
import { saveSearch } from '../../../reducer/search/actions';
import { HeaderMenu } from './header-menu';
import { HeaderProfileDropdown } from './header-profile-dropdown';
import { HeaderSearch } from './header-search';
import './header.scss';

export interface IHeaderProps extends RouteComponentProps<{}> {
    logout: () => void;
    t?: any;

    getSession: () => void;
    location: any;
    match: any;
    history: any;

    isAuthenticated: boolean;
    account: User;
    saveSearch: (search: Search) => void;
    openPaidDiseaseModal: () => void;
}

export const NAVS = {
    HOME: 'home',
    SPEC: 'specialty',
    DIAG: 'diagnapp',
    SMART: 'smart-bulas',
    INTER: 'interactions',
    PROCEDURE: 'procedure',
    VACCINATION: 'vaccination',
    LABORATORY: 'laboratory',
    SEMIOLOGY: 'semiology',
    TOXICOLOGY: 'toxicology',
    PROTOCOLS: 'protocols',
    CID: 'cid',
    SUS: 'sus'
};

export interface IHeaderState {
    dropdownProfileOpen: boolean;
    navActive?: any;
    showMenu?: boolean;
}

export class Header extends React.Component<IHeaderProps, IHeaderState> {
    private headerMenuRef: any;
    private headerContentRef: any;
    private showMenuButton: any;
    constructor(props) {
        super(props);
        this.state = {
            dropdownProfileOpen: false,
            navActive: NAVS.HOME
        };
    }
    componentDidMount(): void {
        this.props.getSession();
        document.addEventListener('mouseover', this.handleMouse);
    }
    componentWillUnmount(): void {
        document.addEventListener('mouseover', this.handleMouse);
    }

    private onOpenMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        });
    };
    private changeActiveNav = value => {
        this.setState({
            navActive: value
        });
    };

    private handleMouse = (event: any): void => {
        if (
            this.headerMenuRef &&
            !this.headerMenuRef.contains(event.target) &&
            this.headerContentRef &&
            !this.headerContentRef.contains(event.target) &&
            this.showMenuButton &&
            !this.showMenuButton.contains(event.target)
        ) {
            this.setState({
                showMenu: false
            });
        }
    };
    private onLogout = () => {
        this.props.logout();
        this.props.history.push('/');
    };

    render() {
        const { showMenu, dropdownProfileOpen } = this.state;
        const { t, account, history } = this.props;
        const hasSubscription: boolean =
            this.props.account && this.props.account.customer ? !!this.props.account.customer.hasSubscription : false;
        if (account == null || account.customer == null) {
            return null;
        }
        console.log({account: this.props.account});
        return (
            <div id="app-header" className="App-header" style={!hasSubscription ? { marginBottom: 50 } : {}}>
                <div className="blank-header fixed-header">
                    {!hasSubscription && <PremiumHeader t={this.props.t} history={this.props.history} />}

                    <div className="app-header__main-content" ref={ref => (this.headerContentRef = ref)}>
                        <Link to="/home">
                            <div className="header-logo-name" />
                        </Link>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <HeaderSearch
                                t={t}
                                account={account}
                                history={history}
                                saveSearch={this.props.saveSearch}
                                openPaidDiseaseModal={this.props.openPaidDiseaseModal}
                                setNavActive={(navActive: any) => this.setState({ navActive })}
                            />
                            <div className="app-header__open-menu" onClick={() => this.props.history.push('/recent-history')} ref={ref => (this.showMenuButton = ref)}>
                                <div className={'recents-icon'} />
                            </div>
                            <div className="app-header__open-menu" onClick={this.onOpenMenu} ref={ref => (this.showMenuButton = ref)}>
                                <div className={`app-header__open-menu__btn ${showMenu ? 'app-header__open-menu__btn--active' : ''}`} />
                            </div>
                        </div>
                        <HeaderProfileDropdown
                            t={this.props.t}
                            account={this.props.account}
                            onLogout={this.onLogout}
                            hasSubscription={hasSubscription}
                            isOpen={dropdownProfileOpen}
                        />
                    </div>
                    {showMenu && (
                        <div ref={ref => (this.headerMenuRef = ref)}>
                            <HeaderMenu t={this.props.t} pathname={this.props.location.pathname} onChangeActiveNav={this.changeActiveNav} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IRootState) => ({
    isAuthenticated: state.authentication.isAuthenticated,
    account: state.authentication.account
});

const mapDispatchToProps = {
    logout: logoutRequest,
    saveSearch,
    openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Header) as React.ComponentType<any>;
