import _ from 'lodash';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { ResultType } from '../../model/medical-calculator/result-type';
import { MedicalCriteriaParam } from '../../model/medical-criteria/medical-criteria-param';
import { createFunctionByString } from '../../shared/util/markdown-utils';
import {
    CalculatorResultContainer,
    MainContainer,
    ResultContainer,
    ResultText,
    ResultTypeContainer,
    ResultTypeText
} from './medical-criteria-styles';
import { useMedicalCriteriaContext } from './medical-criteria.provider';
import './medical-criteria.scss';
import MedicalCriteriaParamType from '../../model/enums/medical-criteria-param-type';

interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
}

const MedicalCriteriaResult = (props: Props) => {
    const { result, setResult, paramValues, medicalCriteria } = useMedicalCriteriaContext();

    useEffect(() => {
        if (medicalCriteria !== undefined) {
            try {
                const formulaParams = [...medicalCriteria.formulaParams!];
                const params: any[] = [];
                _.sortBy(formulaParams, 'position').forEach(it => {
                    params.push(getValueOfFormulaParam(it as MedicalCriteriaParam));
                });
                console.log({ params });
                const newResult = createFunctionByString(`var run1 = ${medicalCriteria.formula!}; run1(${[...params]});`);
                setResult(newResult);
            } catch (e) {
                console.log(e);
            }
        }
    }, [paramValues]);

    const getValueOfFormulaParam = (formulaParam: MedicalCriteriaParam) => {
        const param = paramValues[formulaParam.code!];
        switch (formulaParam.inputType) {
            case MedicalCriteriaParamType.MULTI_ANSWER_SELECT:
                return `{answers: [${param.answers.map(answer => `{code: '${answer.code}', points: ${answer.points}}`).join(',')}]}`;
            case MedicalCriteriaParamType.CHECKBOX:
                return param?.code != null ? `{code: '${param.code}', points: ${param.points}}` : `null`;
            case MedicalCriteriaParamType.MULTI_SELECT:
            case MedicalCriteriaParamType.MULTI_SELECT_TOGGLE_FIRST:
                return param?.code != null ? `{code: '${param.code}', points: ${param.points}}` : `null`;
            case MedicalCriteriaParamType.INPUT:
                return Number(_.replace(paramValues[formulaParam.code!] as string, ',', '.'));
        }
        return param;
    };

    const formatCriteriaResult = (text: string) => {
        const newText = text === '' ? 'Descrição' : text;
        if (isNaN(Number(newText))) {
            return newText;
        }
        return `${newText} pontos`;
    };

    console.log({ result });

    return (
        <MainContainer>
            {result.resultText !== undefined ? (
                <CalculatorResultContainer>
                    <ResultContainer type={result.resultType?.type ?? ResultType.NONE}>
                        {result.resultType != null && result.resultType?.type !== ResultType.NONE && (
                            <ResultTypeContainer type={result.resultType?.type ?? ResultType.NORMAL}>
                                <ResultTypeText>{formatCriteriaResult(result.resultType?.title) ?? ''}</ResultTypeText>
                            </ResultTypeContainer>
                        )}
                        <ResultText>{_.replace(result.resultText, /\*\*/gm, '')}</ResultText>
                    </ResultContainer>
                </CalculatorResultContainer>
            ) : (
                <div />
            )}
        </MainContainer>
    );
};

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(MedicalCriteriaResult) as React.ComponentType<any>;
