import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { MedicalScoreParam } from '../../../model/medical-score/medical-score-param';
import { useMedicalScoreContext } from '../medical-score.provider';
import '../medical-score.scss';
import { InputParam, InputParamContainer, MeasurementText } from './medical-score-params-styles';

interface Props {
    placeholder?: string;
    containerStyle?: any;
    parameterInfo: MedicalScoreParam;
}

const MedicalScoreInputParam = (props: Props) => {
    const { paramValues, setParamValues } = useMedicalScoreContext();
    const [value, setValue] = useState<string | undefined>();
    const { parameterInfo, containerStyle, placeholder } = props;

    useEffect(() => {
        if (paramValues[parameterInfo.code!] !== value) {
            setValue(paramValues[parameterInfo.code!] as string);
        }
    }, [paramValues]);

    useEffect(() => {
        if (value !== undefined && value !== '') {
            const delayDebounceFn = setTimeout(() => {
                const inputMin = parameterInfo.inputMin ?? 0;
                const inputMax = parameterInfo.inputMax;
                if (inputMin != null && inputMax != null) {
                    if (inputMin > Number(value)) {
                        setValue(String(inputMin));
                        return;
                    }
                    if (inputMax < Number(value)) {
                        setValue(String(inputMax));
                        return;
                    }
                }
                const newValues = { ...paramValues };
                newValues[parameterInfo.code!] = value;
                setParamValues(newValues);
            }, 500);

            return () => clearTimeout(delayDebounceFn)
        }
    }, [value]);

    return (
        <InputParamContainer style={containerStyle}>
            <InputParam
                type={'text'}
                placeholder={placeholder ?? '0'}
                value={value ? value.replace('.', ',') : ''}
                onChange={e => setValue(_.replace(e.target.value, /[^\d,]+/gm, ''))} 
            />
            <MeasurementText>{parameterInfo.measurement}</MeasurementText>
        </InputParamContainer>
    );
};

export default MedicalScoreInputParam;
