import { AxiosResponse } from 'axios';
import { DiagnAppParams } from '../model/diagnAppParams';
import { DiseaseWeight } from '../model/diseaseWeight';
import { api } from './api';

export const diagnAppApi = () => {
    const getResults = (params: DiagnAppParams): Promise<AxiosResponse<DiseaseWeight[]>> => {
        return api.post<DiseaseWeight[]>(`/diagnapp`, params);
    };

    return {
        getResults
    };
};

export default diagnAppApi();
