import * as React from 'react';
import './font-resizer.scss';

interface Props {
    changeFontSize: (size: number) => void;
    fontSize: number;
}

const FontResizer = (props: Props) => {
    const MAX_FONT_SIZE = 24;
    const MIN_FONT_SIZE = 10;

    const changeFontSize = (newAddedValue: number) => {
        const newFontSize = props.fontSize + newAddedValue;
        if (MAX_FONT_SIZE >= newFontSize && newFontSize >= MIN_FONT_SIZE) {
            props.changeFontSize(newFontSize);
        }
    };

    return (
        <div className="font-resizer-container">
            <div className="sort-ascending-img" onClick={() => changeFontSize(-1)} />
            <span className="font-size-label">{props.fontSize}</span>
            <div className="sort-descending-img" onClick={() => changeFontSize(1)} />
        </div>
    );
};

export default FontResizer;