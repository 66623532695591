import styled from 'styled-components';

export const MainContainer = styled.div`
    height: calc(100vh - 80px - 50px);
    display: flex;
    padding-top: 25px;
    justify-content: center;
    background-color: #f9fafa;
`;

export const Container = styled.div`
    height: 100%;
    width: 60vw;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    margin-left: 60px;
`;

export const ArrowBack = styled.div`
    height: 24px;
    width: 24px;
    margin-right: 10px;
    cursor: pointer;
`;

export const HeaderContainer = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const HeaderText = styled.span`
    color: #181f22;
    font-size: 28px;
    font-family: Raleway;
    font-weight: 600;
    line-height: 31.2px;
    letter-spacing: 0.48px;
    word-wrap: break-word;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: relative;
    width: 70vw;
    max-width: 950px;
    height: 55vh;
    padding: 24px 32px 32px 32px;
    background-color: #ffffff;
    border: 1.2px solid #e9eced;
    border-radius: 8px;
    overflow-y: auto;
    @media (max-width: 1080px) {
        width: 650px;
        margin-left: 0;
    }
`;

export const TabsHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70vh;
    margin-right: 6vw;
    margin-left: 6vw;
    position: absolute;
    top: 4vh;
`;

export const TabHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    justify-content: center;
`;

export const TabHeaderTitle = styled.span<{isActive: boolean}>`
    color: ${props => props.isActive ? '#414E52' : '#6D7C81'};
    text-align: center;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: ${props => props.isActive ? '600' : '500'};
`;

export const TabLineHeader = styled.div<{isActive: boolean}>`
    width: 90%;
    height: 3px;
    margin-top: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: ${props => props.isActive ? '#EF7D00' : 'transparent'};
`;

export const ContentPartContainer = styled.div`
    width: 50%;
    height: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;
