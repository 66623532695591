import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { closePaidDiseaseModal } from '../../reducer/globalModal/actions';
import Button from '../button/button';
import './paid-disease-modal.scss';

export interface IPaidDiseaseModalProps {
    t: (path: string, params?: any) => string;
    history: any;

    open: boolean;
    closeModal: () => void;
}

export class PaidDiseaseModal extends React.Component<IPaidDiseaseModalProps> {
    constructor(props) {
        super(props);

        this.state = {};
    }
    private onClose = () => {
        this.props.closeModal();
    };
    private onButtonPressDiseases = () => {
        this.props.closeModal();
        this.props.history.push('/preferences/free-diseases');
    };
    private onButtonPressProcedures = () => {
        this.props.closeModal();
        this.props.history.push('/preferences/free-procedures');
    };
    private onButtonPressSemiologies = () => {
        this.props.closeModal();
        this.props.history.push('/preferences/free-semiologies');
    };
    private onButtonPressCriteria = () => {
        this.props.closeModal();
        this.props.history.push('/preferences/free-criteria');
    };
    private onButtonPressLaboratories = () => {
        this.props.closeModal();
        this.props.history.push('/preferences/free-laboratories');
    };
    private onButtonPressCalculators = () => {
        this.props.closeModal();
        this.props.history.push('/preferences/free-calculators');
    };
    private onButtonPressScores = () => {
        this.props.closeModal();
        this.props.history.push('/preferences/free-scores');
    };
    private onBannerPress = () => {
        this.props.closeModal();
        this.props.history.push('/subscription');
    };
    render() {
        const { open } = this.props;
        if (!open) {
            return <div />;
        }
        return (
            <div className="modal">
                <div className="modal__full-content">
                    <a className="close-btn" onClick={this.onClose}>
                        <div className="close-btn__icon" />
                    </a>
                    <div className="modal__content">
                        <div className="top-content">
                            <div className="wemeds-logo-img" />
                            <span className="top-content__title">{this.props.t('paidDiseaseModal.title')}</span>
                            <span className="top-content__subtitle">{this.props.t('paidDiseaseModal.subtitle')}</span>
                        </div>
                        <div className="middle-banner">
                            <a className="middle-banner__img" onClick={this.onBannerPress} />
                        </div>
                        <div className="bottom-content">
                            <span className="bottom-content__text-container">
                                <span className="bottom-content__text">{this.props.t('paidDiseaseModal.bottomDescription1')}</span>
                                <span className="bottom-content__text bottom-content__text--bold">
                                    {this.props.t('paidDiseaseModal.bottomDescription2')}
                                </span>
                                <span className="bottom-content__text">{this.props.t('paidDiseaseModal.bottomDescription3')}</span>
                            </span>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginRight: 30 }}>
                                    <div className="bottom-content__btn-container">
                                        <Button
                                            label={this.props.t('paidDiseaseModal.buttonLabelDiseases').toUpperCase()}
                                            onPress={this.onButtonPressDiseases}
                                            labelStyle={{ fontSize: 10 }}
                                        />
                                    </div>
                                    <div className="bottom-content__btn-container">
                                        <Button
                                            label={this.props.t('paidDiseaseModal.buttonLabelProcedures').toUpperCase()}
                                            onPress={this.onButtonPressProcedures}
                                            labelStyle={{ fontSize: 10 }}
                                        />
                                    </div>
                                    <div className="bottom-content__btn-container">
                                        <Button
                                            label={this.props.t('paidDiseaseModal.buttonLabelSemiologies').toUpperCase()}
                                            onPress={this.onButtonPressSemiologies}
                                            labelStyle={{ fontSize: 10 }}
                                        />
                                    </div>
                                    <div className="bottom-content__btn-container">
                                        <Button
                                            label={this.props.t('paidDiseaseModal.buttonLabelCriteria').toUpperCase()}
                                            onPress={this.onButtonPressCriteria}
                                            labelStyle={{ fontSize: 10 }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="bottom-content__btn-container">
                                        <Button
                                            label={this.props.t('paidDiseaseModal.buttonLabelLaboratories').toUpperCase()}
                                            onPress={this.onButtonPressLaboratories}
                                            labelStyle={{ fontSize: 10 }}
                                        />
                                    </div>
                                    <div className="bottom-content__btn-container">
                                        <Button
                                            label={this.props.t('paidDiseaseModal.buttonLabelCalculators').toUpperCase()}
                                            onPress={this.onButtonPressCalculators}
                                            labelStyle={{ fontSize: 10 }}
                                        />
                                    </div>
                                    <div className="bottom-content__btn-container">
                                        <Button
                                            label={this.props.t('paidDiseaseModal.buttonLabelScores').toUpperCase()}
                                            onPress={this.onButtonPressScores}
                                            labelStyle={{ fontSize: 10 }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    closeModal: closePaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(PaidDiseaseModal) as React.ComponentType<any>;
