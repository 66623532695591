import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Annotations from '../../components/annotations/annotations';
import Loading from '../../components/loading/loading';
import TabHeader, { TabHeaderItem } from '../../components/tabs/tab-header';
import { Note, NoteType } from '../../model/note';
import { IRootState } from '../../reducer';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import MedicalScoreHeader from './medical-score-header';
import MedicalScoreList from './medical-score-list';
import MedicalScoreMarkdown from './medical-score-markdown';
import MedicalScoreParams from './medical-score-params/medical-score-params';
import MedicalScoreResult from './medical-score-result';
import { useMedicalScoreContext } from './medical-score.provider';
import './medical-score.scss';

interface Props extends RouteComponentProps<{}, {}, { fromAnnotation?: boolean }> {
    t: any;
    hasSubscription?: boolean;
    openPaidModal: () => void;
}

const MedicalScoreDashboard = (props: Props) => {
    const [tabItems] = useState<TabHeaderItem[]>([
        { index: 0, title: props.t('medicalCalculator.tabs.calculator') },
        { index: 1, title: props.t('medicalCalculator.tabs.interpretation') },
        { index: 2, title: props.t('medicalCalculator.tabs.evidence') }
    ]);
    const [showContent, setShowContent] = useState<boolean>(false);
    const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(true);
    const {
        medicalScore,
        showAnnotation,
        medicalScoreList,
        isLoading,
        setNewCurrentMedicalScore,
        setShowAnnotation,
        setMedicalScore
    } = useMedicalScoreContext();

    useEffect(() => {
        if (medicalScore !== undefined) {
            if (props.location?.state?.fromAnnotation != null) {
                const fromAnnotation = props.location?.state?.fromAnnotation ?? false;
                setShowAnnotation(fromAnnotation);
            }
            setShowContent(true);
            setIsLoadingScreen(false);
        }
    }, [medicalScore]);

    useEffect(() => {
        if (medicalScoreList.length > 0) {
            const {
                match: { params }
            } = props;
            const medicalScoreId: number = (params as any).scoreId ?? 44;
            setNewCurrentMedicalScore({ id: medicalScoreId });
        }
    }, [medicalScoreList]);

    const onSaveNote = (note: Note) => {
        setMedicalScore({ ...medicalScore, note });
    };

    return isLoadingScreen ? (
        <div className="disease-screen-container">
            <Loading />
        </div>
    ) : (
        <div className={'dose-main-container'}>
            <div className={'screen-dose-search-container'}>
                <MedicalScoreList {...props} />
            </div>
            <div className={'screen-dose-content-container'} style={{ display: showContent ? 'block' : 'none' }}>
                <div
                    style={{ display: isLoading ? 'flex' : 'none', height: '100%', alignItems: 'center', justifyItems: 'center' }}
                >
                    <Loading />
                </div>
                <div style={{ display: isLoading ? 'none' : 'block', height: '100%' }}>
                    <MedicalScoreHeader {...props} />
                    <TabHeader tabs={tabItems}>
                        <div>
                            <MedicalScoreParams />
                            <MedicalScoreResult />
                        </div>
                        <MedicalScoreMarkdown
                            {...props}
                            hasSubscription={props.hasSubscription}
                            markdownContent={medicalScore?.interpretationText}
                        />
                        <MedicalScoreMarkdown
                            {...props}
                            hasSubscription={props.hasSubscription}
                            markdownContent={medicalScore?.evidenceText}
                        />
                    </TabHeader>
                </div>
            </div>
            <div>
                {showAnnotation && (
                    <Annotations
                        note={medicalScore?.note}
                        itemId={medicalScore?.id!}
                        noteType={NoteType.MEDICAL_SCORE}
                        saveCallback={onSaveNote}
                    />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account?.customer?.hasSubscription === true
});

const mapDispatchToProps = {
    openPaidModal: openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(MedicalScoreDashboard) as React.ComponentType<any>;
