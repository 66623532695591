import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { Laboratory } from '../../../model/laboratory/laboratory';
import { IRootState } from '../../../reducer';
import LaboratoryService from '../../../services/laboratoryService';
import './components.scss';

interface Props extends RouteComponentProps<{}> {
    t: (path: string, param?: any) => string;
    history: any;
}

const FreeLaboratories = (props: Props) => {
    const [laboratories, setLaboratories] = useState<Laboratory[]>([]);

    useEffect(() => {
        LaboratoryService.getAvailableForFree()
            .then(response => setLaboratories(response));
    }, []);

    return (
        <div className="component">
            <h1 className="component__title">{props.t('preferences.freeLaboratoriesComponent.title')}</h1>
            <div className="component__content">
                {laboratories && laboratories.map((it, index) => (
                    <a key={index} className="component__card-item" href={`/laboratory/${it.id}`}>
                        <div className="component__card-item__content">
                            <div className="component__card-item__content__row">
                                <span className="component__card-item__label">{it.name}</span>
                                <div className="arrow-right-img" />
                            </div>
                        </div>
                        <div className="component__card-item__extension" />
                    </a>
                ))}
            </div>
        </div>
    );
}

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(FreeLaboratories) as React.ComponentType<any>;
