import { Reducer } from 'redux';
import AuthUtil from '../../api/authUtil';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { AuthenticationActionTypes, AuthenticationState } from './types';

export const initialState: AuthenticationState = {
  getSessionStatus: HttpRequestStatus.NOOP,
  loginStatus: HttpRequestStatus.NOOP,
  isAuthenticated: false
};

const reducer: Reducer<AuthenticationState> = (state = initialState, action) => {
  switch (action.type) {
    case AuthenticationActionTypes.LOGIN_REQUEST: {
      return { ...state, loginStatus: HttpRequestStatus.ON_GOING };
    }
    case AuthenticationActionTypes.LOGIN_SUCCESS: {
      return { ...state, loginStatus: HttpRequestStatus.SUCCESS, isAuthenticated: true };
    }
    case AuthenticationActionTypes.LOGIN_ERROR: {
      return { ...state, loginStatus: HttpRequestStatus.ERROR, errors: action.payload };
    }
    case AuthenticationActionTypes.GET_SESSION_REQUEST: {
      return { ...state, getSessionStatus: HttpRequestStatus.ON_GOING };
    }
    case AuthenticationActionTypes.GET_SESSION_SUCCESS: {
      return { ...state, getSessionStatus: HttpRequestStatus.SUCCESS, isAuthenticated: true, account: action.payload };
    }
    case AuthenticationActionTypes.GET_SESSION_ERROR: {
      return { ...state, getSessionStatus: HttpRequestStatus.ERROR, errors: action.payload };
    }
    case AuthenticationActionTypes.LOGOUT_REQUEST: {
      AuthUtil.removeToken();
      return { ...state, getSessionStatus: HttpRequestStatus.NOOP, loginStatus: HttpRequestStatus.NOOP, isAuthenticated: false };
    }
    default: {
      return state;
    }
  }
};

export { reducer as authenticationReducer };

