import _ from 'lodash';
import * as React from 'react';
import DropdownSearch from '../../components/dropdown-search/dropdown-search';
import { DiagnAppSearchParams } from '../../model/diagnAppSearchParams';
import { Symptom } from '../../model/symptom';
import DiagnAppService from '../../services/diagnAppService';
import './diagnapp.scss';

export interface IDropdownSearchSymptomsProps {
    inputNumber: number;
    onClickItem: (item: Symptom) => void;
    onRemove: () => void;
    getSearchParams: () => DiagnAppSearchParams;
    selectedItem?: Symptom;
}
export interface IDropdownSearchSymptomsState {
    selectedItem?: Symptom;
    searchResults: Symptom[];
    isLoading?: boolean;
}

export default class DropdownSearchSymptoms extends React.Component<IDropdownSearchSymptomsProps, IDropdownSearchSymptomsState> {
    private currentLetter: string = '';
    constructor(props) {
        super(props);

        this.state = {
            searchResults: [],
            selectedItem: props.selectedItem
        };
    }
    // Api Calls
    private search = async (value: string): Promise<any> => {
        const params: DiagnAppSearchParams = {
            ...this.props.getSearchParams(),
            name: value
        };
        setTimeout(() => this.setState({ isLoading: false }), 5000);
        const result: Symptom[] = await DiagnAppService.searchSymptoms(params);
        clearTimeout(0);
        this.setState({
            searchResults: result,
            isLoading: false
        });
    };
    private searchDebounce = _.debounce(this.search, 2000, { leading:false, trailing:true });
     
    private searchDebounceContainer = (value: string) => {
        this.setState({ isLoading: true });
        this.searchDebounce(value);
    };

    // Handlers
    private onClickItem = (item: any) => {
        this.setState({ selectedItem: item }, () => {
            this.props.onClickItem(item);
        });
    };
    private onRemove = () => {
        this.setState({
            selectedItem: undefined
        });
        this.props.onRemove();
    };

    // Renders
    render() {
        const { selectedItem, searchResults, isLoading } = this.state;
        const { inputNumber } = this.props;
        return (
            <div className="dropdown-select-search">
                <DropdownSearch
                    data={searchResults}
                    getData={this.searchDebounceContainer}
                    onClickItem={this.onClickItem}
                    title={`Adicionar sinal ou sintoma ${inputNumber}`}
                    style={{ width: 317, backgroundColor: '#f6f6f6' }}
                    notAlphabeticalOrder
                    selectedRender={
                        selectedItem
                            ? () => (
                                  <div className="dropdown-select-search__selected">
                                      <div className="dropdown-select-search__selected__left">
                                          <div className="dropdown-select-search__selected__rect" />
                                          <div className="dropdown-select-search__selected__text">
                                              <p className="dropdown-select-search__selected__title">{selectedItem.name}</p>
                                          </div>
                                      </div>
                                      <div className="dropdown-select-search__selected__remove-icon" onClick={this.onRemove} />
                                  </div>
                              )
                            : undefined
                    }
                    resultItemStyle={{ fontWeight: 'normal' }}
                    isLoading={isLoading}
                />
            </div>
        );
    }
}
