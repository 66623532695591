import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { IRootState } from '../../reducer';
import './smart-leaflets.scss';

export interface CardItemContent {
    title: string;
    subtitle?: string;
}
export interface LeafletCardItemProps extends RouteComponentProps<{}> {
    t: any;
    item: CardItemContent;
}

export class LeafletCardItem extends React.Component<LeafletCardItemProps> {
    constructor(props: Readonly<LeafletCardItemProps>, context?: any) {
        super(props, context);

        this.state = {};
    }
    render() {
        const { item } = this.props;
        return ( 
            <div className="leaflet-card-content">
                <div className="leaflet-card-content__row">
                    <div className="leaflet-card-content__col">
                        <p className="leaflet-card-content__title">{item.title}</p>
                        {item.subtitle && <p className="leaflet-card-content__subtitle">{item.subtitle}</p>}
                    </div>
                    <div className="leaflet-card-content__arrow-icon" />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LeafletCardItem) as React.ComponentType<any>;
