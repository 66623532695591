import _ from 'lodash';
import { Moment } from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import ContentType from '../../model/enums/contentType';
import MedicalScoreParamType from '../../model/enums/medical-score-param-type';
import { FormulaResult } from '../../model/medical-calculator/formula-result';
import { MedicalScore, MedicalScoreSimple } from '../../model/medical-score/medical-score';
import ContentRatingService from '../../services/contentRatingService';
import MedicalScoreService from '../../services/medical-score.service';

interface MedicalScoreProviderProps {
    children: any;
}

interface MedicalScoreProviderData {
    paramValues: ParamValues;
    result: FormulaResult;
    fontSize: number;
    showAnnotation: boolean;
    showResizerModal: boolean;
    isLoading: boolean;
    showFavoriteSuccessToast: boolean;
    medicalScoreList: MedicalScoreSimple[];
    medicalScore?: MedicalScore;
    setParamValues: (value: ParamValues) => void;
    setResult: (value: FormulaResult) => void;
    setFontSize: (value: number) => void;
    setShowAnnotation: (value: boolean) => void;
    setShowResizerModal: (value: boolean) => void;
    setShowFavoriteSuccessToast: (value: boolean) => void;
    setMedicalScore: (dose: MedicalScore) => void;
    setNewCurrentMedicalScore: (dose: MedicalScore) => void;
}

const MedicalScoreContext = createContext<MedicalScoreProviderData>({} as MedicalScoreProviderData);

interface ParamValues {
    [index: string]: string | undefined | Moment | any;
}

export const MedicalScoreProvider = ({ children }: MedicalScoreProviderProps) => {
    const [fontSize, setFontSize] = useState<number>(14);
    const [showAnnotation, setShowAnnotation] = useState<boolean>(false);
    const [showResizerModal, setShowResizerModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [medicalScoreList, setMedicalScoreList] = useState<MedicalScoreSimple[]>([]);
    const [showFavoriteSuccessToast, setShowFavoriteSuccessToast] = useState<boolean>(false);
    const [medicalScore, setMedicalScore] = useState<MedicalScore | undefined>(undefined);
    const [paramValues, setParamValues] = useState<ParamValues>({});
    const [result, setResult] = useState<FormulaResult>({ resultText: 'Preencha todos os campos acima para o resultado' });

    useEffect(() => {
        MedicalScoreService.search().then(setMedicalScoreList);
    }, []);

    const setNewCurrentMedicalScore = (score: MedicalScoreSimple) => {
        setIsLoading(true);
        setShowAnnotation(false);
        setShowFavoriteSuccessToast(false);
        setResult({ resultText: 'Preencha todos os campos acima para o resultado' });
        MedicalScoreService.getInfo(score.id!)
            .then(setMedicalScore)
            .finally(() => setIsLoading(false));
        window.fbq('track', 'ViewContent', { content_name: score.name, content_type: 'MEDICAL_SCORE' });
        ContentRatingService.sendContentRating({ name: score.name!, contentId: score.id!, type: ContentType.MEDICAL_SCORE });
    };

    useEffect(() => {
        const newValues: ParamValues = {};
        medicalScore?.formulaParams?.map(it => {
            switch (it.inputType) {
                case MedicalScoreParamType.MULTI_ANSWER_SELECT:
                    newValues[it.code!] = { answers: [] };
                    break;
                default:
                    newValues[it.code!] = undefined;
                    break;
            }
        });
        setParamValues(newValues);
    }, [medicalScore]);

    return (
        <MedicalScoreContext.Provider
            value={{
                setFontSize,
                fontSize,
                medicalScoreList: medicalScoreList,
                showAnnotation,
                setShowAnnotation,
                setNewCurrentMedicalScore,
                setMedicalScore,
                showResizerModal,
                setShowResizerModal,
                medicalScore: medicalScore,
                isLoading,
                showFavoriteSuccessToast,
                setShowFavoriteSuccessToast,
                paramValues,
                setParamValues,
                result,
                setResult
            }}
        >
            {children}
        </MedicalScoreContext.Provider>
    );
};

export const useMedicalScoreContext = () => useContext(MedicalScoreContext);
