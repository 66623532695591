import jwt_decode from 'jwt-decode';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { compose } from 'redux';
import 'toasted-notes/src/styles.css';
import AuthUtil from '../../../api/authUtil';
import '../../../App.scss';
import { AUTHORITIES } from '../../../config/constants';
import { Authentication } from '../../../model/authentication';
import { Customer } from '../../../model/customer';
import { CustomerRegister } from '../../../model/customer-register';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { IRootState } from '../../../reducer';
import { getSessionRequest, loginRequest } from '../../../reducer/authentication/actions';
import { registerRequest } from '../../../reducer/register/actions';
import { getMobileOperatingSystem, OperatingSystem } from '../../../shared/util/utils';
import { InfoStep } from './infoStep';
import { LoginStep } from './loginStep';

export interface RegisterState {
    customerRegister: CustomerRegister;
    showRegisterScreen: boolean;
    step: RegisterMobileStep;
}

export interface RegisterProps extends RouteComponentProps<{}> {
    register: (customer: CustomerRegister) => void;
    login: (authentication: Authentication) => void;
    getSession: () => void;
    registerStatus: HttpRequestStatus;

    loginStatus: HttpRequestStatus;
    t: any;
    isAuthenticated: any;
}

export enum RegisterMobileStep {
    LOGIN_STEP = 1,
    INFO_STEP = 2,
    FINISHED_STEP = 3
}

export class RegisterMobile extends React.Component<RegisterProps, RegisterState> {
    constructor(props: Readonly<RegisterProps>, context?: any) {
        super(props, context);
        this.state = {
            customerRegister: {
                email: '',
                password: '',
                customer: {} as Customer
            },
            showRegisterScreen: false,
            step: RegisterMobileStep.LOGIN_STEP
        };
    }

    componentDidMount() {
        if (AuthUtil.isAuthenticated()) {
            const decodeJwt: any = jwt_decode(AuthUtil.getToken()!);
            if (decodeJwt.auth.includes(AUTHORITIES.CUSTOMER)) {
                this.props.getSession();
                return;
            }
        }
    }

    componentWillReceiveProps(newProps: RegisterProps) {
        if (newProps.isAuthenticated === true && this.props.isAuthenticated !== newProps.isAuthenticated) {
            this.props.history.push('/home');
        }
        if (newProps.registerStatus === HttpRequestStatus.SUCCESS) {
            this.setState({
                step: RegisterMobileStep.FINISHED_STEP
            });
        }
    }

    handleFinishedFirstStep = (customerRegister: CustomerRegister) => {
        this.setState({
            customerRegister: {
                ...this.state.customerRegister,
                email: customerRegister.email,
                password: customerRegister.password,
                customer: { ...this.state.customerRegister.customer, name: customerRegister.customer.name }
            },
            step: RegisterMobileStep.INFO_STEP
        });
    };

    handleFinishedSecondStep = (customerRegister: CustomerRegister) => {
        const { gender, fieldOfWork, formation, specialization, formationYear } = customerRegister.customer;
        this.setState(
            {
                customerRegister: {
                    ...this.state.customerRegister,
                    customer: { ...this.state.customerRegister.customer, gender, fieldOfWork, formation, specialization, formationYear }
                }
            },
            () => this.props.register(this.state.customerRegister)
        );
    };

    setStep = (step: RegisterMobileStep) => {
        this.setState({
            step
        });
    };

    private goToLogin = () => {
        this.props.history.push('/');
    };

    private openApp = (): void => {
        if (getMobileOperatingSystem() === OperatingSystem.ANDROID) {
            window.location.replace('com.wemeds://');
        } else if (getMobileOperatingSystem() === OperatingSystem.IOS) {
            window.location.replace('wemeds://');
        }
    };

    private downloadApp = (): void => {
        if (getMobileOperatingSystem() === OperatingSystem.ANDROID) {
            window.location.replace('market://details?id=com.wemeds');
        } else if (getMobileOperatingSystem() === OperatingSystem.IOS) {
            window.location.replace('https://apps.apple.com/us/app/wemeds-medicina/id1446915058?l=pt&ls=1');
        }
    };

    render() {
        return (
            <div className="page-container">
                <div className="blank-header">
                    <div onClick={this.goToLogin} className="header-logo" />
                </div>
                <div className="step-info">
                    <div style={this.state.step === RegisterMobileStep.LOGIN_STEP ? {} : { display: 'none' }}>
                        {this.state.showRegisterScreen && (
                            <div className="step-container-mobile">
                                <Row className="head-step">
                                    <div className="progress-step" />
                                </Row>
                                <Row className="head-step-text text-step">
                                    <span>{this.props.t('register.step.login')}</span>
                                </Row>
                                <Row className="row-mobile" />
                            </div>
                        )}
                        <LoginStep
                            {...this.props}
                            handleFinishedFirstStep={(customerRegister: CustomerRegister) => this.handleFinishedFirstStep(customerRegister)}
                            setShowRegisterScreen={(show: boolean) => this.setState({ showRegisterScreen: show })}
                            login={this.props.login}
                        />
                    </div>

                    <div style={this.state.step === RegisterMobileStep.INFO_STEP ? {} : { display: 'none' }}>
                        <div className="step-container-mobile">
                            <Row className="head-step">
                                <div className="progress-finish" />
                            </Row>
                            <Row className="head-step-text text-step">
                                <span>{this.props.t('register.step.login')}</span>
                            </Row>
                            <span className="register-mobile-edit-label" onClick={() => this.setStep(RegisterMobileStep.LOGIN_STEP)}>{this.props.t('register.step.edit')}</span>
                            <Row className="row-mobile" />
                        </div>
                        <div className="step-container-mobile">
                            <Row className="head-step">
                                <div className="progress-step" />
                            </Row>
                            <Row className="head-step-text text-step">
                                <span>{this.props.t('register.step.data')}</span>
                            </Row>
                            <Row className="row-mobile" />
                        </div>
                        <InfoStep
                            handleFinishedSecondStep={(customerRegister: CustomerRegister) =>
                                this.handleFinishedSecondStep(customerRegister)
                            }
                            {...this.props}
                        />
                    </div>
                </div>
                {this.state.step === RegisterMobileStep.FINISHED_STEP && (
                    <div>
                        <div className="step-container">
                            <Row className="head-step-text text-step">
                                <span>{this.props.t('register.step.login')}</span>
                                <div className="space-step-blank" />
                                <span>{`${this.props.t('register.step.data')} `}</span>
                            </Row>
                            <Row className="head-step">
                                <div className="progress-finish" />
                                <div className="space-step-on" />
                                <div className="progress-finish" />
                            </Row>
                        </div>
                        <Row className="justity-content-center">
                            <div className="success-back">
                                <div className="success-img" />
                            </div>
                        </Row>
                        <Row style={{ marginTop: '-20px' }} className="justity-content-center">
                            <b>
                                <p className={'main-text'} style={{ fontSize: '26px' }}>
                                    {this.props.t('register.text3')}
                                </p>
                            </b>
                        </Row>
                        <Row className="justity-content-center">
                            <p className={'sub-text sub-text-mobile'}>{this.props.t('register.text3-sub')}</p>
                        </Row>
                        <div className="button-container">
                            <Col className="centered-column">
                                <Button
                                    onClick={this.downloadApp}
                                    className="button-primary"
                                    style={{ justifyContent: 'center', display: 'flex', width: '170px' }}
                                >
                                    {this.props.t('global.button.downloadApp')}
                                </Button>
                            </Col>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ register, authentication }: IRootState) => ({
    isAuthenticated: authentication.isAuthenticated,
    registerStatus: register.registerStatus,
    loginStatus: authentication.loginStatus
});

const mapDispatchToProps = {
    getSession: getSessionRequest,
    register: registerRequest,
    login: loginRequest
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(RegisterMobile) as React.ComponentType<any>;
