import _ from 'lodash';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { OnDutyDoctor } from '../context/divider-on-duty-provider';
import '../divider-on-duty.scss';
import { AddNew, FooterLineContainer, Index, IndexContainer, LineContainer, NameInput, RightIconContainer } from '../on-duty-styles';

interface Props {
    t: any;
    items: OnDutyDoctor[];
    onItemsChange: (item: OnDutyDoctor[]) => void;
}

const DragDropDoctors = (props: Props) => {
    const onAdd = () => {
        const newItems = [...props.items];
        newItems.push({ index: props.items.length, name: '' } as OnDutyDoctor);
        props.onItemsChange(newItems);
    }

    const onEditName = (item: OnDutyDoctor, newValue: string) => {
        const newItems = [...props.items];
        newItems[item.index].name = newValue;
        props.onItemsChange(newItems);
    }

    const onDelete = (item: OnDutyDoctor) => {
        const newItems = _.sortBy(props.items.filter(it => it.index !== item.index), 'index');
        newItems.forEach((it, index) => it.index = index);
        props.onItemsChange(newItems);
    }

    const reorder = (list: OnDutyDoctor[], startIndex, endIndex) => {
        const result = Array.from(list);
        // tslint:disable-next-line: no-assign-mutated-array
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newItems: OnDutyDoctor[] = reorder(
            props.items,
            result.source.index,
            result.destination.index
        );

        newItems.forEach((it, index) => it.index = index);
        props.onItemsChange(newItems);
    }

    const renderFooter = () => {
        return (
            <FooterLineContainer onClick={() => onAdd()}>
                <div />
                <AddNew>{props.t(['onDuty.addNew'])}</AddNew>
                <RightIconContainer onClick={() => onAdd()}>
                    <div className={'on-duty-circle-plus'} />
                </RightIconContainer>
            </FooterLineContainer>
        );
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {props.items.map((item, index) => (
                                <Draggable key={item.index} draggableId={String(item.index)} index={index}>
                                    {(p, s) => (
                                        <LineContainer ref={p.innerRef}
                                            {...p.draggableProps}
                                            {...p.dragHandleProps}
                                            key={item.index}
                                            id={item.index}>
                                            <div className={'on-duty-dots'} />
                                            <IndexContainer>
                                                <Index>{item.index + 1}</Index>
                                            </IndexContainer>
                                            <NameInput value={item.name} placeholder={props.t(['onDuty.namePlaceholder'])} onChange={e => onEditName(item, e.target.value)} type={'text'} />
                                            <RightIconContainer onClick={() => onDelete(item)}>
                                                <div className={'on-duty-circle-cross'} />
                                            </RightIconContainer>
                                        </LineContainer>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {renderFooter()}
        </div>
    );
}

export default compose(connect(null, null), withTranslation())(DragDropDoctors) as React.ComponentType<any>;