import React from 'react';
import '../specialties/specialty.scss';

export interface Props {
    iconSize: {height: string, width: string};
    protocolIcon?: any;
    protocolTitleImage?: any;
}

const ProtocolsCardItem = (props: Props) => {
    return (
        <div className="protocols-card-content">
            <div className="specialty-card-content__left">
                {props.protocolIcon && (
                    <img src={`${props.protocolIcon}`} className="protocols-card-content__icon" />
                )}
                {props.protocolTitleImage && (
                    <img src={`${props.protocolTitleImage}`} style={{...props.iconSize}} />
                )}
            </div>
        </div>
    );
};

export default ProtocolsCardItem;
