import { AxiosResponse } from 'axios';
import MedicalCriteriaApi from '../api/medical-criteria.api';
import HttpStatus from '../model/enums/httpStatus';
import { PageableResponse } from '../model/pageable';
import {
  MedicalCriteria,
  MedicalCriteriaSimple,
} from '../model/medical-criteria/medical-criteria';

export const MedicalCriteriaService = () => {
  const getInfo = async (id: number): Promise<MedicalCriteria> => {
    try {
      const result: AxiosResponse<MedicalCriteria> =
        await MedicalCriteriaApi.getInfo(id);
      if (result.status === HttpStatus.OK) {
        return await Promise.resolve(result.data);
      } else {
        return await Promise.reject({ status: 400 });
      }
    } catch (error) {
      console.error(error);
      return await Promise.reject(error);
    }
  };

  const getAvailableForFree = async (): Promise<MedicalCriteria[]> => {
    try {
      const result: AxiosResponse<PageableResponse<MedicalCriteria>> =
        await MedicalCriteriaApi.getAvailableForFreeCriterias();
      if (result.status === HttpStatus.OK) {
        return await Promise.resolve(result.data.content);
      } else {
        return await Promise.reject({ status: 400 });
      }
    } catch (error) {
      console.error(error);
      return await Promise.reject(error);
    }
  };

  const search = async (text?: string): Promise<MedicalCriteriaSimple[]> => {
    try {
      const result: AxiosResponse<PageableResponse<MedicalCriteriaSimple>> =
        await MedicalCriteriaApi.search(text);
      if (result.status === HttpStatus.OK) {
        return await Promise.resolve(result.data.content);
      } else {
        return await Promise.reject({ status: 400 });
      }
    } catch (error) {
      console.error(error);
      return await Promise.reject(error);
    }
  };

  return {
    getInfo,
    getAvailableForFree,
    search,
  };
};

export default MedicalCriteriaService();
