import React from 'react';
import { PracticeButton, PracticeIcon, PracticeText } from '../../flashcard-styles';
import '../../flashcard.scss';
import {
    ButtonContainer,
    MainContainer,
    RocketIcon,
    Text,
    TextContainer,
    Title
} from './flashcard-run-info-card.styles';

interface Props {
    title: string;
    text: string;
    onPress: () => void;
}

const FlashcardRunInfoCard = (props: Props) => {
    return (
        <MainContainer>
            <TextContainer>
                <Title>{props.title}</Title>
                <RocketIcon className={'rocket-img'} />
                <Text>{props.text}</Text>
            </TextContainer>
            <ButtonContainer>
                <PracticeButton onClick={props.onPress}>
                    <div style={{ height: 56 }} />
                    <PracticeText>
                        {'Começar'}
                    </PracticeText>
                    <PracticeIcon className={'orange-play-img'} />
                </PracticeButton>
            </ButtonContainer>
        </MainContainer>
    );
};

export default FlashcardRunInfoCard;
