import { AxiosResponse } from 'axios';
import { EditPasswordParams } from '../model/editPassword';
import { User } from '../model/user';
import { api } from './api';

export const customerApi = () => {
    const save = (user: User): Promise<AxiosResponse<User>> => {
        return api.put<User>('/account/update', user);
    };
    const activateCustomer = (key: string): Promise<AxiosResponse<void>> => {
        return api.post<void>(`/account/activate/${key}`);
    };
    const indicateFriend = (email: string): Promise<AxiosResponse<void>> => {
        return api.get<void>(`/customers/indicate?email=${email}`);
    };

    const editPassword = (params: EditPasswordParams): Promise<AxiosResponse<any>> => {
        return api.put<any>('/account/change-password', params);
    };

    return {
        save,
        activateCustomer,
        indicateFriend,
        editPassword
    };
};

export default customerApi();
