import { RecentHistory } from '../../model/recentHistory';

export type ApiResponse = Record<string, any>;

export enum RecentHistoryTypes {
  ADD_IN_RECENTS = '@@recentHistory/ADD_IN_RECENTS',
  SET_RECENTS = '@@recentHistory/SET_RECENTS'
}

export interface RecentHistoryState {
  readonly recents: RecentHistory[];
  readonly ordenedRecents: RecentHistory[];
}
