import { FlashcardAnswer } from './flashcard-answer';
import { FlashcardAnswers } from './flashcard-answers';
import { FlashcardStatistic } from './flashcard-statistic';

export interface PerformanceData {
    statistic?: FlashcardStatistic;
    secondsSpent?: number;
    remainingQuestions?: number;
    answeredQuestions?: number;
    answers: FlashcardAnswer[];
}

export function convertToPerformanceData(flashcardStatistic: FlashcardStatistic) {
    const answers = (flashcardStatistic.answers as FlashcardAnswers).answers?.answers!;
    const result: PerformanceData = {
        statistic: flashcardStatistic,
        secondsSpent: flashcardStatistic.secondsSpent!,
        remainingQuestions: flashcardStatistic.remainingQuestions!,
        answeredQuestions: flashcardStatistic.answeredQuestions!,
        answers
    };

    return result;
}
