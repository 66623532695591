import React, { useEffect } from 'react';
import { MedicalCalculatorParam } from '../../../model/medical-calculator/medical-calculator-param';
import { useMedicalCalculatorContext } from '../medical-calculator.provider';
import MedicalCalculatorDateParam from './medical-calculator-date-param';
import MedicalCalculatorInputParam from './medical-calculator-input-param';
import { ParamContainer, ParamTitle, UsgParamContainer } from './medical-calculator-params-styles';

interface Props {
    params: MedicalCalculatorParam[];
}

const MedicalCalculatorUsgView = (props: Props) => {
    const { params } = props;
    const { paramValues, setParamValues } = useMedicalCalculatorContext();

    useEffect(() => {
        const weekParam = params[2];
        const dayParam = params[3];
        if (paramValues[weekParam.code!] !== undefined && paramValues[dayParam.code!] === undefined) {
            const newValues = { ...paramValues };
            newValues[dayParam.code!] = '0';
            setParamValues(newValues);
        }
    }, [paramValues]);

    const weekInputStyle = { marginRight: 30 };

    return (
        <>
            <ParamContainer>
                <ParamTitle>{params[0].presentationName}</ParamTitle>
                <MedicalCalculatorDateParam parameterInfo={params[0]} />
            </ParamContainer>
            <ParamContainer>
                <ParamTitle>{params[1].presentationName}</ParamTitle>
                <MedicalCalculatorDateParam parameterInfo={params[1]} />
            </ParamContainer>
            <ParamContainer>
                <ParamTitle>{params[2].presentationName}</ParamTitle>
                <UsgParamContainer>
                    <MedicalCalculatorInputParam
                        placeholder={'0'}
                        parameterInfo={params[2]}
                        containerStyle={weekInputStyle}
                    />
                    <MedicalCalculatorInputParam
                        placeholder={'0'}
                        parameterInfo={params[3]}
                    />
                </UsgParamContainer>
            </ParamContainer>
        </>
    );
};

export default MedicalCalculatorUsgView;
