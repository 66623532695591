import { Base64 } from 'js-base64';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import htmlParser from 'react-markdown/plugins/html-parser';
import ReactMarkdown from 'react-markdown/with-html';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { cleanMarkdownSyntax, createFunctionByString } from '../../shared/util/markdown-utils';
import { usePediatricDoseContext } from './pediatric-dose.provider';
import './pediatric-dose.scss';

interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
    hasSubscription?: boolean;
    openPaidModal: () => void;
}

const parseHtml = htmlParser({
    isValidNode: node => node.type !== 'script',
    processingInstructions: [
        /* ... */
    ]
});

const PediatricDoseContent = (props: Props) => {
    const [contentText, setContentText] = useState<string>('');
    const [originalContentText, setOriginalContentText] = useState<string>('');
    const { currentPediatricDose, inputValue, inError, setInError, fontSize } = usePediatricDoseContext();

    useEffect(() => {
        if (inError) {
            const newContentText = _.replace(contentText, new RegExp(`"dose-markdown__formula-container-text"`, 'g'), '"dose-markdown__formula-container-text--inError"');
            setContentText(newContentText);
        } else {
            const newContentText = _.replace(contentText, new RegExp(`"dose-markdown__formula-container-text--inError"`, 'g'), '"dose-markdown__formula-container-text"');
            setContentText(newContentText);
        }
    }, [inError]);

    useEffect(() => {
        if (currentPediatricDose !== undefined) {
            let newText = cleanMarkdownSyntax(Base64.decode(currentPediatricDose?.contentText!));
            setOriginalContentText(newText);
            if(!_.isEmpty(inputValue)) {
                insertCalculateResultInContextText(inputValue, newText);
            } else {
                currentPediatricDose?.contents?.forEach((content) => {
                    const dosageMetric = _.replace(content.code?.split(';')[1] ?? '', '))', '');
                    const codeRegex = new RegExp(`${content.code!.replace('((', '\\(\\(').replace('))', '\\)\\)')}`, 'g');
                    // tslint:disable-next-line: prefer-template
                    const codeHtml = '<span className="dose-markdown__formula-container-text">00 ' + (dosageMetric ?? '') + '</span>';
                    newText = _.replace(newText, codeRegex, codeHtml);
                });
                setContentText(newText);
            }
        }
    }, [currentPediatricDose]);


    useEffect(() => {
        insertCalculateResultInContextText(inputValue, originalContentText);
    }, [inputValue]);

    const insertCalculateResultInContextText = (value: string | null, originalMarkdownText: string) => {
        if (currentPediatricDose !== undefined) {
            if (props.hasSubscription === false && currentPediatricDose.isAvailableForFree !== true) {
                props.openPaidModal();
                return;
            }
            let newText = originalMarkdownText;
            currentPediatricDose.contents?.forEach((content, index) => {
                let result = '00';
                const dosageMetric = _.replace(content.code?.split(';')[1] ?? '', '))', '');
                if (value !== null) {
                    try {
                        result = createFunctionByString(`var run${index} = ${content.formula!}; run${index}(${value});`);
                        if (result.toString().includes('[Error]')) {
                            alert(_.replace(result, '[Error]', ''));
                            setInError(true);
                            result = '00';
                        }
                    } catch (e) {
                        result = '';
                    }
                }
                const codeRegex = new RegExp(`${content.code!.replace('((', '\\(\\(').replace('))', '\\)\\)')}`, 'g');
                result = formatFormulaResult(result);

                // tslint:disable-next-line: prefer-template
                const codeHtml = '<span className="dose-markdown__formula-container-text' + (inError ? '--inError' : '') + '">' + result + ' ' + (dosageMetric ?? '') + '</span>';
                newText = _.replace(newText, codeRegex, codeHtml);
            });
            setContentText(newText);
        }
    }

    const formatFormulaResult = (result: string) => {
        if (result === '') {
            return '00';
        }
        if (!_.isNaN(Number(result))) {
            const numberResult = Number(result);
            return numberResult % 1 === 0 ? _.padStart(numberResult.toString(), 2, '0') : numberResult.toPrecision(3);
        }
        return result;
    }

    return (
        <div className="dose-content">
            <ReactMarkdown
                source={contentText}
                className="dose-markdown"
                escapeHtml={false}
                astPlugins={[parseHtml]}
                renderers={{
                    paragraph: markdownProps => (
                        <div className="dose-markdown__paragraph" style={{ fontSize }}>
                            {markdownProps.children.map(it => it)}
                        </div>
                    ),
                    strong: markdownProps => <span className="dose-markdown__strong">{markdownProps.children.map(it => it)}</span>,
                    emphasis: markdownProps => <span className="dose-markdown__emphasis">{markdownProps.children.map(it => it)}</span>,
                    listItem: markdownProps => <li className="dose-markdown__list-item" style={{ fontSize }}>{markdownProps.children.map(it => it)}</li>,
                    code: markdownProps => <p className="dose-markdown__code">{markdownProps.value}</p>,
                    blockquote: markdownProps => (
                        <div className="dose-markdown__blockquote">
                            <div className="dose-markdown__blockquote__content">{markdownProps.children.map(it => it)}</div>
                        </div>
                    ),
                }}
            />
        </div>
    );
}

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(PediatricDoseContent) as React.ComponentType<any>;
