import styled from 'styled-components';

export const StepContainer = styled.div`
    /* margin-top: 10px; */
`;

export const CardContainer = styled.div`
    margin-top: 20px;
    width: 484px;
    min-height: 440px;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
`;

export const HeaderContainer = styled.div`
    display: flex;
    margin-top: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

export const BoxPaginationContainer = styled.div`
    width: 200px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: rgba(186, 204, 210, 0.1);
`;

export const Title = styled.span`
    font-family: 'AccordAlt';
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 10px;
`;

export const PrintLogoSubtitle = styled.span`
    height: 22px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #1A3C47;
`;

export const TimeTitle = styled.span`
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 30px;
`;

export const TimeText = styled.span`
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
`;

export const Info = styled.span`
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #5AB0AE;
    margin-left: 10px;
`;

export const PageTitle = styled.span`
    font-family: 'Raleway';
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
`;

export const Subtitle = styled.span`
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 14px;
`;

export const PrintSubtitle = styled.span`
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 14px;
    color: #EF7D00;
    margin-right: 20px;
`;

export const ArrowContainer = styled.div<{ position: 'left' | 'right', disabled: boolean, isDownload: boolean }>`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    cursor: pointer;
    border-radius: 200px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: ${props => props.position === 'left' ? '-25px' : '460px'};
    background: ${props => props.position === 'left' ? props.disabled ? '#F4F5F5' : '#FFFFFF' : props.isDownload ? '#5AB0AE' : '#EF7D00'};
`;

export const LineContainer = styled.div`
    display: flex;
    width: 314px;
    height: 48px;
    margin-bottom: 10px;
    border-radius: 6px;
    align-items: center;
    background: #FAFAFB;
    border: 1px solid #E9ECED;
`;

export const FooterLineContainer = styled.div`
    display: flex;
    width: 314px;
    height: 48px;
    cursor: pointer;
    border-radius: 6px;
    margin-bottom: 10px;
    align-items: center;
    background: #FAFAFB;
    border: 1px solid #E9ECED;
    justify-content: space-between;
`;

export const IndexContainer = styled.div`
    width: 35px;
    height: 22px;
    display: flex;
    margin-right: 10px;
    margin-left: 5px;
    align-items: center;
    border-radius: 150px;
    background: #4C6C7610;
    justify-content: center;
`;

export const DoctorIndexContainer = styled.div`
    width: 50px;
    height: 22px;
    display: flex;
    margin-right: 10px;
    margin-left: 5px;
    align-items: center;
    border-radius: 13px;
    background: #4C6C76;
    justify-content: center;
`;

export const Index = styled.span`
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 12px;
`;

export const AddNew = styled.span`
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 14px;
`;

export const NameInput = styled.input`
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Raleway';
    background-color: transparent;
    border: solid 0px transparent;
    &:focus-visible {
        outline: none;
    }
`;

export const ScaleTime = styled.span`
    font-size: 14px;
    font-weight: 500;
    font-family: 'Raleway';
`;

export const RightIconContainer = styled.div`
`;

export const StepMainContainer = styled.div`
    display: flex; 
    align-items: center;
    flex-direction: column; 
`;

export const TimeContainer = styled.div`
    display: flex;
    width: 314px;
    height: 54px;
    border-radius: 6px;
    align-items: center;
    background: #FAFAFB;
    box-sizing: border-box;
    border: 1px solid #E9ECED;
`;

export const PenContainer = styled.div`
    height: 70%;
    display: flex;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    justify-content: center;
    border-left: 1.5px solid #E9ECED;
`;

export const InfoContainer = styled.div`
    display: flex;
    margin-top: 20px;
    align-items: center;
`;

export const LogoContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
    align-items: center;
`;

export const PrintMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 48px;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    justify-content: center;
    background-color: white;
`;