import { AxiosResponse } from 'axios';
import { FlashcardView } from '../model/flashcard/flashcard';
import { FlashcardStatistic } from '../model/flashcard/flashcard-statistic';
import { MedicalScore } from '../model/medical-score/medical-score';
import { PageableResponse } from '../model/pageable';
import { api } from './api';

const FlashcardStatisticApi = () => {
    const save = (statistic: FlashcardStatistic): Promise<AxiosResponse<void>> => {
        return api.post<void>('/flashcard-statistics', statistic);
    };

    const getByDiseaseId = (id: number): Promise<AxiosResponse<FlashcardStatistic | null>> => {
        return api.get<FlashcardStatistic | null>(`/flashcard-statistics?id=${id}`);
    };

    return {
        save,
        getByDiseaseId
    };
};

export default FlashcardStatisticApi();
