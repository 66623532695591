import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useFlashcardViewContext } from '../flashcard-carousel/flashcard.provider';
import {
    ArrowBack,
    EyeIcon,
    HeaderContainer,
    HeaderRightContainer,
    HeaderSubtitle,
    HeaderText
} from '../flashcard-styles';
import '../flashcard.scss';
import FlashcardFontResize from './flashcard-font-resize';

interface Props extends WithTranslation {
    title: string;
    subtitle: string;
    onPressBack: () => void;
    viewMode: 'LIST' | 'PERFORMANCE' | 'CARD';
    children?: React.ReactNode;
}

const FlashcardHeader = (props: Props) => {
    const { cards, setCards } = useFlashcardViewContext();
    const { title, subtitle, onPressBack, viewMode, children } = props;

    const changeAnswersVisualization = () => {
        const newCards = [...cards];
        const answersOpen = newCards.some(card => card.seeAnswerInList);
        newCards.forEach(card => {
            card.seeAnswerInList = !answersOpen;
        });
        setCards(newCards);
    };

    return (
        <HeaderContainer>
            <ArrowBack className={'black-caret-left-img'} onClick={onPressBack} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <HeaderSubtitle>{subtitle}</HeaderSubtitle>
                <HeaderText>{title}</HeaderText>
            </div>
            <HeaderRightContainer>
                <FlashcardFontResize showIcon={viewMode === 'LIST'} />
                {viewMode === 'LIST' && <EyeIcon onClick={changeAnswersVisualization}
                                                 className={cards.some(card => card.seeAnswerInList) ? 'orange-open-eye-img' : 'orange-close-eye-img'} />}
                {children}
            </HeaderRightContainer>
        </HeaderContainer>
    );
};

export default withTranslation()(FlashcardHeader);
