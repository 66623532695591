import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import FlashcardAnswerValue from '../../../model/enums/flashcard-answer-value';
import { FlashcardView } from '../../../model/flashcard/flashcard';
import '../flashcard.scss';
import { useFlashcardViewContext } from './flashcard.provider';

interface Props extends WithTranslation {
    index: number;
    card: FlashcardView;
    startCardTimestamp: number;
    goToNextNotAnswered: (index: number) => void;
}

const FlashcardAnswers = (props: Props) => {
    const { updateCard, onAnswerFlashcard } = useFlashcardViewContext();
    const { index, card, goToNextNotAnswered, startCardTimestamp } = props;

    const onAnswer = (answer: FlashcardAnswerValue) => {
        const newCard = { ...card, answer };
        updateCard(index, newCard);
        onAnswerFlashcard(newCard, startCardTimestamp);
        goToNextNotAnswered(index);
    };

    const renderButton = (buttonText: string, answer: FlashcardAnswerValue) => {
        return (
            <div
                className={`answer-button-container--${answer.toString()}`}
                onClick={() => (card.answer !== answer ? onAnswer(answer) : null)}
            >
                <span className={`answer-button-text--${answer.toString()}`}>{props.t(`flashcardCarousel.${buttonText}`)}</span>
            </div>
        );
    };

    return (
        <>
            {(card.answer == null || card.answer === FlashcardAnswerValue.WRONG) && renderButton('wrong', FlashcardAnswerValue.WRONG)}
            {(card.answer == null || card.answer === FlashcardAnswerValue.ALMOST) && renderButton('almost', FlashcardAnswerValue.ALMOST)}
            {(card.answer == null || card.answer === FlashcardAnswerValue.RIGHT) && renderButton('right', FlashcardAnswerValue.RIGHT)}
        </>
    );
};

export default withTranslation()(FlashcardAnswers);
