import { AxiosResponse } from 'axios';
import { FlashcardView } from '../model/flashcard/flashcard';
import { FlashcardCustomer } from '../model/flashcard/flashcard-customer';
import { MedicalScore } from '../model/medical-score/medical-score';
import { PageableResponse } from '../model/pageable';
import { api } from './api';

const FlashcardCustomerApi = () => {
    const getMeFlashcardCustomers = (): Promise<AxiosResponse<FlashcardCustomer[]>> => {
        return api.get<FlashcardCustomer[]>('/flashcard-customers/me');
    };

    const saveFlashcardCustomer = (flashcardCustomer: FlashcardCustomer): Promise<AxiosResponse<void>> => {
        return api.post<void>('/flashcard-customers', flashcardCustomer);
    };

    return {
        getMeFlashcardCustomers,
        saveFlashcardCustomer
    };
};

export default FlashcardCustomerApi();
