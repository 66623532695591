enum Category {
    SURGERY = 'SURGERY',
    CLINIC = 'CLINIC',
    GYNECOLOGIC = 'GYNECOLOGIC',
    OBSTETRICS = 'OBSTETRICS',
    DENTISTRY = 'DENTISTRY',
    PREVENTIVE = 'PREVENTIVE',
    PEDIATRICS = 'PEDIATRICS'
}

export default Category;
