import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { WeekDay } from '../../../../model/enums/week-day';
import '../../flashcard.scss';
import { InputContainer, MainContainer, WeekDayContainer, WeekDayText } from './week-select.styles';

interface Props extends WithTranslation {
    values: WeekDay[];
    onSelectWeekDay: (value: WeekDay) => void;
}

const WeekSelect = (props: Props) => {
    const { values, onSelectWeekDay } = props;
    const WEEK_DAYS = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];

    const onPressWeekDay = (weekDay: WeekDay) => {
        onSelectWeekDay(weekDay);
    };

    const renderWeekDay = (weekDayKey: string, index: number) => {
        const weekDay: WeekDay = Object.values(WeekDay)[index] as WeekDay;
        const isSelected = values.includes(weekDay);
        return (
            <WeekDayContainer onClick={() => onPressWeekDay(weekDay)} isSelected={isSelected} key={weekDayKey}>
                <WeekDayText isSelected={isSelected}>
                    {weekDayKey}
                </WeekDayText>
            </WeekDayContainer>
        );
    };

    return (
        <MainContainer>
            <InputContainer>
                {WEEK_DAYS.map((it, index) => renderWeekDay(it, index))}
            </InputContainer>
        </MainContainer>
    );
};

export default withTranslation()(WeekSelect);
