import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Button from '../../../components/button/button';
import { IRootState } from '../../../reducer';
import '../diagnapp.scss';

export interface SuccessComponentProps extends RouteComponentProps<{}> {
    t: (path: string, params?: any) => string;
    onSeeResults: () => void;
}

export class SuccessComponent extends React.Component<SuccessComponentProps> {
    constructor(props: Readonly<SuccessComponentProps>, context?: any) {
        super(props, context);

        this.state = {};
    }

    // Renders
    render() {
        return (
            <div className="success-component">
                <div className="success-component__icon" />
                <p className="success-component__title">{this.props.t('diagnApp.successComponent.title').toUpperCase()}</p>
                <p className="success-component__subtitle">{this.props.t('diagnApp.successComponent.subtitle')}</p>
                <Button label={this.props.t('diagnApp.successComponent.buttonLabel')} onPress={this.props.onSeeResults}/>
            </div>
        );
    }
}

const mapStateToProps = ({}: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SuccessComponent) as React.ComponentType<any>;
