import { AxiosResponse } from 'axios';
import _ from 'lodash';
import flashcardApi from '../api/flashcard.api';
import Category from '../model/enums/category';
import HttpStatus from '../model/enums/httpStatus';
import { FlashcardNode } from '../model/flashcard/flashcard-node';

const FlashcardService = () => {
    const findAllSpecialityIdsByCategory = async (node: FlashcardNode): Promise<FlashcardNode> => {
        try {
            const result: AxiosResponse<FlashcardNode[]> = await flashcardApi.getFlashcardsNodeGroupInfo(node.category);
            const contentNodes = result.data;
            node.flashcardAmount = contentNodes.map(it => it.flashcardAmount ?? 0).reduce((a, b) => a + b);
            node.specialitiesSimple = _.uniqWith(contentNodes, (arrVal, othVal) => arrVal.id === othVal.id);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(node);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const findAllSpecialityIdsByGroupId = async (node: FlashcardNode): Promise<FlashcardNode> => {
        try {
            const result: AxiosResponse<FlashcardNode[]> = await flashcardApi.getFlashcardsNodeGroupInfo(undefined, node.id!);
            const contentNodes = result.data;
            node.flashcardAmount = contentNodes.map(it => it.flashcardAmount ?? 0).reduce((a, b) => a + b);
            node.specialitiesSimple = _.uniqWith(contentNodes, (arrVal, othVal) => arrVal.id === othVal.id);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(node);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const findAllSpecialityAndGroupsFlashcardsByCategory = async (category: Category): Promise<FlashcardNode[]> => {
        try {
            const result: AxiosResponse<FlashcardNode[]> = await flashcardApi.getFlashcardsNodeInfo(category);
            const contentNodes = result.data;
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(contentNodes);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const findAllByGroupId = async (groupId: number): Promise<FlashcardNode[]> => {
        try {
            const result: AxiosResponse<FlashcardNode[]> = await flashcardApi.getFlashcardsNodeInfo(undefined, groupId);
            const contentNodes = result.data;
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(contentNodes);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    return {
        findAllSpecialityIdsByCategory,
        findAllSpecialityIdsByGroupId,
        findAllSpecialityAndGroupsFlashcardsByCategory,
        findAllByGroupId
    };
};

export default FlashcardService();
