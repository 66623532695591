import { StylesConfig } from 'react-select';
import styled from 'styled-components';

export const ParamContainer = styled.div`
    display: flex;
    width: 95%;
    margin-bottom: 26px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
`;

export const ParamTitle = styled.span`
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1A3C47;
    margin-bottom: 10px;
`;

export const InputParamContainer = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #E9ECED;
    border-radius: 6px;
    padding-left: 13px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
`;

export const MeasurementText = styled.span`
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 14px;
    color: #A3B1B5;
    margin-right: 15px;
`;

export const PlaceholderText = styled.span`
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 14px;
    color: #A3B1B5;
    margin-right: 15px;
`;

export const DateText = styled.span`
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #1A3C47;
`;

export const InputParam = styled.input`
    border: 0px solid transparent;
    width: 100%;
    text-align: left;
    padding-right: 10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #1A3C47;
    &:focus-visible {
        outline: none;
    };
    &::placeholder {
        color: #A3B1B5;
    }
`;

export const ParamPickerContainer = styled.div`
    display: flex;
    border: 1px solid #DBD9D1;
    border-radius: 100px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    background-color: #F6F5F4;
`;

export const OptionLabel = styled.span`
    text-align: left;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #1A3C47;
    width: 80%;
`;

export const UsgParamContainer = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 26px;
    width: 75%;
    justify-content: flex-end;
`;

export const ParamGroupTitle = styled.div`
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.44px;
    margin-bottom: 15px;
    color: #414E52;
    width: 100%;
`;

export const pickerStyles: StylesConfig<any> = {
    control: styles => ({
        ...styles,
        backgroundColor: '#F6F5F4',
        width: '36.5vw',
        border: '1px solid #DBD9D1',
        borderRadius: '50px',
        boxShadow: '0 0 0 0 transparent',
        minHeight: '44px',
        ':focus': {
            ...styles[':focus'],
            boxShadow: '0 0 0 0 transparent'
        }
    }),
    option: (styles, { isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? '#DEEBFF' : undefined,
            ':active': {
                ...styles[':active'],
                backgroundColor: isSelected ? '#2684FF' : undefined
            }
        };
    },
    indicatorSeparator: styles => ({ ...styles, backgroundColor: 'transparent' }),
    multiValue: styles => ({ ...styles, backgroundColor: 'transparent' })
};