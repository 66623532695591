import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import DiagnAppRadio, { RadioOption } from '../../../components/diagnapp-radio/diagnapp-radio';
import SwitchButton from '../../../components/switch-button/switch-button';
import Region from '../../../model/enums/region';
import RegionType from '../../../model/enums/regionType';
import { IRootState } from '../../../reducer';
import '../diagnapp.scss';
import _ from 'lodash';

export interface RegionComponentProps extends RouteComponentProps<{}> {
    t: (path: string, params?: any) => string;
    onChangeInput: (regionType: RegionType, region: Region) => void;
    region?: Region;
    regionType?: RegionType;
}

export interface RegionComponentState {
    region?: Region;
    regionType: RegionType;
}
export class RegionComponent extends React.Component<RegionComponentProps, RegionComponentState> {
    private insideBrazilOptions: RadioOption[] = [
        { label: this.props.t('diagnApp.regionComponent.north'), data: Region.NORTH },
        { label: this.props.t('diagnApp.regionComponent.northeast'), data: Region.NORTHEAST },
        { label: this.props.t('diagnApp.regionComponent.midwest'), data: Region.MIDWEST },
        { label: this.props.t('diagnApp.regionComponent.southeast'), data: Region.SOUTHEAST },
        { label: this.props.t('diagnApp.regionComponent.south'), data: Region.SOUTH }
    ];
    private outsideBrazilOptions: RadioOption[] = [
        { label: this.props.t('diagnApp.regionComponent.africa'), data: Region.AFRICA },
        { label: this.props.t('diagnApp.regionComponent.europe'), data: Region.EUROPE },
        { label: this.props.t('diagnApp.regionComponent.asia'), data: Region.ASIA },
        { label: this.props.t('diagnApp.regionComponent.latinAmerica'), data: Region.LATIN_AMERICA },
        { label: this.props.t('diagnApp.regionComponent.centralAmerica'), data: Region.CENTRAL_AMERICA },
        { label: this.props.t('diagnApp.regionComponent.northAmerica'), data: Region.NORTH_AMERICA },
        { label: this.props.t('diagnApp.regionComponent.oceania'), data: Region.OCEANIA }
    ];

    constructor(props: Readonly<RegionComponentProps>, context?: any) {
        super(props, context);

        this.state = {
            region: props.region,
            regionType: props.regionType || RegionType.COUNTRY
        };
    }

    // Handlers
    private onChangeRegionType = (): void => {
        this.setState({
            regionType: this.state.regionType === RegionType.COUNTRY ? RegionType.CONTINENTS : RegionType.COUNTRY,
            region: undefined
        });
    };
    private onChangeRegion = (region: Region) => {
        this.setState({ region }, () => {
            this.props.onChangeInput(this.state.regionType, this.state.region!);
        });
    };
    private getRegionIndex = (region?: Region): number | undefined => {
        if (region != null) {
            if (this.state.regionType === RegionType.COUNTRY) {
                return _.findIndex(this.insideBrazilOptions, ['data', region]);
            } else {
                return _.findIndex(this.outsideBrazilOptions, ['data', region]);
            }
        }
        return undefined;
    };

    // Renders
    render() {
        const { regionType, region } = this.state;
        const regionOptions = regionType === RegionType.COUNTRY ? this.insideBrazilOptions : this.outsideBrazilOptions;
        return (
            <div className="region-component">
                <p className="region-component__title">{this.props.t('diagnApp.regionComponent.title').toUpperCase()}</p>
                <SwitchButton
                    onPress={this.onChangeRegionType}
                    optionOneText={this.props.t('diagnApp.regionComponent.insideBrazil')}
                    optionOneIcon={require('../../../images/brasil.svg')}
                    optionTwoText={this.props.t('diagnApp.regionComponent.outsideBrazil')}
                    optionTwoIcon={require('../../../images/mundo.svg')}
                    optionSelected={regionType === RegionType.COUNTRY ? 0 : 1}
                    iconStyle={{ width: 24, height: 24, marginRight: 6 }}
                />
                <div className="region-component__radio">
                    <DiagnAppRadio
                        options={regionOptions}
                        onPress={this.onChangeRegion}
                        centeredOption
                        useRow
                        initialIndex={this.getRegionIndex(region)}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({}: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(RegionComponent) as React.ComponentType<any>;
