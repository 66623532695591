import styled from 'styled-components';

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const HeaderItem = styled.div<{ isActive: boolean }>`
    width: 110px;
    display: flex;
    cursor: pointer;
    padding-bottom: 5px;
    margin-right: 16px;
    justify-content: center;
    border-bottom: ${props => props.isActive ? '3px solid #EF7D00' : '0px solid'};
`;

export const HeaderItemTitle = styled.span<{ isActive: boolean }>`
    font-size: 16px;
    color: #414E52;
    line-height: 19px;
    font-style: normal;
    font-family: 'Raleway';
    font-weight: ${props => props.isActive ? 600 : 500};
`;

export const TabsContainer = styled.div`
    display: flex;
    height: 79%;
    margin-top: 20px;
    overflow-y: auto;
    flex-direction: column;
`;