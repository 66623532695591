import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { MedicalCriteriaParam } from '../../../model/medical-criteria/medical-criteria-param';
import { MedicalCriteriaParamGroup } from '../../../model/medical-criteria/medical-criteria-param-group';
import { useMedicalCriteriaContext } from '../medical-criteria.provider';
import '../medical-criteria.scss';
import {
  ParamContainer,
  ParamGroupTitle,
  ParamTitle,
} from './medical-criteria-params-styles';
import MedicalCriteriaPickerParam from './medical-criteria-picker-param';
import MedicalCriteriaToggleParam from './medical-criteria-toggle-param';
import MedicalCriteriaCheckboxParam from './medical-criteria-checkbox-param';
import MedicalCriteriaInputParam from './medical-criteria-input-param';
import MedicalCriteriaParamType from '../../../model/enums/medical-criteria-param-type';

interface Props extends RouteComponentProps<{}, {}, {}> {
  t: any;
}

const MedicalCriteriaParams = (props: Props) => {
  const { formulaParams, formulaParamGroups } = useMedicalCriteriaContext()
    .medicalCriteria ?? { formulaParams: undefined };
  const [params, setParams] = useState<
    Array<MedicalCriteriaParam | MedicalCriteriaParamGroup>
  >([]);

  useEffect(() => {
    if (formulaParams != null || formulaParamGroups != null) {
      let newParams = [
        ...formulaParams!.filter((it) => it.medicalCriteriaParamGroup == null),
        ...formulaParamGroups!,
      ];
      newParams = _.sortBy(newParams, 'position');
      setParams(newParams);
    }
  }, [formulaParams, formulaParamGroups]);

  const renderParamContent = (param: MedicalCriteriaParam) => {
    switch (param.inputType) {
      case MedicalCriteriaParamType.INPUT:
        return <MedicalCriteriaInputParam parameterInfo={param} />;
      case MedicalCriteriaParamType.MULTI_SELECT_TOGGLE_FIRST:
        return <MedicalCriteriaToggleParam parameterInfo={param} />;
      case MedicalCriteriaParamType.MULTI_SELECT:
        return (
          <MedicalCriteriaPickerParam isMulti={false} parameterInfo={param} />
        );
      case MedicalCriteriaParamType.CHECKBOX:
        // eslint-disable-next-line no-case-declarations
        const paramsWithPointsGreaterOne = params.filter((it) => {
          if ('formulaParams' in it) {
            return it.formulaParams?.some(
              (fp) => fp.options?.some((opt) => (opt.points ?? 0) > 1)
            );
          } else {
            return (it as MedicalCriteriaParam).options?.some(
              (opt) => (opt.points ?? 0) > 1
            );
          }
        });
        // eslint-disable-next-line no-case-declarations
        const showPoints = paramsWithPointsGreaterOne.length > 0;
        return (
          <MedicalCriteriaCheckboxParam
            parameterInfo={param}
            showPoints={showPoints}
          />
        );
      case MedicalCriteriaParamType.MULTI_ANSWER_SELECT:
        return <MedicalCriteriaPickerParam isMulti parameterInfo={param} />;
      default:
        return <div />;
    }
  };

  const renderParam = (param: MedicalCriteriaParam) => {
    return (
      <ParamContainer>
        <ParamTitle>{param.presentationName}</ParamTitle>
        {renderParamContent(param)}
      </ParamContainer>
    );
  };

  const renderParamGroup = (paramGroup: MedicalCriteriaParamGroup) => {
    return (
      <ParamContainer>
        <ParamGroupTitle>{paramGroup.title}</ParamGroupTitle>
        <div style={{ marginLeft: 10, width: '100%' }}>
          {_.sortBy(paramGroup.formulaParams, 'position').map((param) =>
            renderParam(param)
          )}
        </div>
      </ParamContainer>
    );
  };

  return (
    <div>
      {_.sortBy(params, 'position').map((param) => (
        <div key={param.id ?? '0'}>
          {'formulaParams' in param
            ? renderParamGroup(param)
            : renderParam(param as MedicalCriteriaParam)}
        </div>
      ))}
    </div>
  );
};

const mapDispatchToProps = {};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation()
)(MedicalCriteriaParams) as React.ComponentType<any>;
