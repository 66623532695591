import { Modal } from '@material-ui/core';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import '../../flashcard.scss';
import {
    BottomContainer,
    ButtonContainer,
    ButtonText, CancelText,
    MainContainer,
    Subtitle,
    Title
} from './flashcard-modal-reset.styles';

interface Props extends WithTranslation {
    title: string;
    subtitle: string;
    confirmLabel: string;
    show: boolean;
    onClose: () => void;
    onPressConfirm: () => void;
    hideCancelButton?: boolean;
}

const FlashcardModalReset = (props: Props) => {
    const { show, onClose, title, subtitle, onPressConfirm, hideCancelButton } = props;

    return (
        <Modal
            open={show}
            onClose={() => onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <MainContainer>
                <Title>
                    {title}
                </Title>
                <Subtitle>
                    {subtitle}
                </Subtitle>
                <BottomContainer>
                    <ButtonContainer onClick={onPressConfirm}>
                        <ButtonText>
                            {props.confirmLabel}
                        </ButtonText>
                    </ButtonContainer>
                    {!hideCancelButton && <CancelText onClick={() => onClose()}>
                        {props.t('flashcardExtensive.resetModalCancel')}
                    </CancelText>}
                </BottomContainer>
            </MainContainer>
        </Modal>
    );
};

export default withTranslation()(FlashcardModalReset);
