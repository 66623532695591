import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import Button from '../../../components/button/button';
import { getMobileOperatingSystem, OperatingSystem } from '../../../shared/util/utils';

export interface Props {
    t: (path: string, params?: any) => string;
    history: any;
}

export const FinishStep = (props: Props): JSX.Element => {

    useEffect(() => {
      window.fbq('track', 'CompleteRegistration');
    }, [])
    
    const openApp = (): void => {
        if (getMobileOperatingSystem() === OperatingSystem.ANDROID) {
            window.location.replace('com.wemeds://');
        } else if (getMobileOperatingSystem() === OperatingSystem.IOS) {
            window.location.replace('wemeds://');
        }
    };
    const downloadApp = (): void => {
        if (getMobileOperatingSystem() === OperatingSystem.ANDROID) {
            window.location.replace('market://details?id=com.wemeds');
        } else if (getMobileOperatingSystem() === OperatingSystem.IOS) {
            window.location.replace('https://apps.apple.com/us/app/wemeds-medicina/id1446915058?l=pt&ls=1');
        }
    };
    const onGoToLogin = (): void => {
        props.history.push('/');
    }
    return (
        <div className="finish-step">
            <Row className="justity-content-center">
                <div className="success-back">
                    <div className="success-img" />
                </div>
            </Row>
            <Row style={{ marginTop: '-20px' }} className="justity-content-center">
                <b>
                    <p className={'main-text'} style={{ fontSize: '26px' }}>
                        {props.t('register.text3')}
                    </p>
                </b>
            </Row>
            <MediaQuery minDeviceWidth={1224}>
                <div className="button-container">
                    <Row className="justify-content-center">
                        <Button
                            label={props.t('global.button.goLogin')}
                            onPress={onGoToLogin}
                            style={{ width: 170 }}
                            rightIcon={<div className="advance-icon" />}
                        />
                    </Row>
                </div>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={1224}>
                <div className="button-container">
                    <Col className="centered-column">
                        <Button label={props.t('global.button.openApp')} onPress={openApp} style={{ width: 170, marginBottom: 22 }} />
                        <Button label={props.t('global.button.downloadApp')} onPress={downloadApp} style={{ width: 170 }} />
                    </Col>
                </div>
            </MediaQuery>
        </div>
    );
};

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(FinishStep) as React.ComponentType<
    Omit<Props, 't'>
>;
