import { AxiosResponse } from 'axios';
import FlashcardRunMode from '../model/enums/flashcard-run-mode';
import { FlashcardView } from '../model/flashcard/flashcard';
import { FlashcardRun } from '../model/flashcard/flashcard-run';
import { api } from './api';

const FlashcardRunApi = () => {
    const resetFlashcardRunByMode = (mode: FlashcardRunMode): Promise<AxiosResponse<void>> => {
        return api.delete<void>(`/flashcard-runs/mode?mode=${mode}`);
    };

    const getFlashcardIdsByRepetitionAlgorithm = (quantity: number): Promise<AxiosResponse<number[]>> => {
        return api.get<number[]>(`/flashcard-runs/repetition-algorithm?quantity=${quantity}`);
    };

    const getFlashcardIdsForExtensiveCheckpoint = (ids: number[]): Promise<AxiosResponse<number[]>> => {
        return api.get<number[]>(`/flashcard-runs/checkpoint?specialityIds=${ids.join(',')}`);
    };

    const getFlashcardRunByMode = (mode: FlashcardRunMode): Promise<AxiosResponse<FlashcardRun>> => {
        return api.get<FlashcardRun>(`/flashcard-runs/mode/me?mode=${mode}`);
    };

    const create = (flashcardRun: FlashcardRun): Promise<AxiosResponse<void>> => {
        return api.put<void>('/flashcard-runs', flashcardRun);
    };

    const update = (flashcardRun: FlashcardRun): Promise<AxiosResponse<void>> => {
        return api.put<void>('/flashcard-runs', flashcardRun);
    };

    return {
        getFlashcardIdsForExtensiveCheckpoint,
        getFlashcardIdsByRepetitionAlgorithm,
        resetFlashcardRunByMode,
        getFlashcardRunByMode,
        create,
        update
    };
};

export default FlashcardRunApi();
