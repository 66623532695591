// @ts-nocheck

import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    makeStyles
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../components/loading/loading';
import { CompanyDashboardData } from '../../model/company-dashboard-data';
import { Customer } from '../../model/customer';
import { Pageable } from '../../model/pageable';
import { IRootState } from '../../reducer';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import CompanyUserService from '../../services/company-user.service';
import './company-dashboard.scss';

interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
    hasSubscription?: boolean;
    openPaidModal: () => void;
}

const useStyles = makeStyles({
    table: {
        minWidth: 800
    }
});

const CompanyDashboard = (props: Props) => {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [dashboardData, setDashboardData] = useState<CompanyDashboardData>({ companySubscriptionAmount: 0 });
    const [pageable, setPageable] = useState<Pageable>({ number: 0, size: 20, totalElements: 0 });
    const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(true);
    const classes = useStyles();

    const getUsers = (page: Pageable) => {
        setIsLoadingScreen(true);
        CompanyUserService.getCompanyUsers(searchText, page)
            .then(pageCustomers => {
                setCustomers(pageCustomers.content);
                setPageable({ number: pageCustomers.number, size: pageCustomers.size, totalElements: pageCustomers.totalElements });
            })
            .finally(() => setIsLoadingScreen(false));
    };

    useEffect(() => {
        CompanyUserService.getDashboardData()
            .then(setDashboardData)
            .finally(() => getUsers(pageable));
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getUsers({ number: 0, size: 20 });
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    const onChangePage = (newPage: number) => {
        getUsers({ ...pageable, number: newPage });
    };

    const onPressChangeCustomer = (customer?: Customer) => {
        props.history.push({
            pathname: `/company-create-login`,
            state: { customer }
        });
    };

    const deleteUser = (customer?: Customer) => {
        setIsLoadingScreen(true);
        CompanyUserService.deleteCustomer(customer).then(() => {
            toast.success(props.t('companyLogin.deleteMessage'));
            getUsers(pageable);
        });
    };

    return isLoadingScreen ? (
        <div className="disease-screen-container">
            <Loading />
        </div>
    ) : (
        <div className={'dose-main-container'}>
            <div className={'screen-company-content-container'} style={{ display: 'block' }}>
                <label style={{ marginBottom: 10 }}>{`Usuários Liberados: ${dashboardData.companySubscriptionAmount}/600`}</label>
                <div style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
                    <TextField
                        variant="outlined"
                        label="Pequisar"
                        placeholder="Digite o nome do aluno..."
                        size="small"
                        value={searchText}
                        onChange={event => setSearchText(event.target.value)}
                    />
                    <Button variant="outlined" color="primary" style={{ marginLeft: 10 }} onClick={() => onPressChangeCustomer()}>
                        Criar Login
                    </Button>
                </div>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell align="right">Liberado</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customers.map(customer => (
                                <TableRow key={customer.name}>
                                    <TableCell>{customer.name}</TableCell>
                                    <TableCell>{customer.email}</TableCell>
                                    <TableCell align="right">{customer.hasSubscription ? 'Sim' : 'Não'}</TableCell>
                                    <TableCell align="right">
                                        <Button variant="outlined" color="primary" onClick={() => onPressChangeCustomer(customer)}>
                                            Editar
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            style={{ marginLeft: 10 }}
                                            onClick={() => deleteUser(customer)}
                                        >
                                            Excluir
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    component="div"
                                    labelRowsPerPage={''}
                                    count={pageable.totalElements}
                                    rowsPerPage={pageable.size}
                                    page={pageable.number}
                                    onChangePage={(event, page) => onChangePage(page)}
                                    labelDisplayedRows={({ from, to, count }) =>
                                        `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
                                    }
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account?.customer?.hasSubscription === true
});

const mapDispatchToProps = {
    openPaidModal: openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(CompanyDashboard) as React.ComponentType<any>;
