import React, { useEffect, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FontSizeSlider } from '../flashcard-carousel/flashcard-carousel';
import { useFlashcardViewContext } from '../flashcard-carousel/flashcard.provider';
import {
    ArrowBack,
    EyeIcon,
    HeaderContainer,
    HeaderRightContainer,
    HeaderSubtitle,
    HeaderText
} from '../flashcard-styles';
import '../flashcard.scss';

interface Props {
    showIcon: boolean;
}

const FlashcardFontResize = (props: Props) => {
    const { fontSize, cards, setFontSize, setCards } = useFlashcardViewContext();
    const { showIcon } = props;
    const [showFontSlider, setShowFontSlider] = useState<boolean>(false);
    const sliderRef: any = useRef(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showFontSlider]);

    const handleClickOutside = (event: any): void => {
        if (sliderRef?.current != null && !sliderRef.current.contains(event.target) && showFontSlider) {
            setShowFontSlider(false);
        }
    };

    return (
        <>
            {showIcon && <EyeIcon onClick={() => setShowFontSlider(!showFontSlider)}
                                  className={showFontSlider ? 'font-resize-img font-resize-img--orange' : 'font-resize-img'} />}
            {showFontSlider && <div className="disease-content__slider-container" ref={sliderRef}>
                <FontSizeSlider
                    className="disease-content__slider"
                    value={fontSize}
                    max={17}
                    min={13}
                    step={2}
                    marks={[{ value: 15, label: '' }]}
                    onChange={(e, v) => setFontSize(Number(v))}
                />
            </div>}
        </>
    );
};

export default FlashcardFontResize;
