import { action } from 'typesafe-actions';
import { Authentication } from '../../model/authentication';
import { User } from './../../model/user';
import { AuthenticationActionTypes } from './types';

export const loginRequest = (authenticate: Authentication) => action(AuthenticationActionTypes.LOGIN_REQUEST, authenticate);
export const loginSuccess = () => action(AuthenticationActionTypes.LOGIN_SUCCESS);
export const loginError = (message: string) => action(AuthenticationActionTypes.LOGIN_ERROR, message);

export const getSessionRequest = () => action(AuthenticationActionTypes.GET_SESSION_REQUEST);
export const getSessionSuccess = (user: User) => action(AuthenticationActionTypes.GET_SESSION_SUCCESS, user);
export const getSessionError = (message: string) => action(AuthenticationActionTypes.GET_SESSION_ERROR, message);

export const logoutRequest = () => action(AuthenticationActionTypes.LOGOUT_REQUEST);
