import { AxiosResponse } from 'axios';
import { api } from './api';

export const forgotPasswordApi = () => {
    const sendEmail = (sendTo: string): Promise<AxiosResponse<string>> => {
        return api.post<string>('/mobile/account/reset-password/init', { email: sendTo });
    };

    return {
        sendEmail
    };
};

export default forgotPasswordApi();
