import _ from 'lodash';
import React from 'react';
import { MedicalCalculatorParam } from '../../../model/medical-calculator/medical-calculator-param';
import { useMedicalCalculatorContext } from '../medical-calculator.provider';
import { ParamPicker, ParamPickerContainer } from './medical-calculator-params-styles';

interface Props {
    parameterInfo: MedicalCalculatorParam;
}

const MedicalCalculatorPickerParam = (props: Props) => {
    const { parameterInfo } = props;
    const options = _.sortBy(parameterInfo?.options, 'order')
        .map(it => { return { label: it.presentationName, value: it.code } });
    const { paramValues, setParamValues } = useMedicalCalculatorContext();

    const handleSelectOption = (value: string) => {
        const newValues = { ...paramValues };
        newValues[parameterInfo.code!] = value;
        setParamValues(newValues);
    }

    return (
        // TODO: Ver esse caso retirando o any
        <ParamPickerContainer onChange={(e: any) => handleSelectOption(e.target.value)}>
            <ParamPicker value={options.find(it => it.value === paramValues[parameterInfo.code!])?.value!}>
                {options.map(it => <option key={it.value!} value={it.value!}>{it.label}</option>)}
            </ParamPicker>
        </ParamPickerContainer>
    );
};

export default MedicalCalculatorPickerParam;
