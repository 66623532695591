import { AxiosResponse } from 'axios';
import { api } from './api';

export const couponApi = () => {
    const checkCoupon = (couponCode: string): Promise<AxiosResponse<number>> => {
        return api.get<number>(`/coupons/check`, { params: { couponCode } });
    };

    return {
        checkCoupon
    };
};

export default couponApi();
