enum MedicalScoreParamType {
    CHECKBOX = 'CHECKBOX',
    MULTI_SELECT_TOGGLE_FIRST = 'MULTI_SELECT_TOGGLE_FIRST',
    INPUT_INTEGER = 'INPUT_INTEGER',
    MULTI_ANSWER_SELECT = 'MULTI_ANSWER_SELECT',
    MULTI_SELECT = 'MULTI_SELECT',
    INPUT = 'INPUT'
}

export default MedicalScoreParamType;
