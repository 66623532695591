import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import '../../App.scss';
import FontResizer from '../../components/font-resizer/font-resizer';
import { Favorite, FavoriteType } from '../../model/favorite';
import { MedicineLeaflet } from '../../model/medicine-leaflet';
import { IRootState } from '../../reducer';
import FavoritesService from '../../services/favoritesService';
import LeafletSearch from './smart-leaflet-search';

export interface SmartLeafletTabProps extends RouteComponentProps<{}> {
    t: any;
    leaflet: MedicineLeaflet;
    markdown: JSX.Element;

    onChangeFontSize: (fontSize: number) => void;
    onShowAnnotation: () => void;

    title: string;
    onBack: () => void;

    annotationIsOpen?: boolean;
}

export interface SmartLeafletTabState {
    showFontSlider?: boolean;
    fontSize: number;

    isFavorited?: boolean;
    showFavoriteSuccessToast?: boolean;

    showMedicinePrice?: boolean;
}

export class SmartLeafletTab extends React.Component<SmartLeafletTabProps, SmartLeafletTabState> {
    private sliderRef;
    constructor(props: Readonly<SmartLeafletTabProps>, context?: any) {
        super(props, context);

        this.state = {
            fontSize: 13,
            isFavorited: props.leaflet.favorite && props.leaflet.favorite.isActive,
        };
    }
    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    private handleClickOutside = (event: any): void => {
        if (this.sliderRef && !this.sliderRef.contains(event.target) && this.state.showFontSlider) {
            this.setState({
                showFontSlider: false
            });
        }
    };
    private onChangeSlider = (value: number): void => {
        this.setState({ fontSize: value});
        this.props.onChangeFontSize(value);
    };
    private onResizeFont = () => {
        this.setState({
            showFontSlider: !this.state.showFontSlider
        });
    };
    private onFavorite = async (): Promise<void> => {
        const { isFavorited } = this.state;
        const { leaflet } = this.props;
        const params: Favorite = {
            type: FavoriteType.MEDICINE_LEAFLET,
            medicineLeaflet: {
                id: leaflet.id
            },
            isActive: isFavorited ? false : undefined
        };
        FavoritesService.favorite(params).then(() => {
            this.setState(
                {
                    isFavorited: !isFavorited,
                    showFavoriteSuccessToast: !isFavorited
                },
                () => {
                    setTimeout(() => this.setState({ showFavoriteSuccessToast: false }), 3000);
                }
            );
        });
    };
    private onAnnotation = () => {
        this.props.onShowAnnotation();
    };
    private onMedicinePrice = () => {
        // to do
    };

    render(): JSX.Element {
        const { markdown, title, onBack } = this.props;
        const { showFontSlider, showFavoriteSuccessToast, showMedicinePrice, isFavorited } = this.state;

        const contentRender: JSX.Element = markdown;
        return (
            <div className="tab">
                <div className="tab__menu-containter">
                    <div className="tab__menu">
                        <div className="tab__menu__left">
                            <div className="tab__menu__left__title">
                                <div className="screen__title">
                                    <div className="screen__title__back" onClick={onBack}>
                                        <div className="screen__title__back__img" />
                                    </div>
                                    <span className="screen__title__text">{title}</span>
                                </div>
                            </div>
                        </div>
                        <LeafletSearch
                            fontSliderIsOpen={this.state.showFontSlider}
                            annotationIsOpen={this.props.annotationIsOpen}
                            onMedicinePrice={this.onMedicinePrice}
                            onResizeFont={this.onResizeFont}
                            onFavorite={this.onFavorite}
                            onAnnotation={this.onAnnotation}
                            isFavorited={isFavorited}
                        />
                        {showFontSlider && (
                            <div className="disease-content__slider-container" ref={ref => (this.sliderRef = ref)}>
                                <FontResizer
                                    changeFontSize={this.onChangeSlider}
                                    fontSize={this.state.fontSize}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="tab__content">{contentRender}</div>

                {showFavoriteSuccessToast && (
                    <div className="disease-content__favorite-success-toast">
                        <div className="disease-content__white-star-img" />
                        <span className="disease-content__favorite-success-msg">{this.props.t('smartLeaflet.favoriteSuccessMessage')}</span>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SmartLeafletTab) as React.ComponentType<any>;
