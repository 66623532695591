import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../../components/loading/loading';
import FlashcardRunMode from '../../../model/enums/flashcard-run-mode';
import FlashcardRunService from '../../../services/flashcard-run.service';
import FlashcardHeader from '../components/flashcard-header';
import FlashcardRunInfoCard from '../components/flashcard-run-info-card/flashcard-run-info-card';
import { Container, MainContainer } from '../flashcard-styles';
import '../flashcard.scss';

interface Props extends RouteComponentProps<{}, {}, {}>, WithTranslation {
    hasSubscription?: boolean;
    openPaidModal: () => void;
}

const FlashcardExtensiveCreate = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        FlashcardRunService.getFlashcardRunByMode(FlashcardRunMode.EXTENSIVE).then(it => {
            if (it != null) {
                props.history.push('/flashcard-extensive-dashboard');
            }
        }).finally(() => setIsLoading(false));
    }, []);

    const onPressCreate = () => {
        setIsLoading(true);
        FlashcardRunService.createExtensive().then(() => props.history.push('/flashcard-extensive-dashboard'));
    };

    return isLoading ? (
        <div className="disease-screen-container">
            <Loading />
        </div>
    ) : (
        <MainContainer>
            <Container>
                <FlashcardHeader
                    {...props}
                    title={props.t('flashcardExtensive.subtitle')}
                    subtitle={props.t('flashcardExtensive.title')}
                    viewMode={'CARD'}
                    onPressBack={() => props.history.push('/flashcard-dashboard')}
                />
                <FlashcardRunInfoCard title={props.t('flashcardExtensiveCreate.welcomeTitle')}
                                      text={props.t('flashcardExtensiveCreate.welcomeSubtitle')}
                                      onPress={onPressCreate} />
            </Container>
        </MainContainer>
    );
};

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(FlashcardExtensiveCreate) as React.ComponentType<any>;
