import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import moment from 'moment';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import StepBar from '../../components/step-bar/step-bar';
import { IRootState } from '../../reducer';
import { openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import DragDropDoctors from './component/drag-drop-doctors';
import HourStep from './component/hour-step';
import ShareStep from './component/share-step';
import { DividerOnDutyStep, useDividerOnDutyContext } from './context/divider-on-duty-provider';
import './divider-on-duty.scss';
import { ArrowContainer, BoxPaginationContainer, CardContainer, HeaderContainer, PageTitle, StepContainer, Subtitle, Title } from './on-duty-styles';

export interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
    hasSubscription?: boolean;
    openPaidModal: () => void;
}

const DividerOnDuty = (props: Props): JSX.Element => {
    const { currentStep, onChangeStep, startTime, endTime, doctors, setDoctors } = useDividerOnDutyContext();
    const [downloadScale, setDownloadScale] = useState<boolean>(false);
    const pages = [
        { index: DividerOnDutyStep.NAME_STEP, name: 'nameStep', isBoxView: false },
        { index: DividerOnDutyStep.HOURS_STEP, name: 'hourStep', isBoxView: false },
        { index: DividerOnDutyStep.SHARE_STEP, name: 'shareStep', isBoxView: true },
    ];

    const onChangeIndex = (newIndex: number) => {
        onChangeStep(newIndex as DividerOnDutyStep);
    }

    const onPressDownload = () => {
        setDownloadScale(true);
    }


    const renderByStep = (step: DividerOnDutyStep) => {
        // tslint:disable-next-line: switch-default
        switch (step) {
            case DividerOnDutyStep.HOURS_STEP:
                return <HourStep {...props} />;
            case DividerOnDutyStep.NAME_STEP:
                return <DragDropDoctors {...props} items={doctors} onItemsChange={setDoctors} />;
            case DividerOnDutyStep.SHARE_STEP:
                return <ShareStep {...props} downloadScale={downloadScale} setDownloadScale={setDownloadScale} />;
        }
    }

    const timePerDoctor = doctors.length > 0 ? Math.round(moment.duration(endTime.diff(startTime)).asMinutes() / doctors.length) : 0;
    const isFirstPage = currentStep === DividerOnDutyStep.NAME_STEP;
    const isLastPage = currentStep === DividerOnDutyStep.SHARE_STEP;

    return (
        <div className="specialty-screen-container" style={{ display: 'flex', justifyItems: 'center' }}>
            <div style={{ marginTop: '20px' }}>
                <Title>{props.t([`onDuty.title`])}</Title>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowContainer position={'left'} disabled={isFirstPage} onClick={() => !isFirstPage ? onChangeIndex(currentStep - 1) : () => null} isDownload={false}>
                        <div className={`on-duty-card-icon-left${isFirstPage ? '--disabled' : ''}`} />
                    </ArrowContainer>
                    <CardContainer>
                        <StepContainer>
                            <StepBar maxSteps={3} currentStep={currentStep + 1} />
                        </StepContainer>
                        <HeaderContainer>
                            <PageTitle>{props.t([`onDuty.${pages[currentStep].name}.title`])}</PageTitle>
                            {currentStep !== DividerOnDutyStep.SHARE_STEP ?
                                (<Subtitle>{props.t([`onDuty.${pages[currentStep].name}.subtitle`])}</Subtitle>)
                                :
                                (<BoxPaginationContainer>
                                    <Subtitle style={{ fontWeight: 500, fontSize: 16, marginRight: '5px' }}>{moment().startOf('day').add(timePerDoctor, 'minutes').format('HH[h ]mm[min ]')}</Subtitle>
                                    <Subtitle style={{ fontWeight: 400, fontSize: 14 }}>{props.t([`onDuty.${pages[currentStep].name}.subtitle`])}</Subtitle>
                                </BoxPaginationContainer>)
                            }
                        </HeaderContainer>
                        {renderByStep(currentStep)}
                    </CardContainer>
                    <ArrowContainer position={'right'} disabled={false} isDownload={isLastPage} onClick={() => !isLastPage ? onChangeIndex(currentStep + 1) : onPressDownload()}>
                        <div className={isLastPage ? 'on-duty-card-download-right' : 'on-duty-card-icon-right'} />
                    </ArrowContainer>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    hasSubscription: authentication.account && authentication.account.customer ? authentication.account.customer.hasSubscription : undefined
});
const mapDispatchToProps = {
    openPaidModal: openPaidDiseaseModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(DividerOnDuty) as React.ComponentType<any>;

