import styled from 'styled-components';

export const ListContainer = styled.div`
    height: 100%;
    width: 100%;
    padding-left: 23%;
    padding-right: 23%;
    @media (max-width: 1080px) {
        padding-left: 20%;
        padding-right: 20%;
    }
    overflow-y: auto;
`;

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LineContainer = styled.div`
    min-height: 48px;
    width: 100%;
    display: flex;
    cursor: pointer;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
`;

export const CheckBoxContainer = styled.div`
    display: flex;
    align-items: center;
    width: 90%;
`;

export const CheckBox = styled.div`
    height: 28px;
    width: 28px;
    cursor: pointer;
`;

export const CheckBoxLabel = styled.span`
    color: #414E52;
    margin-left: 12px;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway;
    width: 100%;
    cursor: pointer;
    font-size: 1.1vw;
    @media (max-width: 1080px) {
        font-size: 1.4vw;
    }
    font-weight: 400;
`;

export const CountLabel = styled.span`
    color: #A3B1B5;
    text-align: center;
    font-family: Raleway;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
`;

export const Title = styled.span`
    color: #6D7C81;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    /* Heading/XLarge | SBold */
    font-family: Raleway;
    font-size: 22px;
    margin-bottom: 15px;
    font-style: normal;
    font-weight: 500;
`;

export const ButtonLabel = styled.span`
    color: #FFFFFF;
    text-align: center;
    font-family: Raleway;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
`;

export const AccordionArrow = styled.div`
    height: 24px;
    width: 24px;
    cursor: pointer;
`;

export const ButtonContainer = styled.div`
    background-color: #3D8789;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    margin-bottom: 1vh;
    cursor: pointer;
    display: flex;
    height: 7vh;
    width: 20vw;
`;


