import styled from 'styled-components';
import { getBackgroundColorToResult, getBackgroundColorToResultBorder, getBackgroundColorToType, ResultType } from '../../model/medical-calculator/result-type';

export const MainContainer = styled.div`
    width: 100%;
    margin-top: 40px;
    margin-bottom: 100px;
`;

export const CalculatorResultContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    flex-direction: column;
`;

export const Title = styled.span`
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 10px;
    color: #1A3C47;
`;

export const ResultText = styled.span`
    font-family: 'Raleway';
    font-style: normal;
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 16px;
    color: #181F22;
    white-space: pre-line;
`;

export const ResultTypeText = styled.span`
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
`;

export const ResultContainer = styled.div<{ type: ResultType }>`
    display: flex;
    flex-direction: column;
    width: 85%;
    border: 2px solid;
    padding-top: 25px;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    background-color: ${props => getBackgroundColorToResult(props.type)};
    border-color: ${props => getBackgroundColorToResultBorder(props.type)};
`;

export const ResultTypeContainer = styled.div<{ type: ResultType }>`
    height: 47px;
    width: 101%;
    border: 1px solid;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: transparent;
    background-color: ${props => getBackgroundColorToType(props.type)};
`;
