import styled from 'styled-components';


export const MainContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

export const WeekDayContainer = styled.div<{isSelected: boolean}>`
    display: flex;
    width: 48px;
    height: 48px;
    border-radius: 32px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${(props: {isSelected: boolean}) => (props.isSelected ? '#C25576' : '#F7F8F8')};
`;

export const WeekDayText = styled.span<{isSelected: boolean}>`
    text-align: center;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: ${props => props.isSelected ? '#FFFFFF' : '#1A3C47'};
`;

export const InputContainer = styled.div`
    display: flex;
    width: 24.5vw;
    margin-top: 2.5vh;
    justify-content: space-between;
`;
