import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../components/loading/loading';
import { RecentHistory } from '../../model/recentHistory';
import { SearchType } from '../../model/search';
import { convertContentRatingToSearchResult, SearchResult } from '../../model/searchResult';
import { User } from '../../model/user';
import { IRootState } from '../../reducer';
import { openFeedbackModal, openPaidDiseaseModal } from '../../reducer/globalModal/actions';
import { SearchState } from '../../reducer/search/types';
import EditorContentService from '../../services/editorContentService';
import { ResultItem } from '../menu/menu-components';
import './editor-content.scss';

export interface Props extends RouteComponentProps<{}> {
    t: any;
    history: any;
    searchState: SearchState;
    recents: RecentHistory[];
    account: User;
    openPaidDiseaseModal: () => void;
    openFeedbackModal: () => void;
}

const EditorContent = (props: Props) => {
    const [hasLoaded, setHasLoaded] = useState<boolean | undefined>(true);
    const [editorContents, setEditorContents] = useState<SearchResult[]>([]);

    useEffect(() => {
        EditorContentService.getEditorContents()
            .then(result => setEditorContents(result.map(convertContentRatingToSearchResult)))
            .finally(() => setHasLoaded(false));
    }, []);

    const onClickItem = (item: SearchResult): void => {
        if (item.isPaid && !(props.account.customer && props.account.customer.hasSubscription)) {
            props.openPaidDiseaseModal();
            return;
        }
        switch (item.type) {
            case SearchType.SPECIALTY:
                props.history.push(`/disease/${item.id}`);
                break;
            case SearchType.SMART_BULAS:
                props.history.push(`/smart-bulas/${item.id}`);
                break;
            case SearchType.ILLUSTRATED_PROCEDURE:
                props.history.push(`/procedure/${item.id}`);
                break;
            case SearchType.MEDICAL_CALCULATOR:
                props.history.push(`/calculator/${item.id}`);
                break;
            case SearchType.MEDICAL_SCORE:
                props.history.push(`/scores/${item.id}`);
                break;
            case SearchType.MEDICAL_CRITERIA:
                props.history.push(`/criteria/${item.id}`);
                break;
            case SearchType.CID:
                props.history.push(`/cid/${item.id}`);
                break;
            case SearchType.SEMIOLOGY:
                props.history.push(`/semiology/${item.id}`);
                break;
            case SearchType.PEDIATRIC_DOSE:
                props.history.push(`/pediatric-dose/${item.id}`);
                break;
            case SearchType.LABORATORY:
                props.history.push(`/laboratory/${item.id}`);
                break;
            case SearchType.SUS:
                props.history.push(`/sus/${item.id}`);
                break;
            default:
                props.history.push('/construction');
        }
    };

    return (
        <div className="screen-container">
            <div className="content-container" style={hasLoaded ? { alignItems: 'center' } : {}}>
                {editorContents.length > 0 && (
                    <div className="results">
                        <div className="content-header">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span className="content-title">{props.t('specialty.editorChoose')}</span>
                            </div>
                        </div>
                        {editorContents.map((it, index) => (
                            <ResultItem
                                key={index}
                                result={it}
                                index={index}
                                onClick={onClickItem}
                                hasSubscription={props.account.customer && props.account.customer.hasSubscription}
                            />
                        ))}
                    </div>
                )}
                {hasLoaded === undefined && <Loading style={{ marginTop: 200 }} />}
            </div>
        </div>
    );
};

const mapStateToProps = ({ search, authentication, recentHistory }: IRootState) => ({
    searchState: search,
    recents: recentHistory.ordenedRecents,
    account: authentication.account
});

const mapDispatchToProps = {
    openPaidDiseaseModal,
    openFeedbackModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(EditorContent) as React.ComponentType<any>;
