import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ToggleButton from '../../../components/toggle-button/toggle-button';
import PediatricDoseInputType from '../../../model/enums/pediatric-dose-input-type';
import { usePediatricDoseContext } from '../pediatric-dose.provider';
import './pediatric-dose-input.scss';

interface Props {
    t: any;
}

enum AgeOption {
    MONTHS = 0,
    YEARS = 1
}

const PediatricDoseInput = (props: Props) => {
    const { inputValue, inError, setInput, currentPediatricDose } = usePediatricDoseContext();
    const [value, setValue] = useState<string | null>(inputValue);
    const [ageOption, setAgeOption] = useState<AgeOption>(AgeOption.YEARS);
    const inputType = usePediatricDoseContext().currentPediatricDose?.inputType ?? PediatricDoseInputType.AGE;

    useEffect(() => {
        if (value !== null && value !== '') {
            const delayDebounceFn = setTimeout(() => {
                if (inputType === PediatricDoseInputType.AGE) {
                    setAgeValue(value);
                } else {
                    const isValid = Number(value) <= 100;
                    setInput(value, isValid);
                }
            }, 500);

            return () => clearTimeout(delayDebounceFn)
        }
    }, [value]);

    useEffect(() => {
        setValue(inputValue);
    }, [currentPediatricDose?.id]);

    const setAgeValue = (newValue: string) => {
        if (AgeOption.YEARS === ageOption) {
            if (Number(newValue) > 18) {
                alert('Idade máxima 18 anos!');
                setValue('18');
                setInput('18', true);
                return;
            }
            setInput(newValue, true);
        }

        if (AgeOption.MONTHS === ageOption) {
            if (Number(newValue) > 12) {
                const ageValue = Math.round(Number(newValue) / 12).toString();
                setAgeOption(AgeOption.YEARS);
                setInput(ageValue, true);
                setValue(ageValue);
                return;
            }
            setInput(`0.${_.padStart(newValue, 2, '0')}`, true);
        }

        return;
    }

    const onChangeInput = (newValue: string) => {
        setValue(newValue.replace(',', '.'));
    }

    const onChangeAgeOption = (option: number) => {
        const newOption = option === 0 ? AgeOption.MONTHS : AgeOption.YEARS;
        setValue('');
        setInput(null, true);
        setAgeOption(newOption);
    }

    return (
        <div className={'dose-input-main-container'}>
            <div className={'dose-input-left-container'}>
                <div>
                    {inError && <div className={'dose-input-icon-warning'} />}
                </div>
                <div>
                    <span className={'dose-input-type-text'}>{props.t(`enum.pediatricDoseInputType.${inputType.toString()}`)}</span>
                    <input className={`dose-input-input${inError ? '--inError' : ''}`} type={'text'} name={'icon-input'} value={value ? value.replace('.', ',') : ''} onChange={(e) => onChangeInput(e.target.value)} />
                    {inputType === PediatricDoseInputType.WEIGHT && <span className={'dose-input-type-text'} style={{ marginLeft: '10px' }}>{props.t('pediatricDose.kg')}</span>}
                </div>
                {inputType === PediatricDoseInputType.WEIGHT && <div />}
            </div>
            {inputType === PediatricDoseInputType.AGE && <div className={'dose-input-right-container'}>
                <ToggleButton
                    inError={inError}
                    optionSelected={ageOption}
                    onPress={onChangeAgeOption}
                    optionTwoText={props.t('enum.ageType.YEARS')}
                    optionOneText={props.t('enum.ageType.MONTHS')}
                />
            </div>}
        </div>
    );
}

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(PediatricDoseInput) as React.ComponentType<any>;
