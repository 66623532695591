import React from 'react';
import './premium-header.scss';

export interface Props {
    t: (path: string, params?: any) => void;
    history: any;
}
export const PremiumHeader = (props: Props) => {
    const onClick = (): void => {
        props.history.push('/subscription');
    }
    return (
        <div className="premium-header">
            <div className="premium-header__content">
                <div className="premium-header__price-icon"/>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className="premium-header__wemeds-img"/>
                    <div className="premium-header__premium-img"/>
                </div>
                <span style={{ display: 'flex' }}>
                    <span className="premium-header__text">{'em até 12x sem juros.'}</span>
                    <span className="premium-header__text-orange" onClick={onClick}>{'Assine já!'}</span>
                </span>
            </div>
        </div>
    )
}