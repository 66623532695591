import { AxiosResponse } from 'axios';
import { GroupsLeaflets } from '../model/apiResponses/GroupsLeaflets';
import { MedicineLeaflet } from '../model/medicine-leaflet';
import { MedicineGroup } from '../model/medicineGroup';
import { PageableResponse } from '../model/pageable';
import { api } from './api';

export const leafletsApi = () => {
    const searchGroups = (params: any): Promise<AxiosResponse<PageableResponse<MedicineGroup>>> => {
        const urlParams: string = Object.keys(params)
            .filter(it => params[it] != null)
            .map(key => `${key}=${params[key]}`)
            .join('&');
        return api.get<PageableResponse<MedicineGroup>>(`/medicine-groups/search?${urlParams}`);
    };
    const searchLeaflets = (params: any): Promise<AxiosResponse<any>> => {
        const urlParams: string = Object.keys(params)
            .filter(it => params[it] != null)
            .map(key => `${key}=${params[key]}`)
            .join('&');
        return api.get<any>(`/medicine-leaflets/search?${urlParams}`);
    };
    const getLeafletMarkdown = (leafletId: number): Promise<AxiosResponse<MedicineLeaflet>> => {
        return api.get<MedicineLeaflet>(`/medicine-leaflets/markdown/${leafletId}`);
    };
    const getLeafletsAndGroups = (parentId?: number): Promise<AxiosResponse<GroupsLeaflets>> => {
        return api.get<GroupsLeaflets>(`/medicine-leaflet-groups/${parentId ? parentId : ''}`);
    };

    return {
        searchGroups,
        searchLeaflets,
        getLeafletMarkdown,
        getLeafletsAndGroups
    };
};

export default leafletsApi();
