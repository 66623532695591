import styled from 'styled-components';

export const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const BarPart = styled.div<{height: number; borderPosition: 'BOTTOM' | 'TOP' | 'MIDDLE'; color: string}>`
    width: 100%;
    cursor: pointer;
    height: ${(props: { height: number }) => props.height}%;
    background-color: ${(props: { color: string }) => props.color};
    border-top-right-radius: ${(props: { borderPosition: string }) => (props.borderPosition === 'TOP' ? '20' : '0')}px;
    border-top-left-radius: ${(props: { borderPosition: string }) => (props.borderPosition === 'TOP' ? '20' : '0')}px;
    border-bottom-right-radius: ${(props: {
        borderPosition: string
    }) => (props.borderPosition === 'BOTTOM' ? '20' : '0')}px;
    border-bottom-left-radius: ${(props: {
        borderPosition: string
    }) => (props.borderPosition === 'BOTTOM' ? '20' : '0')}px;
`;

export const CountTipContainer = styled.div`
    display: flex;
    height: 28px;
    min-width: 72px;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    background-color: #1a3c47;
    position: absolute;
    top: 11vh;
`;

export const CountTipLabel = styled.span`
    color: #FFFFFF;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'liga' off;
    /* Small/Small | SBold */
    font-family: Raleway;
    font-size: 13px;
    font-weight: 600;
`;

export const BarContainer = styled.div<{height: number; color: string}>`
  height: ${(props: {height: number}) => props.height}vh;
  cursor: pointer;
  width: 1vw;
  background-color: ${(props: {color: string}) => props.color};
  border-radius: 20px;
`;

export const ChartLabel = styled.span`
    color: #1A3C47;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Small/XSmall | SBold */
    font-family: Raleway;
    margin-top: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 14.4px */
`;

export const BarsContainer = styled.div`
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
`;