import { AxiosResponse } from 'axios';
import diseaseApi from '../api/diseaseApi';
import flashcardStatisticApi from '../api/flashcard-statistic.api';
import { Disease, DiseaseName } from '../model/disease';
import HttpStatus from '../model/enums/httpStatus';
import { FlashcardStatistic } from '../model/flashcard/flashcard-statistic';
import { FreeDiseases } from '../model/freeDiseases';

const FlashcardStatisticService = () => {
    const save = async (statistic: FlashcardStatistic): Promise<void> => {
        try {
            const result: AxiosResponse<void> = await flashcardStatisticApi.save(statistic);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve();
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };

    const getByDiseaseId = async (id: number): Promise<FlashcardStatistic | null> => {
        try {
            const result: AxiosResponse<FlashcardStatistic | null> = await flashcardStatisticApi.getByDiseaseId(id);
            if (result.status === HttpStatus.OK) {
                return Promise.resolve(result.data);
            } else if (result.status === HttpStatus.UNAUTHORIZED) {
                return Promise.reject({ status: 401 } as AxiosResponse);
            } else {
                return Promise.reject({ status: 400 } as AxiosResponse);
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    };
    return {
        save,
        getByDiseaseId
    };
};

export default FlashcardStatisticService();
