import React from 'react';
import { BarContainer, ProgressBar } from './step-bar-styles';

interface Props {
    maxSteps: number;
    currentStep: number;
}

const StepBar = (props: Props) => {

    const barWidth = props.currentStep / props.maxSteps * 100;
    console.log({maxSteps: props.maxSteps, currentStep: props.currentStep})
    return (
        <BarContainer>
            <ProgressBar barWidth={barWidth} />
        </BarContainer>
    );
}

export default StepBar;
