import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../components/loading/loading';
import MultipleCardList, { CardItem, CardList } from '../../components/multiple-card-list/multiple-card-list';
import { CidAmount } from '../../model/cid';
import { CidCategory } from '../../model/cidCategory';
import { CidChapter } from '../../model/cidChapter';
import { CidGroup } from '../../model/cidGroup';
import { CidTree } from '../../model/cidTree';
import { IRootState } from '../../reducer';
import AnalyticsService from '../../services/analytics-service';
import CidService from '../../services/cidService';
import CidCardItem, { CardItemContent } from './cid-card-item';
import CidLastColumn from './cid-last-column';
import './cid.scss';

export interface CidScreenProps extends RouteComponentProps<{}> {
    t: any;
}

export interface CidScreenState {
    firstCardList?: CardList;
    allLists?: CardList[];
    lastColumnData?: any;
}

export class CidScreen extends React.Component<CidScreenProps, CidScreenState> {
    constructor(props: Readonly<CidScreenProps>, context?: any) {
        super(props, context);

        this.state = {};
    }
    // Component Life Cycle
    componentDidMount(): void {
        const {
            match: { params }
        } = this.props;
        const cidId: number = (params as any).cidId;
        if (cidId) {
            this.getCidTree(cidId);
        } else {
            this.getCidChapters();
        }
    }
    componentDidUpdate(prevProps: CidScreenProps, prevState: CidScreenState): void {
        if (prevProps.match !== this.props.match) {
            const {
                match: { params }
            } = this.props;
            const cidId: number = (params as any).cidId;
            if (cidId) {
                this.getCidTree(cidId);
            } else {
                this.getCidChapters();
            }
        }
    }
    // Api Calls
    private getCidTree = async (cidId: number): Promise<void> => {
        const response: CidTree = await CidService.getCidTree(cidId);
        this.setState({
            allLists: this.mapTreeToCardLists(response),
            lastColumnData: response.cids.filter((it) => it.selected)[0]
        })
    }
    private getCidChapters = async () => {
        CidService.getChapters().then((result: CidChapter[]) => {
            const firstCardList: CardList = {
                title: this.props.t('cid.title'),
                items: this.mapChaptersToCardItem(result)
            };
            this.setState({
                firstCardList
            });
        });
    };
    private getCidGroups = async (chapter: CidChapter): Promise<CardList> => {
        const result = await CidService.getGroups(chapter.id);
        const groupsList: CardList = {
            title: chapter.code ? this.props.t('cid.chapter', { number: chapter.code }) : '',
            items: this.mapGroupsToCardItem(result)
        };
        return groupsList;
    };
    private getCidCategories = async (group: CidGroup): Promise<CardList> => {
        const result = await CidService.getCategories(group.id);
        const categoriesList: CardList = {
            title: `${group.startCategory}-${group.endCategory}`,
            items: this.mapCategoriesToCardItem(result)
        };
        return categoriesList;
    };
    private getCids = async (category: CidCategory): Promise<CardList> => {
        const result: CidAmount[] = await CidService.getCids(category.id);
        const cidsList: CardList = {
            title: category.code || '',
            items: this.mapCidsToCardItem(result)
        };
        return cidsList;
    };
    private getCid = async (cid: CidAmount): Promise<CidAmount> => {
        AnalyticsService.cidClick(cid?.cid ?? {});
        window.fbq('track', 'ViewContent', { content_name: cid.cid?.code, content_type: 'CID' });
        return Promise.resolve(cid);
    };

    // Mappers
    private mapTreeToCardLists = (cidTree: CidTree): CardList[] => {
        return ([
            {
                title: this.props.t('cid.title'),
                items: this.mapChaptersToCardItem(cidTree.chapters.map((it) => ({ ...it.cidChapter, amount: it.amount, selected: it.selected })))
            },
            {
                title: this.props.t('cid.chapter', { number: cidTree.chapters.filter((it) => it.selected)[0].cidChapter.code || '' }),
                items: this.mapGroupsToCardItem(cidTree.groups.map((it) => ({ ...it.cidGroup, amount: it.amount, selected: it.selected })))
            },
            {
                title: cidTree.groups.filter((it) => it.selected)[0].cidGroup.description!,
                items: this.mapCategoriesToCardItem(cidTree.categories.map((it) => ({ ...it.cidCategory, amount: it.amount, selected: it.selected })))
            },
            {
                title: cidTree.categories.filter((it) => it.selected)[0].cidCategory.description!,
                items: this.mapCidsToCardItem(cidTree.cids)
            },
        ])
    }
    private mapChaptersToCardItem = (chapters: CidChapter[]): CardItem[] =>
        chapters.map<CardItem>((chapter, index) => ({
            renderItem: () => <CidCardItem item={this.mapCidTaxonomyToContent(chapter)} />,
            getChildren: () => this.getCidGroups(chapter),
            childrenAmount: chapter.amount,
            isSelected: chapter.selected
        }));
    private mapGroupsToCardItem = (groups: CidGroup[]): CardItem[] =>
        groups.map<CardItem>((group, index) => ({
            renderItem: () => <CidCardItem item={this.mapCidTaxonomyToContent(group)} />,
            getChildren: () => this.getCidCategories(group),
            childrenAmount: group.amount,
            isSelected: group.selected
        }));
    private mapCategoriesToCardItem = (categories: CidCategory[]): CardItem[] =>
        categories.map<CardItem>((category, index) => ({
            renderItem: () => <CidCardItem item={this.mapCidTaxonomyToContent(category)} />,
            getChildren: () => this.getCids(category),
            childrenAmount: category.amount,
            isSelected: category.selected
        }));
    private mapCidsToCardItem = (cids: CidAmount[]): CardItem[] =>
        cids.map<CardItem>((cid, index) => ({
            renderItem: () => <CidCardItem item={this.mapCidTaxonomyToContent(cid.cid)} />,
            getChildren: () => this.getCid(cid),
            isSelected: cid.selected
        }));
    private mapCidTaxonomyToContent = (cidTaxonomy: any): CardItemContent => {
        let title: string | undefined;
        let subtitle: string = '';
        if (cidTaxonomy.range) {
            // is chapter
            title = this.props.t('cid.chapter', { number: cidTaxonomy.code });
            subtitle = cidTaxonomy.range;
        } else if (cidTaxonomy.startCategory && cidTaxonomy.endCategory) {
            // is group
            subtitle = `${cidTaxonomy.startCategory}-${cidTaxonomy.endCategory}`;
        } else if (cidTaxonomy.code) {
            // is category
            subtitle = cidTaxonomy.code;
        }
        return {
            title,
            subtitle,
            description: cidTaxonomy.description
        };
    };

    // Renders
    render() {
        const { firstCardList, allLists, lastColumnData } = this.state;
        if (firstCardList == null && allLists == null) {
            return (
                <div className="cid-screen-container">
                    <Loading />
                </div>
            );
        }
        return (
            <div className="cid-screen-container">
                <div style={{ alignItems: 'flex-start', width: '100%', height: '100%' }}>
                    {/* <div style={{ marginBottom: 25 }}>
                        <h1 className="screen-title">{this.props.t('cid.title')}</h1>
                    </div> */}
                    <MultipleCardList
                        firstCardList={firstCardList}
                        color={'#0f74a9'}
                        maxNumberOfColumns={5}
                        renderLastColumn={(cid: CidAmount) => (
                            <CidLastColumn cid={cid.cid} isFavorited={cid.favorite && cid.favorite.isActive} />
                        )}
                        allLists={allLists}
                        lastColumnData={lastColumnData}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(CidScreen) as React.ComponentType<any>;
