import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import AuthUtil from '../../api/authUtil';

const PrivateRoute = ({ component: Component, additionalVerify, ...rest }: any) => {
    let authorized = AuthUtil.isAuthenticated();
    // if (additionalVerify) {
    //     authorized = AuthUtil.hasCompanyManagerPermission();
    // }
    return (
        <Route
            {...rest}
            component={authorized ? Component : null}
            render={authorized ? null : () => <Redirect to={{ pathname: '/' }} />}
            // render={(props: RouteComponentProps<any>) => AuthUtil.isAuthenticated() ? (
            //     <Component {...props} />
            //   ) : (
            //     <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            //   )
            // }
        />
    );
};

export default PrivateRoute;
