import React from 'react';
import './collapse.scss';

export interface CollapseProps {
    title: string;
    content: string;
    style?: any;
    titleStyle?: any;
}

export interface CollapseState {
    isOpen: boolean;
}
export default class Collapse extends React.Component<CollapseProps, CollapseState> {
    constructor(props: Readonly<CollapseProps>, context?: any) {
        super(props, context);

        this.state = { 
            isOpen: false
        };
    }
    // Handlers
    private onClick = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    // Renders
    render() {
        const { title, content, style, titleStyle } = this.props;
        const { isOpen } = this.state;
        const iconClassName: string = isOpen ? 'collapse__open-icon' : 'collapse__closed-icon';
        return (
            <a className="collapse" onClick={this.onClick} style={style}>
                <div className="collapse__title">
                    <span className="collapse__title__text" style={titleStyle}>{title}</span>
                    <div className={iconClassName}/>
                </div>
                {isOpen && (
                    <div className="collapse__content">
                        <p className="collapse__content__text">
                            {content}
                        </p>
                    </div>
                )}
            </a>
        );
    }
}