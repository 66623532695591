import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../../components/loading/loading';
import SemiologyContent from '../../../model/enums/SemiologyContent';
import { Semiology } from '../../../model/semiology/semiology';
import { User } from '../../../model/user';
import { IRootState } from '../../../reducer';
import semiologyService from '../../../services/semiologyService';
import SemiologyTabContent from './semiology-info-content';
import SemiologyTab from './semiology-info-tab';
import './semiology-info.scss';
import { Base64 } from 'js-base64';
import AnalyticsService from '../../../services/analytics-service';
import ContentType from '../../../model/enums/contentType';
import ContentRatingService from '../../../services/contentRatingService';
import { RecentHistory } from '../../../model/recentHistory';
import { addInRecents } from '../../../reducer/recentHistory/actions';

interface Props extends RouteComponentProps<{}, {}, { fromAnnotation?: boolean }> {
    t: any;
    hasSubscription?: boolean;
    account?: User;
    addInRecents: (recent: RecentHistory) => void;
}

interface State {
    semiology?: Semiology;
    fontSize?: number;
    hasSubscription?: boolean;
    showAnnotation?: boolean;
    physicalExamHeadingIndex?: number;
    anamnesisHeadingIndex?: number;
    changesHeadingIndex?: number;
    fromAnnotation?: boolean;
}

export class SemiologyInfoScreen extends React.Component<Props, State> {
    constructor(props: Readonly<Props>, context?: any) {
        super(props, context);

        this.state = {
            hasSubscription: props.account && props.account.customer && props.account.customer.hasSubscription
        };
    }

    componentDidMount(): void {
        const {
            match: { params }
        } = this.props;
        const semiologyId: number = (params as any).semiologyId;
        const fromAnnotation: boolean | undefined = this.props.location?.state?.fromAnnotation;
        this.getSemiology(semiologyId);
        this.setState({ fromAnnotation });
    }

    componentDidUpdate(prevProps: Props, prevState: State): void {
        if (prevProps.match !== this.props.match) {
            const {
                match: { params }
            } = this.props;
            const semiologyId: number = (params as any).semiologyId;
            this.getSemiology(semiologyId);
        }
    }

    private getSemiology = async (semiologyId: number): Promise<void> => {
        const semiology = await semiologyService.getSemiology(semiologyId);
        AnalyticsService.semiologyClick(semiology);
        window.fbq('track', 'ViewContent', { content_name: semiology.name, content_type: 'SEMIOLOGY' });
        ContentRatingService.sendContentRating({ name: semiology.name!, contentId: semiologyId, type: ContentType.SEMIOLOGY });
        this.props.addInRecents({ name: semiology.name!, contentId: semiologyId, type: ContentType.SEMIOLOGY });
        this.setState({
            semiology
        });
    };
    private onChangeFontSize = (fontSize: number): void => {
        this.setState({
            fontSize
        });
    };
    private onShowAnnotation = (): void => {
        this.setState({
            showAnnotation: !this.state.showAnnotation
        });
    };

    private onBack = (): void => {
        this.props.history.push({
            pathname: '/semiology'
        });
    };

    private onChangeIndex = (type: SemiologyContent) => {
        switch (type) {
            case SemiologyContent.ANAMNESIS:
                this.setState({
                    anamnesisHeadingIndex: 0,
                    physicalExamHeadingIndex: undefined,
                    changesHeadingIndex: undefined
                });
                break;
            case SemiologyContent.PHYSICAL_EXAM:
                this.setState({
                    anamnesisHeadingIndex: undefined,
                    physicalExamHeadingIndex: 0,
                    changesHeadingIndex: undefined
                });
                break;
            case SemiologyContent.CHANGES:
                this.setState({
                    anamnesisHeadingIndex: undefined,
                    physicalExamHeadingIndex: undefined,
                    changesHeadingIndex: 0
                });
                break;
        }
    };

    render(): JSX.Element {
        const { semiology, fontSize, showAnnotation, anamnesisHeadingIndex, physicalExamHeadingIndex, changesHeadingIndex } = this.state;
        if (semiology == null) {
            return (
                <div className="disease-screen-container">
                    <Loading />
                </div>
            );
        }

        const anamnesisMarkdown: string = semiology.anamnesisContent ? Base64.decode(semiology.anamnesisContent?.rawMarkdownAvailable) : '';
        const physicalExamMarkdown: string = semiology.physicalExamContent
            ? Base64.decode(semiology.physicalExamContent?.rawMarkdownAvailable)
            : '';
        const changesMarkdown: string = semiology.changesContent ? Base64.decode(semiology.changesContent?.rawMarkdownAvailable) : '';

        let name: any = semiology.name || anamnesisMarkdown.match(/^# ([a-zA-Z].*)/g);
        if (!semiology.name && name && name[0]) {
            name = String(name[0]).replace('# ', '');
        }

        return (
            <div className="disease-screen-container">
                <div className="screen__title">
                    <div className="screen__title__back" onClick={this.onBack}>
                        <div className="screen__title__back__img" />
                    </div>
                    <span className="screen__title__text">{name}</span>
                </div>
                <SemiologyTab
                    semiology={semiology}
                    onBack={this.onBack}
                    anamnesis={() => (
                        <SemiologyTabContent
                            {...this.props}
                            semiology={semiology}
                            applicationPictures={semiology.applicationPictures}
                            hasSubscription={this.props.hasSubscription}
                            markdown={anamnesisMarkdown}
                            key={'ANAMNESIS'}
                            tab={SemiologyContent.ANAMNESIS}
                            fontSize={fontSize}
                            showAnnotation={showAnnotation}
                            onShowAnnotation={this.onShowAnnotation}
                            selectedHeadingIndex={anamnesisHeadingIndex}
                        />
                    )}
                    physicalExam={() => (
                        <SemiologyTabContent
                            {...this.props}
                            key={'PHYSICAL_EXAM'}
                            semiology={semiology}
                            applicationPictures={semiology.applicationPictures}
                            hasSubscription={this.props.hasSubscription}
                            markdown={physicalExamMarkdown}
                            tab={SemiologyContent.PHYSICAL_EXAM}
                            fontSize={fontSize}
                            showAnnotation={showAnnotation}
                            onShowAnnotation={this.onShowAnnotation}
                            selectedHeadingIndex={physicalExamHeadingIndex}
                        />
                    )}
                    changes={() => (
                        <SemiologyTabContent
                            {...this.props}
                            semiology={semiology}
                            applicationPictures={semiology.applicationPictures}
                            hasSubscription={this.props.hasSubscription}
                            markdown={changesMarkdown}
                            key={'CHANGES'}
                            tab={SemiologyContent.CHANGES}
                            fontSize={fontSize}
                            showAnnotation={showAnnotation}
                            onShowAnnotation={this.onShowAnnotation}
                            selectedHeadingIndex={changesHeadingIndex}
                        />
                    )}
                    onChangeFontSize={this.onChangeFontSize}
                    onShowAnnotation={this.onShowAnnotation}
                    onChangeTab={this.onChangeIndex}
                    annotationIsOpen={this.state.fromAnnotation}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    isAuthenticated: authentication.isAuthenticated,
    hasSubscription: authentication.account && authentication.account.customer ? authentication.account.customer.hasSubscription : undefined,
});

const mapDispatchToProps = {
    addInRecents: addInRecents
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SemiologyInfoScreen) as React.ComponentType<any>;
