import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Button from '../../components/button/button';
import { IRootState } from '../../reducer';
import './diagnapp.scss';

export interface DiagnAppCarouselProps extends RouteComponentProps<{}> {
    t: (path: string, params?: any) => string;
    cards: DiagnAppCarouselCard[];
    currentIndex: number;
    onAdvance: () => void;
    showBackButton?: boolean;
    showAdvanceButton?: boolean;
    onBack: () => void;
}

export interface DiagnAppCarouselState {}

export interface DiagnAppCarouselCard {
    type: RenderOrder;
    render: () => JSX.Element;
}
export enum RenderOrder {
    AGE_COMPONENT,
    GENDER_COMPONENT,
    ETHNICITY_COMPONENT,
    REGION_COMPONENT,
    RISK_FACTOR_COMPONENT,
    SYMPTOM_COMPONENT,
    RESULT_COMPONENT
}
export class DiagnAppCarousel extends React.Component<DiagnAppCarouselProps, DiagnAppCarouselState> {
    constructor(props: Readonly<DiagnAppCarouselProps>, context?: any) {
        super(props, context);

        this.state = {
            currentIndex: props.currentIndex
        };
    }
    // Renders
    render() {
        const { cards, currentIndex, onAdvance, showBackButton, onBack, showAdvanceButton } = this.props;
        if (cards.length === 0) {
            return <div />;
        }
        const currentCardRender: () => JSX.Element = cards[currentIndex].render;
        return (
            <div className="diagnapp-carousel">
                <div className="diagnapp-carousel__card">{currentCardRender()}</div>
                <div className="diagnappp-carousel__footer">
                    {showBackButton ? (
                        <Button
                            label={this.props.t('diagnApp.back')}
                            inverse
                            onPress={onBack}
                            style={{ width: 151 }}
                            leftIcon={<div className="diagnapp-carousel__back-icon"/>}
                        />
                    ) : (
                        <div style={{ width: 151 }} />
                    )}
                    <div className="diagnappp-carousel__indicators">
                        {cards.map((_it, index) => (
                            <div
                                key={index}
                                className={`diagnappp-carousel__indicator ${
                                    index === currentIndex ? 'diagnappp-carousel__indicator--active' : ''
                                }`}
                            />
                        ))}
                    </div>
                    {showAdvanceButton ? (
                        <Button
                            label={this.props.t('diagnApp.advance')}
                            onPress={onAdvance}
                            style={{ width: 151 }}
                            rightIcon={<div className="diagnapp-carousel__advance-icon"/>}
                        />
                    ): <div style={{ width: 151 }} />}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({}: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(DiagnAppCarousel) as React.ComponentType<any>;
