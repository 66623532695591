import React from 'react';
import { CommonLoading } from 'react-loadingg';
import './loading.scss';

export const Loading = ({ style, imgStyle }: { style?: any, imgStyle?: any }) => {
    return (
        <div className="wemeds-loading" style={style}>
            <div className="wemeds-loading__img" style={imgStyle} />
        </div>
    );
};

export default Loading;
