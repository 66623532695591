import { action } from 'typesafe-actions';
import { GlobalModalActionTypes } from './types';

export const openPaidFlashcardModal = () => action(GlobalModalActionTypes.OPEN_PAID_FLASHCARD_MODAL);
export const closePaidFlashcardModal = () => action(GlobalModalActionTypes.CLOSE_PAID_FLASHCARD_MODAL);

export const openPaidDiseaseModal = () => action(GlobalModalActionTypes.OPEN_PAID_DISEASE_MODAL);
export const closePaidDiseaseModal = () => action(GlobalModalActionTypes.CLOSE_PAID_DISEASE_MODAL);

export const openImageModal = (imageSrc: string) => action(GlobalModalActionTypes.OPEN_PAID_IMAGE_MODAL, imageSrc);
export const closeImageModal = () => action(GlobalModalActionTypes.CLOSE_PAID_IMAGE_MODAL);

export const openFeedbackModal = () => action(GlobalModalActionTypes.OPEN_FEEDBACK_MODAL);
export const closeFeedbackModal = () => action(GlobalModalActionTypes.CLOSE_FEEDBACK_MODAL);

export const openExpiredHourModal = () => action(GlobalModalActionTypes.OPEN_EXPIRED_HOUR_MODAL);
export const closeExpiredHourModal = () => action(GlobalModalActionTypes.CLOSE_EXPIRED_HOUR_MODAL);

export const openAccessedFromAnotherDeviceModal = () => action(GlobalModalActionTypes.OPEN_ACCESSED_FROM_ANOTHER_DEVICE_MODAL);
export const closeAccessedFromAnotherDeviceModal = () => action(GlobalModalActionTypes.CLOSE_ACCESSED_FROM_ANOTHER_DEVICE_MODAL);

export const openErrorPaymentModal = (onPressPaymentError: () => void) => action(GlobalModalActionTypes.OPEN_ERROR_PAYMENTE_MODAL, onPressPaymentError);
export const closeErrorPaymentModal = () => action(GlobalModalActionTypes.CLOSE_ERROR_PAYMENTE_MODAL);