import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { DiagnAppSearchParams } from '../../../model/diagnAppSearchParams';
import { RiskFactor } from '../../../model/riskFactor';
import { IRootState } from '../../../reducer';
import '../diagnapp.scss';
import DropdownSearchRiskFactors from '../dropdown-search-risk-factors';

export interface RiskFactorComponentProps extends RouteComponentProps<{}> {
    t: (path: string, params?: any) => string;
    onChangeInput: (riskFactors: RiskFactor[]) => void;
    getSearchParams: () => DiagnAppSearchParams;
    riskFactors?: RiskFactor[];
}

export interface RiskFactorComponentState {
    riskFactors: RiskFactor[];
}

export class RiskFactorComponent extends React.Component<RiskFactorComponentProps, RiskFactorComponentState> {
    constructor(props: Readonly<RiskFactorComponentProps>, context?: any) {
        super(props, context);

        this.state = {
            riskFactors: props.riskFactors ? props.riskFactors.concat({}) : [{}]
        };
    }

    // Handlers
    private onSelectRiskFactor = (item: any): void => {
        let riskFactors: any[] = this.state.riskFactors;
        if (riskFactors.length === 2) {
            if (_.isEmpty(riskFactors[0])) {
                riskFactors =  [item, {}];
            } else {
                riskFactors =  [riskFactors[0], item, {}];
            }
        } else {
            riskFactors[riskFactors.length - 1] = item;
            riskFactors = riskFactors.length === 5 ? riskFactors : riskFactors.concat({});
        }
        this.setState({
            riskFactors
        }, () => {
            this.props.onChangeInput(this.state.riskFactors.filter((it) => it.name));
        })
    };
    private onRemoveRiskFactor = (index: number): void => {
        const { riskFactors } = this.state;
        riskFactors.splice(index, 1);
        this.setState({
            riskFactors
        }, () => {
            this.props.onChangeInput(this.state.riskFactors.filter((it) => it.name));
        });
    };

    // Renders
    render() {
        const { getSearchParams } = this.props;
        const { riskFactors } = this.state;
        return (
            <div className="risk-factor-component">
                <p className="risk-factor-component__title">{this.props.t('diagnApp.riskFactorComponent.title').toUpperCase()}</p>
                <p className="risk-factor-component__subtitle">{this.props.t('diagnApp.riskFactorComponent.subtitle')}</p>
                <div className="risk-factor-component__dropdowns">
                    {riskFactors.map((it, index) => (
                        <DropdownSearchRiskFactors
                            key={index}
                            inputNumber={index + 1}
                            onClickItem={this.onSelectRiskFactor}
                            onRemove={() => this.onRemoveRiskFactor(index)}
                            getSearchParams={getSearchParams}
                            selectedItem={it.id ? it : undefined}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({}: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(RiskFactorComponent) as React.ComponentType<any>;
