import { Base64 } from 'js-base64';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import htmlParser from 'react-markdown/plugins/html-parser';
import ReactMarkdown from 'react-markdown/with-html';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import WemedsLinkUtil from '../../model/enums/wemedsLink';
import { cleanMarkdownSyntax } from '../../shared/util/markdown-utils';
import { WemedsReference } from '../disease/disease-tab-content';
import { useMedicalCriteriaContext } from './medical-criteria.provider';
import './medical-criteria.scss';

interface Props extends RouteComponentProps<{}> {
  markdownContent?: any;
  hasSubscription?: boolean;
}

const parseHtml = htmlParser({
  isValidNode: (node) => node.type !== 'script',
  processingInstructions: [
    /* ... */
  ],
});

const MedicalCriteriaMarkdown = (props: Props) => {
  const { fontSize } = useMedicalCriteriaContext();
  const [contentText, setContentText] = useState<string>('');
  const [highlights, setHighlights] = useState<WemedsReference[]>([]);

  useEffect(() => {
    if (props.markdownContent !== undefined) {
      if (props.markdownContent != null) {
        const references = WemedsLinkUtil.getLinksFromMarkdown(
          props.markdownContent
        );
        setHighlights(references);
      }
      let newContent = cleanMarkdownSyntax(
        Base64.decode(props.markdownContent)
      );
      // Remove first line
      newContent = newContent.split('\n').slice(1).join('\n');
      // Remove last line
      newContent = newContent.substring(newContent.lastIndexOf('\n') + 1, -1);
      const references = WemedsLinkUtil.getLinksFromMarkdown(newContent);
      if (references.length > 0) {
        newContent =
          newContent + '\n\n***\n\n #### **CONTEÚDOS RELACIONADOS** \n\n';
        references.forEach((reference) => {
          newContent = newContent + ` ${reference.code} `;
        });
      }

      newContent = setInternalLinksInMarkdown(newContent);
      setContentText(newContent);
    }
  }, [props.markdownContent, props.hasSubscription]);

  const setInternalLinksInMarkdown = (markdown: string) => {
    const regex =
      /\(\((MEDICINE_LEAFLET|DISEASE|PEDIATRIC_DOSES|MEDICAL_CALCULATORS|ILLUSTRADED_PROCEDURES|LABORATORIES|CRITERIA|SCORES|MICRO);(\d+);([^\)]*)\)\)/gi;
    markdown = _.replace(markdown, regex, '[$3](/$1/$2)');

    return _.replace(markdown, /;HIGHLIGHT/gi, '');
  };

  return (
    <div className={'pediatrc-dose-container'}>
      {/* {highlights.map(reference => (
                <InternalLinkHighlight key={reference.id} internalLink={reference} {...props} />
            ))} */}
      <ReactMarkdown
        source={contentText}
        className="markdown"
        escapeHtml={false}
        astPlugins={[parseHtml]}
        renderers={{
          paragraph: (componentProps) => (
            <div className="markdown__paragraph" style={{ fontSize }}>
              {componentProps.children.map((it) => it)}
            </div>
          ),
          strong: (componentProps) => (
            <span className="markdown__strong">
              {componentProps.children.map((it) => it)}
            </span>
          ),
          heading: (componentProps) => (
            <p id={`heading`} className="markdown__heading">
              {componentProps.children.map((it) => it)}
            </p>
          ),
          emphasis: (componentProps) => (
            <span className="markdown__emphasis">
              {componentProps.children.map((it) => it)}
            </span>
          ),
          listItem: (componentProps) => (
            <li className="markdown__list-item">
              {componentProps.children.map((it) => it)}
            </li>
          ),
          code: (componentProps) => (
            <p className="markdown__code">{componentProps.value}</p>
          ),
          blockquote: (componentProps) => (
            <div className="markdown__blockquote">
              <div className="markdown__blockquote__content">
                {componentProps.children.map((it) => it)}
              </div>
            </div>
          ),
          link: (componentProps) => {
            const isInternalLinkRegex =
              /(MEDICINE_LEAFLET|DISEASE|PEDIATRIC_DOSES|MEDICAL_CALCULATORS|ILLUSTRADED_PROCEDURES|LABORATORIES|CRITERIA|SCORES|MICRO)/gi;
            if (isInternalLinkRegex.test(componentProps.href)) {
              const url = WemedsLinkUtil.replaceEnumNameByLink(
                componentProps.href
              );
              const isContent = WemedsLinkUtil.isContent(componentProps.href);
              const isMedicineLeaflet = WemedsLinkUtil.isMedicineLeaflet(
                componentProps.href
              );
              const isAvailable =
                props.hasSubscription === true ||
                isMedicineLeaflet ||
                WemedsLinkUtil.isFreeItemOfType(componentProps.href);
              return isAvailable ? (
                <a
                  href={url}
                  className={
                    isContent
                      ? 'markdown_content_link'
                      : 'markdown_feature_link'
                  }
                >
                  {componentProps.children.map((it) =>
                    it.props?.value != null
                      ? _.upperFirst(String(it.props?.value))
                      : ''
                  )}
                </a>
              ) : (
                <span
                  className={
                    isContent
                      ? 'markdown_content_link'
                      : 'markdown_feature_link'
                  }
                  onClick={() => {
                    !isAvailable
                      ? console.log('pode')
                      : console.log('não pode');
                  }}
                >
                  {`🏆 `}
                  {componentProps.children.map((it) =>
                    it.props?.value != null
                      ? _.upperFirst(String(it.props?.value))
                      : ''
                  )}
                </span>
              );
            } else {
              return (
                <a href={componentProps.href} className="markdown__link">
                  {componentProps.children.map((it) =>
                    it.props?.value != null
                      ? _.upperFirst(String(it.props?.value))
                      : ''
                  )}
                </a>
              );
            }
          },
        }}
      />
    </div>
  );
};

const mapDispatchToProps = {};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation()
)(MedicalCriteriaMarkdown) as React.ComponentType<any>;
