
export enum GlobalModalActionTypes {
  OPEN_PAID_FLASHCARD_MODAL = '@@globalModal/OPEN_PAID_FLASHCARD_MODAL',
  CLOSE_PAID_FLASHCARD_MODAL = '@@globalModal/CLOSE_PAID_FLASHCARD_MODAL',

  OPEN_PAID_DISEASE_MODAL = '@@globalModal/OPEN_PAID_DISEASE_MODAL',
  CLOSE_PAID_DISEASE_MODAL = '@@globalModal/CLOSE_PAID_DISEASE_MODAL',

  OPEN_PAID_IMAGE_MODAL = '@@globalModal/OPEN_PAID_IMAGE_MODAL',
  CLOSE_PAID_IMAGE_MODAL = '@@globalModal/CLOSE_PAID_IMAGE_MODAL',

  OPEN_FEEDBACK_MODAL = '@@globalModal/OPEN_FEEDBACK_MODAL',
  CLOSE_FEEDBACK_MODAL = '@@globalModal/CLOSE_FEEDBACK_MODAL',
  
  OPEN_ACCESSED_FROM_ANOTHER_DEVICE_MODAL = '@@globalModal/OPEN_ACCESSED_FROM_ANOTHER_DEVICE_MODAL',
  CLOSE_ACCESSED_FROM_ANOTHER_DEVICE_MODAL = '@@globalModal/CLOSE_ACCESSED_FROM_ANOTHER_DEVICE_MODAL',

  OPEN_ERROR_PAYMENTE_MODAL = '@@globalModal/OPEN_ERROR_PAYMENTE_MODAL',
  CLOSE_ERROR_PAYMENTE_MODAL = '@@globalModal/CLOSE_ERROR_PAYMENTE_MODAL',
  
  OPEN_EXPIRED_HOUR_MODAL = '@@globalModal/OPEN_EXPIRED_HOUR_MODAL',
  CLOSE_EXPIRED_HOUR_MODAL = '@@globalModal/CLOSE_EXPIRED_HOUR_MODAL'
}

export interface GlobalModalState {
  paidFlashcardModalOpen: boolean;
  paidDiseaseModalOpen: boolean;
  imageModal: ImageModalState;
  feedbackModal: boolean;
  expiredHourModal: boolean;
  onPressPaymentError: () => void;
  errorPaymentModal: boolean;
  accessedFromAnotherDevice: boolean;
}

export interface ImageModalState {
  imageSrc?: string;
  isOpen?: boolean;
}