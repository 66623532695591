import styled from 'styled-components';

export const OptionText = styled.span<{ selected: boolean }>`
    line-height: 1;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-family: 'Raleway';
    letter-spacing: normal;
    color: ${it => (it.selected ? '#1a3c47' : '#6D7C81')};
`;

export const OptionContainer = styled.div<{ selected: boolean; width: number }>`
    height: 44px;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 100px;
    background-color: transparent;
    width: ${it => `${it.width}%`};
    zindex: ${it => (it.selected ? 1 : 0)};
    border: ${it => (it.selected ? `1px solid #DBD9D1` : '0px solid')};
`;

export const OptionPointsText = styled.span<{ selected: boolean }>`
    font-size: 11px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    font-family: 'Raleway';
    letter-spacing: normal;
    color: ${it => (it.selected ? '#F4F5F5' : '#6D7C81')};
`;

export const OptionPointsContainer = styled.div<{ selected: boolean }>`
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${it => (it.selected ? '#f59e24' : '#E9ECED')};
`;
