import styled from 'styled-components';

export const MainContainer = styled.div`
    height: 65vh;
    width: 60vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1.2px solid #e9eced;
    border-radius: 8px;
    background-color: #FFFFFF;
    overflow-y: auto;
    padding-top: 20px;
`;

export const Title = styled.span`
    color: #414E52;
    font-family: Raleway,serif;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-top: 2vh;
`;

export const RocketIcon = styled.div`
    height: 52px;
    width: 52px;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
`;

export const Text = styled.span`
    color: #414E52;
    font-family: Raleway,serif;
    font-size: 18px;
    font-weight: 500;
    width: 80%;
    line-height: 3.5vh;
`;

export const TextContainer = styled.span`
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    height: 80%;
`;

export const ButtonContainer = styled.span`
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
`;