import { FormControl } from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { compose } from 'redux';
import 'toasted-notes/src/styles.css';
import AuthUtil from '../../api/authUtil';
import '../../App.scss';
import ForgotPasswordModal from '../../components/fogot-password-modal/forgot-password-modal';
import Loading from '../../components/loading/loading';
import TextFieldMeds from '../../components/text-field-meds/text-field-meds';
import { AUTHORITIES } from '../../config/constants';
import { Authentication } from '../../model/authentication';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { IRootState } from '../../reducer';
import { getSessionRequest, loginRequest, logoutRequest } from '../../reducer/authentication/actions';
import AnalyticsService from '../../services/analytics-service';
import { validateEmail } from '../../shared/util/utils';

export interface LoginProps extends RouteComponentProps<{}> {
    getSession: () => void;
    logout: () => void;
    login: (authentication: Authentication) => void;

    isAuthenticated: any;
    loginStatus: HttpRequestStatus;
    getSessionStatus: HttpRequestStatus;
    t: any;
}
export interface LoginState {
    login: string;
    password: string;
    loading: boolean;
    hasErrorLogin: boolean;
    background: number;
    usernameError?: boolean;
    usernameErrorText: string;
    passwordError?: boolean;
    passwordErrorText: string;
    showForgotPasswordModal: boolean;
}

export class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: Readonly<LoginProps>, context?: any) {
        super(props, context);
        this.state = {
            login: '',
            password: '',
            loading: false,
            hasErrorLogin: false,
            background: _.random(1, 3),
            usernameErrorText: this.props.t('login.error.username'),
            passwordErrorText: this.props.t('login.error.password'),

            showForgotPasswordModal: false
        };
    }

    async componentDidMount() {
        if (AuthUtil.isAuthenticated()) {
            const decodeJwt: any = jwt_decode(AuthUtil.getToken()!);
            if (decodeJwt.auth.includes(AUTHORITIES.CUSTOMER)) {
                this.props.getSession();

                return;
            }
            this.setState({
                loading: false
            });
        }
    }
    async componentWillReceiveProps(newProps: LoginProps) {
        if (newProps.isAuthenticated === true && this.props.isAuthenticated !== newProps.isAuthenticated) {
            this.setState({
                loading: false
            });

            AnalyticsService.loginEvent(this.state.login);
            this.props.history.push('/home');
        }

        if (newProps.loginStatus === HttpRequestStatus.SUCCESS) {
            this.props.getSession();
        } else if (newProps.loginStatus === HttpRequestStatus.ERROR) {
            this.setState({
                loading: false,
                usernameError: undefined,
                passwordError: true,
                passwordErrorText: this.props.t('login.error.password')
            });
        }

        if (newProps.getSessionStatus === HttpRequestStatus.SUCCESS) {
            this.setState({
                loading: false
            });

            this.props.history.push('/home');
        } else if (newProps.getSessionStatus === HttpRequestStatus.ERROR) {
            this.setState({
                loading: false
            });
            this.props.history.push('/home');
        }
    }

    handleSubmit = () => {
        if (this.state.login.length === 0 || this.state.password.length === 0) {
            this.setState({
                usernameError: this.state.login.length === 0,
                usernameErrorText: this.props.t('login.error.usernameLength'),
                passwordError: this.state.password.length === 0,
                passwordErrorText: this.props.t('login.error.passwordLength')
            });
        } else {
            this.setState({
                loading: true,
                passwordError: false,
                usernameError: false
            });
            this.props.login({ username: this.state.login, password: this.state.password });
        }
    };

    onChangeUserName = (value: string) => {
        this.setState({
            login: value
        });
    };

    onSetErrorLogin = (value: boolean) => {
        this.setState({
            hasErrorLogin: value
        });
    };

    onChangePassword = (value: string) => {
        this.setState({
            password: value
        });
    };

    private onBlurUsername = () => {
        if (validateEmail(this.state.login)) {
            this.setState({
                usernameError: this.state.usernameError ? (this.state.login.length === 0 ? true : false) : undefined
            });
        } else {
            this.setState({
                usernameErrorText: this.props.t('login.error.usernameInvalid'),
                usernameError: true
            });
        }
    };
    private onBlurPassword = () => {
        this.setState({
            passwordError: this.state.passwordError ? (this.state.password.length === 0 ? true : false) : undefined
        });
    };
    private onForgotPasswordPress = (): void => {
        this.setState({
            showForgotPasswordModal: true
        });
    };
    private onCloseForgotPasswordModal = (): void => {
        this.setState({
            showForgotPasswordModal: false
        });
    };

    render() {
        const { login, password, usernameError, usernameErrorText, passwordError, passwordErrorText, showForgotPasswordModal } = this.state;
        return (
            <div className="page-container">
                <Row className={`background-login--${this.state.background}`}>
                    <Col xs={12} md={6}>
                        <div className="container-banner">
                            <div className="container-banner--group-web">
                                <div className="logo" />
                                <div style={{ marginTop: 30 }}>
                                    <div className={'logo-word'} />
                                    <p className="description-banner">
                                        {this.props.t('login.loginBannerDescription1')}
                                        <br />
                                        <p>{this.props.t('login.loginBannerDescription2')}</p>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} md={6}>
                        {this.state.loading ? (
                            <Loading />
                        ) : (
                            <div className="login-container">
                                <p className="title-login">{`${this.props.t('login.welcome1')} `}</p>
                                <FormControl style={{ width: '60%', marginRight: '20%', marginLeft: '20%' }}>
                                    <div style={{ display: 'none' }}>
                                        <input type="password" tabIndex={-1} />
                                    </div>
                                    <TextFieldMeds
                                        onChange={this.onChangeUserName}
                                        id="username"
                                        isEmail
                                        value={login}
                                        label={this.props.t('login.form.username')}
                                        placeholder={this.props.t('login.form.username')}
                                        error={usernameError}
                                        errorText={usernameErrorText}
                                        onBlur={this.onBlurUsername}
                                    />
                                    <br />

                                    <TextFieldMeds
                                        onChange={this.onChangePassword}
                                        id="password"
                                        isPassword
                                        value={password}
                                        helpText={this.props.t('login.form.helpPass')}
                                        label={this.props.t('login.form.password')}
                                        placeholder={this.props.t('login.form.password')}
                                        onEnterPress={this.handleSubmit}
                                        error={passwordError}
                                        errorText={passwordErrorText}
                                        onBlur={this.onBlurPassword}
                                        InputProps={{
                                            autComplete: 'off'
                                        }}
                                    />
                                </FormControl>

                                <div className="button-container-login" style={{ marginTop: 25 }}>
                                    <Row style={{ justifyContent: 'center' }}>
                                        <Button onClick={this.handleSubmit} className="button-primary-web">
                                            <div style={{ margin: 'auto' }}>{this.props.t('login.confirm')}</div>
                                            <div className="mobile-arrow">
                                                <img src="" alt="" />
                                            </div>
                                        </Button>
                                    </Row>
                                </div>

                                <div style={{ textAlign: 'center', marginTop: 30 }}>
                                    <a
                                        className="link-sm"
                                        style={{ marginBottom: '5px', marginTop: '20px' }}
                                        onClick={this.onForgotPasswordPress}
                                    >
                                        {this.props.t('login.form.forgotPassword')}
                                    </a>
                                </div>

                                <div className="button-container-login" style={{ marginTop: 25 }}>
                                    <Row style={{ justifyContent: 'center' }}>
                                        <Button onClick={() => this.props.history.push('/register')} className="button-white-primary-web">
                                            <div style={{ margin: 'auto' }}>{this.props.t('login.register')}</div>
                                            <div className="mobile-arrow">
                                                <img src="" alt="" />
                                            </div>
                                        </Button>
                                    </Row>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
                <ForgotPasswordModal open={showForgotPasswordModal} closeModal={this.onCloseForgotPasswordModal} />
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    isAuthenticated: authentication.isAuthenticated,
    loginStatus: authentication.loginStatus,
    getSessionStatus: authentication.getSessionStatus
});

const mapDispatchToProps = {
    getSession: getSessionRequest,
    login: loginRequest,
    logout: logoutRequest
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Login) as React.ComponentType<any>;
