import { AxiosResponse } from 'axios';
import { MedicalScore } from '../model/medical-score/medical-score';
import { PageableResponse } from '../model/pageable';
import { api } from './api';

const MedicalScoreApi = () => {
    const getInfo = (id: number): Promise<AxiosResponse<MedicalScore>> => {
        return api.get<MedicalScore>(`/medical-scores/info/${id}`);
    };

    const search = (text?: string): Promise<AxiosResponse<PageableResponse<MedicalScore>>> => {
        const query = text !== undefined ? `&name=${text}` : '';
        return api.get<PageableResponse<MedicalScore>>(`/medical-scores/search?size=1000${query}`);
    };

    const getAvailableForFreeScores = (): Promise<AxiosResponse<PageableResponse<MedicalScore>>> => {
        return api.get<PageableResponse<MedicalScore>>(`/medical-scores/free?size=100`);
    };

    return {
        getInfo,
        getAvailableForFreeScores,
        search
    };
};

export default MedicalScoreApi();
