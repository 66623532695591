import { AxiosResponse } from 'axios';
import { DiagnAppSearchParams } from '../model/diagnAppSearchParams';
import { RiskFactor } from '../model/riskFactor';
import { api } from './api';

export const riskFactorApi = () => {
    const search = (params: DiagnAppSearchParams): Promise<AxiosResponse<RiskFactor[]>> => {
        const urlParams: string = Object.keys(params)
            .filter((key) => params[key] != null)
            .map(key => `${key}=${params[key]}`)
            .join('&');

        return api.get<RiskFactor[]>(`/risk-factor/diagnapp?${urlParams}`);
    };

    return {
        search
    };
};

export default riskFactorApi();
