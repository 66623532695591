import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import toaster from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import '../../../App.scss';
import Button from '../../../components/button/button';
import CustomToast from '../../../components/custom-toast/custom-toast';
import Loading from '../../../components/loading/loading';
import RadioFieldMeds from '../../../components/radio-field-meds/radio-field-meds';
import SelectFieldMeds from '../../../components/select-field-meds/select-field-meds';
import { CustomerRegister } from '../../../model/customer-register';
import FieldOfWork from '../../../model/enums/fieldOfWork';
import Formation from '../../../model/enums/formation';
import Gender from '../../../model/enums/gender';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import Specialization from '../../../model/enums/specialization';
import { getSessionRequest, loginRequest } from '../../../reducer/authentication/actions';
import { registerRequest } from '../../../reducer/register/actions';

export interface InfoStepState {
    specializations: any[];
    fieldOfWorks: any[];
    gender: Gender;
    fieldOfWork?: FieldOfWork;
    specialization?: Specialization;
    formationYear?: number;
    formation: Formation;
    yearsList: any[];
    acceptedTerms: boolean;
}

export interface InfoStepProps extends RouteComponentProps<{}> {
    handleFinishedSecondStep: (customerRegister: CustomerRegister) => void;
    registerStatus: HttpRequestStatus;
    t: any;
}

export class InfoStep extends React.Component<InfoStepProps, InfoStepState> {
    constructor(props: Readonly<InfoStepProps>, context?: any) {
        super(props, context);
        this.state = {
            gender: Gender.MALE,
            formation: Formation.STUDENT,
            yearsList: [],
            specializations: Object.values(Specialization),
            fieldOfWorks: Object.values(FieldOfWork),
            acceptedTerms: false
        };
    }

    componentDidMount() {
        this.buildYears();
        this.buildSpecializations();
        this.buildFieldsOfWork();
    }

    private buildYears = () => {
        const years: any[] = [];

        for (let index = 0; index < 15; index++) {
            const year: string = moment()
                .add(index, 'years')
                .format('YYYY');
            years.push({
                label: year,
                value: year
            });
        }

        this.setState({
            yearsList: years
        });
    };

    private buildSpecializations = () => {
        const specializations: any[] = [];

        Object.values(Specialization).forEach(spec => {
            specializations.push({
                label: this.props.t(`specialization.${spec}`),
                value: spec
            });
        });

        this.setState({
            specializations
        });
    };

    private buildFieldsOfWork = () => {
        const fields: any[] = [];

        Object.values(FieldOfWork).forEach(field => {
            fields.push({
                label: this.props.t(`fieldOfWork.${field}`),
                value: field
            });
        });

        this.setState({
            fieldOfWorks: fields
        });
    };

    private onChangeGender = () =>
        this.setState({
            gender: this.state.gender === Gender.MALE ? Gender.FEMALE : Gender.MALE
        });

    private onChangeDegree = () => {
        this.setState({
            formation: this.state.formation === Formation.STUDENT ? Formation.PROFESSIONAL : Formation.STUDENT,
            specialization: undefined,
            fieldOfWork: undefined,
            formationYear: undefined
        });
    };

    private onChangeFormationYear = (formationYear: number) => {
        this.setState({
            formationYear
        });
    };

    private onChangeSpecialization = (specialization: Specialization) => {
        this.setState({
            specialization
        });
    };

    private onChangeFieldOfWork = (fieldOfWork: FieldOfWork) => {
        this.setState({
            fieldOfWork
        });
    };

    private handleSubmit = () => {
        const { gender, formation, fieldOfWork, specialization, formationYear } = this.state;
        if (this.state.acceptedTerms && !_.isEmpty(gender) && !_.isEmpty(formation) && !_.isEmpty(fieldOfWork)) {
            const customerRegister: CustomerRegister = {
                email: '',
                password: '',
                customer: {
                    gender,
                    formation,
                    fieldOfWork,
                    specialization: formation === Formation.PROFESSIONAL ? specialization : undefined,
                    formationYear: formation === Formation.STUDENT ? formationYear : undefined
                }
            };

            this.props.handleFinishedSecondStep(customerRegister);
        } else {
            toaster.notify(
                callback => <CustomToast title={this.props.t('register.error.step-1')} onClose={callback.onClose} t={this.props.t} />,
                {
                    duration: 3000,
                    position: 'bottom'
                }
            );
        }
    };

    render() {
        return (
            <div className="page-container">
                <div>
                    {this.props.registerStatus === HttpRequestStatus.ON_GOING ? (
                        <Loading />
                    ) : (
                        <div>
                            <div className="step-container">
                                <Row className="head-step-text text-step">
                                    <span>{this.props.t('register.step.login')}</span>
                                    <div className="space-step-blank" />
                                    <span>{`${this.props.t('register.step.data')} `}</span>
                                </Row>
                                <Row className="head-step">
                                    <div className="progress-finish" />
                                    <div className="space-step-on" />
                                    <div className="progress-step" />
                                </Row>
                            </div>
                            <Row className="justity-content-center">
                                <b>
                                    <p className={'main-text main-text-mobile'}>{this.props.t('register.text2')}</p>
                                </b>
                            </Row>
                            <Row className="justify-content-center second-step-register-mobile">
                                <Col md="5">
                                    <FormControl className="form" style={{ width: '100%' }}>
                                        <span className="label-radio">{this.props.t('register.form.gender')}</span>

                                        <Row className="radio-options justify-content-center">
                                            <RadioFieldMeds
                                                id={'male-gender'}
                                                onChange={this.onChangeGender}
                                                placeholder={this.props.t('global.gender.male')}
                                                checked={this.state.gender === Gender.MALE}
                                            />

                                            <RadioFieldMeds
                                                id={'female-gender'}
                                                onChange={this.onChangeGender}
                                                placeholder={this.props.t('global.gender.female')}
                                                checked={this.state.gender === Gender.FEMALE}
                                            />
                                        </Row>

                                        <span className="label-radio">{this.props.t('register.form.formation')}</span>
                                        <Row className="radio-options justify-content-center">
                                            <RadioFieldMeds
                                                id={'student-formation'}
                                                onChange={this.onChangeDegree}
                                                placeholder={this.props.t('global.formation.student')}
                                                checked={this.state.formation === Formation.STUDENT}
                                            />

                                            <RadioFieldMeds
                                                id={'professional-formation'}
                                                onChange={this.onChangeDegree}
                                                placeholder={this.props.t('global.formation.pro')}
                                                checked={this.state.formation === Formation.PROFESSIONAL}
                                            />
                                        </Row>

                                        <Row className="justify-content-center" style={{ width: '100%', marginTop: '20px' }}>
                                            {this.state.formation === Formation.STUDENT && (
                                                <SelectFieldMeds
                                                    dataArray={this.state.yearsList}
                                                    id={'year-formation'}
                                                    onChange={this.onChangeFormationYear}
                                                    placeholder={this.props.t('register.form.formationYear')}
                                                    selected={!_.isEmpty(this.state.formationYear)}
                                                />
                                            )}

                                            <SelectFieldMeds
                                                dataArray={this.state.fieldOfWorks}
                                                id={'fieldOfWorks'}
                                                onChange={this.onChangeFieldOfWork}
                                                initialValue={this.state.fieldOfWork}
                                                placeholder={this.props.t('register.form.fieldOfWork')}
                                                selected={!_.isEmpty(this.state.fieldOfWork)}
                                            />

                                            {this.state.formation !== Formation.STUDENT &&
                                                this.state.fieldOfWork === FieldOfWork.MEDICINE && (
                                                    <SelectFieldMeds
                                                        dataArray={this.state.specializations}
                                                        id={'specializations'}
                                                        onChange={this.onChangeSpecialization}
                                                        placeholder={this.props.t('register.form.specialization')}
                                                        selected={!_.isEmpty(this.state.specialization)}
                                                    />
                                                )}
                                        </Row>
                                        <FormControlLabel
                                            style={{ marginTop: '30px' }}
                                            control={
                                                <Checkbox
                                                    checked={this.state.acceptedTerms}
                                                    onChange={() =>
                                                        this.setState({
                                                            acceptedTerms: !this.state.acceptedTerms
                                                        })
                                                    }
                                                    value={'terms'}
                                                />
                                            }
                                            label={
                                                <div>
                                                    <span className="terms">{this.props.t('register.terms')} </span>{' '}
                                                    <a href="https://wemeds.com.br/termos/" target="_blank" className="terms-link">
                                                        {this.props.t('register.terms-link')}
                                                    </a>
                                                </div>
                                            }
                                        />
                                    </FormControl>
                                </Col>
                            </Row>

                            <div className="button-container">
                                <Row className="justify-content-center">
                                    <Button
                                        label={this.props.t('global.button.finish')}
                                        onPress={this.handleSubmit}
                                        style={{ justifyContent: 'center', display: 'flex', width: '160px' }}
                                        rightIcon={<div className="steps__footer__advance-icon" />}
                                    />
                                </Row>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getSession: getSessionRequest,
    register: registerRequest,
    login: loginRequest
};

export default compose(connect(null, mapDispatchToProps), withTranslation())(InfoStep) as React.ComponentType<any>;
