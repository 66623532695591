import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '../../../../components/button/button';
import Loading from '../../../../components/loading/loading';
import TextFieldMeds from '../../../../components/text-field-meds/text-field-meds';
import CustomerService from '../../../../services/customerService';
import { validateEmail } from '../../../../shared/util/utils';
import '../components.scss';
import './indicate-friend.scss';

export interface IIndicateFriendProps {
    t: (path: string, params?: any) => string;
    open: boolean;
}
export interface IIndicateFriendState {
    email?: string;
    emailError?: boolean;
    emailErrorMessage?: string;
    contentType: 'REQUEST' | 'SUCCESS' | 'ERROR' | 'LOADING';
}

export class IndicateFriend extends React.Component<IIndicateFriendProps, IIndicateFriendState> {
    constructor(props) {
        super(props);
        this.state = {
            contentType: 'REQUEST'
        };
    }
    private onSendPress = async (): Promise<void> => {
        const { email } = this.state;
        if (!email || email.length === 0) {
            this.setState({
                emailError: true,
                emailErrorMessage: this.props.t('indicateFriend.requiredEmail')
            });
            return;
        } else if (!validateEmail(email)) {
            this.setState({
                emailError: true,
                emailErrorMessage: this.props.t('indicateFriend.invalidEmail')
            });
            return;
        }
        this.setState({
            contentType: 'LOADING'
        });
        await CustomerService.indicateFriend(email)
            .then(it => {
                this.setState({
                    contentType: 'SUCCESS'
                });
            })
            .catch(e => {
                this.setState({
                    contentType: 'ERROR'
                });
            });
    };

    private onChangeEmail = (value: string): void => {
        this.setState({
            email: value
        });
    };

    private onBlurEmail = (): void => {
        const { email } = this.state;
        if (!email || email.length === 0) {
            this.setState({
                emailError: true,
                emailErrorMessage: this.props.t('indicateFriend.requiredEmail')
            });
        } else if (!validateEmail(email)) {
            this.setState({
                emailError: true,
                emailErrorMessage: this.props.t('indicateFriend.invalidEmail')
            });
        }
    };

    private onBack = (): void => {
        this.setState({
            contentType: 'REQUEST',
            email: undefined,
            emailError: undefined,
            emailErrorMessage: undefined
        });
    };

    private renderError = () => {
        return (
            <div className='modal__content'>
                <div className='top-content' style={{ width: 400 }}>
                    <div className='wemeds-logo-img' />
                    <span className='top-content__subtitle'>{this.props.t('indicateFriend.error.message')}</span>
                </div>
                <div className='bottom-content' style={{ marginTop: 20 }}>
                    <div className='bottom-content__btn-container'>
                        <Button label={this.props.t('indicateFriend.error.buttonLabel').toUpperCase()} onPress={() => this.setState({ contentType: 'REQUEST' })} />
                    </div>
                </div>
            </div>
        );
    };

    private renderSuccess = () => {
        const { email } = this.state;
        return (
            <div className='modal__content'>
                <div className='top-content' style={{ width: 400 }}>
                    <div className='wemeds-logo-img' />
                    <span className='top-content__subtitle top-content__subtitle--bold'>{this.props.t('indicateFriend.success.title', { email })}</span>
                    <span className='top-content__subtitle'>{this.props.t('indicateFriend.success.subtitle')}</span>
                </div>
                <div className='bottom-content' style={{ marginTop: 20 }}>
                    <div className='bottom-content__btn-container'>
                        <Button label={this.props.t('indicateFriend.success.buttonLabel').toUpperCase()} onPress={this.onBack} />
                    </div>
                </div>
            </div>
        );
    };

    private renderRequest = () => {
        const { email, emailError, emailErrorMessage } = this.state;
        return (
            <div className='modal__content'>
                <div className='top-content' style={{ width: 400 }}>
                    <div className='wemeds-logo-img' />
                    <span className='top-content__title'>{this.props.t('indicateFriend.title')}</span>
                    <span className='top-content__subtitle'>{this.props.t('indicateFriend.subtitle')}</span>
                </div>
                <div className='bottom-content' style={{ marginTop: 20 }}>
                    <TextFieldMeds
                        id='email'
                        value={email}
                        onChange={this.onChangeEmail}
                        placeholder={this.props.t('indicateFriend.inputPlaceholder')}
                        label={this.props.t('indicateFriend.inputPlaceholder')}
                        isEmail
                        errorText={emailErrorMessage}
                        error={emailError}
                        onBlur={this.onBlurEmail}
                    />
                    <div className='bottom-content__btn-container'>
                        <Button label={this.props.t('indicateFriend.buttonLabel').toUpperCase()} onPress={this.onSendPress} />
                    </div>
                </div>
            </div>
        );
    };

    private renderContent = () => {
        const { contentType } = this.state;
        switch (contentType) {
            case 'REQUEST':
                return this.renderRequest();
            case 'LOADING':
                return <Loading />;
            case 'SUCCESS':
                return this.renderSuccess();
            case 'ERROR':
                return this.renderError();
            default:
                return <div />;
        }
    };

    render() {
        return (
            <div className="component">
                {this.renderContent()}
            </div>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(IndicateFriend) as React.ComponentType<Omit<IIndicateFriendProps, 't'>>;
