import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { IRootState } from '../../reducer';
import './cid.scss';

export interface CardItemContent {
    title?: string;
    subtitle: string;
    description: string;
}
export interface CidCardItemProps extends RouteComponentProps<{}> {
    t: any;
    item: CardItemContent;
}

export interface CidCardItemState {
    //
}

export class CidCardItem extends React.Component<CidCardItemProps, CidCardItemState> {
    constructor(props: Readonly<CidCardItemProps>, context?: any) {
        super(props, context);

        this.state = {};
    }
    render() {
        const { item } = this.props;
        return ( 
            <div className="card-content">
                <div className="card-content__row">
                    <div className="card-content__column">
                        {item.title != null ?  <span className="card-content__title">{item.title}</span> : <div />}
                        <span className="card-content__subtitle">{item.subtitle}</span>
                    </div>
                    <div className="arrow-right-img"/>
                </div>
                <div style={{ marginTop: 6 }}>
                    <span className="card-content__description">{item.description}</span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(CidCardItem) as React.ComponentType<any>;
