import { AxiosResponse } from 'axios';
import cidApi from '../api/cidApi';
import { CidAmount } from '../model/cid';
import { CidCategory, CidCategoryAmount } from '../model/cidCategory';
import { CidChapter, CidChapterAmount } from '../model/cidChapter';
import { CidGroup, CidGroupAmount } from '../model/cidGroup';
import { CidTree } from '../model/cidTree';
import HttpStatus from '../model/enums/httpStatus';

export const CidService = () => {
   const getChapters = async (): Promise<CidChapter[]> => {
       try {
           const result: AxiosResponse<CidChapterAmount[]> = await cidApi.getChapters();
           console.log('result ', result);
           if (result.status === HttpStatus.OK && result.data != null) {
               const response = result.data.map((it) => ({ ...it.cidChapter, amount: it.amount }));
               return Promise.resolve(response);
           } else if (result.status === HttpStatus.UNAUTHORIZED) {
               // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
               return Promise.reject({ status: 401 } as AxiosResponse);
           } else {
               return Promise.reject({ status: 400 } as AxiosResponse);
           }
       } catch (error) {
           console.error(error);
           return Promise.reject(error);
       }
   };   
   const getGroups = async (chapterId: number): Promise<CidGroup[]> => {
       try {
           const result: AxiosResponse<CidGroupAmount[]> = await cidApi.getGroups(chapterId);
           console.log('result ', result);
           if (result.status === HttpStatus.OK && result.data != null) {
               console.info(result.data);
               const response = result.data.map((it) => ({ ...it.cidGroup, amount: it.amount }));
               return Promise.resolve(response);
           } else if (result.status === HttpStatus.UNAUTHORIZED) {
               // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
               return Promise.reject({ status: 401 } as AxiosResponse);
           } else {
               return Promise.reject({ status: 400 } as AxiosResponse);
           }
       } catch (error) {
           console.error(error);
           return Promise.reject(error);
       }
   };
   const getCategories = async (groupId: number): Promise<CidCategory[]> => {
       try {
           const result: AxiosResponse<CidCategoryAmount[]> = await cidApi.getCategories(groupId);
           console.log('result ', result);
           if (result.status === HttpStatus.OK && result.data != null) {
               console.info(result.data);
               const response = result.data.map((it) => ({ ...it.cidCategory, amount: it.amount }));
               return Promise.resolve(response);
           } else if (result.status === HttpStatus.UNAUTHORIZED) {
               // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
               return Promise.reject({ status: 401 } as AxiosResponse);
           } else {
               return Promise.reject({ status: 400 } as AxiosResponse);
           }
       } catch (error) {
           console.error(error);
           return Promise.reject(error);
       }
   };   
   const getCids = async (categoryId: number): Promise<CidAmount[]> => {
       try {
           const result: AxiosResponse<CidAmount[]> = await cidApi.getCids(categoryId);
           console.log('result ', result);
           if (result.status === HttpStatus.OK && result.data != null) {
               console.info(result.data);
               const response = result.data;
               return Promise.resolve(response);
           } else if (result.status === HttpStatus.UNAUTHORIZED) {
               // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
               return Promise.reject({ status: 401 } as AxiosResponse);
           } else {
               return Promise.reject({ status: 400 } as AxiosResponse);
           }
       } catch (error) {
           console.error(error);
           return Promise.reject(error);
       }
   };  
    const getCidTree = async (cidId: number): Promise<CidTree> => {
       try {
           const result: AxiosResponse<CidTree> = await cidApi.getCidTree(cidId);
           if (result.status === HttpStatus.OK && result.data != null) {
               return Promise.resolve(result.data);
           } else if (result.status === HttpStatus.UNAUTHORIZED) {
               return Promise.reject({ status: 401 } as AxiosResponse);
           } else {
               return Promise.reject({ status: 400 } as AxiosResponse);
           }
       } catch (error) {
           console.error(error);
           return Promise.reject(error);
       }
   };
  return {
    getChapters,
    getGroups,
    getCategories,
    getCids,
    getCidTree
  }
}

export default CidService();
