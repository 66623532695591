import styled from 'styled-components';

export const CardContainer = styled.div<{isCheckpoint: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    position: relative;
    width: 25vw;
    min-height: 10vh;
    border: ${props => props.isCheckpoint ? '1.2px solid #EF7D00' : '1.2px solid #e9eced'};
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 80%;
    padding-left: 5%;
`;

export const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 20%;
    padding-right: 5%;
`;

export const SectionTitleContainer = styled.div`
    margin-bottom: 10px;
    margin-top: 15px;
`;

export const Title = styled.span`
    color: #414E52;
    font-family: Raleway,serif;
    font-size: 14px;
    font-weight: 500;
`;

export const Subtitle = styled.span`
    color: #A3B1B5;
    font-family: Raleway,serif;
    font-size: 12px;
    font-weight: 600;
    margin-top: 3px;
`;

export const QuantityText = styled.span<{color: string}>`
    color: ${props => props.color};
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway,serif;
    font-size: 13px;
    font-weight: 500;
`;

export const CompleteIcon = styled.div`
    height: 52px;
    width: 52px;
`;

export const PadlockIcon = styled.div`
    height: 32px;
    width: 32px;
`;