import { Checkbox } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import SwitchButton from '../../../components/switch-button/switch-button';
import AgeType from '../../../model/enums/ageType';
import { IRootState } from '../../../reducer';
import '../diagnapp.scss';

export interface AgeComponentProps extends RouteComponentProps<{}> {
    t: (path: string, params?: any) => string;
    onChangeInput: (value: number, type: AgeType, lessThanThirty?: boolean) => void;
    age?: number;
    ageType?: AgeType;
    lessThanThirtyDays?: boolean;
}

export interface AgeComponentState {
    ageType: AgeType;
    ageInput: string;
    lessThanThirty?: boolean;
}

export class AgeComponent extends React.Component<AgeComponentProps, AgeComponentState> {
    constructor(props: Readonly<AgeComponentProps>, context?: any) {
        super(props, context);

        this.state = {
            ageType: props.ageType || AgeType.YEARS,
            ageInput: props.age ? String(props.age) : '',
            lessThanThirty: props.lessThanThirtyDays || false

        };
    }

    // Handlers
    private onChangeAgeType = () => {
        this.setState({
            ageType: this.state.ageType === AgeType.YEARS ? AgeType.MONTHS : AgeType.YEARS
        }, () => {
            this.props.onChangeInput(Number(this.state.ageInput), this.state.ageType)
        });
    };
    private onKeyPress = (event): boolean => {
        return event.keycode > 48 && event.keycode < 57;
    }
    private onInputChange = (event): void => {
        const {
            target: { value }
        } = event;
        this.setState({
            ageInput: value
        });
        const regex = /^\d+$/;
        if (value != null && regex.test(value)) {
            this.props.onChangeInput(value, this.state.ageType);
        }
    };
    private onChangeCheckbox = (event): void => {
        const {
            target: { checked }
        } = event;
        this.setState({ ageInput: '', lessThanThirty: checked });
        this.props.onChangeInput(0, this.state.ageType, checked);
    }
    // Renders
    render() {
        const { ageType, ageInput, lessThanThirty } = this.state;
        const ageLabel: string = ageType === AgeType.YEARS ? this.props.t('diagnApp.ageComponent.years').toLowerCase() : this.props.t('diagnApp.ageComponent.months').toLowerCase()
        return (
            <div className="age-component">
                <p className="age-component__title">{this.props.t('diagnApp.ageComponent.title').toUpperCase()}</p>
                <SwitchButton
                    onPress={this.onChangeAgeType}
                    optionOneText={this.props.t('diagnApp.ageComponent.years')}
                    optionOneIcon={require('../../../images/person.svg')}
                    optionTwoText={this.props.t('diagnApp.ageComponent.months')}
                    optionTwoIcon={require('../../../images/baby.svg')}
                    optionSelected={ageType === AgeType.YEARS ? 0 : 1}
                />
                <p className="age-component__input-title">{this.props.t('diagnApp.ageComponent.inputTitle').toUpperCase()}</p>
                <div className="age-component__input-container">
                    <input value={ageInput} className="age-component__input" onKeyPress={this.onKeyPress} onChange={this.onInputChange} maxLength={3}/>
                    <p className="age-component__input-label">{ageLabel}</p>
                </div>
                <div className="age-component__checkbox">
                    <Checkbox onChange={this.onChangeCheckbox} checked={lessThanThirty}/>
                    <span className="age-component__checkbox__label">{this.props.t('diagnApp.ageComponent.checkboxLabel')}</span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({}: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(AgeComponent) as React.ComponentType<any>;
