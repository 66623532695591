import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Disease } from '../../../../model/disease';
import PediatricDoseInputType from '../../../../model/enums/pediatric-dose-input-type';
import { Pageable } from '../../../../model/pageable';
import DiseaseService from '../../../../services/diseaseService';
import '../../flashcard.scss';
import {
    BottomContainer,
    ButtonContainer,
    ButtonText,
    LineContainer,
    ListContainer,
    MainContainer, SearchInput,
    SelectedIcon,
    ThemeName,
    Title
} from './my-flashcard-theme-modal.styles';

interface Props extends WithTranslation {
    show: boolean;
    theme?: Disease;
    onClose: () => void;
    onPressConfirm: (disease?: Disease) => void;
}

const MyFlashcardThemeModal = (props: Props) => {
    const [theme, setTheme] = useState<Disease | undefined>(props.theme);
    const [themes, setThemes] = useState<Disease[]>([]);
    const [searchInput, setSearchInput] = useState<string | null>(null);
    const [page, setPage] = useState<Pageable>({ size: 20, number: 0, last: false });
    const { show, onClose, onPressConfirm } = props;

    useEffect(() => {
        DiseaseService.getSimpleDiseasesByName(page, searchInput ?? '').then(result => setThemes(result.content));
    }, []);

    useEffect(() => {
        if (searchInput !== null && searchInput !== '') {
            const delayDebounceFn = setTimeout(() => {
                const newPage = { size: 20, number: 0, last: false };
                DiseaseService.getSimpleDiseasesByName(newPage, searchInput ?? '').then(result => {
                    setThemes([...result.content]);
                    newPage.last = result.last;
                    setPage(newPage);
                });
            }, 500);

            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchInput]);


    const fetchData = () => {
        const newPage = { ...page, number: page.number! + 1 };
        DiseaseService.getSimpleDiseasesByName(newPage, searchInput ?? '').then(result => {
            setThemes([...themes, ...result.content]);
            newPage.last = result.last;
            setPage(newPage);
        });
    };

    const renderTheme = (disease: Disease) => {
        const isSelected = theme?.id === disease.id;
        return (
            <LineContainer isSelected={isSelected} onClick={() => setTheme(!isSelected ? disease : undefined)}>
                <SelectedIcon className={isSelected ? 'radio-on-img' : 'radio-off-img'} />
                <ThemeName>{disease.name}</ThemeName>
                <SelectedIcon />
            </LineContainer>
        );
    };

    return (
        <Modal
            open={show}
            onClose={() => onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <MainContainer>
                <Title>
                    {props.t('myFlashcardForm.themeModal.title')}
                </Title>

                <SearchInput placeholder={props.t('myFlashcardForm.themeModal.searchPlaceholder')} onChange={e => setSearchInput(e.target.value)} />
                <ListContainer id={'list-container'}>
                    <InfiniteScroll
                        next={fetchData}
                        loader={<p>{''}</p>}
                        endMessage={<p>{''}</p>}
                        dataLength={themes.length}
                        hasMore={page.last !== true}
                        scrollableTarget={'list-container'}
                    >
                        {themes.map(t => renderTheme(t))}
                    </InfiniteScroll>
                </ListContainer>

                <BottomContainer>
                    <ButtonContainer onClick={() => onPressConfirm(theme)}>
                        <ButtonText>
                            {props.t('myFlashcardForm.themeModal.select')}
                        </ButtonText>
                    </ButtonContainer>
                </BottomContainer>
            </MainContainer>
        </Modal>
    );
};

export default withTranslation()(MyFlashcardThemeModal);
