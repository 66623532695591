import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Row } from 'reactstrap';
import { compose } from 'redux';
import '../../App.scss';
import { IRootState } from '../../reducer';
import { logoutRequest } from '../../reducer/authentication/actions';

export interface ConstructionProps extends RouteComponentProps<{}> {
    logout: () => void;
    t: any;
}

export class Construction extends React.Component<ConstructionProps> {
    constructor(props: Readonly<ConstructionProps>, context?: any) {
        super(props, context);
    }

    logoutFunction = () => {
        this.props.history.push('/');
    };

    render() {
        return (
            <div className="page-container">
                <Row onClick={this.logoutFunction} className="justify-content-center">
                    <div className="message-box">
                        <div className="send-svg" />

                        <p>{'Está página ainda se encontra em fase de construção. Por favor, aguarde sua liberação.'}</p>
                    </div>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
    isAuthenticated: authentication.isAuthenticated,
    logout: logoutRequest
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Construction) as React.ComponentType<any>;
